import { logErrors } from '@core/utils/logErrors';

export const checkWeb3 = async () => {
	try {
		if (typeof window.ethereum !== 'undefined') {
			const accounts: string[] = await window.ethereum.request({
				method: 'eth_requestAccounts'
			});
			if (accounts.length) return true;
			logErrors('Please unlock your web3 provider (probably, Metamask)');
		} else {
			logErrors(
				`Web3 missing, please, <a href='https://metamask.io/download/' target='_blank' rel='noopener noreferrer'>install metamask extension</a>.`
			);
		}
	} catch (e) {
		logErrors(e);
	}
};

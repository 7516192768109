import React, { useState } from 'react';
import cn from 'classnames';

import { setReferralsTransactionThunk } from '@core/store/referral/referral.thunk';
import { cropAddress } from '@core/utils/helpers';
import { useAppDispatch } from '@core/utils/hooks';
import { CopiableLink } from '@components/DashboardComponents';
import { Button } from '@components/MainComponents/Button/Button';
import { ReferralCountList } from '@skeletons/referral/ReferrallCountList';

import styles from '@pages/Dashboard/Referrals/Referrals.module.scss';

export const ReferralsDetails = ({
	userAvatarSrc,
	username,
	nickname,
	referralCode,
	referralNumbers,
	referralBalance,
	isLoading
}: {
	userAvatarSrc: string;
	username: string;
	nickname: string;
	referralCode: string;
	referralNumbers: number;
	referralBalance: string;
	isLoading: boolean;
}) => {
	/** Redux hooks **/
	const dispatch = useAppDispatch();

	/** React hooks **/
	const [isWithdrawBtnLoading, setIsWithdrawBtnLoading] = useState(false);

	/** Handlers **/
	const handleWithdraw = async () => {
		setIsWithdrawBtnLoading(true);
		await dispatch(setReferralsTransactionThunk(referralBalance));
		setIsWithdrawBtnLoading(false);
	};

	return (
		<dl className={cn(styles.details)}>
			<div className={cn(styles.detail, styles.detailProfile)}>
				<img
					src={userAvatarSrc}
					alt='profile avatar'
					className={styles.detailProfileAvatar}
				/>
				<div className={styles.detailProfileData}>
					<p className={cn(styles.profileName, 'h6')}>{nickname}</p>
					<CopiableLink
						text={cropAddress(username, 6, 6)}
						copyText={username}
						url={`profile/${username}/items`}
						size='body-sm'
					/>
					<CopiableLink
						text={`https://zen.land/@${
							nickname || cropAddress(username, 6, 6)
						}`}
						copyText={`https://zen.land/@${
							nickname || cropAddress(username, 6, 6)
						}`}
						url={`profile/${nickname}/items`}
						size='body-sm'
					/>
				</div>
			</div>
			<div className={cn(styles.detail, styles.detailLink)}>
				<span>
					<dt className='body-md'>Referral link:</dt>
					<dd className='body'>
						<CopiableLink
							text={'zen.land/?invite=' + referralCode}
							asLink={false}
						/>
					</dd>
				</span>
				<span>
					<dt className='body-md'>Commission rate:</dt>
					<dd className='body'>50%</dd>
				</span>
			</div>
			<div className={cn(styles.detail, styles.detailReferrals)}>
				<span>
					<dt className='body-md'>Number of referrals:</dt>
					<dd className='body'>
						{isLoading ? <ReferralCountList /> : referralNumbers}
					</dd>
				</span>
				<span>
					<dt className='body-md'>Credit:</dt>
					<dd className='body'>{referralBalance}$</dd>
					<Button
						color='primary'
						size='small'
						variant='contained'
						className={styles.btnWithdraw}
						isLoader={isWithdrawBtnLoading}
						onClick={handleWithdraw}
					>
						Withdraw
					</Button>
				</span>
			</div>
		</dl>
	);
};

import React from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import { removeAlertAction } from '@core/store/alert/alert.slice';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Button } from '@components/index';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { AlertProps } from './Alert.props';

import styles from './Alert.module.scss';

export const Alert = ({ className }: AlertProps) => {
	/* Redux hooks */
	const alerts = useAppSelector(({ alert }) => alert);
	const dispatch = useAppDispatch();

	/* Handlers */
	const handleClick = (id: string) => () => {
		dispatch(removeAlertAction(id));
	};

	const renderNotifications = () => {
		return alerts.map(item => (
			<div
				key={item.id}
				className={cn(
					styles.alert,
					className,
					styles[item.type],
					{
						[styles.active]: item.id
					},
					'body-sm'
				)}
				aria-live='polite'
				aria-atomic='true'
			>
				{item.type === 'error' ? (
					<SvgSprite
						className={styles.alertIcon}
						iconId='status-warning-outlined-triangle'
						color='#fd3f3f'
					/>
				) : (
					<SvgSprite
						className={styles.alertIcon}
						iconId='status-success-outlined'
					/>
				)}
				<p className={styles.content}>{parse(item.text)}</p>
				{item.isSubmit && (
					<Button
						className={styles.btn}
						onClick={handleClick(item.id)}
						type='button'
					>
						<SvgSprite iconId='cross' size={20} />
					</Button>
				)}
			</div>
		));
	};

	return (
		<div
			className={cn(styles.wrapper, {
				[styles.active]: alerts.length
			})}
		>
			{renderNotifications()}
		</div>
	);
};

import React from 'react';

import styles from './LoginNotificate.module.scss';

export const LoginNotificate = ({
	children,
	display = true
}: {
	children: JSX.Element;
	display?: boolean;
}) => {
	return display ? (
		<div className={styles.loginNotificate}>{children}</div>
	) : (
		<div />
	);
};

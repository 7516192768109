import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';

interface CountdownProps {
	date: string | number | Date;
	format?: string;
	interval?: number;
	expiredContent?: JSX.Element | string;
}

export const Countdown = ({
	date,
	format = 'd:h:m:s',
	interval = 1000,
	expiredContent = ''
}: CountdownProps) => {
	// More info: https://github.com/jsmreese/moment-duration-format#template
	const removeNegativeSign = (t: string) => {
		if (t.charAt(0) === '-') return t.replace('-', '');
		return t;
	};

	if (moment(date).isAfter(Date.now())) {
		return (
			<Moment
				interval={interval}
				date={date}
				filter={removeNegativeSign}
				format={format}
				durationFromNow
				trim
			/>
		);
	}

	return <>{expiredContent}</>;
};

import { WS } from '@core/config/webSocket';
import { ChatCommand, WSReadyState } from '@core/models/enums';
import { WSChatResponse } from '@core/models/interfaces';
import {
	EventsNames,
	MessageSubscriberType,
	StatusSubscriberType
} from '@core/websocket/types';

let ws: WebSocket | null = null;

const subscribers: {
	message: MessageSubscriberType<WSChatResponse>[];
	status: StatusSubscriberType[];
} = {
	message: [],
	status: []
};
let roomId: number | null = null;

const openHandler = () => {
	console.log(
		'%c Chat Websocket opened',
		'color:#3c97ff;border:1px solid dodgerblue;padding-right:5px'
	);

	notifySubscribersAboutStatus(WSReadyState.Open);

	ws?.send(
		JSON.stringify({
			command: ChatCommand.GetNewMessageCount,
			offset: 0,
			cutoff: 100
		})
	);
};

const closeHandler = (e: CloseEvent) => {
	console.log(
		`%c Chat Websocket closed with code ${e.code}`,
		'color:red;border:1px solid dodgerblue;padding-right:5px'
	);
	notifySubscribersAboutStatus(WSReadyState.Closed);

	subscribers.message = [];
	subscribers.status = [];
	// See https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1
	if ((e.code === 1006 || e.code === 1009) && roomId) {
		console.log(
			'%c Chat Websocket reconnecting in 5 seconds...',
			'color:#ffa200;border:1px solid dodgerblue;padding-right:5px'
		);
		setTimeout(() => {
			if (roomId) ChatSocket.start(roomId);
		}, 5000);
	}
};

const messageHandler = (e: MessageEvent) => {
	const newMessages: WSChatResponse = JSON.parse(e.data);
	subscribers.message.forEach(subscriber => subscriber(newMessages));
};

const errorHandler = () => {
	console.log(
		'%c Chat Websocket error',
		'color:red;border:1px solid dodgerblue;padding-right:5px'
	);
	notifySubscribersAboutStatus(WSReadyState.Error);
};

const notifySubscribersAboutStatus = (status: WSReadyState) => {
	subscribers.status.forEach(subscriber => subscriber(status));
};

const createChannel = (id: number) => {
	roomId = id ? id : roomId;
	ws?.close();
	ws = new WebSocket(`${WS.chat}${roomId}/`);
	notifySubscribersAboutStatus(WSReadyState.Connecting);

	ws.onopen = openHandler;
	ws.onclose = closeHandler;
	ws.onmessage = messageHandler;
	ws.onerror = errorHandler;
};

export const ChatSocket = {
	start(roomId: number) {
		createChannel(roomId);
	},
	stop() {
		ws?.close();
	},
	fetchMessages(offset = 0, cutoff = 100) {
		ws?.send(
			JSON.stringify({
				command: ChatCommand.FetchMessages,
				offset,
				cutoff
			})
		);
	},
	sendMessage(message: string) {
		ws?.send(
			JSON.stringify({
				message: message,
				command: ChatCommand.NewMessage
			})
		);
	},

	markAsRead(ids: number[]) {
		ws?.send(
			JSON.stringify({
				command: ChatCommand.MarkAsRead,
				ids
			})
		);
	},
	getNewMessagesCount() {
		ws?.send(
			JSON.stringify({
				command: ChatCommand.GetNewMessageCount
			})
		);
	},
	subscribe(
		eventName: EventsNames,
		cb: MessageSubscriberType<WSChatResponse> | StatusSubscriberType
	) {
		switch (eventName) {
			case 'message':
				subscribers.message.push(cb as MessageSubscriberType<WSChatResponse>);
				return () => {
					subscribers.message = subscribers.message.filter(
						subscriber => subscriber !== cb
					);
				};
			case 'status':
				subscribers.status.push(cb as StatusSubscriberType);
				return () => {
					subscribers.status = subscribers.status.filter(
						subscriber => subscriber !== cb
					);
				};
			default:
				break;
		}
	},
	unsubscribe(
		eventName: EventsNames,
		cb: MessageSubscriberType<WSChatResponse> | StatusSubscriberType
	) {
		switch (eventName) {
			case 'message':
				subscribers.message = subscribers.message.filter(
					subscriber => subscriber !== cb
				);
				break;
			case 'status':
				subscribers.status = subscribers.status.filter(
					subscriber => subscriber !== cb
				);
				break;
			default:
				break;
		}
	}
};

import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';

import { cropAddress } from '@core/utils/helpers';
import { DialogsListProps } from '@pages/Dashboard/Chats/Chats.props';
import { Badge, SvgSprite } from '@components/index';
import { DialogSkeleton } from '@skeletons/chat/dialog/DialogSkeleton';

import styles from './Dialog.module.scss';

export const DialogsList = ({
	rooms,
	isLoading
}: {
	rooms: DialogsListProps[] | null;
	isLoading: boolean;
}) => {
	const renderDialogs = () => {
		return rooms?.map((room: DialogsListProps) => (
			<li key={room.roomId}>
				<NavLink
					className={styles.companionLink}
					activeClassName={styles.active}
					to={`/chats/${room.roomId}`}
				>
					<div className={styles.companionImgWrapper}>
						<img src={room.member.avatar} alt='avatar' />
					</div>
					<div className={styles.companionInfo}>
						<span className={cn(styles.companionName, 'h6')}>
							{room.member.nickname || cropAddress(room.member.username, 5, 3)}
						</span>
						<p className={cn(styles.companionMsg, 'body')}>
							{room.lastActivity ? room.lastActivity.last_message : ''}
						</p>
						<div className={styles.companionMsgInfo}>
							<div className={styles.companionMsgTimeWrapper}>
								{room.lastActivity?.is_message_owner &&
									(room.lastActivity?.last_message_is_unread ? (
										<SvgSprite size={20} color='#2D9CDB' iconId='checked' />
									) : (
										<SvgSprite
											size={20}
											color='#2D9CDB'
											iconId='doublechecked'
										/>
									))}
								<span className={cn(styles.companionMsgTime, 'caption')}>
									{room.lastActivity &&
										(moment(room.lastActivity.last_message_timestamp).isSame(
											moment(),
											'day'
										) ? (
											<Moment format='HH:mm'>
												{room.lastActivity.last_message_timestamp}
											</Moment>
										) : (
											<Moment format='MM/DD/YYYY'>
												{room.lastActivity.last_message_timestamp}
											</Moment>
										))}
								</span>
							</div>
							{room.lastActivity && room.lastActivity.unread_count > 0 && (
								<span className={cn(styles.companionMsgCount, 'body-md')}>
									{room.lastActivity.unread_count}
								</span>
							)}
						</div>
					</div>
				</NavLink>
			</li>
		));
	};

	if (!rooms && isLoading) return <DialogSkeleton />;
	if (!rooms?.length && !isLoading)
		return (
			<Badge color='await' className={styles.informBadge}>
				Sorry you don't have any dialogs
			</Badge>
		);

	return <ul className={styles.chatsList}>{renderDialogs()}</ul>;
};

import { API } from '@core/config/api';
import {
	IUserRating,
	IUserRatingGetParams,
	IUserRatingGetResponse
} from '@core/models/interfaces';
import { IUserRatingPostParams } from '@core/services/users/models';
import { getSearchParams } from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const UserRatings = {
	async getAll(
		queryParams?: Partial<IUserRatingGetParams>
	): Promise<IUserRatingGetResponse | void> {
		let params: URLSearchParams | string = '';
		if (queryParams) params = new URLSearchParams(getSearchParams(queryParams));

		try {
			const response = await fetch(`${API.userRatings}?${params}`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},

	async post(body: IUserRatingPostParams): Promise<IUserRating | void> {
		try {
			const response = await fetch(API.userRatings, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				body: JSON.stringify(body)
			});
			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

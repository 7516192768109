import React from 'react';
import cn from 'classnames';

import { ProgressBarProps } from './ProgressBar.props';

import styles from './ProgressBar.module.scss';

export const ProgressBar = ({
	percent,
	height = 12,
	color = '#FFBB16',
	className,
	...props
}: ProgressBarProps) => {
	return (
		<div className={cn(styles.border, className)} {...props}>
			<div
				className={styles.progress}
				role='progressbar'
				aria-valuenow={percent}
				aria-valuemin={0}
				aria-valuemax={100}
				style={{ width: `${percent}%`, height: height, backgroundColor: color }}
			>
				<span className='srOnly'>{percent}%</span>
			</div>
		</div>
	);
};

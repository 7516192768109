import React, { useState } from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';
import Moment from 'react-moment';

import { Author, Button, Rating, SvgSprite } from '@components/index';

import { CommentProps } from './Comment.props';

import styles from './Comment.module.scss';

export const Comment = ({ comment }: CommentProps) => {
	let isNeedCrop = false;
	if (comment.text) isNeedCrop = comment.text.length > 1111;

	const croppedHeight = isNeedCrop ? 95 : 'auto';

	/* React hooks */
	const [isCropped, setIsCropped] = useState(isNeedCrop);
	const [height, setHeight] = useState<string | number>(croppedHeight);

	/* Handlers */
	const handleShowClick = () => {
		setHeight(isCropped ? 'auto' : croppedHeight);
		setIsCropped(!isCropped);
	};

	return (
		<article className={styles.comment}>
			<Author
				avatar={comment.author.avatar}
				nickname={comment.author.nickname}
				username={comment.author.username}
				role={comment.author.role}
				isLink
			/>
			<p className={cn('body-sm', styles.commentInfo)}>
				Reviewed on <Moment format='MMMM DD, YYYY'>{comment.created}</Moment>
			</p>
			<div className={styles.titleWrapper}>
				<Rating size='sm' rating={comment.score} />
			</div>
			<AnimateHeight
				duration={500}
				height={height}
				className={cn({ [styles.commentShadow]: isCropped })}
			>
				<p className={cn('body', styles.content)}>{comment.text}</p>
			</AnimateHeight>
			<footer>
				{isNeedCrop && (
					<Button
						onClick={handleShowClick}
						type='button'
						className={styles.moreBtn}
					>
						<SvgSprite
							iconId='arrow-down'
							className={cn(styles.chevronIcon, {
								[styles.active]: !isCropped
							})}
						/>
						Read {isCropped ? 'more' : 'less'}
					</Button>
				)}
			</footer>
		</article>
	);
};

import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { ContractRole } from '@core/models/enums';
import {
	receiveNetworksThunk,
	receiveWhoAmIThunk,
	refreshPageThunk
} from '@core/store/auth/auth.thunks';
import { getCookie } from '@core/utils/helpers/getCookie';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Referrals } from '@pages/Dashboard/Referrals/Referrals';
import {
	Chats,
	ContractLayout,
	Contracts,
	ItemLayout,
	Items,
	Notifications,
	NotificationsSettings,
	Profile,
	ProfileSettings,
	Wallet
} from '@pages/index';
import { NotFound } from '@pages/NotFound/NotFound';
import { NewContractForm } from '@components/DashboardComponents';
import { NewItemContractForm } from '@components/DashboardComponents/Forms/NewItemContractForm';
import { NewItemForm } from '@components/DashboardComponents/Forms/NewItemForm';

export const DashboardRoutes = () => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const auth = useAppSelector(({ auth }) => auth);

	/* Router hooks */
	const { pathname } = useLocation();

	/* Functions */
	const isAuthenticated =
		(!pathname.includes('/profile/') || !pathname.includes('/items/')) &&
		getCookie('is_authenticated').length;

	/* React hooks */
	useEffect(() => {
		if (isAuthenticated || auth.isLogged) {
			dispatch(refreshPageThunk());
			dispatch(receiveNetworksThunk());
			if (!auth.whoAmI.username) dispatch(receiveWhoAmIThunk());
		}
	}, [auth.isLogged, dispatch]);

	if (
		auth.role === ContractRole.Agent ||
		auth.role === ContractRole.Zen_Agent
	) {
		return (
			<Switch>
				<Route path='/contracts/:id' component={ContractLayout} />
				<Route path='/chats/:id' component={Chats} />
				<Route path='/chats' component={Chats} />
				<Route path='/notifications' component={Notifications} />
				<Route path='/profile/:id' component={Profile} />
				<Route
					path='/settings/notifications'
					component={NotificationsSettings}
				/>
				<Route path='/settings/profile' component={ProfileSettings} />
				<Route path='/referrals/*' component={Referrals} />
				<Route path='/referrals' component={Referrals} />
				<Route path='/wallet' component={Wallet} />
				<Route path='/' component={Contracts} />
			</Switch>
		);
	}

	return (
		<Switch>
			<Route path='/contracts/:id' component={ContractLayout} />
			<Route path='/contracts' component={Contracts} />
			<Route path='/chats/new/:companion' component={Chats} />
			<Route path='/chats/:id' component={Chats} />
			<Route path='/chats' component={Chats} />
			<Route
				exact
				path='/profile/:id/items/:slug/create'
				component={NewItemContractForm}
			/>
			<Route path='/profile/:id' component={Profile} />
			<Route path='/wallet' component={Wallet} />
			<Route path='/notifications' component={Notifications} />
			<Route path='/referrals/*' component={Referrals} />
			<Route path='/referrals' component={Referrals} />
			<Route path='/settings/notifications' component={NotificationsSettings} />
			<Route path='/settings/profile' component={ProfileSettings} />
			<Route path='/items/:slug' component={ItemLayout} />
			<Route path='/items' component={Items} />
			<Route path='/item/new' component={NewItemForm} />
			<Route path='/item/*' component={NewItemForm} />
			<Route path='/item' component={NewItemForm} />
			<Route path='/new' component={NewContractForm} />
			<Route exact path='/' component={NewContractForm} />
			<Route component={NotFound} />
		</Switch>
	);
};

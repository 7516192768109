import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	IChat,
	IChatLastActivity,
	IChatRoom
} from '@core/store/chat/chat.types';

const initialState: IChat = {
	rooms: { data: null, isLoading: true },
	searchCompanion: '',
	foundRoom: null
};

const chatSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setChatRooms: (state, action: PayloadAction<IChatRoom[] | null>) => {
			if (action.payload) {
				if (state.rooms.data) {
					const existRoomsId = state.rooms.data.map(room => room.id);
					state.rooms.data = [
						...state.rooms.data,
						...action.payload.filter(room => !existRoomsId.includes(room.id))
					];
				} else state.rooms.data = action.payload;
			} else state.rooms.data = action.payload;
			state.rooms.isLoading = false;
		},
		setChatRoom: (state, action: PayloadAction<IChatRoom>) => {
			if (state.rooms.data) {
				const index = state.rooms.data.findIndex(
					room => room.id === action.payload.id
				);
				if (index < 0) state.rooms.data = [action.payload, ...state.rooms.data];
				else {
					return {
						...state,
						rooms: {
							...state.rooms,
							data: [
								action.payload,
								...state.rooms.data.filter(
									room => room.id !== action.payload.id
								)
							]
						}
					};
				}
			} else state.rooms.data = [action.payload];
		},
		setChatRoomActivity: (
			state,
			action: PayloadAction<{ id: number; activity: IChatLastActivity }>
		) => {
			return {
				...state,
				rooms: {
					...state.rooms,
					data:
						state.rooms.data?.map(room => {
							if (room.id === action.payload.id)
								return { ...room, last_activity: action.payload.activity };
							return room;
						}) || null
				}
			};
		},
		setRoomsLoading: (state, action: PayloadAction<boolean>) => {
			state.rooms.isLoading = action.payload;
		},
		setSearchCompanion: (state, action: PayloadAction<string | undefined>) => {
			if (action.payload && action.payload.length)
				state.searchCompanion = action.payload;
			else state.searchCompanion = null;
		},
		setFoundChatRoom: (state, action: PayloadAction<IChatRoom | null>) => {
			state.foundRoom = action.payload;
		}
	}
});

export const {
	actions: {
		setChatRooms,
		setFoundChatRoom,
		setChatRoom,
		setChatRoomActivity,
		setSearchCompanion,
		setRoomsLoading
	},
	reducer: chatReducer
} = chatSlice;

import { INetwork } from '@core/models/interfaces';

export const getZenfAllowedNetworks = (
	networks: INetwork[] | null,
	allowedNetworks: Record<string, number> | undefined
) => {
	const allowedNetworksChainId =
		allowedNetworks && Object.keys(allowedNetworks);
	if (allowedNetworksChainId && networks) {
		return networks
			.filter(n => {
				if (allowedNetworksChainId.includes(n.chain_id)) return n;
			})
			.map(n => ({ name: n.display_name, value: n.chain_id }));
	}
};

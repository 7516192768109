import { ChangeNetworks } from './models';

export const changeNetworkWeb3: ChangeNetworks = async ({
	chainId,
	chainName,
	rpcUrls,
	blockExplorerUrls,
	symbol,
	decimals
}) => {
	try {
		await window.ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId }]
		});
		return true;
	} catch (e) {
		// This error code indicates that the chain has not been added to MetaMask.
		if (e.code === 4902 || e.code === -32603) {
			try {
				await window.ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [
						{
							chainId,
							chainName,
							rpcUrls,
							blockExplorerUrls,
							nativeCurrency: { symbol, decimals }
						}
					]
				});
				return true;
			} catch (addError) {
				console.log(addError);
			}
		}
		console.log(e);
	}
};

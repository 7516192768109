import { AbiItem } from 'web3-utils';

import { IContract } from '@core/models/interfaces';
import { web3 } from '@core/services';
import { toHex } from '@core/utils/helpers';

export const getEstimateGas = async (
	abi: AbiItem | AbiItem[],
	bytecode: string,
	contract: IContract,
	agent: string
) => {
	const myContract = new web3.eth.Contract(abi);
	return await myContract
		.deploy({
			data: bytecode,
			arguments: [
				web3.utils.toChecksumAddress(contract.buyer.username),
				web3.utils.toChecksumAddress(contract.seller.username),
				web3.utils.toChecksumAddress(agent),
				contract.token.contract_address,
				toHex(+contract.price * 10 ** contract.token.decimals),
				contract.buyer_protection_time
			]
		})
		.estimateGas(
			{ from: web3.utils.toChecksumAddress(contract.buyer.username) },
			(err: Error, gas: number) => {
				return gas;
			}
		);
};

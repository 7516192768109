import { useEffect, useState } from 'react';
import cn from 'classnames';

import { INetwork } from '@core/models/interfaces';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setChainIdAction } from '@core/store/auth/auth.slice';
import { changeNetworkThunk } from '@core/store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { useNetworkChange } from '@core/utils/hooks/useNetworkChange';
import { logErrors } from '@core/utils/logErrors';
import { Select } from '@components/index';

import styles from './Header.module.scss';

export const NetworkList = () => {
	/*** Redux hooks ***/
	const dispatch = useAppDispatch();
	const auth = useAppSelector(({ auth }) => auth);

	/*** React hooks ***/
	const [isEditable, setIsEditable] = useState(false);
	const [network, setNetwork] = useState({
		name: 'Choose the network',
		value: ''
	});

	useEffect(() => {
		if (!auth.networks?.length || !auth.chain_id) return;

		const foundNetwork = auth.networks.find(n => {
			return n.chain_id.toLowerCase() === auth.chain_id.toLowerCase();
		});

		if (foundNetwork) {
			setNetwork({
				name: foundNetwork.display_name,
				value: foundNetwork.chain_id
			});
			dispatch(changeNetworkThunk(foundNetwork));
		} else {
			dispatch(
				addAlert({
					text: 'Your current network is not listed on our site, please switch to a network that is on our list',
					type: 'error',
					isSubmit: true
				})
			);
			setNetwork({
				name: 'Choose the network',
				value: ''
			});
		}
	}, [auth.networks, auth.chain_id]);

	useEffect(() => {
		try {
			window.ethereum?.on('chainChanged', async (chainId: string) => {
				dispatch(setChainIdAction(chainId));
				if (
					auth.networks?.findIndex(
						network => network.chain_id.toLowerCase() === chainId.toLowerCase()
					) === -1
				) {
					setNetwork({
						name: 'Choose the network',
						value: ''
					});

					dispatch(
						addAlert({
							text: 'Your current network is not listed on our site, please switch to a network that is on our list',
							type: 'error',
							isSubmit: true
						})
					);
				}
			});
		} catch (e) {
			logErrors(e);
		}
	}, [auth.networks]);

	const handleNetworkChange = (network: { name: string; value: string }) => {
		useNetworkChange(auth.networks, network, setIsEditable);
	};

	const renderNetworks = (networks: INetwork[]) => {
		return networks.map(n => ({
			name: n.display_name,
			value: n.chain_id
		}));
	};

	return auth.networks?.length ? (
		<Select
			setOption={handleNetworkChange}
			options={renderNetworks(auth.networks)}
			defaultOption={network}
			className={cn(styles.networkSelect, {
				[styles.unknown]: !network.value
			})}
			size='lg'
			id='networks'
			isEditable={isEditable}
		/>
	) : null;
};

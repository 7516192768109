import React, { useEffect, useMemo, useState } from 'react';

import {
	ContractRole,
	ContractStatusName,
	ContractStatusNumber,
	RatingRole
} from '@core/models/enums';
import { IUserRating } from '@core/models/interfaces';
import { UserRatings } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { getFilteredRatings } from '@core/utils/helpers/getFilteredRatings';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { RatingForm } from '@components/DashboardComponents';
import {
	Author,
	Badge,
	Button,
	Comment,
	Rating,
	SvgSprite
} from '@components/index';

import { StepContainer } from '../StepContainer';
import { StepProps } from '../Steps.props';

import styles from '../Steps.module.scss';

// TODO: need to add loader logic of button
export const ReviewContract = ({ contract, auth }: StepProps) => {
	const { whoAmI, maintenance } = auth;

	/** Redux hooks **/
	const dispatch = useAppDispatch();
	const user = useAppSelector(({ auth }) => auth.whoAmI);

	/** React hooks **/
	const oppositeUser = useMemo(() => {
		return auth.role === ContractRole.Seller ? contract.buyer : contract.seller;
	}, [auth.role, contract.id]);

	// Filter only Seller and Buyer ratings
	const [ratings, setRatings] = useState(
		contract.ratings.filter(r => r.reviewed_user_role !== RatingRole.Agent)
	);
	const [reviews, setReviews] = useState<{
		list: IUserRating[];
		mine: { text: string; score: number };
	}>({
		list: [],
		mine: { text: '', score: 0 }
	});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [hasOwnComment, setHasOwnComment] = useState(false);
	const [isEditable, setIsEditable] = useState(
		ContractStatusNumber[contract.status] >= ContractStatusNumber.executed &&
			ratings.length === 0
	);

	useEffect(() => {
		setRatings(
			contract.ratings.filter(r => r.reviewed_user_role !== RatingRole.Agent)
		);
	}, [contract.ratings]);

	useEffect(() => {
		if (ratings.length) {
			const { Reviews, IsEditable, HasOwnComment } = getFilteredRatings(
				ratings,
				whoAmI.username
			);

			setIsEditable(IsEditable);
			setHasOwnComment(HasOwnComment);
			setReviews(Reviews);
		} else {
			setIsEditable(
				ContractStatusNumber[contract.status] >= ContractStatusNumber.executed
			);
		}
	}, [ratings, contract.status, whoAmI]);

	/** Handlers **/
	const onSubmit = async (value: string) => {
		if (!reviews.mine) return;
		if (reviews.mine.score < 1) {
			return dispatch(
				addAlert({
					text: 'Please rate the contract',
					isSubmit: true,
					type: 'error'
				})
			);
		}

		const res = await UserRatings.post({
			model_name: 'user',
			object_id: oppositeUser.id,
			related_object_model_name: 'contract',
			related_object_object_id: contract.id,
			score: reviews.mine.score,
			text: value
		});

		if (res && res.score) {
			setIsSubmitted(true);
			setIsEditable(false);
			setHasOwnComment(true);
			setReviews(prevState => ({
				...prevState,
				mine: { score: res.score, text: res.text || reviews.mine.text }
			}));
			if (reviews) {
				const index = reviews.list.findIndex(r => r.id === res.id);
				if (index === -1) {
					setReviews({ list: [res, ...reviews.list], mine: reviews.mine });
				} else {
					setReviews({
						...reviews,
						list: [...reviews.list.filter(r => r.id !== res.id), res]
					});
				}
			}
		}
	};

	const handleClickEdit = () => setIsEditable(!isEditable);
	const isExecuted =
		ContractStatusNumber[contract.status] >= ContractStatusNumber.executed;

	const renderReviews = () => {
		return reviews.list.map(item => <Comment key={item.id} comment={item} />);
	};

	const showInverseRole = () => {
		if (isExecuted) {
			return auth.role === ContractRole.Seller
				? ' ' + ContractRole.Buyer
				: ' ' + ContractRole.Seller;
		}
		return '';
	};

	return (
		<div id='review'>
			<StepContainer
				stepStatus={ContractStatusName.Executed}
				contractStatus={contract.status}
				title={'Review' + showInverseRole()}
			>
				<>
					{isSubmitted && (
						<Badge color='success' hasIcon className={styles.reviewSuccessText}>
							The review has been sent. Thank you!
						</Badge>
					)}

					<div className={styles.reviewAuthorWrapper}>
						{isExecuted ? (
							<>
								<Rating
									rating={reviews.mine.score}
									setRating={val =>
										setReviews({
											...reviews,
											mine: { ...reviews.mine, score: val }
										})
									}
									isEditable={isEditable}
								/>
								{hasOwnComment && (
									<Button
										onClick={handleClickEdit}
										type='button'
										className={styles.editBtn}
										title='Edit'
										disabled={maintenance.isActive}
									>
										<SvgSprite iconId='pencil' />
									</Button>
								)}
							</>
						) : (
							<Badge text='sm' color='warn' hasIcon>
								Only executed contracts can be reviewed.
							</Badge>
						)}
						{isEditable && (
							<Author
								isLink
								nickname={user.nickname || 'Anonymous'}
								username={auth.whoAmI.username}
								avatar={user.avatar}
								className={styles.reviewAuthor}
							/>
						)}
					</div>
					{isEditable && (
						<RatingForm
							setReviews={setReviews}
							initValue={reviews.mine.text}
							onSubmit={onSubmit}
						/>
					)}
					{reviews && reviews.list.length > 0 && (
						<div className={styles.commentWrapper}>{renderReviews()}</div>
					)}
				</>
			</StepContainer>
		</div>
	);
};

import { INetwork, IToken } from '@core/models/interfaces';
import { addAlert } from '@core/store/alert/alert.thunks';
import { store } from '@core/store/store';

interface IcheckIsAvailableTokenChecked {
	selectedTokens: number[];
	checkAvailableTokens: (
		networks: INetwork[],
		tokens: IToken[],
		availableNetworks: (string | number)[],
		availableTokens: (string | number)[]
	) => void;
	isHasUncheckedToken: () => number;
	clearSelectedTokens: () => void;
}

export const checkIsAvailableTokenChecked: IcheckIsAvailableTokenChecked = {
	selectedTokens: [],
	checkAvailableTokens: function (
		networks,
		tokens,
		availableNetworks,
		availableTokens
	) {
		const availableTokensWithNetworkId = tokens
			.filter(token => availableNetworks.includes(token.network.chain_id))
			.map(token => ({
				id: token.id,
				networkId: token.network.chain_id
			}));

		availableNetworks.forEach(available_network => {
			const currentNetworkTokens = availableTokensWithNetworkId.filter(
				availableTokenWithNetworkId =>
					availableTokenWithNetworkId.networkId === available_network
			);

			const index = currentNetworkTokens.findIndex(currentNetworkToken =>
				availableTokens.includes(currentNetworkToken.id)
			);

			this.selectedTokens.push(index);
			if (index < 0) {
				const network = networks.find(
					network => network.chain_id === available_network
				);
				if (network)
					return store.dispatch(
						addAlert({
							text: `Please choose ${network.display_name} tokens or mark this network unchecked`,
							type: 'error'
						})
					);
			}
		});
	},
	isHasUncheckedToken: function () {
		return this.selectedTokens.findIndex(selectedToken => selectedToken < 0);
	},
	clearSelectedTokens: function () {
		this.selectedTokens = [];
	}
};

import Web3 from 'web3';

import { Login, Signup } from '@core/services';
import { checkWeb3, getChainId, getWeb3Account } from '@core/utils/helpers';
import { activateMetaMaskProvider } from '@core/utils/helpers/web3/activateMetaMaskProvider';
import { logErrors } from '@core/utils/logErrors';

import { LoginWithSignature, Web3Login } from './models';
export const provider = Web3.givenProvider || 'ws://localhost:8545';

export const web3 = new Web3(activateMetaMaskProvider(provider));

export const loginWithSignature: LoginWithSignature = async () => {
	const data = await web3Login();
	if (!data) return;

	const referralCode = localStorage.getItem('referralCode') || undefined;

	const { address, message, signature } = data;
	const addressLowerCase = address.toLowerCase();
	const chain_id = await getChainId();

	const isLogged = await Login.post(
		{
			address: addressLowerCase,
			message,
			signature
		},
		true
	);

	if (isLogged) {
		return {
			registered: false,
			logged: isLogged,
			address: addressLowerCase,
			chain_id
		};
	}

	const isSignedUp = await Signup.post({
		address: addressLowerCase,
		referralCode
	});
	if (isSignedUp) {
		return {
			registered: isSignedUp,
			logged: false,
			address: addressLowerCase,
			chain_id
		};
	}
};

export const web3Login: Web3Login = async () => {
	if (!activateMetaMaskProvider(provider)) {
		logErrors(
			"Please use only MetaMask. Ensure it's activated and other web3 wallets are deactivated or not present."
		);
		return;
	}
	const hasWeb3 = await checkWeb3();
	const token = await Login.get(); // ! TOKEN
	const address = await getWeb3Account(); // ! ADDRESS

	if (!token || !address || !hasWeb3) return;

	try {
		const message = `Welcome to Zenland!
Click "Sign" to sign in. No password needed!
This request will not trigger a blockchain transaction or cost any gas fees.
By signing in you accept Zenland Terms of Service: https://zen.land/terms-of-service/

Your Address:
${address}

Nonce:
${token}`;
		const hashMessage = web3.utils.fromUtf8(message);

		const signature: string = await window.ethereum.request({
			method: 'personal_sign',
			params: [address, hashMessage]
		}); // ! SIGNATURE
		return { address, message, signature };
	} catch (e) {
		logErrors(e);
	}
};

export enum Coins {
	Ethereum = 'ethereum',
	Usdc = 'usdc',
	Dai = 'dai',
	BitcoinCash = 'bitcoincash',
	Dogecoin = 'dogecoin',
	Litecoin = 'litecoin',
	Bitcoin = 'bitcoin',
	Zenf = 'zenf'
}

export enum WalletType {
	Debit = 'debit',
	Credit = 'credit'
}

export enum WalletStatus {
	Created = 'created',
	Pending = 'pending',
	Confirmed = 'confirmed',
	Failed = 'failed'
}

import { API } from '@core/config/api';
import { FetchOptions } from '@core/models/interfaces';
import {
	TransactionsGetParams,
	TransactionsPostParams
} from '@core/services/transactions/models';
import { ITransactions } from '@core/store/transaction/transaction.types';
import { getCookie } from '@core/utils/helpers/getCookie';
import { getQueryParams } from '@core/utils/helpers/types/getQueryParams';
import { logErrors } from '@core/utils/logErrors';

export const Transactions = {
	// TODO: if you need to get multiple response need to fix result
	async get(
		body: TransactionsGetParams,
		options?: FetchOptions
	): Promise<ITransactions | void> {
		const params = getQueryParams(body);

		try {
			const response = await fetch(`${API.transactions}?${params}`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});
			if (!response.ok) return logErrors(await response.json());
			const { results } = await response.json();
			return results[0];
		} catch (e) {
			logErrors(e);
		}
	},
	async post(body: TransactionsPostParams): Promise<ITransactions | void> {
		const payload = {
			address: body.address,
			contract: body.contractId,
			type: body.type,
			tx_hash: body.txHash
		};
		try {
			const response = await fetch(API.transactions, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				body: JSON.stringify(
					body.message ? { ...payload, message: body.message } : payload
				)
			});
			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './TableSkeleton.module.scss';

export const ContractSkeleton = () => (
	<ContentLoader
		preserveAspectRatio='none'
		backgroundColor='#e3e9ef'
		foregroundColor='#fff'
		width='100%'
		height={1000}
		speed={2}
	>
		<rect
			x='0'
			y='0'
			rx='16'
			ry='16'
			height='56'
			className={styles.rectSwitcher}
		/>
		<rect x='0' y='72' rx='24' ry='24' width='100%' height='446' />
		<rect x='0' y='534' rx='24' ry='24' width='100%' height='80' />
		<rect x='0' y='630' rx='24' ry='24' width='100%' height='80' />
		<rect x='0' y='726' rx='24' ry='24' width='100%' height='80' />
		<rect x='0' y='822' rx='24' ry='24' width='100%' height='80' />
		<rect x='0' y='918' rx='24' ry='24' width='100%' height='80' />
	</ContentLoader>
);

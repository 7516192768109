import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';

import { useOnClickOutside } from '@core/utils/hooks/useOnClickOutside';
import { Button, SvgSprite } from '@components/index';

import { OptionProps, SelectProps } from './Select.props';

import styles from './Select.module.scss';

export const Select = <OptionValueType extends string | number = string>({
	options,
	defaultOption,
	setOption,
	className,
	bg = 'primary',
	color = 'active',
	size = 'md',
	listStartSide = 'start',
	isEditable = false,
	id,
	...props
}: SelectProps<OptionValueType>) => {
	/* React hooks */
	const [currentOption, setCurrentOption] = useState(defaultOption); // handles selected option
	const [isExpanded, setExpanded] = useState(false); // controls list visibility
	const ref = useRef(null); // to use useOnClickOutside hook

	useEffect(() => {
		setCurrentOption(defaultOption);
	}, [defaultOption]);

	/* Custom hooks */
	useOnClickOutside(ref, () => setExpanded(false));

	/* Handlers */
	const handleButtonClick = () => () => setExpanded(!isExpanded);

	const handleOptionClick = (option: OptionProps<OptionValueType>) => () => {
		setExpanded(false);

		if (option.value !== currentOption.value) {
			if (isEditable) setCurrentOption(option);
			setOption(option);
		}
	};

	const handleButtonKeyDown =
		(option: OptionProps<OptionValueType>) =>
		(e: KeyboardEvent<HTMLButtonElement>) => {
			const index = options.findIndex(opt => opt.value === option.value);
			if (e.key !== undefined) {
				// Handle the event with KeyboardEvent.key and set handled true.
				if (e.key === 'ArrowUp' && index && index > 0) {
					setCurrentOption(options[index - 1]);
					setOption(options[index - 1]);
				} else if (e.key === 'ArrowDown' && index < options.length - 1) {
					setCurrentOption(options[index + 1]);
					setOption(options[index + 1]);
				}
			}
		};

	const handleOptionKeyDown =
		(option: OptionProps<OptionValueType>) =>
		(e: KeyboardEvent<HTMLLIElement>) => {
			if (e.key !== undefined) {
				// Handle the event with KeyboardEvent.key and set handled true.
				if (e.key === 'Enter' || e.key === ' ') {
					setCurrentOption(option);
					setOption(option);
					setExpanded(false);
				}
			}
		};

	const renderOptions = () => {
		return options.map((option, index) => (
			<li
				className={cn(styles.selectListItem, {
					[styles.selectListItemSm]: size === 'sm',
					[styles.selectListItemMd]: size === 'md',
					[styles.selectListItemLgXl]: size === 'lg' || 'xl'
				})}
				onClick={handleOptionClick(option)}
				onKeyDown={handleOptionKeyDown(option)}
				key={index}
				id={`listbox${defaultOption.value}-${option.value}`}
				role='option'
				aria-selected={option.name === currentOption.name}
				tabIndex={0}
			>
				<data value={option.value}>
					{option.icon && <SvgSprite iconId={option.icon} />}
					{parse(option.name)}
				</data>
			</li>
		));
	};

	return currentOption.name ? (
		<div
			className={cn(
				styles.select,
				className,
				isExpanded ? styles.zIndexOne : styles.zIndexZero
			)}
			ref={ref}
			{...props}
		>
			<Button
				className={cn(
					styles.selectHeader,
					styles[bg],
					styles[color],
					styles[size],
					{
						caption: size === 'sm',
						'body-md': size === 'md',
						subtitle: size === 'lg'
					}
				)}
				onClick={handleButtonClick()}
				onKeyDown={handleButtonKeyDown(currentOption)}
				aria-haspopup='listbox'
				role='combobox'
				aria-expanded={isExpanded}
				aria-controls={`listbox${defaultOption.value}`}
				aria-activedescendant={
					isExpanded
						? `listbox${defaultOption.value}-${currentOption.value}`
						: ''
				}
				type='button'
				id={id}
			>
				<span>{parse(currentOption.name)}</span>
				<SvgSprite iconId='arrow-down' />
			</Button>
			<ul
				className={cn(
					styles.selectList,
					{
						'body-sm': size === 'sm',
						'body-md': size === 'md',
						'subtitle-md': size === 'lg' || 'xl'
					},
					{
						[styles.active]: isExpanded,
						[styles.endSide]: listStartSide === 'end'
					}
				)}
				role='listbox'
				id={`listbox${defaultOption.value}`}
			>
				{renderOptions()}
			</ul>
		</div>
	) : null;
};

import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { addAlert } from '@core/store/alert/alert.thunks';
import { setMetamaskAddress } from '@core/store/auth/auth.slice';
import { logoutThunk } from '@core/store/auth/auth.thunks';
import {
	useAppDispatch,
	useAppSelector,
	useOnClickOutside
} from '@core/utils/hooks';
import { Button, SvgSprite } from '@components/index';

import styles from './Header.module.scss';

export const ProfileMenu = () => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const auth = useAppSelector(({ auth }) => auth);

	useEffect(() => {
		window.ethereum?.on('accountsChanged', async ([account]: string[]) => {
			if (account) dispatch(setMetamaskAddress(account));
		});

		if (
			auth.metamaskAddress &&
			auth.whoAmI.username &&
			auth.metamaskAddress !== auth.whoAmI.username
		) {
			dispatch(
				addAlert({
					type: 'error',
					text: 'You have changed your account, please re-login'
				})
			);
			dispatch(logoutThunk());
		}
	}, [auth.metamaskAddress, auth.whoAmI.username]);

	/* React hooks */
	const [isOpened, setIsOpened] = useState(false);

	const ref = useRef(null);

	/* Custom hooks */
	useOnClickOutside(ref, () => setIsOpened(false));

	/* Handlers */
	const handleLogout = () => dispatch(logoutThunk());

	const toggleMenu = () => setIsOpened(!isOpened);

	return (
		<div className={styles.profileMenuWrapper} ref={ref}>
			<Button
				className={styles.navBtn}
				onClick={toggleMenu}
				title='Menu'
				type='button'
			>
				<SvgSprite iconId='user' color='#676767' />
			</Button>
			<ul
				className={cn(styles.profileList, 'h6', {
					[styles.active]: isOpened
				})}
			>
				<li>
					<NavLink
						to={`/profile/${
							auth.whoAmI.username || auth.metamaskAddress
						}/items`}
						onClick={toggleMenu}
						activeClassName={styles.activeLink}
					>
						<SvgSprite iconId='user' />
						Profile
					</NavLink>
				</li>
				<li>
					<NavLink
						to='/wallet?limit=10'
						onClick={toggleMenu}
						activeClassName={styles.activeLink}
					>
						<SvgSprite iconId='nav-wallet' />
						My wallet
					</NavLink>
				</li>
				<li>
					<NavLink
						to='/notifications?limit=10'
						onClick={toggleMenu}
						activeClassName={styles.activeLink}
					>
						<SvgSprite iconId='nav-notification' />
						Notifications
					</NavLink>
				</li>
				<li>
					<NavLink
						to='/settings/profile'
						onClick={toggleMenu}
						activeClassName={styles.activeLink}
					>
						<SvgSprite iconId='nav-settings' />
						Settings
					</NavLink>
				</li>
				<li>
					<Button className='h6' onClick={handleLogout}>
						<SvgSprite iconId='nav-door' />
						Log out
					</Button>
				</li>
			</ul>
		</div>
	);
};

import React from 'react';
import cn from 'classnames';

import { addAlert } from '@core/store/alert/alert.thunks';
import { useAppDispatch } from '@core/utils/hooks';
import { InputProps } from '@pages/Dashboard/Settings/Profile/ProfileSettings.props';
import { Input, SvgSprite, Textarea } from '@components/index';

import styles from '@pages/Dashboard/Settings/Profile/ProfileSettings.module.scss';

export const TextInputs = ({
	onChange,
	setFields,
	fields,
	toolTipDataFor,
	toolTipHandleClick
}: InputProps) => {
	/* Redux hooks */
	const dispatch = useAppDispatch();

	/* Handlers */
	const handleChange =
		(prop: 'email' | 'bio' | 'nickname') =>
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const val = prop === 'bio' ? e.target.value : e.target.value.trim();
			switch (prop) {
				case 'email':
					if (val.length > 254) {
						return dispatch(
							addAlert({
								text: 'Maximum length is 254 characters',
								type: 'error'
							})
						);
					}
					break;

				case 'bio':
					if (val.length > 200) {
						return dispatch(
							addAlert({
								text: 'The maximum length for bio is 200 characters (including spaces)',
								type: 'error'
							})
						);
					}
					break;

				case 'nickname':
					if (val.length > 42) {
						return dispatch(
							addAlert({
								text: 'Nickname must start and end with a letter or number. Limit 42 characters',
								type: 'error'
							})
						);
					}
			}

			onChange();
			setFields({ ...fields, [prop]: val });
		};

	return (
		<div className={styles.textInputs}>
			<label className={styles.textInputWrapper}>
				<span>
					Nickname
					<SvgSprite
						onClick={toolTipHandleClick}
						data-for={toolTipDataFor}
						data-html
						data-tip='Nickname cannot start or end with a symbol and contain only letters, numbers, and underscores. No special characters or spaces can be used. Use no more than 42 characters.'
					/>
				</span>
				<Input
					onChange={handleChange('nickname')}
					value={fields.nickname || ''}
					largeness='md'
					type='text'
					placeholder='Enter Nickname'
				/>
			</label>
			<label
				className={cn(styles.textInputWrapper, styles.textEmailInputWrapper)}
			>
				<span>Email Address</span>
				<Input
					onChange={handleChange('email')}
					value={fields.email || ''}
					largeness='md'
					type='email'
					placeholder='Enter Email'
				/>
			</label>
			<label className={styles.textInputWrapper}>
				<span>
					Bio
					<SvgSprite
						onClick={toolTipHandleClick}
						data-for={toolTipDataFor}
						data-html
						data-tip='The maximum length for bio is 200 characters (including spaces). If necessary, special characters (symbols, accent marks, emojis, etc.), or alphabets other than Latin, can be used.'
					/>
				</span>
				<Textarea
					className={styles.textarea}
					onChange={handleChange('bio')}
					placeholder='(Optional)'
					value={fields.bio || ''}
				/>
			</label>
		</div>
	);
};

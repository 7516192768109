import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState = {
	buildTimestamp: '',
	version: ''
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setAppInfo: (state, action: PayloadAction<typeof initialState>) => {
			return { ...state, ...action.payload };
		}
	}
});

export const {
	reducer: appReducer,
	actions: { setAppInfo }
} = appSlice;

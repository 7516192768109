import React, { useEffect } from 'react';
import cn from 'classnames';
import Moment from 'react-moment';

import {
	addLoading,
	addReputationData
} from '@core/store/reputation/reputation.slice';
import { setReputation } from '@core/store/reputation/reputation.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Heading } from '@components/MainComponents/Heading/Heading';
import { SvgIcon } from '@components/MainComponents/SvgIcon/SvgIcon';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';
import { ReputationsSkeleton } from '@skeletons/profile/ReputationsSkeleton';
import { ReactComponent as AmountCounter } from '@assets/img/icons/extra/amount-counter.svg';

import { ProfileTabTypes, ReputationProps } from '../Profile.types';

import styles from '../Profile.module.scss';

export const Reputation = ({
	address,
	handleNavBtnClick,
	isCurrentUserAgent
}: ReputationProps) => {
	/*** Redux hooks ***/
	const dispatch = useAppDispatch();
	const [reputation, isLoading] = useAppSelector(({ reputation }) => [
		reputation.data,
		reputation.isLoading
	]);

	/*** React hooks ***/
	useEffect(() => {
		handleNavBtnClick(ProfileTabTypes.Ratings);

		return () => {
			dispatch(addReputationData(null));
			dispatch(addLoading(true));
		};
	}, []);

	useEffect(() => {
		const res = dispatch(setReputation({ address }));

		return () => {
			res.abort();
		};
	}, [address]);

	if (isLoading && !reputation) return <ReputationsSkeleton />;
	if (!reputation) return <p>Nothing found</p>;

	const showHoursBySeconds = (seconds: number) => {
		return Math.round(seconds / 3600);
	};

	const showContractTypePercentage = (total: number, count: number) => {
		if (count === 0) return '(0%)';
		return `(${Math.round((count / total) * 100)}%)`;
	};

	const getTokenImageUrl = (tokenName: string) => {
		const tokenNameLowercase = tokenName?.toLowerCase();
		if (!tokenNameLowercase) return;
		switch (true) {
			case tokenNameLowercase.includes('usdt'):
				return 'token-usdt';
			case tokenNameLowercase.includes('busd'):
				return 'token-busd';
			case tokenNameLowercase.includes('usdc'):
				return 'token-usdc';
			case tokenNameLowercase.includes('dai'):
				return 'token-dai';
		}
	};

	const contractTypesStatisticData = [
		{
			icon: {
				id: 'status-success-outlined',
				className: 'reputationIconSuccess'
			},
			title: 'Finished',
			contracts_count: reputation.total_successfully_finished_contracts_count
		},
		{
			icon: {
				id: isCurrentUserAgent
					? 'status-warning-outlined-triangle'
					: 'status-warning-outlined-circle',
				className: 'reputationIconWarning'
			},
			title: 'Disputed',
			contracts_count: isCurrentUserAgent
				? reputation.total_contracts_with_agent_invited_count
				: reputation.total_disputed_contracts_count
		},
		{
			icon: {
				id: 'circle-justice',
				className: 'reputationIconJustice'
			},
			title: isCurrentUserAgent ? 'Agent invited' : 'With agent',
			contracts_count: reputation.total_finished_with_agent_contracts_count
		}
	];

	return (
		<div className={styles.reputationContainer}>
			<dl className={cn(styles.reputation, styles.reputationCol1)}>
				<div
					className={cn(styles.reputationData, styles.reputationDataNetwork)}
				>
					<div>
						<dt className='h6'>Favorite Network</dt>
						{reputation.favourite_network && (
							<dd className='body-sm'>
								{reputation.favourite_network.display_name}
							</dd>
						)}
					</div>
					{reputation.favourite_network &&
						reputation.favourite_network.image && (
							<img
								src={reputation.favourite_network.image}
								alt={reputation.favourite_network.display_name}
								className={styles.reputationDataIcon}
							/>
						)}
				</div>
				<div className={cn(styles.reputationData, styles.reputationDataToken)}>
					<div>
						<dt className='h6'>Favorite Token</dt>
						<dd className='body-sm'>{reputation.favourite_token}</dd>
					</div>
					<SvgSprite
						iconId={getTokenImageUrl(reputation.favourite_token)}
						className={styles.reputationDataIcon}
					/>
				</div>
				<div
					className={cn(
						styles.reputationData,
						styles.reputationDataContractAmount
					)}
				>
					<dt className='h6'>Contract amount</dt>
					<ul>
						<li>
							<span className={styles.AmountIcons}>
								<SvgIcon Icon={AmountCounter} />
							</span>
							<Heading tag='h6' className='subtitle-md'>
								Minimum
							</Heading>
							<p className='body-sm'>{reputation.min_deal_amount}$</p>
						</li>
						<li>
							<span className={styles.AmountIcons}>
								<SvgIcon Icon={AmountCounter} />
								<SvgIcon Icon={AmountCounter} />
							</span>
							<Heading tag='h6' className='subtitle-md'>
								Average
							</Heading>
							<p className='body-sm'>{reputation.average_deal_amount}$</p>
						</li>
						<li>
							<span className={styles.AmountIcons}>
								<SvgIcon Icon={AmountCounter} />
								<SvgIcon Icon={AmountCounter} />
								<SvgIcon Icon={AmountCounter} />
							</span>
							<Heading tag='h6' className='subtitle-md'>
								Maximum
							</Heading>
							<p className='body-sm'>{reputation.max_deal_amount}$</p>
						</li>
					</ul>
				</div>
			</dl>
			<dl className={cn(styles.reputation, styles.reputationCol2)}>
				{isCurrentUserAgent ? (
					<>
						<div
							className={cn(
								styles.reputationData,
								styles.reputationDataTotalContracts,
								styles.reputationDataJoined,
								{ [styles.agentView]: isCurrentUserAgent }
							)}
						>
							<div>
								<dt className='h6'>Date joined</dt>
								<dd className='body-sm'>
									<Moment format='DD MMMM YYYY'>
										{reputation.date_joined}
									</Moment>
								</dd>
							</div>
						</div>

						<div
							className={cn(
								styles.reputationData,
								styles.reputationDataAgentAverageContracts
							)}
						>
							<div className={cn(styles.reputationDataTitle, 'h6')}>
								Average contracts
							</div>
							<div className={styles.reputationDataColumns}>
								<div className={styles.left}>
									<dt className='h6'>Per month</dt>
									<dd className='body-sm'>
										{reputation.average_contracts_per_month} contracts
									</dd>
								</div>
								<div className={styles.right}>
									<dt className='h6'>Per day</dt>
									<dd className='body-sm'>
										{reputation.average_contracts_per_day} contracts
									</dd>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<div
							className={cn(
								styles.reputationData,
								styles.reputationDataProtection
							)}
						>
							<div className={styles.left}>
								<dd className='h4'>
									{showHoursBySeconds(
										+reputation.average_buyer_protection_time
									)}{' '}
									hours
								</dd>
								<dt className='subtitle'>Average buyer protection time</dt>
							</div>
							<ul className={styles.right}>
								<li>
									<dt className='subtitle-md'>Min.</dt>
									<dd className='body-sm'>
										{showHoursBySeconds(reputation.min_buyer_protection_time)}{' '}
										hours
									</dd>
								</li>
								<li>
									<dt className='subtitle-md'>Max.</dt>
									<dd className='body-sm'>
										{showHoursBySeconds(reputation.max_buyer_protection_time)}{' '}
										hours
									</dd>
								</li>
							</ul>
							<SvgSprite
								size={32}
								iconId='reputation-security'
								className={styles.reputationIcon}
							/>
						</div>
						<div
							className={cn(
								styles.reputationData,
								styles.reputationDataExecution
							)}
						>
							<div className={styles.left}>
								<dd className='h4'>
									{showHoursBySeconds(+reputation.average_execution_time)} hours
								</dd>
								<dt className='subtitle'>
									{isCurrentUserAgent
										? 'Average Dispute resolving time'
										: 'Average execution time'}{' '}
								</dt>
							</div>
							<ul className={styles.right}>
								<li>
									<dt className='subtitle-md'>Min.</dt>
									<dd className='body-sm'>
										{showHoursBySeconds(reputation.min_execution_time)} hours
									</dd>
								</li>
								<li>
									<dt className='subtitle-md'>Max.</dt>
									<dd className='body-sm'>
										{showHoursBySeconds(reputation.max_execution_time)} hours
									</dd>
								</li>
							</ul>
							<SvgSprite
								size={32}
								iconId='reputation-execution'
								className={styles.reputationIcon}
							/>
						</div>
					</>
				)}
			</dl>
			<dl
				className={cn(styles.reputation, styles.reputationCol3, {
					[styles.agentView]: isCurrentUserAgent
				})}
			>
				<div
					className={cn(
						styles.reputationData,
						styles.reputationDataTotalContracts,
						{ [styles.agentView]: isCurrentUserAgent }
					)}
				>
					<div>
						<dt className='h6'>Total contracts</dt>
						<dd className='body-sm'>
							{reputation.total_contracts_count} Contracts
						</dd>
					</div>
					{isCurrentUserAgent || (
						<div>
							<dt className='h6'>Total Items</dt>
							<dd className='body-sm'>{reputation.total_items_count} Items</dd>
						</div>
					)}
				</div>

				{isCurrentUserAgent || (
					<>
						<div
							className={cn(
								styles.reputationData,
								styles.reputationDataContractsCirculation
							)}
						>
							<SvgSprite
								iconId='arrow-bolded-down'
								className={styles.reputationDataContractsCirculationAsBuyerIcon}
							/>
							<p className='h6'>Buy</p>
							<p className='body-sm'>
								{reputation.total_contracts_as_buyer_count}{' '}
								{showContractTypePercentage(
									reputation.total_contracts_count,
									reputation.total_contracts_as_buyer_count
								)}
							</p>
						</div>
						<div
							className={cn(
								styles.reputationData,
								styles.reputationDataContractsCirculation
							)}
						>
							<SvgSprite
								iconId='arrow-bolded-down'
								className={
									styles.reputationDataContractsCirculationAsSellerIcon
								}
							/>
							<p className='h6'>Sell</p>
							<p className='body-sm'>
								{reputation.total_contracts_as_seller_count}{' '}
								{showContractTypePercentage(
									reputation.total_contracts_count,
									reputation.total_contracts_as_seller_count
								)}
							</p>
						</div>
					</>
				)}

				{contractTypesStatisticData.map((c, i) => {
					if (c.title === 'Finished' && isCurrentUserAgent)
						return <React.Fragment key={i}></React.Fragment>;
					return (
						<div
							className={cn(
								styles.reputationData,
								styles.reputationDataContractsType
							)}
							key={i}
						>
							<SvgSprite
								iconId={c.icon.id}
								className={styles[c.icon.className]}
							/>
							<dt className='subtitle-md'>{c.title}</dt>
							<dd className='body-sm'>
								{c.contracts_count}{' '}
								{showContractTypePercentage(
									reputation.total_contracts_count,
									c.contracts_count
								)}
							</dd>
						</div>
					);
				})}
			</dl>
		</div>
	);
};

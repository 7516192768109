import React from 'react';
import ContentLoader from 'react-content-loader';

export const ReviewsSkeleton = () => (
	<div>
		<ContentLoader
			preserveAspectRatio='none'
			backgroundColor='#e3e9ef'
			foregroundColor='#fff'
			width='100%'
			height={622}
			speed={2}
		>
			<rect x='0' y='0' rx='16' ry='16' width='100%' height='92' />
			<rect x='0' y='110' rx='16' ry='16' width='100%' height='269' />
			<rect x='0' y='398' rx='16' ry='16' width='100%' height='213' />
		</ContentLoader>
	</div>
);

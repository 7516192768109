export const getHoursBySeconds = (seconds: number) => {
	const toHours = seconds / 3600;

	const subjectOut = (value: number, inSingle: string, inPlural: string) => {
		if (value === 1) return value + ' ' + inSingle;
		else return value + ' ' + inPlural;
	};

	if (toHours > 24) {
		const days = Math.floor(toHours / 24);
		const hours = toHours % 24;

		return hours > 0
			? `${subjectOut(days, 'day', 'days')} ${subjectOut(
					hours,
					'hour',
					'hours'
			  )}`
			: `${subjectOut(days, 'day', 'days')}`;
	} else {
		return subjectOut(toHours, 'hour', 'hours');
	}
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { Chat } from '@core/services';

import { setRoom } from './room.slice';

export const getRoomByIdThunk = createAsyncThunk<void, number>(
	'room/getRoomById',
	async (id, { dispatch, signal }) => {
		const room = await Chat.get(id, { signal });

		if (room) dispatch(setRoom(room));
	}
);

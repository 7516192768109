export const getZenCommission = (price: number): number => {
	const fixedTo2D = (num: number) => +num.toFixed(2);
	if (price > 0) {
		switch (true) {
			// less than 10K
			case 10000 > price:
				return fixedTo2D((price * 1.75) / 100);

			// more or equal to 10K but less than 100K
			case 10000 <= price && 100000 > price:
				return fixedTo2D((price * 1.5) / 100 + 25);

			// more or equal to 100K but less than 1M
			case 100000 <= price && 1000000 > price:
				return fixedTo2D((price * 0.75) / 100 + 750);

			// more or equal to 1M but less than 10M
			case 1000000 <= price && 10000000 > price:
				return fixedTo2D((price * 0.5) / 100 + 2500);

			// more or equal to 10M but less than 100M
			case 10000000 <= price && 1000000000 > price:
				return fixedTo2D((price * 0.25) / 100 + 25000);

			// more or equal to 100M
			default:
				return fixedTo2D((price * 0.1) / 100 + 150000);
		}
	}
	return 0;
};

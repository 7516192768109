import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { WalletStatus } from '@core/models/enums';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setUserBalance } from '@core/store/auth/auth.slice';
import { markNotificationsAsReadThunk } from '@core/store/notification/notification.thunks';
import { receivedCurrentWalletTx } from '@core/store/wallet/wallet.slice';
import {
	getLastTxFromWalletTxsThunk,
	postWalletTxThunk
} from '@core/store/wallet/wallet.thunks';
import { getNotificationInfo } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { AmountFormProps } from '@pages/Dashboard/Wallet/Wallet.props';
import { Button, Input } from '@components/index';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import styles from '@pages/Dashboard/Wallet/Wallet.module.scss';

export const AmountForm = ({ setCurrentStep }: AmountFormProps) => {
	const { t } = useTranslation('common');
	/* Redux hooks */
	const [wallet, notifications, systemEvents] = useAppSelector(
		({ wallet, notification }) => [
			wallet,
			notification.dropdownList,
			notification.systemEvents
		]
	);
	const dispatch = useAppDispatch();

	/* React hooks */
	const [amount, setAmount] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (wallet.transactions.current) {
			setIsLoading(false);

			const currentWalletEvent = notifications.find(
				n =>
					getNotificationInfo(n).title === 'Invoice' &&
					n.target_object_id === wallet.transactions.current?.id &&
					n.unread
			);

			if (currentWalletEvent) {
				dispatch(getLastTxFromWalletTxsThunk());
				dispatch(markNotificationsAsReadThunk([currentWalletEvent.id]));
			}
		}
	}, [notifications]);

	useEffect(() => {
		if (wallet.transactions.current) {
			const zenfTransactionExist = systemEvents.find(
				n =>
					n.action_object_object_id === wallet.transactions.current?.zenfTxId &&
					getNotificationInfo(n).title === 'Zenf-Invoice' &&
					n.unread
			);

			if (zenfTransactionExist) {
				dispatch(getLastTxFromWalletTxsThunk());
				dispatch(markNotificationsAsReadThunk([zenfTransactionExist.id]));
			}
		}
	}, [systemEvents]);

	useEffect(() => {
		if (
			wallet.transactions.current &&
			wallet.transactions.current.status === WalletStatus.Confirmed
		)
			dispatch(setUserBalance(wallet.transactions.current.running_balance));
	}, [wallet.transactions.current]);

	/* Handlers */
	const handleAmountChange = async (e: ChangeEvent<HTMLInputElement>) => {
		// Replace all non-numeric characters
		if (!isNaN(+e.target.value)) {
			setAmount(e.target.value);
		}
	};

	const handleAmountSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const trimmedAmount = amount.trim();
		if (!trimmedAmount.length) return;
		if (trimmedAmount.split('.')[1] && trimmedAmount.split('.')[1].length > 2) {
			return dispatch(
				addAlert({
					text: 'Amount must be a number with no more than 2 decimal places',
					type: 'error'
				})
			);
		}
		if (+trimmedAmount < 0.05) {
			return dispatch(
				addAlert({
					text: 'Amount must be more than 0.05',
					type: 'error'
				})
			);
		}
		setIsLoading(true);

		dispatch(receivedCurrentWalletTx(null));
		setAmount('');

		await dispatch(postWalletTxThunk(amount));
		setIsLoading(false);
		setCurrentStep(1);
	};

	return (
		<form className={styles.form} onSubmit={handleAmountSubmit}>
			<label className={styles.label}>
				<span className={cn('body-md', styles.labelText)}>
					Amount{' '}
					<SvgSprite
						size={20}
						data-tip={t('app.toolTips.wallet.amount')}
						data-for='walletTooltip'
						onClick={e => e.preventDefault()}
					/>
				</span>
				<div className={styles.inputWrapper}>
					<Input
						value={amount}
						largeness='md'
						onChange={handleAmountChange}
						className={styles.input}
						type='text'
						placeholder='0'
					/>
				</div>
			</label>
			<Button
				variant='contained'
				size='large'
				color='primary'
				isLoader={isLoading}
			>
				Add To Wallet
			</Button>
		</form>
	);
};

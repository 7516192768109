export enum ChatCommand {
	NewMessage = 'new_message',
	FetchMessages = 'fetch_messages',
	MarkAsRead = 'mark_as_read',
	GetNewMessageCount = 'get_new_message_count'
}

/*
 * More info about readyState
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState}.
 */
export enum WSReadyState {
	Connecting,
	Open,
	Closing,
	Closed,
	Error
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationModel } from '@core/models/enums';
import { INotification, NotificationResponse } from '@core/models/interfaces';
import { getNotificationInfo } from '@core/utils/helpers';

import { NotificationsInitialState } from './notifications.types';

const initialState: NotificationsInitialState = {
	list: [],
	dropdownList: [],
	systemEvents: [],
	lastNewNotification: null,
	isLoaded: false,
	filters: {
		limit: 10,
		offset: 0,
		search: '',
		ordering: '',
		action_object_content_type__model: ''
	},
	count: {
		total_count: 0,
		count: 0,
		chat_count: 0,
		contract_count: 0,
		user_rating_count: 0,
		wallet_transaction_count: 0,
		is_system_count: 0,
		regular_count: 0,
		regular_unread_count: 0
	}
};

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		notificationsReceived: (state, action: PayloadAction<INotification[]>) => {
			return { ...state, list: action.payload, isLoaded: true };
		},
		notificationsDropdownReceived: (
			state,
			action: PayloadAction<INotification[]>
		) => {
			return { ...state, dropdownList: action.payload };
		},
		systemEventsReceived: (
			state,
			action: PayloadAction<INotification[] | INotification>
		) => {
			if (Array.isArray(action.payload)) {
				return {
					...state,
					systemEvents: action.payload
				};
			} else {
				return {
					...state,
					systemEvents: [action.payload, ...state.systemEvents]
				};
			}
		},
		newNotificationReceived: (state, action: PayloadAction<INotification>) => {
			let index: number;
			if (
				action.payload.action_object_content_type?.model ===
				NotificationModel.Chat
			) {
				index = state.dropdownList.findIndex(
					notification =>
						notification.data?.contract_id === action.payload.data.contract_id
				);
			}
			index = state.dropdownList.findIndex(
				notification =>
					getNotificationInfo(notification).title ===
						getNotificationInfo(action.payload).title &&
					getNotificationInfo(notification).id ===
						getNotificationInfo(action.payload).id
			);
			if (index === -1) {
				return {
					...state,
					dropdownList: [action.payload, ...state.dropdownList.slice(0, 9)],
					list: [action.payload, ...state.list.slice(0, 9)],
					count: {
						...state.count,
						regular_unread_count: state.count.regular_unread_count + 1
					}
				};
			}
			return {
				...state,
				dropdownList: [
					...state.dropdownList.slice(0, index),
					action.payload,
					...state.dropdownList.slice(index + 1)
				],
				list: [
					...state.list.slice(0, index),
					action.payload,
					...state.list.slice(index + 1)
				],
				count: {
					...state.count,
					regular_unread_count: state.dropdownList[index].unread
						? state.count.regular_unread_count
						: state.count.regular_unread_count + 1
				}
			};
		},
		lastNewNotificationReceived: (
			state,
			action: PayloadAction<INotification | null>
		) => {
			if (action.payload) state.lastNewNotification = action.payload;
		},
		markedNotificationsAsRead: (state, action: PayloadAction<number[]>) => {
			let unreadCount = state.count.regular_unread_count;
			return {
				...state,
				list: state.list.map(notification => {
					if (action.payload.includes(notification.id)) {
						return {
							...notification,
							unread: false
						};
					}
					return notification;
				}),
				dropdownList: state.dropdownList.map(notification => {
					if (action.payload.includes(notification.id)) {
						if (notification.unread) {
							unreadCount = unreadCount - 1 < 0 ? 0 : unreadCount - 1;
						}
						return {
							...notification,
							unread: false
						};
					}
					return notification;
				}),
				count: { ...state.count, regular_unread_count: unreadCount }
			};
		},
		systemEventsAsRead: (state, action: PayloadAction<number[]>) => {
			return {
				...state,
				systemEvents: state.systemEvents.map(event => {
					if (action.payload.includes(event.id)) {
						return {
							...event,
							unread: false
						};
					}
					return event;
				})
			};
		},
		markedAllNotificationsAsRead: state => {
			return {
				...state,
				list: state.list.map(notification => ({
					...notification,
					unread: false
				})),
				count: {
					...state.count,
					regular_unread_count: 0
				},
				dropdownList: state.dropdownList.map(notification => ({
					...notification,
					unread: false
				})),
				systemEvents: state.systemEvents.map(notification => ({
					...notification,
					unread: false
				}))
			};
		},
		notificationsCountReceived: (
			state,
			action: PayloadAction<Omit<NotificationResponse, 'results'>>
		) => {
			return {
				...state,
				count: { ...state.count, ...action.payload }
			};
		},
		setNotificationLimitAction: (state, action: PayloadAction<number>) => {
			return { ...state, limit: action.payload };
		},
		setNotificationOffsetAction: (state, action: PayloadAction<number>) => {
			return {
				...state,
				filters: { ...state.filters, offset: action.payload }
			};
		},
		setNotificationModelAction: (
			state,
			action: PayloadAction<NotificationModel | '' | 'unread'>
		) => {
			return {
				...state,
				filters: {
					...state.filters,
					action_object_content_type__model: action.payload
				}
			};
		},
		setNotificationSearchAction: (state, action: PayloadAction<string>) => {
			return {
				...state,
				filters: { ...state.filters, search: action.payload }
			};
		},
		setNotificationUnreadAction: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				filters: { ...state.filters, unread: action.payload }
			};
		},
		setIsNotificationsLoaded: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				isLoaded: action.payload
			};
		}
	}
});

export const {
	reducer: notificationReducer,
	actions: {
		markedAllNotificationsAsRead,
		markedNotificationsAsRead,
		newNotificationReceived,
		notificationsCountReceived,
		notificationsDropdownReceived,
		notificationsReceived,
		lastNewNotificationReceived,
		setIsNotificationsLoaded,
		setNotificationModelAction,
		setNotificationOffsetAction,
		setNotificationSearchAction,
		setNotificationUnreadAction,
		systemEventsReceived,
		systemEventsAsRead
	}
} = notificationSlice;

import React from 'react';
import cn from 'classnames';
import ReactDOMServer from 'react-dom/server';

import { getURLFromFile } from '@core/utils/helpers';
import { InputProps } from '@pages/Dashboard/Settings/Profile/ProfileSettings.props';
import { Input, SvgSprite } from '@components/index';
import coverPlaceholder from '@assets/img/bg.png';

import styles from './ProfileSettings.module.scss';

export const FileInputs = ({
	onChange,
	setFields,
	fields,
	toolTipDataFor,
	toolTipHandleClick
}: InputProps) => {
	const handleChange =
		(prop: 'avatar' | 'cover') =>
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files;
			if (!file?.length) return;

			const url = await getURLFromFile(file[0]);
			if (typeof url === 'string') {
				setFields({ ...fields, [prop]: { file: file[0], url } });
				onChange();
				e.target.value = '';
			}
		};

	return (
		<div className={styles.fileInputs}>
			<label className={styles.imgInputWrapper}>
				<span className={styles.inputTitle}>
					Profile image
					<SvgSprite
						onClick={toolTipHandleClick}
						data-for={toolTipDataFor}
						data-html
						data-tip={ReactDOMServer.renderToString(
							<div className={cn('caption', styles.tooltipWrapper)}>
								<p>Recommended dimension 240x240</p>
								<p>Max size: 2MB in total (for profile and background)</p>
								<p>Format: PNG, JPG, JPEG</p>
							</div>
						)}
					/>
				</span>
				<Input
					accept='image/*'
					multiple={false}
					onChange={handleChange('avatar')}
					className='imgInput'
					type='file'
				/>
				<div className={cn(styles.imgWrapper, styles.profileImgWrapper)}>
					<div className={styles.editIconWrapper}>
						<SvgSprite iconId='pencil' />
					</div>
					<img src={fields.avatar.url || coverPlaceholder} alt='user avatar' />
				</div>
			</label>
			<label className={styles.imgInputWrapper}>
				<span className={styles.inputTitle}>
					Background image
					<SvgSprite
						onClick={toolTipHandleClick}
						data-for={toolTipDataFor}
						data-html
						data-tip={ReactDOMServer.renderToString(
							<div className={cn('caption', styles.tooltipWrapper)}>
								<p>Recommended dimension 1800x240</p>
								<p>Max size: 2MB in total (for profile and background)</p>
								<p>Format: PNG, JPG, JPEG</p>
							</div>
						)}
					/>
				</span>
				<Input
					accept='image/*'
					multiple={false}
					onChange={handleChange('cover')}
					className='imgInput'
					type='file'
				/>
				<div className={cn(styles.imgWrapper, styles.bgImgWrapper)}>
					<div className={styles.editIconWrapper}>
						<SvgSprite iconId='pencil' />
					</div>
					<img src={fields.cover.url || coverPlaceholder} alt='user banner' />
				</div>
			</label>
		</div>
	);
};

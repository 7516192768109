import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { WSReadyState } from '@core/models/enums';
import {
	getNotifications,
	getSystemEvents
} from '@core/store/notification/notification.thunks';
import {
	startNotificationsListening,
	stopNotificationsListening
} from '@core/store/ws/notification/wsNotification.thunks';
import {
	useAppDispatch,
	useAppSelector,
	useOnClickOutside
} from '@core/utils/hooks';
import { UnreadCountIcon } from '@components/DashboardComponents';
import { Button } from '@components/index';

import { NotificationDropdownItem } from './NotificationDropdownItem';

import styles from './NotificationsDropdown.module.scss';

export const NotificationDropdownList = () => {
	/* Redux hooks */
	const [notifications, unreadCount, wsNotificationStatus] = useAppSelector(
		({ notification, wsNotification }) => [
			notification.dropdownList,
			notification.count.regular_unread_count,
			wsNotification.status
		]
	);

	const dispatch = useAppDispatch();

	/* React hooks */
	const [isOpened, setIsOpened] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		dispatch(startNotificationsListening());

		return () => {
			dispatch(stopNotificationsListening());
		};
	}, []);

	useEffect(() => {
		if (wsNotificationStatus === WSReadyState.Open) {
			dispatch(
				getNotifications({
					offset: 0,
					limit: 10,
					unread: true
				})
			);
			dispatch(getSystemEvents());
		}
	}, [wsNotificationStatus]);

	/* Custom hooks */
	useOnClickOutside(ref, () => setIsOpened(false));

	/* Handlers */
	const handleClick = () => setIsOpened(!isOpened);

	const renderNotifications = () => {
		const sortedNotifications = [...notifications].sort((firstEl, secondEl) => {
			const prevDate = +new Date(firstEl.modified);
			const nextDate = +new Date(secondEl.modified);
			return nextDate - prevDate;
		});
		return sortedNotifications.map(item => (
			<NotificationDropdownItem data={item} key={item.id} />
		));
	};

	return (
		<div className={styles.notification} ref={ref}>
			<Button
				title='Notifications'
				type='button'
				onClick={handleClick}
				className={styles.notificationsBtn}
			>
				<UnreadCountIcon
					unreadCount={unreadCount}
					className={styles.notificationsIcon}
				/>
			</Button>
			<div
				className={cn(styles.notificationsWrapper, {
					[styles.active]: isOpened
				})}
			>
				<div className={styles.notificationsHeader}>
					<span>Notifications</span>
					<Link
						to='/notifications?limit=10'
						onClick={handleClick}
						className='caption'
					>
						All notifications
					</Link>
				</div>
				{notifications.length ? (
					<ul className={cn(styles.notificationsList)}>
						{renderNotifications()}
					</ul>
				) : (
					<div className={styles.noNotifications}>
						<span>The list is empty</span>
					</div>
				)}
			</div>
		</div>
	);
};

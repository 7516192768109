import React from 'react';
import cn from 'classnames';

import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { MaintenanceInfoProps } from './MaintenanceInfo.props';

import styles from './MaintenanceInfo.module.scss';

export const MaintenanceInfo = ({
	children,
	className,
	...props
}: MaintenanceInfoProps) => {
	return (
		<div
			className={cn('subtitle', styles.wrapper, className)}
			role='alert'
			{...props}
		>
			<SvgSprite className={styles.icon} width={24} height={24} />
			{children}
		</div>
	);
};

import React from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';

import { TooltipProps } from './Tooltip.props';

const isTouchScreen = () => {
	return navigator.maxTouchPoints > 0;
};

export const Tooltip = ({
	id,
	className,
	type = 'info',
	effect = 'solid',
	place = 'bottom',
	delayHide = 200,
	offset = { top: 2 },
	arrowColor = '#fff'
}: TooltipProps) => {
	return (
		<ReactTooltip
			id={id}
			type={type}
			effect={effect}
			place={place}
			clickable={isTouchScreen()}
			globalEventOff={isTouchScreen() ? '' : 'click'}
			delayHide={delayHide}
			offset={offset}
			arrowColor={arrowColor}
			getContent={content => content}
			className={cn('tooltip', className)}
		/>
	);
};

import React from 'react';
import cn from 'classnames';

import { WalletStatus } from '@core/models/enums';
import { IWalletTransaction } from '@core/models/interfaces';
import { Modal, SvgSprite } from '@components/index';

import styles from '@pages/Dashboard/Wallet/Wallet.module.scss';

interface WalletModalProps {
	currentTx: IWalletTransaction | null;
}

export const WalletModal = ({ currentTx }: WalletModalProps) => {
	return (
		<div
			className={cn(styles.modalWrapper, {
				[styles.active]: currentTx && currentTx.status !== WalletStatus.Created
			})}
		>
			<Modal
				className={cn('subtitle', styles.modal, {
					[styles.active]:
						currentTx && currentTx.status !== WalletStatus.Created
				})}
			>
				{currentTx?.status === WalletStatus.Pending && (
					<>
						<SvgSprite
							className={cn(styles.walletLoader, 'rotateAnimation')}
							iconId='loader-wallet'
						/>
						<span>Transaction detected.</span>
						<span>Waiting for the network confirmation.</span>
					</>
				)}
				{currentTx?.status === WalletStatus.Confirmed && (
					<>
						<SvgSprite
							className={styles.walletSuccessIcon}
							iconId='status-success-outlined'
						/>
						<span>Transaction confirmed.</span>
						<span>Funds have been added to your balance.</span>
						<span>You may proceed with the contract now.</span>
					</>
				)}
				{currentTx?.status === WalletStatus.Failed && (
					<>
						<SvgSprite
							className={styles.walletFailIcon}
							iconId='status-fail-outlined-circle'
						/>
						<span>Transaction failed.</span>
					</>
				)}
			</Modal>
		</div>
	);
};

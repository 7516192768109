import { WS } from '@core/config/webSocket';
import { WSReadyState } from '@core/models/enums';
import { WSNotificationResponse } from '@core/models/interfaces';
import { store } from '@core/store/store';
import { startNotificationsListening } from '@core/store/ws/notification/wsNotification.thunks';
import {
	EventsNames,
	MessageSubscriberType,
	StatusSubscriberType
} from '@core/websocket/types';

let ws: WebSocket | null = null;

const subscribers: {
	message: MessageSubscriberType<WSNotificationResponse>[];
	status: StatusSubscriberType[];
} = {
	status: [],
	message: []
};

const openHandler = () => {
	console.log(
		'%c Notification Websocket opened',
		'color:#3c97ff;border:1px solid dodgerblue;padding-right:5px'
	);
	notifySubscribersAboutStatus(WSReadyState.Open);

	if (store.getState().wsNotification.status !== WSReadyState.Open)
		store.dispatch(startNotificationsListening());
};

const closeHandler = (e: CloseEvent) => {
	// See https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1
	console.log(
		`%c Notification Websocket closed with code ${e.code}`,
		'color:red;border:1px solid dodgerblue;padding-right:5px'
	);
	notifySubscribersAboutStatus(WSReadyState.Closed);
	ws = null;
	subscribers.message = [];
	subscribers.status = [];
	if (e.code === 1006 || e.code === 1009) {
		console.log(
			'%c Notification Websocket reconnecting in 5 seconds...',
			'color:#ffa200;border:1px solid dodgerblue;padding-right:5px'
		);
		setTimeout(() => {
			NotificationSocket.start();
		}, 5000);
	}
};

const messageHandler = (e: MessageEvent) => {
	const newMessages = JSON.parse(e.data);
	subscribers.message.forEach(subscriber => subscriber(newMessages));
};

const cleanUp = () => {
	ws?.removeEventListener('open', openHandler);
	ws?.removeEventListener('close', closeHandler);
	ws?.removeEventListener('message', messageHandler);
	ws?.removeEventListener('error', errorHandler);
};

const errorHandler = () => {
	console.log(
		'%c Notification Websocket error',
		'color:red;border:1px solid dodgerblue;padding-right:5px'
	);
	notifySubscribersAboutStatus(WSReadyState.Error);
};

const notifySubscribersAboutStatus = (status: WSReadyState) => {
	subscribers.status.forEach(subscriber => subscriber(status));
};

const createChannel = () => {
	cleanUp();
	ws?.close();
	ws = new WebSocket(WS.notification);
	notifySubscribersAboutStatus(WSReadyState.Connecting);

	ws.addEventListener('open', openHandler);
	ws.addEventListener('close', closeHandler);
	ws.addEventListener('message', messageHandler);
	ws.addEventListener('error', errorHandler);
};

export const NotificationSocket = {
	start() {
		createChannel();
	},
	stop() {
		notifySubscribersAboutStatus(WSReadyState.Closed);
		ws?.close();
	},
	subscribe(
		eventName: EventsNames,
		cb: MessageSubscriberType<WSNotificationResponse> | StatusSubscriberType
	) {
		switch (eventName) {
			case 'message':
				subscribers.message.push(
					cb as MessageSubscriberType<WSNotificationResponse>
				);
				return () => {
					subscribers.message = subscribers.message.filter(
						subscriber => subscriber !== cb
					);
				};
			case 'status':
				subscribers.status.push(cb as StatusSubscriberType);
				return () => {
					subscribers.status = subscribers.status.filter(
						subscriber => subscriber !== cb
					);
				};
			default:
				break;
		}
	},
	unsubscribe(
		eventName: EventsNames,
		cb: MessageSubscriberType<WSNotificationResponse> | StatusSubscriberType
	) {
		switch (eventName) {
			case 'message':
				subscribers.message = subscribers.message.filter(
					subscriber => subscriber !== cb
				);
				break;
			case 'status':
				subscribers.status = subscribers.status.filter(
					subscriber => subscriber !== cb
				);
				break;
			default:
				break;
		}
	}
};

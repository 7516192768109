import React, { useState } from 'react';
import cn from 'classnames';

import { Button, Input, SvgSprite } from '@components/index';

import { SearchFormProps } from './Forms.types';

import styles from './Forms.module.scss';

export const SearchForm = ({
	onSubmit,
	className,
	hasIcon,
	initialValue = ''
}: SearchFormProps) => {
	/* React hooks */
	const [value, setValue] = useState(initialValue);

	/* Handlers */
	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onSubmit(value.trim());
	};

	/* Handlers */
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		if (!e.target.value) onSubmit('');
	};

	return (
		<form className={cn(className, styles.searchForm)} onSubmit={handleSubmit}>
			{hasIcon && <SvgSprite className={styles.searchIcon} iconId='search' />}
			<Input
				onChange={handleChange}
				value={value}
				className={styles.searchInput}
				type='search'
				largeness='md'
				placeholder='Search'
			/>
			<Button className={styles.searchBtn}>
				<SvgSprite iconId='search' />
			</Button>
		</form>
	);
};

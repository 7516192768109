import React, { useEffect, useRef, useState } from 'react';

import { useAppSelector } from '@core/utils/hooks';

import { MessagesProps } from './Chat.props';
import { Message } from './Message';

import styles from './Chat.module.scss';

export const Messages = ({
	agentAddress,
	fetchMessages
}: MessagesProps & {
	fetchMessages?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	let currentDate = '';
	/* Redux hooks */
	const messages = useAppSelector(({ wsChat }) => wsChat.messages);

	/* React hooks */
	const messagesEndRef = useRef<null | HTMLLIElement>(null);
	const [isAutoScroll, setIsAutoScroll] = useState(true);

	useEffect(() => {
		if (messages.length) currentDate = formatDate(messages[0].created);
		scrollToBottom();
	}, [messages]);

	/* Custom functions */
	const scrollToBottom = () => {
		if (messagesEndRef.current && isAutoScroll) {
			messagesEndRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end'
			});
		}
	};

	const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
		const element = e.currentTarget;

		if (element.scrollTop < 1 && fetchMessages) fetchMessages(true);

		const isBottom =
			Math.abs(
				element.scrollHeight - element.scrollTop - element.clientHeight
			) < 300;
		isBottom ? setIsAutoScroll(true) : setIsAutoScroll(false);
	};

	const formatDate = (ISOString: string) => {
		const res = ISOString?.split('T').shift();
		return res ? res : '';
	};
	if (!messages.length) return null;

	const renderMessages = () => {
		return messages.map(item => {
			const messageDate = formatDate(item.created);
			if (messageDate !== currentDate) {
				currentDate = messageDate;
				return (
					<React.Fragment key={item.id}>
						<Message
							item={{
								...item,
								author: 'calendar'
							}}
							key={item.id + 'cm'}
							agentAddress={agentAddress || ''}
						/>
						<Message
							item={item}
							key={item.id + 'm'}
							agentAddress={agentAddress || ''}
						/>
					</React.Fragment>
				);
			}
			return (
				<Message
					item={item}
					key={item.id + 'am'}
					agentAddress={agentAddress || ''}
				/>
			);
		});
	};
	return (
		<ul className={styles.list} onScroll={handleScroll}>
			{renderMessages()}
			<li style={{ marginBottom: 10 }} ref={messagesEndRef} />
		</ul>
	);
};

import cn from 'classnames';

import notFound from '@assets/img/notFound.png';

import styles from './NotFound.module.scss';

export const NotFound = () => {
	return (
		<div className={styles.notFoundPage}>
			<div className={styles.container}>
				<img src={notFound} alt='404 error' />
				<p className={cn(styles.text, 'h5')}>page not found</p>
			</div>
		</div>
	);
};

import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';

import { ContractStatusName, ContractStatusNumber } from '@core/models/enums';
import { setContractAllFilters } from '@core/store/contract/contract.slice';
import { getSearchParams } from '@core/utils/helpers/getSearchParams';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Badge, Button, SvgSprite } from '@components/index';
import { TableSkeleton } from '@skeletons/contracts/TableSkeleton';

import { ContractsTableProps } from './Contract.props';

import styles from './Contract.module.scss';

export const ContractsTable = ({ list, isLoading }: ContractsTableProps) => {
	/* React Router hooks */
	const history = useHistory();

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const contractsFilter = useAppSelector(
		({ contractsFilter }) => contractsFilter
	);

	if (isLoading || !list) return <TableSkeleton />;
	if (list.length === 0) return <p>Contracts not found.</p>;

	/* Handlers */
	const handleClick = (ordering: string) => () => {
		if (contractsFilter.ordering === ordering) {
			dispatch(
				setContractAllFilters({
					...contractsFilter,
					offset: 0,
					ordering: `-${ordering}`
				})
			);
		} else {
			dispatch(
				setContractAllFilters({
					...contractsFilter,
					offset: 0,
					ordering: ordering
				})
			);

			const params = new URLSearchParams(
				getSearchParams({ ...contractsFilter, offset: 0, ordering })
			);
			history.push(`/contracts?${params}`);
		}
	};

	const renderExpiredStatus = (status: ContractStatusName) => {
		if (
			ContractStatusNumber[ContractStatusName.Approved] <=
				ContractStatusNumber[status] &&
			ContractStatusNumber[status] <
				ContractStatusNumber[ContractStatusName.Deployed]
		) {
			return (
				<Badge className={styles.badge} text='md' color='expired-danger'>
					Expired
					<SvgSprite
						iconId='status-warning-outlined-triangle'
						color='#45454f'
					/>
				</Badge>
			);
		} else {
			return (
				<Badge className={styles.badge} text='md' color='expired-warn'>
					Expired
					<SvgSprite iconId='status-warning-expired' />
				</Badge>
			);
		}
	};

	const renderStatus = (status: ContractStatusName) => {
		switch (status) {
			case ContractStatusName.Disputed:
				return (
					<Badge className={styles.badge} text='md' color='danger'>
						Disputed
					</Badge>
				);

			case ContractStatusName.AgentInvited:
				return (
					<Badge className={styles.badge} text='md' color='danger'>
						Disputed
						<SvgSprite iconId='justice' />
					</Badge>
				);
			case ContractStatusName.DisputeFinished:
				return (
					<Badge className={styles.badge} text='md' color='success'>
						Executed
						<SvgSprite iconId='justice' />
					</Badge>
				);
			case ContractStatusName.Executed:
				return (
					<Badge className={styles.badge} text='md' color='success'>
						<span className={styles.feeStatus}>Executed</span>
					</Badge>
				);
			case ContractStatusName.Deployed:
				return (
					<Badge className={styles.badge} text='md' color='primary'>
						<span className={styles.feeStatus}>Deployed</span>
					</Badge>
				);
			default:
				return (
					<Badge className={styles.badge} text='md' color='primary'>
						{status}
					</Badge>
				);
		}
	};

	const renderLinks = () => {
		return list?.map(item => (
			<Link
				to={`/contracts/${item.id}`}
				className={cn('subtitle', styles.tableLink)}
				key={item.id}
			>
				<div className={styles.contractItem}>
					<span className={styles.contractTitle}>Contract ID</span>
					<span>#{item.id}</span>
				</div>

				<hr className={styles.divider} />

				<div className={cn(styles.contractItem, styles.contractProduct)}>
					{item.product && (
						<span className={styles.contractTitle}>Contract product image</span>
					)}
					{item.product &&
						(item.product.image ? (
							<img
								src={item.product.image}
								alt='product'
								className={styles.contractProductThumbnail}
							/>
						) : (
							<SvgSprite iconId='image' width={42} height={42} />
						))}
				</div>

				<div className={styles.contractItem}>
					<span className={styles.contractTitle}>Contract name</span>
					<p className='truncate'>{item.title || 'N/A'}</p>
				</div>

				<div className={styles.contractItem}>
					<span className={styles.contractTitle}>Type</span>
					<span>{item?.type?.display_name || 'Escrow'}</span>
				</div>

				<div className={cn(styles.contractItem, styles.contractItemCenter)}>
					<span className={styles.contractTitle}>Network / Token</span>
					<div>
						<span className={styles.capitalize}>{item.network}</span>/
						<span>{item.token.name}</span>
					</div>
				</div>

				<div className={cn(styles.contractItem, styles.contractItemCenter)}>
					<span className={styles.contractTitle}>Agreed amount</span>
					<span>{item.price}</span>
				</div>

				<div className={styles.contractItem}>
					<span className={styles.contractTitle}>Created on</span>
					<Moment format='DD/MM/YYYY HH:mm'>{item.created}</Moment>
				</div>

				<div className={styles.contractItem}>
					<span className={styles.contractTitle}>Due date</span>
					<Moment format='DD/MM/YYYY HH:mm'>{item.deadline}</Moment>
				</div>
				{moment().isAfter(item.deadline) &&
				ContractStatusNumber[item.status] <
					ContractStatusNumber[ContractStatusName.Executed]
					? renderExpiredStatus(item.status)
					: renderStatus(item.status)}
			</Link>
		));
	};

	return (
		<div className={styles.tableGrid}>
			<div className={styles.tableHead}>
				<Button
					onClick={handleClick('id')}
					className={cn(styles.orderingBtn, styles.orderingByID, 'body-md', {
						[styles.active]: contractsFilter.ordering === 'id'
					})}
					type='button'
				>
					<span className={styles.moreDetail}># </span>
					ID
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					className={cn(styles.orderingBtn, 'body-md')}
					type='button'
					tabIndex={-1}
					role='button'
					aria-pressed='false'
				/>
				<Button
					onClick={handleClick('title')}
					className={cn(styles.orderingBtn, styles.orderingByTitle, 'body-md', {
						[styles.active]: contractsFilter.ordering === 'title'
					})}
					type='button'
				>
					Contract name
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleClick('type')}
					className={cn(styles.orderingBtn, styles.orderingByType, 'body-md', {
						[styles.active]: contractsFilter.ordering === 'type'
					})}
					type='button'
				>
					Type
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleClick('token')}
					className={cn(styles.orderingBtn, styles.orderingByToken, 'body-md', {
						[styles.active]: contractsFilter.ordering === 'token'
					})}
					type='button'
				>
					Network <span className={styles.moreDetail}>/ Token</span>
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleClick('price')}
					className={cn(styles.orderingBtn, styles.orderingByPrice, 'body-md', {
						[styles.active]: contractsFilter.ordering === 'price'
					})}
					type='button'
				>
					Agreed <span className={styles.moreDetail}>amount</span>
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleClick('created')}
					className={cn(
						styles.orderingBtn,
						styles.orderingByCreated,
						'body-md',
						{
							[styles.active]: contractsFilter.ordering === 'created'
						}
					)}
					type='button'
				>
					Created on
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleClick('deadline')}
					className={cn(
						styles.orderingBtn,
						styles.orderingByDeadline,
						'body-md',
						{
							[styles.active]: contractsFilter.ordering === 'deadline'
						}
					)}
					type='button'
				>
					Due date
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleClick('status')}
					className={cn(
						styles.orderingBtn,
						styles.orderingByStatus,
						'body-md',
						{
							[styles.active]: contractsFilter.ordering === 'status'
						}
					)}
					type='button'
				>
					State
					<SvgSprite iconId='arrow-down' />
				</Button>
			</div>
			{renderLinks()}
		</div>
	);
};

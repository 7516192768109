import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMainFilter } from '@core/models/interfaces/mainInterfaces/mainInterfaces';
import { initialFilterState } from '@core/store/item/item.slice';

export const referralBalanceFilterSlice = createSlice({
	name: 'referral/balance/Filter',
	initialState: initialFilterState,
	reducers: {
		setReferralBalanceAllFilters: (
			state,
			action: PayloadAction<IMainFilter>
		) => {
			return {
				...state,
				...action.payload
			};
		},
		setOffsetAction: (state, action: PayloadAction<number>) => {
			return { ...state, offset: action.payload };
		}
	}
});

export const {
	reducer: ReferralBalanceFilterReducer,
	actions: { setReferralBalanceAllFilters, setOffsetAction }
} = referralBalanceFilterSlice;

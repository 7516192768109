import { API } from '@core/config/api';
import { IToken } from '@core/models/interfaces';
import { GetTokensQueryParamsDraft } from '@core/services/tokens/models';
import { getSearchParams } from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Tokens = {
	async getAll(queryParams?: GetTokensQueryParamsDraft) {
		let params: URLSearchParams | string = '';
		if (queryParams) params = new URLSearchParams(getSearchParams(queryParams));

		try {
			const response = await fetch(`${API.tokens}?${params}`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			const data = await response.json();
			const tokens: IToken[] = data.results;
			const foundTokens = tokens.filter(token => token.is_active);

			if (foundTokens.length) return foundTokens;
		} catch (e) {
			logErrors(e);
		}
	}
};

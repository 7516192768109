import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
	ContractRole,
	ContractStatusName,
	ContractStatusNumber,
	TransactionType
} from '@core/models/enums';
import { deployContract } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { TransactionStatuses } from '@core/store/transaction/transaction.types';
import { getTxByType } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { CopiableLink } from '@components/DashboardComponents';
import { Badge, Button, SvgSprite } from '@components/index';

import { StepContainer } from './StepContainer';
import { StepProps } from './Steps.props';

import styles from './Steps.module.scss';

export const Deployment = ({ contract, auth }: StepProps) => {
	const { t } = useTranslation('common');
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const transaction = useAppSelector(({ transaction }) => transaction);

	/* React hooks */
	const [loading, setLoading] = useState<boolean>(false);
	const [confirmingLoading, setConfirmingLoading] = useState<boolean>(false);
	const [isDeploymentEvent, setIsDeploymentEvent] = useState<boolean>(false);

	useEffect(() => {
		if (isDeploymentEvent) {
			if (contract.transactions.length) {
				if (
					contract.transactions[0].status === TransactionStatuses.Pending &&
					contract.transactions[0].type === TransactionType.deployed
				) {
					setConfirmingLoading(true);
				}
			}

			if (contract.status === ContractStatusName.Deployed && contract.address) {
				if (
					transaction.status === TransactionStatuses.Confirmed &&
					confirmingLoading
				) {
					dispatch(
						addAlert({
							text: 'You have successfully deployed the contract.',
							type: 'info',
							isSubmit: true
						})
					);
					setConfirmingLoading(false);
					setIsDeploymentEvent(false);
				}
			}

			if (
				transaction.status === TransactionStatuses.Pending &&
				!contract.address
			) {
				setConfirmingLoading(true);
			}

			if (
				transaction.status === TransactionStatuses.Failed &&
				!contract.address
			) {
				setConfirmingLoading(false);
				setLoading(false);
				dispatch(
					addAlert({
						text: 'Transaction for deploying contract is failed',
						type: 'error',
						isSubmit: true
					})
				);
				setIsDeploymentEvent(false);
			}
		}
	}, [contract.address, transaction.status]);

	const { role } = auth;
	const isBuyer = role === ContractRole.Buyer;
	const isDisabled =
		!isBuyer ||
		ContractStatusNumber[contract.status] < ContractStatusNumber.approved ||
		ContractStatusNumber[contract.status] > ContractStatusNumber.deployed;

	const txByType =
		getTxByType(contract.transactions, TransactionType.deployed)?.tx_hash || '';

	const handleDeploy = async () => {
		if (isDisabled) {
			return dispatch(
				addAlert({
					text: `Contract is ${contract.status}, not approved!`,
					type: 'error',
					isSubmit: true
				})
			);
		}

		setLoading(true);
		setIsDeploymentEvent(true);

		const res = await deployContract(contract);
		if (!res) setLoading(false);
	};

	return (
		<StepContainer
			stepStatus={ContractStatusName.Approved}
			contractStatus={contract.status}
			title='Deployment'
			tooltip={t('app.toolTips.contracts.deployment')}
		>
			<div className={styles.stepContent}>
				{ContractStatusNumber[contract.status] ===
				ContractStatusNumber.draft ? (
					<Badge text='sm' color='warn' hasIcon>
						Both parties must approve the contract before deployment.
					</Badge>
				) : (
					<>
						{ContractStatusNumber[contract.status] <
							ContractStatusNumber.deployed &&
							role === ContractRole.Seller && (
								<Badge text='sm' color='warn' hasIcon>
									Contract has not been deployed yet.
								</Badge>
							)}

						{ContractStatusNumber[contract.status] ===
							ContractStatusNumber.deployed && (
							<Badge text='sm' color='success' hasIcon>
								{role === 'buyer'
									? 'The contract has been successfully deployed. Transfer agreed amount into the contract.'
									: 'Contract has been deployed.'}
							</Badge>
						)}
						{ContractStatusNumber[contract.status] >
							ContractStatusNumber.deployed && (
							<Badge text='sm' color='success' hasIcon>
								Contract has been deployed.
							</Badge>
						)}
						{ContractStatusNumber[contract.status] >=
							ContractStatusNumber.deployed &&
							contract.transactions.length > 0 && (
								<div className={styles.deploymentInfo}>
									<div className={cn('subtitle', styles.copyTextWrapper)}>
										<span className={styles.bold}>Contract address: </span>
										<CopiableLink
											text={contract.address}
											url={`${contract.network.explorer_url}/address/${contract.address}`}
										/>
									</div>
									<div className={cn('subtitle', styles.copyTextWrapper)}>
										<span className={styles.bold}>Transaction ID: </span>
										<CopiableLink
											text={txByType}
											url={`${contract.network.explorer_url}/tx/${txByType}`}
										/>
									</div>
								</div>
							)}
					</>
				)}

				{ContractStatusNumber[contract.status] <
					ContractStatusNumber.deployed && (
					<>
						<div className={styles.deploymentChargesInfo}>
							<Badge text='sm' color='warn' hasIcon>
								Deploying the contract charges the Zenland fee from payer(s)
								internal wallet(s).
							</Badge>
							<SvgSprite
								size={20}
								data-for='contractStepTooltip'
								data-html
								data-tip={t('app.toolTips.contracts.dataTip.deployment')}
								className={styles.tooltipIcon}
							/>
						</div>
						{isBuyer && (
							<Button
								onClick={handleDeploy}
								disabled={contract.isNetworkNotMatched || isDisabled}
								className={styles.stepBtn}
								isLoader={loading}
								isConfirming={confirmingLoading}
								variant='contained'
								size='medium'
								color='primary'
							>
								<SvgSprite
									iconId='metamask-fox-outlined'
									className={styles.metamaskIcon}
								/>{' '}
								Deploy
							</Button>
						)}
					</>
				)}
			</div>
		</StepContainer>
	);
};

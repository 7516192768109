import { createAsyncThunk } from '@reduxjs/toolkit';

import { INotification, INotificationFilter } from '@core/models/interfaces';
import { Notifications } from '@core/services';
import { RootState } from '@core/store/store.types';

import {
	markedAllNotificationsAsRead,
	markedNotificationsAsRead,
	notificationsCountReceived,
	notificationsDropdownReceived,
	notificationsReceived,
	systemEventsAsRead,
	systemEventsReceived
} from './notification.slice';

export const markNotificationsAsReadThunk = createAsyncThunk<
	void,
	number[] | undefined
>('notification/markNotificationsAsRead', async (ids, thunkAPI) => {
	const body = await Notifications.markAsRead(ids);
	if (!body || !body.success) return;

	if (ids?.length) {
		thunkAPI.dispatch(markedNotificationsAsRead(ids));
	} else {
		thunkAPI.dispatch(markedAllNotificationsAsRead());
	}
});

export const getNotifications = createAsyncThunk<
	void,
	Partial<INotificationFilter> | void
>(
	'notification/getNotifications',
	async (payload, { dispatch, signal }) => {
		if (!payload) payload = { offset: 0, limit: 10 };

		const notifications = await Notifications.getAll(payload, false, {
			signal
		});
		if (!notifications) return;

		const { results, ...count } = notifications;

		dispatch(notificationsCountReceived(count));
		// boolean for unread notifications in header notifications dropdown
		if (typeof payload.unread === 'boolean') {
			dispatch(notificationsDropdownReceived(results));
		} else {
			dispatch(notificationsReceived(results));
		}
	},
	{
		dispatchConditionRejection: true
	}
);

export const getSystemEvents = createAsyncThunk<void>(
	'notification/getSystemEvents',
	async (_, { dispatch, signal }) => {
		const query = { offset: 0, limit: 10, unread: true };

		const notifications = await Notifications.getAll(query, true, {
			signal
		});
		if (!notifications) return;

		dispatch(systemEventsReceived(notifications.results));
	},
	{
		dispatchConditionRejection: true
	}
);

export const getUniqSystemEvents = createAsyncThunk<
	void,
	INotification,
	{ state: RootState }
>(
	'notification/getUniqSystemEvents',
	async (payload, { dispatch, getState }) => {
		const state: RootState = getState();
		const systemEvents = state.notification.systemEvents;

		if (systemEvents.findIndex(e => e.id === payload.id) >= 0) {
			dispatch(getSystemEvents());
		} else {
			dispatch(systemEventsReceived(payload));
		}
	}
);

export const markSystemEventAsReadThunk = createAsyncThunk<
	void,
	number[] | undefined
>('notification/systemEventAsRead', async (ids, thunkAPI) => {
	const body = await Notifications.markAsRead(ids);
	if (!body || !body.success) return;

	if (ids?.length) {
		thunkAPI.dispatch(systemEventsAsRead(ids));
	}
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WSReadyState } from '@core/models/enums';
import { WsNotificationState } from '@core/store/ws/ws.types';

export const initialState: WsNotificationState = {
	status: WSReadyState.Closed
};

export const wsNotificationSlice = createSlice({
	name: 'wsNotification',
	initialState,
	reducers: {
		NotificationStatusChanged: (state, action: PayloadAction<WSReadyState>) => {
			return {
				...state,
				status: action.payload
			};
		}
	}
});

export const {
	reducer: wsNotificationReducer,
	actions: { NotificationStatusChanged }
} = wsNotificationSlice;

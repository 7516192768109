import React from 'react';

import { ShowCountProps } from './ShowingCount.props';

import styles from './ShowCount.module.scss';

export const ShowCount = ({ start, end }: ShowCountProps) => {
	return (
		<span className={styles.countInfo}>
			Showing {start}-{end}
		</span>
	);
};

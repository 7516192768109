import { createAsyncThunk } from '@reduxjs/toolkit';

import { ContractRole, UserGroupName } from '@core/models/enums';
import { INetwork } from '@core/models/interfaces';
import {
	changeNetworkWeb3,
	loginWithSignature,
	Logout,
	Network,
	Users
} from '@core/services';
import { getChainId, getWeb3Account } from '@core/utils/helpers';

import {
	saveReceivedNetworks,
	setChainIdAction,
	setLogged,
	setMetamaskAddress,
	setNetworkAction,
	setRole,
	setWhoAmI
} from './auth.slice';

export const loginThunk = createAsyncThunk(
	'auth/login',
	async (_, { dispatch }) => {
		const res = await loginWithSignature();

		if (res) {
			const { logged, registered, address } = res;
			if (logged || registered) {
				dispatch(setLogged(true));
			} else {
				window.location.reload();
			}
			dispatch(setMetamaskAddress(address));
		}
	}
);

export const logoutThunk = createAsyncThunk(
	'auth/logout',
	async (_, thunkAPI) => {
		const response = await Logout.get();
		if (response) {
			thunkAPI.dispatch(setLogged(false));
			thunkAPI.dispatch(setRole(ContractRole.Seller));
			thunkAPI.dispatch(
				setWhoAmI({
					avatar: '',
					balance: '',
					bio: '',
					cover: '',
					email: '',
					first_name: '',
					last_name: '',
					nickname: '',
					date_joined: '',
					is_active: false,
					is_staff: false,
					is_superuser: false,
					last_login: '',
					rating: {
						total: 0,
						average: '0.0',
						count: 0,
						percentage: '0.0'
					},
					user_permissions: [],
					id: 0,
					groups: [],
					username: '',
					referral_balance: '',
					referral_code: ''
				})
			);
		}
	}
);

export const receiveNetworksThunk = createAsyncThunk(
	'auth/receiveNetworks',
	async (_, thunkAPI) => {
		const tokens = await Network.getAll();
		if (tokens) thunkAPI.dispatch(saveReceivedNetworks(tokens));
	}
);

export const refreshPageThunk = createAsyncThunk(
	'auth/refreshPage',
	async (_, thunkAPI) => {
		const address = await getWeb3Account();
		const chainId = await getChainId();

		if (chainId) thunkAPI.dispatch(setChainIdAction(chainId));
		if (address) thunkAPI.dispatch(setMetamaskAddress(address));
	}
);

export const changeNetworkThunk = createAsyncThunk<
	void,
	Omit<INetwork, 'is_active' | 'id'> & { symbol: string; decimals: number }
>('auth/changeNetwork', async (payload, thunkAPI) => {
	const body = await changeNetworkWeb3({
		chainId: payload.chain_id,
		chainName: payload.metamask_name,
		rpcUrls: [payload.rpc],
		blockExplorerUrls: [payload.explorer_url],
		symbol: payload.symbol,
		decimals: payload.decimals
	});
	if (body) thunkAPI.dispatch(setNetworkAction(payload));
});

export const receiveWhoAmIThunk = createAsyncThunk(
	'auth/receiveWhoAmI',
	async (_, { dispatch }) => {
		const body = await Users.whoAmI();

		if (body) {
			dispatch(setWhoAmI({ ...body, username: body.username.toLowerCase() }));
			if (body.groups.length) {
				switch (body.groups[0].name) {
					case UserGroupName.Agent:
						dispatch(setRole(ContractRole.Agent));
						break;
					case UserGroupName.Zen_Agent:
						dispatch(setRole(ContractRole.Zen_Agent));
						break;

					case UserGroupName.Moderator:
					case UserGroupName.Admin:
						if (body.is_staff) {
							window.location.href = `https://${process.env.REACT_APP_API_DOMAIN_NAME}:${process.env.REACT_APP_API_PORT}/admin`;
						}
						break;
				}
			}
		}
	}
);

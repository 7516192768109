import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { ContractRole, NotificationModel } from '@core/models/enums';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setRole } from '@core/store/auth/auth.slice';
import {
	setContractAction,
	setContractIsSyncing
} from '@core/store/contract/contract.slice';
import { getContractThunk } from '@core/store/contract/contract.thunks';
import { lastNewNotificationReceived } from '@core/store/notification/notification.slice';
import {
	markNotificationsAsReadThunk,
	markSystemEventAsReadThunk
} from '@core/store/notification/notification.thunks';
import { setRoom } from '@core/store/room/room.slice';
import { getRoomByIdThunk } from '@core/store/room/room.thunks';
import { setTransactionData } from '@core/store/transaction/transaction.slice';
import { setChatAuthor } from '@core/store/ws/chat/wsChat.slice';
import {
	startChatMessagesListening,
	stopChatMessagesListening
} from '@core/store/ws/chat/wsChat.thunks';
import { getNotificationInfo } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { ContractRoutes } from '@routes/ContractRoutes';
import { ContractSyncingEventProps } from '@pages/Dashboard/Contracts/Contract.props';
import { UnreadCountIcon } from '@components/DashboardComponents';
import { ContractSkeleton } from '@skeletons/contracts/ContractSkeleton';

import styles from '../Contract.module.scss';

export const ContractLayout = () => {
	/* Router hooks */
	const location = useLocation();

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [
		auth,
		contract,
		newMessagesCount,
		contractIdFromRoom,
		systemEvents,
		dropdownList,
		lastNewNotification
	] = useAppSelector(({ auth, contracts, wsChat, room, notification }) => [
		auth,
		contracts.current,
		wsChat.newMessagesCount,
		room?.contract,
		notification.systemEvents,
		notification.dropdownList,
		notification.lastNewNotification
	]);

	/* React hooks */
	const [ids, setIds] = React.useState<{
		contract: number | undefined;
		chat: number;
		notifications?: number[];
	}>({
		contract: contract.data?.id || 0,
		chat: contract.data?.room || 0,
		notifications: []
	});
	const firstUpdate = useRef({ statement1: true });

	useEffect(() => {
		return () => {
			dispatch(setContractAction(null));
			dispatch(setTransactionData(null));
		};
	}, []);

	useEffect(() => {
		const id = +location.pathname.split('/').slice(-1);
		if (location.pathname.includes('chat')) {
			setIds(prev => {
				if (contractIdFromRoom) {
					return {
						contract: contractIdFromRoom,
						chat: id
					};
				}
				return { ...prev, chat: id };
			});
		} else {
			if (!contract.data || contract.data.id !== id) {
				setIds(prev => ({ ...prev, contract: id }));
			}
		}
	}, [location.pathname, contractIdFromRoom]);

	useEffect(() => {
		if (ids.chat) {
			dispatch(getRoomByIdThunk(ids.chat));

			return () => {
				dispatch(setRoom(null));
			};
		}
	}, [ids.chat]);

	useEffect(() => {
		if (ids.contract && !firstUpdate.current.statement1) {
			const promise = dispatch(getContractThunk(ids.contract));
			return () => {
				promise.abort();
				dispatch(setContractAction(null));
			};
		}
		firstUpdate.current.statement1 = false;
	}, [ids.contract]);

	useEffect(() => {
		/*** Follow user rating notifications for changing contract ***/
		const isContractReviewsChanged =
			lastNewNotification &&
			lastNewNotification.action_object_content_type?.model ===
				NotificationModel.UserRating &&
			lastNewNotification.target_object_id === ids.contract;

		if (isContractReviewsChanged && ids.contract) {
			console.log(
				'%c Review changed',
				'color:orange;border:1px solid dodgerblue;padding-right:5px'
			);
			dispatch(getContractThunk(ids.contract));
			dispatch(lastNewNotificationReceived(null));
		}
	}, [lastNewNotification]);

	useEffect(() => {
		let _markAsReadTransactionNotification: NodeJS.Timeout | null;

		if (ids.contract && (dropdownList.length || systemEvents.length)) {
			/*** Follow contract notifications for changing contract statuses ***/
			const isContractChanged = dropdownList.some(n => {
				const conditionalNotification =
					getNotificationInfo(n).title === 'Contract' &&
					(n.action_object_object_id === ids.contract ||
						n.target_object_id === ids.contract) &&
					n.unread &&
					!ids.notifications?.includes(n.id);

				if (conditionalNotification) {
					dispatch(markNotificationsAsReadThunk([n.id]));
					setIds(prevState => ({
						...prevState,
						notifications: prevState.notifications
							? [...prevState.notifications, n.id]
							: [n.id]
					}));
				}
				return conditionalNotification;
			});

			/*** Follow transaction notifications for changing Confirming status on step buttons ***/
			const isTransactionChanged = systemEvents.some(n => {
				const conditionalNotification =
					getNotificationInfo(n).title === 'Transaction' &&
					n.unread &&
					n.target_object_id === ids.contract;

				if (conditionalNotification) {
					_markAsReadTransactionNotification = setTimeout(() => {
						dispatch(markSystemEventAsReadThunk([n.id]));
					}, 100);
				}

				return conditionalNotification;
			});

			if (isContractChanged) {
				console.log(
					'%c Contract changed',
					'color:purple;border:1px solid dodgerblue;padding-right:5px'
				);
				dispatch(getContractThunk(ids.contract));
			}

			if (isTransactionChanged) {
				console.log(
					'%c Transaction changed',
					'color:yellow;border:1px solid dodgerblue;padding-right:5px'
				);
				dispatch(getContractThunk(ids.contract));
			}

			const _isContractSyncingEvent: ContractSyncingEventProps = {
				id: 0,
				contractId: 0,
				isSync: false,
				isFail: false,
				message: ''
			};

			/*** Follow sync events for update contract statuses  ***/
			const isSyncMessageReceived = systemEvents.some(n => {
				if (n.data?.is_synchronization_related && n.unread) {
					_isContractSyncingEvent.isSync = n.data?.is_syncing;
					_isContractSyncingEvent.message = n.verb;
					_isContractSyncingEvent.id = n.id;
					_isContractSyncingEvent.contractId = n?.action_object_object_id;
					_isContractSyncingEvent.isFail = n.data?.is_error;
				}
				return n.data?.is_synchronization_related && n.unread;
			});

			if (
				isSyncMessageReceived &&
				ids.contract === _isContractSyncingEvent.contractId
			) {
				if (_isContractSyncingEvent.isSync) {
					dispatch(setContractIsSyncing(true));
					dispatch(markSystemEventAsReadThunk([_isContractSyncingEvent.id]));
				} else {
					dispatch(getContractThunk(_isContractSyncingEvent.contractId));
					dispatch(setContractIsSyncing(false));
					dispatch(
						addAlert({
							text: _isContractSyncingEvent.message,
							type: _isContractSyncingEvent.isFail ? 'error' : 'info',
							isSubmit: _isContractSyncingEvent.isFail
						})
					);
					dispatch(markSystemEventAsReadThunk([_isContractSyncingEvent.id]));
				}
			}
		}

		return () => {
			if (_markAsReadTransactionNotification)
				clearTimeout(_markAsReadTransactionNotification);
		};
	}, [systemEvents, dropdownList]);

	useEffect(() => {
		if (contract.data?.room) {
			dispatch(startChatMessagesListening(contract.data.room));
			return () => {
				dispatch(stopChatMessagesListening());
			};
		}
	}, [contract.data?.room]);

	useEffect(() => {
		if (!contract.data) return;
		dispatch(setChatAuthor(auth.metamaskAddress));

		switch (auth.whoAmI.username) {
			case contract.data.buyer.username:
				dispatch(setRole(ContractRole.Buyer));
				break;
			case contract.data.seller.username:
				dispatch(setRole(ContractRole.Seller));
				break;
			case contract.data.agent.username:
				dispatch(setRole(ContractRole.Agent));
				break;
		}
	}, [auth.whoAmI.username, contract.data]);

	return (
		<div
			className={cn('pageWrapper', {
				[styles.contractPageWrapper]: location.pathname.includes('chat')
			})}
		>
			<section className={styles.contractPage}>
				{contract.data ? (
					<>
						<nav className={styles.contractLayoutNav}>
							<ul className='subNavBtns'>
								<li>
									<NavLink
										to={`/contracts/${contract.data.id}`}
										className={cn(styles.navigationBtn, 'subNavBtn')}
									>
										Contract
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/contracts/chat/${contract.data.room}`}
										className={cn(
											styles.navigationBtn,
											'subNavBtn',
											styles.navigationBtnLast
										)}
									>
										Chat
										{auth.role !== ContractRole.Agent && (
											<UnreadCountIcon
												unreadCount={newMessagesCount}
												type='sm'
											/>
										)}
									</NavLink>
								</li>
							</ul>
						</nav>
						<ContractRoutes contract={contract.data} />
					</>
				) : (
					<ContractSkeleton />
				)}
			</section>
		</div>
	);
};

import { useEffect } from 'react';
import cn from 'classnames';
import { Redirect, useParams } from 'react-router-dom';

import { setIsRedirecting, setItemAction } from '@core/store/item/item.slice';
import { setItemThunk } from '@core/store/item/item.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { ItemDetails } from '@pages/Dashboard/Items/Item/ItemDetails';
import { ItemSkeleton } from '@skeletons/items/ItemSkeleton';

import styles from '@pages/Dashboard/Items/Items.module.scss';

export const ItemLayout = () => {
	/* React Router hooks */
	const params: { slug?: string } = useParams();

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [item, username] = useAppSelector(({ items, auth }) => [
		items.current,
		auth.whoAmI.username
	]);

	/* React hooks */
	useEffect(() => {
		if (params && params.slug) {
			const promise = dispatch(setItemThunk({ slug: params.slug }));
			return () => {
				promise.abort();
				dispatch(setItemAction(null));
			};
		}
	}, [params.slug]);

	useEffect(() => {
		if (item.data && item.data.seller !== username && item.data.is_active)
			dispatch(setIsRedirecting(true));
	}, [item.data]);

	/* If shared item link (/items/:slug) Redirect to profile/:id/items/:slug */
	if (item.data && item.data.seller !== username) {
		if (item.data.is_active) {
			return (
				<Redirect to={`/profile/${item.data.seller}/items/${item.data.slug}`} />
			);
		}
		return <Redirect to='/404-page-not-found' />;
	}

	return (
		<div className={cn('pageWrapper', styles.itemDetails)}>
			<section className={styles.itemPage}>
				{item.data ? <ItemDetails item={item.data} /> : <ItemSkeleton />}
			</section>
		</div>
	);
};

export const cropAddress = (
	address: string,
	start = 6,
	end = 6,
	type?: string
): string => {
	if (address.startsWith('0x') || type === 'contract_address') {
		const first = address.slice(0, start);
		const last = address.slice(address.length - end, address.length);
		return `${first}...${last}`;
	}
	return address;
};

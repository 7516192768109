import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { cropAddress } from '@core/utils/helpers';
import { Badge } from '@components/MainComponents/Badge/Badge';

import { AuthorProps } from './Author.props';

import styles from './Author.module.scss';

export const Author = ({
	avatar,
	username,
	nickname,
	role,
	isLink,
	...props
}: AuthorProps) => {
	const Wrapper = isLink ? NavLink : 'div';
	const displayRole = (role: string) => {
		switch (role) {
			case 'buyer': {
				return (
					<Badge
						text='md'
						color='primary'
						className={styles.authorRole}
						rounded='sm-extra'
					>
						{role}
					</Badge>
				);
			}
			case 'seller': {
				return (
					<Badge
						text='md'
						color='warn'
						className={styles.authorRole}
						rounded='sm-extra'
					>
						{role}
					</Badge>
				);
			}
		}
	};

	return (
		<div {...props}>
			<Wrapper to={`/profile/${username}`} className={styles.header}>
				<div className={styles.avatarWrapper}>
					<img
						src={
							avatar ||
							'https://res.cloudinary.com/dzzqqte2x/image/upload/v1643866748/unsplash_GfQEdpIkkuw_u57d8x.png'
						}
						alt=''
					/>
				</div>
				<div className={styles.user}>
					<span className={cn(styles.author, 'subtitle-md')}>
						{nickname || 'Anonymous'}
						{role && displayRole(role)}
					</span>
					<span
						className={cn({
							[styles.author]: !nickname,
							[styles.address]: nickname,
							['body-sm']: nickname
						})}
					>
						{cropAddress(username)}
					</span>
				</div>
			</Wrapper>
		</div>
	);
};

import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import { siteURL } from '@core/config/api';
import { CommissionPayer } from '@core/models/enums';
import { IToken } from '@core/models/interfaces';
import { Contracts, Tokens } from '@core/services';
import { Agents } from '@core/services/agents';
import { IAgent } from '@core/services/agents/models';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setContractAction } from '@core/store/contract/contract.slice';
import {
	cropAddress,
	getZenCommission,
	isDeadlineMoreHour
} from '@core/utils/helpers';
import { useAppDispatch, useAppSelector, useForm } from '@core/utils/hooks';
import { ethAddressPatternString } from '@core/utils/regex';
import { InputLabel } from '@components/DashboardComponents/Forms/Form/InputLabel';
import {
	Button,
	Checkboxes,
	DateTime,
	Input,
	Select,
	SvgSprite,
	Textarea,
	Tooltip
} from '@components/index';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';
import { Item } from '@components/ProductComponents/ProductCard.props';

import { ContractValues } from './Forms.types';

import 'react-datetime/css/react-datetime.css';
import styles from './Forms.module.scss';

export const payerOptions = {
	[CommissionPayer.Fifty]: { name: '50/50', value: CommissionPayer.Fifty },
	[CommissionPayer.Buyer]: { name: 'Buyer', value: CommissionPayer.Buyer },
	[CommissionPayer.Seller]: {
		name: 'Seller',
		value: CommissionPayer.Seller
	}
};

export const NewContractForm = () => {
	const assignAgentLabelText = 'Assign custom Zenland agent';
	const supportChatLink = `<a href="${siteURL}/chats/new/support" target='_blank' rel='noopener noreferrer'>Chat Support</a>`;
	const agentNicknameLink = (nickname: string) =>
		`<a href="${siteURL}/profile/${nickname}" target='_blank' rel='noopener noreferrer'>@${nickname}</a>`;
	const agentUsernameLink = (username: string) =>
		`<a href="${siteURL}/profile/${username}" target='_blank' rel='noopener noreferrer'>Agent</a> participate`;

	/* React-i18next hooks */
	const { t } = useTranslation('common');

	/* React router hooks */
	const location = useLocation();
	const [sellerAddress] = location.pathname.split('/').slice(-1);

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const auth = useAppSelector(({ auth }) => auth);

	/* React hooks */
	const [btnText, setBtnText] = useState('Create');
	const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
	const [contractTokens, setContractTokens] = useState<{
		list: IToken[];
		current: { name: string; value: string };
	}>({
		list: [],
		current: {
			name: '',
			value: ''
		}
	});

	const [protectionOption, setProtectionOption] = useState({
		name: 'Hours',
		value: '1'
	});

	const [activeButton, setActiveButton] = useState({
		sellerBtn: { clicked: false },
		buyerBtn: { clicked: false }
	});
	const [payerOption, setPayerOption] = useState(
		payerOptions[CommissionPayer.Fifty]
	);
	const [createdId, setCreatedId] = useState<number>();
	const [itemData, setItemData] = useState<{
		network: string;
		token: string;
		seller: string;
		slug: string;
	} | null>(null);

	const [agentAddress, setAgentAddress] = useState('');

	const [assignAgentData, setAssignAgentData] = useState<IAgent | null>(null);
	const [assignAgentError, setAssignAgentError] = useState('');

	const [customAgentList, setCustomAgentList] = useState([
		{ value: 'custom_agent', name: assignAgentLabelText }
	]);
	const [checkedCustomAgent, setCheckedCustomAgent] = useState<TCheckboxList[]>(
		[] as TCheckboxList[]
	);

	const [isLoadingAssignAgentButton, setIsLoadingAssignAgentButton] =
		useState(false);

	useEffect(() => {
		const itemDataByLocalStorage = localStorage.getItem(Item.data);
		if (itemDataByLocalStorage && !itemData)
			setItemData(JSON.parse(itemDataByLocalStorage));
	}, [itemData]);

	useEffect(() => {
		(async () => {
			if (auth.network) {
				const tokens = await Tokens.getAll({
					network__chain_id: auth.chain_id,
					is_active: true
				});

				if (tokens) {
					setContractTokens({
						list: tokens,
						current: { value: `${tokens[0].id}`, name: tokens[0].name }
					});
				} else {
					setContractTokens({
						list: [],
						current: { value: '', name: '' }
					});
				}
			}
		})();
	}, [auth.network]);

	/* Custom hooks */
	const { handleSubmit, handleChange, data, errors, setData } =
		useForm<ContractValues>({
			validations: {
				buyer: {
					pattern: {
						value: ethAddressPatternString,
						message: 'Invalid address'
					}
				},
				title: {
					custom: {
						isValid: value => (value ? value.length < 100 : true),
						message: 'Title must be less than 100 characters'
					}
				},
				seller: {
					pattern: {
						value: ethAddressPatternString,
						message: 'Invalid address'
					}
				},
				price: {
					custom: {
						isValid: value => !!(value && +value >= 0.01 && value.length < 40),
						message:
							'Agreed amount must be greater than $0.01 Make sure there are no more than 40 digits in total in the agreed amount.'
					}
				},
				deadline: {
					custom: {
						isValid: value => {
							let dateDeadline = '';
							if (value) dateDeadline = new Date(value).toISOString();
							return value ? isDeadlineMoreHour(dateDeadline) : false;
						},
						message: 'Invalid date'
					}
				},
				buyer_protection_time: {
					custom: {
						isValid: value => !!(value && +value >= 1 && +value <= 999),
						message:
							'Enter a valid buyer protection time (greater than or equal to 1 and less than 999).'
					}
				}
			},
			onSubmit: async () => {
				if (checkedCustomAgent.length) {
					if (assignAgentError)
						return dispatch(
							addAlert({
								text: assignAgentError,
								type: 'error'
							})
						);
					if (!agentAddress || !assignAgentData)
						return dispatch(
							addAlert({
								text: 'Please enter the address and click "Assign" to assign a custom Agent or remove a checkmark',
								type: 'error'
							})
						);
				}

				if (!auth.networks || !auth.network) {
					return dispatch(
						addAlert({
							text: 'Please, check your network and contract types',
							type: 'error'
						})
					);
				}
				if (data.buyer.toLowerCase() === data.seller.toLowerCase()) {
					return dispatch(
						addAlert({
							type: 'error',
							text: 'Buyer and Seller cannot have the same wallet address.'
						})
					);
				}

				if (
					![data.buyer.toLowerCase(), data.seller.toLowerCase()].includes(
						auth.whoAmI.username.toLowerCase()
					)
				) {
					return dispatch(
						addAlert({
							type: 'error',
							text: 'You must be logged in either as seller or buyer.'
						})
					);
				}

				const hours = +protectionOption.value * data.buyer_protection_time;
				const minutes = hours * 60;
				const seconds = minutes * 60;

				if (seconds < 86400) {
					return dispatch(
						addAlert({
							type: 'error',
							text: 'Ensure buyer protection time is greater than or equal to 24 hour'
						})
					);
				}

				setIsBtnDisabled(true);
				setBtnText('Wait...');

				const payload = {
					...data,
					deadline: new Date(data.deadline).toISOString(),
					token: contractTokens.current.value,
					network: auth.chain_id,
					buyer_protection_time: seconds,
					zen_commission_payer: payerOption.value as CommissionPayer
				};

				const contract = await Contracts.post(
					checkedCustomAgent.length
						? { ...payload, agent: agentAddress }
						: payload
				);

				setTimeout(() => {
					setBtnText('Create');
					setIsBtnDisabled(false);
				}, 2000);

				if (contract && contract.id) {
					dispatch(addAlert({ text: 'Created and sent', type: 'info' }));
					data.title = '';
					data.description = '';
					data.price = '';
					data.deadline = '';
					data.buyer_protection_time = 0;
					data.payer = payerOption.value;
					dispatch(setContractAction(contract));
					setCreatedId(contract.id);
				}
			},
			initialValues: {
				buyer: '',
				seller: sellerAddress.startsWith('0x') ? sellerAddress : '',
				payer: payerOption.value
			}
		});

	/* Handlers */
	const handleProtectionOption = (option: { name: string; value: string }) => {
		setProtectionOption(option);
	};

	const handleTokenChange = (option: { name: string; value: string }) => {
		setContractTokens({ ...contractTokens, current: option });
	};

	const handlePayerChange = (option: { name: string; value: string }) => {
		setPayerOption({ ...option, value: option.value as CommissionPayer });
		setData(prev => ({ ...prev, payer: option.value as CommissionPayer }));
	};

	const handleAssignAgent = async () => {
		if (agentAddress) {
			setIsLoadingAssignAgentButton(true);

			const validate = RegExp(ethAddressPatternString).test(agentAddress);
			if (validate) {
				setAssignAgentData(null);
				setAssignAgentError('');

				const res = await Agents.get(agentAddress);

				if (res?.length && res[0].is_active) setAssignAgentData(res[0]);
				else
					setAssignAgentError(
						'This Agent does not exist. Please, contact ' +
							supportChatLink +
							' to assign.'
					);
			} else setAssignAgentError('Invalid wallet address');

			setIsLoadingAssignAgentButton(false);
		}
	};

	const showPayerFeeChange = useMemo(() => {
		let _result: string | number = '';
		if (+data.price > 0 && +data.price <= 1) {
			_result = 0.01;
		} else {
			_result =
				data.payer === CommissionPayer.Fifty
					? (getZenCommission(+data.price) / 2).toFixed(2) || 0
					: getZenCommission(+data.price) || 0;
		}
		return '$' + _result;
	}, [data.price, data.payer]);

	const showAgentData = useMemo(() => {
		return (
			assignAgentData && (
				<>
					<p className='caption'>
						{assignAgentData.nickname
							? parse(agentNicknameLink(assignAgentData.nickname))
							: parse(agentUsernameLink(agentAddress))}{' '}
						{assignAgentData.nickname
							? '(' + assignAgentData.total_contracts_count + ' contracts)'
							: 'in ' + assignAgentData.total_contracts_count + ' contracts'}
					</p>
					{assignAgentData.bio && (
						<p className='caption'>Bio: {assignAgentData.bio}</p>
					)}
				</>
			)
		);
	}, [assignAgentData]);

	let clearDateTimeInput: () => void;

	const clearFormData = {
		buyer: '',
		seller: '',
		title: '',
		description: '',
		price: '',
		deadline: '',
		buyer_protection_time: 0
	};

	const renderTokens = () => {
		return contractTokens.list.map(ct => ({
			name: ct.name,
			value: `${ct.id}`
		}));
	};

	return (
		<>
			<form
				className={cn('pageWrapper', 'body', styles.form)}
				onSubmit={handleSubmit}
			>
				<legend className={cn(styles.formTitle, 'h5')}>New Contract</legend>
				<InputLabel
					labelId='buyer'
					name='Contractee'
					additionalText='(buyer)'
					toolTipDataFor='contractCreationTooltip'
					toolTipData={t('app.toolTips.newContract.buyer')}
				/>
				<div className={styles.inputContainer}>
					<Input
						placeholder='Buyer*'
						name='buyerAddress'
						id='buyer'
						value={data.buyer}
						largeness='md'
						onChange={handleChange('buyer')}
						className={cn(
							styles.formInput,
							{ [styles.error]: errors.buyer },
							styles.formInputBillAddress
						)}
						required
					/>
					<Button
						type='button'
						className={cn(styles.putData, {
							[styles.clicked]: activeButton.buyerBtn.clicked
						})}
						onClick={() => {
							setData(prev => ({
								...prev,
								buyer: auth.whoAmI.username,
								seller: auth.whoAmI.username === data.seller ? '' : data.seller
							}));
							setActiveButton({
								sellerBtn: { clicked: false },
								buyerBtn: { clicked: true }
							});
						}}
					>
						It is me
					</Button>
					{errors.buyer && <p className={styles.errorText}>{errors.buyer}</p>}
				</div>

				<InputLabel
					labelId='seller'
					name='Contractor'
					additionalText='(seller)'
					toolTipDataFor='contractCreationTooltip'
					toolTipData={t('app.toolTips.newContract.contractor')}
				/>
				<div className={styles.inputContainer}>
					<Input
						placeholder='Seller*'
						name='seller'
						id='seller'
						largeness='md'
						value={data.seller}
						onChange={handleChange('seller')}
						className={cn(
							styles.formInput,
							{ [styles.error]: errors.seller },
							styles.formInputBillAddress
						)}
						required
					/>
					<Button
						type='button'
						className={cn(styles.putData, {
							[styles.clicked]: activeButton.sellerBtn.clicked
						})}
						onClick={() => {
							setData(prev => ({
								...prev,
								seller: auth.whoAmI.username,
								buyer: auth.whoAmI.username === data.buyer ? '' : data.buyer
							}));
							setActiveButton({
								sellerBtn: { clicked: true },
								buyerBtn: { clicked: false }
							});
						}}
					>
						It is me
					</Button>
					{errors.seller && <p className={styles.errorText}>{errors.seller}</p>}
				</div>

				<InputLabel
					labelId='contractTitle'
					name='Contract name'
					additionalText='(optional)'
					toolTipDataFor='contractCreationTooltip'
					toolTipData={t('app.toolTips.newContract.contract_name')}
				/>
				<div className={styles.inputContainer}>
					<Input
						id='contractTitle'
						largeness='md'
						placeholder='Contract name'
						name='title'
						value={data.title || ''}
						onChange={handleChange('title')}
						className={cn(styles.formInput, { [styles.error]: errors.title })}
						maxLength={100}
					/>
					{errors.title && <p className={styles.errorText}>{errors.title}</p>}
				</div>

				<InputLabel
					labelId='description'
					name='Contract details'
					additionalText='(optional)'
					toolTipDataFor='contractCreationTooltip'
					toolTipData={t('app.toolTips.newContract.contract_details')}
				/>
				<Textarea
					id='description'
					placeholder='Description'
					value={data.description}
					onChange={handleChange('description')}
					className={cn(styles.formTextarea, styles.formInput, {
						[styles.error]: errors.description
					})}
				/>

				<InputLabel
					labelId='date'
					name='Due date'
					toolTipDataFor='contractCreationTooltip'
					toolTipData={t('app.toolTips.newContract.due_date')}
				/>
				<div className={styles.inputContainer}>
					<DateTime
						className={styles.formInputWrapper}
						renderInput={(props, openCalendar) => {
							clearDateTimeInput = () => {
								props.onChange({ target: { value: '' } });
							};
							return (
								<>
									<input {...props} />
									<SvgSprite
										iconId='calendar'
										width={24}
										height={24}
										className={styles.calendarIcon}
										onClick={() => openCalendar()}
									/>
								</>
							);
						}}
						onChange={e => {
							if (moment(e).isValid()) {
								setData(prev => ({
									...prev,
									deadline: new Date(e.toLocaleString()).toISOString()
								}));
							}
						}}
						inputProps={{
							className: cn(styles.formInput, styles.dateTimeInput, {
								[styles.error]: errors.deadline
							}),
							onInput: e => {
								if (e.currentTarget.value.length > 15 && +e) {
									setData(prev => ({
										...prev,
										deadline: moment(e.currentTarget.value).format(
											'DD-MM-YYYYTkk:mm'
										)
									}));
								}
							},
							autoComplete: 'off',
							required: true
						}}
					/>
					{errors.deadline && (
						<p className={styles.errorText}>{errors.deadline}</p>
					)}
				</div>

				<InputLabel
					labelId='protectionTime'
					name='Buyer protection time'
					toolTipDataFor='contractCreationTooltip'
					toolTipData={t('app.toolTips.newContract.buyer_protection_time')}
				/>
				<div className={styles.inputContainer}>
					<div className={styles.protectionTimeInputWrapper}>
						<Input
							name='protectionTime'
							id='protectionTime'
							placeholder='24*'
							largeness='md'
							type='number'
							value={data.buyer_protection_time || ''}
							autoComplete='off'
							onChange={handleChange('buyer_protection_time')}
							className={cn(styles.formInput, {
								[styles.error]: errors.buyer_protection_time
							})}
							required
						/>
						<Select
							options={[
								{ name: 'Days', value: '24' },
								{ name: 'Hours', value: '1' }
							]}
							defaultOption={protectionOption}
							setOption={handleProtectionOption}
							listStartSide='end'
							className={styles.tooltipSelect}
							color='secondary'
							bg='secondary'
						/>
					</div>
					{errors.buyer_protection_time && (
						<p className={styles.errorText}>{errors.buyer_protection_time}</p>
					)}
				</div>

				<InputLabel
					labelId='price'
					name='Agreed amount'
					toolTipData={t('app.toolTips.newContract.agreed_price')}
					className={styles.agreedPriceLabel}
					toolTipDataFor='contractCreationTooltip'
				>
					<span
						className={cn(styles.selectedNetwork, {
							[styles.unknown]: auth.isUnknownNetwork
						})}
					>
						{auth.isUnknownNetwork ? 'Choose the network' : auth.network}
					</span>
				</InputLabel>
				<div className={styles.agreedAmountInputsContainer}>
					<div
						className={cn(styles.agreedAmountInputsWrapper, {
							[styles.error]: errors.price
						})}
					>
						<div className={styles.inputContainer}>
							<Input
								id='price'
								placeholder='Amount*'
								type='number'
								largeness='md'
								value={data.price || ''}
								onChange={handleChange('price')}
								className={cn(styles.formInput, {
									[styles.error]: errors.price
								})}
								autoComplete='off'
								step='0.01'
								min='0.01'
								lang='en'
								required
							/>
							{contractTokens.list.length ? (
								<Select
									className={cn(
										styles.tooltipSelect,
										styles.contractTokensSelect
									)}
									options={renderTokens()}
									color='secondary'
									bg='secondary'
									setOption={handleTokenChange}
									defaultOption={contractTokens.current}
									listStartSide='end'
								/>
							) : null}
						</div>
						<hr className={styles.divider} />
						<div
							className={cn(styles.inputContainer, styles.feeInputContainer)}
						>
							<div className={styles.feePayerBlock}>
								<span className={styles.feePayerText}>
									Zenland fee
									<SvgSprite
										data-for='contractCreationTooltip'
										data-tip={t('app.toolTips.newContract.zenland_fee')}
									/>
								</span>
								<span className={styles.feePayerValue}>
									{showPayerFeeChange}
								</span>
							</div>
							<div className={styles.feePayerBlock}>
								<span className={styles.feePayerText}>
									Zenland fee payer
									<SvgSprite
										data-for='contractCreationTooltip'
										data-tip={t('app.toolTips.newContract.zenland_fee_payer')}
									/>
								</span>
								<Select
									className={cn(styles.tooltipSelect, styles.feePayerOption)}
									options={Object.values(payerOptions)}
									color='secondary'
									bg='secondary'
									setOption={handlePayerChange}
									defaultOption={payerOption}
								/>
							</div>
						</div>
					</div>
					{errors.price && <p className={styles.errorText}>{errors.price}</p>}
				</div>
				{customAgentList && (
					<div className={styles.assignAgentContainer}>
						<div className={styles.assignAgentCheckboxWrapper}>
							<Checkboxes
								list={customAgentList}
								setCheckedList={setCheckedCustomAgent}
								className={cn(styles.assignAgentCheckbox, 'body-md')}
								size='sm'
								borderHidden
							/>
							<SvgSprite
								data-html
								data-for='contractCreationTooltip'
								data-tip={t('app.toolTips.newContract.assign_custom_agent')}
							/>
						</div>
						{!!checkedCustomAgent.length && (
							<>
								<div className={styles.inputContainer}>
									<Input
										name='zenlandAgent'
										id='zenlandAgent'
										placeholder='Agent*'
										largeness='md'
										type='text'
										value={agentAddress}
										autoComplete='off'
										onChange={e =>
											setAgentAddress(e.target.value.replace(/\s+/g, ''))
										}
										className={cn(styles.formInput, {
											[styles.error]: errors.buyer_protection_time
										})}
										aria-disabled={!!assignAgentData}
										disabled={!!assignAgentData}
										required
									/>
									<Button
										type='button'
										size='small'
										variant={assignAgentData ? 'outlined' : 'contained'}
										color='primary'
										className={cn(styles.assignAgentButton, {
											[styles.active]: activeButton.buyerBtn.clicked
										})}
										onClick={handleAssignAgent}
										isLoader={isLoadingAssignAgentButton}
										aria-disabled={!!assignAgentData}
										disabled={!!assignAgentData}
									>
										{assignAgentData ? 'Assigned' : 'Assign'}
									</Button>
								</div>
								{showAgentData}
								{assignAgentError && (
									<p className={cn('caption', styles.assignAgentError)}>
										{parse(assignAgentError)}
									</p>
								)}
							</>
						)}
					</div>
				)}

				<div className={cn(styles.privacyInfoText, 'caption')}>
					Creating Zenland contract you agree to comply with our{' '}
					<a
						href='https://zen.land/terms-of-service/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Terms of Service
					</a>{' '}
					and{' '}
					<a
						href='https://zen.land/privacy-policy/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Privacy Policy
					</a>
					.
				</div>

				<div className={styles.formFooter}>
					<Button
						color=''
						size='large'
						variant='contained'
						type='reset'
						onClick={() => {
							setData(prevState => ({ ...prevState, ...clearFormData }));
							clearDateTimeInput();

							// reset custom Agent checkbox
							setCustomAgentList([]);
							setCheckedCustomAgent([]);
							setAssignAgentError('');
							setAssignAgentData(null);
							setAgentAddress('');

							setTimeout(() => {
								setCustomAgentList([
									{ value: 'custom_agent', name: assignAgentLabelText }
								]);
							}, 16);
						}}
					>
						Clear
					</Button>
					<Button
						disabled={isBtnDisabled}
						color='primary'
						size='large'
						variant='contained'
					>
						{btnText}
					</Button>
				</div>
			</form>
			<div className={cn(styles.repoInfo, 'caption')}>
				Source code:
				<a
					href='https://github.com/zenland-dao/contracts/commit/91a9b907803926959b645a83b91f13ecf779dd54'
					target='_blank'
					rel='noopener noreferrer'
				>
					{cropAddress(
						'91a9b907803926959b645a83b91f13ecf779dd54',
						4,
						4,
						'contract_address'
					)}
				</a>
			</div>
			{itemData && itemData.seller && (
				<Redirect
					exact
					from='/'
					to={`/profile/${itemData.seller}/items/${itemData.slug}/create`}
				/>
			)}
			{createdId && <Redirect to={`/contracts/${createdId}`} />}
			<Tooltip id='contractCreationTooltip' />
		</>
	);
};

import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { provider } from '@core/services';
import { loginThunk } from '@core/store/auth/auth.thunks';
import { activateMetaMaskProvider } from '@core/utils/helpers/web3/activateMetaMaskProvider';
import { useAppDispatch } from '@core/utils/hooks';
import { logErrors } from '@core/utils/logErrors';
import { LoginNotificate } from '@components/DashboardComponents/LoginNotificate/LoginNotificate';
import { Button, Heading, SvgSprite } from '@components/index';

import styles from './Login.module.scss';

export const Login = () => {
	/* Redux hooks*/
	const dispatch = useAppDispatch();

	/* React hooks */
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let checkMMProvider: NodeJS.Timeout | undefined;
		if (localStorage.getItem('isSubmitClicked')) {
			checkMMProvider = setTimeout(() => {
				if (activateMetaMaskProvider(provider)) dispatch(loginThunk());
				else
					logErrors(
						"Please use only MetaMask. Ensure it's activated and other web3 wallets are deactivated or not present."
					);
			}, 500);
		}

		return () => {
			if (localStorage.getItem('isSubmitClicked')) {
				clearTimeout(checkMMProvider);
				localStorage.removeItem('isSubmitClicked');
				setIsLoading(false);
			}
		};
	}, [localStorage]);

	/* Handlers */
	const handleClick = async () => {
		setIsLoading(true);
		localStorage.setItem('isSubmitClicked', '1');
		await dispatch(loginThunk());
		setIsLoading(false);
	};

	return (
		<div className={styles.loginPage}>
			<LoginNotificate>
				<div className={styles.betaNotificate}>
					<p className={cn('subtitle-md', styles.betaNotificateHeader)}>
						Welcome to Zenland Escrow Platform
					</p>
					<p className={cn('body', styles.betaNotificateText)}>
						For the best experience, please use a <span>PC</span> and a separate
						browser (either Chrome or Firefox) with only the{' '}
						<span>MetaMask extension active</span>.{' '}
					</p>
					<p className={cn('body', styles.betaNotificateText)}>
						If you have TrustWallet, Coinbase, or Safepal installed, please
						switch them off while using Zenland.{' '}
					</p>
					<p className={cn('body', styles.betaNotificateText)}>
						Follow us on Twitter for the latest news{' '}
						<a
							href='https://twitter.com/zenland_app'
							target='_blank'
							rel='noopener noreferrer'
						>
							@Zenland_app
						</a>
					</p>
				</div>
			</LoginNotificate>
			<div className={styles.loginPageContainer}>
				<SvgSprite
					color='#1A82FB'
					iconId='logo'
					className={styles.loginPageLogo}
					width={198}
					height={48}
				/>
				<Heading tag='h1' className={cn('h5', styles.loginPageHeading)}>
					Connect a Wallet
				</Heading>
				<Button
					className={cn(styles.loginBtn, 'subtitle-md')}
					onClick={handleClick}
					isLoader={isLoading}
					loaderPosition='append'
					loaderColor='#1a82fb'
				>
					<SvgSprite
						width={40}
						height={41}
						iconId='metamask-fox'
						className={styles.metaMaskLogo}
					/>
					MetaMask
				</Button>
				<small className={cn('caption', styles.loginPagePrivacyText)}>
					By accessing Zenland, you agree to our
					<a
						href='https://zen.land/terms-of-service'
						target='_blank'
						rel='noopener noreferrer'
						className={styles.link}
					>
						{' '}
						Terms of Service{' '}
					</a>
					and confirm that you have read{' '}
					<a
						href='https://zen.land/privacy-policy'
						target='_blank'
						rel='noopener noreferrer'
						className={styles.link}
					>
						{' '}
						Privacy Policy.
					</a>
				</small>
				<p className={cn(styles.loginPageInfoText, 'subtitle-md')}>
					Dont have MetaMask?
					<a
						href='https://metamask.io/'
						target='_blank'
						rel='noopener noreferrer'
						className={styles.link}
					>
						{' '}
						Install it here
					</a>
				</p>
			</div>
		</div>
	);
};

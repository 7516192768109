import { logErrors } from '@core/utils/logErrors';

export const activateMetaMaskProvider = (provider: any) => {
	try {
		const { ethereum } = window;
		if (ethereum?.isMetaMask) {
			if (ethereum?.providers || ethereum?.isTrust || ethereum?.isSafePal)
				return false;
			return provider;
		} else return false;
	} catch (e) {
		logErrors(e);
	}
};

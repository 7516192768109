import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ContractStatusName, TransactionType } from '@core/models/enums';
import { inviteAgent } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { TransactionStatuses } from '@core/store/transaction/transaction.types';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { StepProps } from '@pages/Dashboard/Contracts/Contract/Steps/Steps.props';
import { Button, SvgSprite } from '@components/index';

import styles from '@pages/Dashboard/Contracts/Contract/Steps/Steps.module.scss';

interface InviteAgentProps extends Omit<StepProps, 'auth'> {
	isAgentInviteDisabled: boolean;
	setIsDisableWhileConfirming: Dispatch<SetStateAction<boolean>>;
}

export const InviteAgent = ({
	isAgentInviteDisabled,
	contract,
	setIsDisableWhileConfirming
}: InviteAgentProps) => {
	const { t } = useTranslation('common');
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [transaction, contractIsSyncing] = useAppSelector(
		({ transaction, contracts }) => [
			transaction,
			contracts.current.isCurrentSyncing
		]
	);

	/* React hooks */
	const [loading, setLoading] = useState(false);
	const [confirmingLoading, setConfirmingLoading] = useState<boolean>(false);
	const [isInviteAgentEvent, setIsInviteAgentEvent] = useState<boolean>(false);

	useEffect(() => {
		if (contract.transactions.length) {
			if (
				contract.transactions[0].status === TransactionStatuses.Pending &&
				contract.transactions[0].type === TransactionType.agent_invited
			) {
				setConfirmingLoading(true);
				setIsInviteAgentEvent(true);
				setIsDisableWhileConfirming(true);
			}
		}

		if (isInviteAgentEvent) {
			if (
				transaction.status === TransactionStatuses.Confirmed &&
				confirmingLoading
			) {
				dispatch(
					addAlert({
						text: 'You invited an agent to the contract',
						type: 'info',
						isSubmit: true
					})
				);
				setConfirmingLoading(false);
				setIsInviteAgentEvent(false);
				setLoading(false);
				setIsDisableWhileConfirming(false);
			}

			if (
				transaction.status === TransactionStatuses.Pending &&
				contract.status !== ContractStatusName.AgentInvited
			) {
				setConfirmingLoading(true);
				setIsDisableWhileConfirming(true);
			}

			if (transaction.status === TransactionStatuses.Failed) {
				setConfirmingLoading(false);
				setLoading(false);
				dispatch(
					addAlert({
						text: 'Transaction for invite agent failed',
						type: 'error',
						isSubmit: true
					})
				);
				setIsInviteAgentEvent(false);
			}
		}
	}, [transaction.status, contract.status]);
	const handleAgentInvite = async () => {
		if (isAgentInviteDisabled) {
			return dispatch(
				addAlert({
					text: `Contract is ${contract.status}, not disputed!`,
					type: 'error',
					isSubmit: true
				})
			);
		}

		setLoading(true);
		setIsInviteAgentEvent(true);

		const res = await inviteAgent(JSON.parse(JSON.stringify(contract)));
		if (!res) setLoading(false);
	};

	const calcAgentFee = (price: number | string) => {
		let agentFee = 0;
		if (typeof price === 'string') agentFee = parseFloat(price);
		return `${(agentFee * 0.01).toFixed(2)} - ${(agentFee * 0.03).toFixed(2)}`;
	};

	return (
		<>
			<Button
				className={styles.stepBtn}
				onClick={handleAgentInvite}
				disabled={
					contract.isNetworkNotMatched ||
					isAgentInviteDisabled ||
					contract.is_syncing ||
					contractIsSyncing
				}
				isLoader={loading}
				isConfirming={confirmingLoading}
				variant='outlined'
				size='medium'
				color='danger'
			>
				<SvgSprite iconId='metamask-fox-outlined' /> Invite an agent
			</Button>
			<p className={cn('caption', styles.inviteAgentHint)}>
				Inviting an Agent you will grant the Agent a full control over your case
				and agree with its resolution. <br />
				The agent fee is {calcAgentFee(contract.price)} {contract.token.name}{' '}
				depending on the complexity of the case.{' '}
				<SvgSprite
					size={20}
					data-for='contractStepTooltip'
					data-tip={t('app.toolTips.contracts.dataTip.agentInvited')}
					data-html
				/>
			</p>
		</>
	);
};

import { API } from '@core/config/api';
import {
	FetchOptions,
	IContract,
	IContractFilter
} from '@core/models/interfaces';
import { Signup } from '@core/services';
import { setContractIsSyncing } from '@core/store/contract/contract.slice';
import { store } from '@core/store/store';
import { getSearchParams } from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';
import { unregisteredUserPattern } from '@core/utils/regex';

import { ContractsResponse, PostContractsParams } from './models';

export const Contracts = {
	async getAll(
		queryParams: Partial<IContractFilter> = {},
		options?: FetchOptions
	): Promise<ContractsResponse | void> {
		const assignedParams = new URLSearchParams(getSearchParams(queryParams));

		try {
			const response = await fetch(`${API.contracts}?${assignedParams}`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async syncWithBlockchain(id: string, options?: FetchOptions): Promise<void> {
		try {
			const response = await fetch(
				`${API.contracts}${id}/sync_with_blockchain/`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'X-CSRFToken': getCookie('csrftoken')
					},
					signal: options?.signal,
					credentials: 'include'
				}
			);

			if (!response.ok) {
				logErrors(await response.json());
				store.dispatch(setContractIsSyncing(false));
			}
		} catch (e) {
			logErrors(e);
		}
	},
	async get(
		id: number,
		withInterface = false,
		options?: FetchOptions
	): Promise<IContract | void> {
		try {
			const response = await fetch(
				`${API.contracts}${id}/${
					withInterface ? '?include_interface=true' : ''
				}`,
				{
					headers: {
						'Content-Type': 'application/json',
						'X-CSRFToken': getCookie('csrftoken')
					},
					signal: options?.signal,
					credentials: 'include'
				}
			);

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async post(params: PostContractsParams): Promise<IContract | void> {
		const buyer = params.buyer.toLowerCase();
		const seller = params.seller.toLowerCase();
		try {
			const response = await fetch(API.contracts, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				body: JSON.stringify({
					...params,
					buyer,
					seller
				})
			});

			const data: IContract = await response.json();

			if (!response.ok) {
				let isSigned = false;

				if (unregisteredUserPattern.test(data.buyer?.toString())) {
					isSigned = await Signup.post({ address: buyer, proceedLogin: false });
				} else if (unregisteredUserPattern.test(data.seller?.toString())) {
					isSigned = await Signup.post({
						address: seller,
						proceedLogin: false
					});
				} else {
					return logErrors(data);
				}

				if (isSigned) return this.post(params);
			}

			return data;
		} catch (e) {
			logErrors(e);
		}
	},
	async patch({
		id,
		body
	}: {
		id: number;
		body: Partial<IContract>;
	}): Promise<IContract | void> {
		try {
			const response = await fetch(`${API.contracts}${id}/`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				body: JSON.stringify({ ...body })
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async getInterface(
		id: number
	): Promise<{ abi: []; bytecode: string; agent: string } | void> {
		try {
			const response = await fetch(`${API.contracts}${id}/get_interface/`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include'
			});
			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

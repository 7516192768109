import React, { useState } from 'react';

import { Button, Heading, Input, SvgSprite } from '@components/index';

import styles from './NotificationsSettings.module.scss';

type SettingsTypes =
	| 'buyerMsg'
	| 'sellerMsg'
	| 'approvement'
	| 'deployment'
	| 'transfer'
	| 'release';

interface ISettingsList {
	id: SettingsTypes;
	title: string;
	desc: string;
}

const settingsList: ISettingsList[] = [
	{
		id: 'buyerMsg',
		title: 'Buyer message',
		desc: 'When buyer sends you a message'
	},
	{
		id: 'sellerMsg',
		title: 'Seller message',
		desc: 'When seller sends you a message'
	},
	{
		id: 'approvement',
		title: 'Contract approvement',
		desc: 'When someone purchased one of your items'
	},
	{
		id: 'deployment',
		title: 'Contract deployment',
		desc: 'When someone purchased one of your items'
	},
	{
		id: 'transfer',
		title: 'Transfer of founds',
		desc: 'When someone purchased one of your items'
	},
	{
		id: 'release',
		title: 'Release of founds',
		desc: 'When someone purchased one of your items'
	},
	{
		id: 'buyerMsg',
		title: 'Buyer message',
		desc: 'When buyer sends you a message'
	},
	{
		id: 'sellerMsg',
		title: 'Seller message',
		desc: 'When seller sends you a message'
	},
	{
		id: 'approvement',
		title: 'Contract approvement',
		desc: 'When someone purchased one of your items'
	},
	{
		id: 'deployment',
		title: 'Contract deployment',
		desc: 'When someone purchased one of your items'
	},
	{
		id: 'transfer',
		title: 'Transfer of founds',
		desc: 'When someone purchased one of your items'
	},
	{
		id: 'release',
		title: 'Release of founds',
		desc: 'When someone purchased one of your items'
	}
];

export const NotificationsSettingsList = () => {
	const [isDisabled, setIsDisabled] = useState(true);
	const [values, setValues] = useState({
		buyerMsg: false,
		sellerMsg: false,
		approvement: false,
		deployment: false,
		transfer: false,
		release: false
	});

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsDisabled(true);
	};

	const handleChange =
		(prop: SettingsTypes) => (e: React.ChangeEvent<HTMLInputElement>) => {
			setIsDisabled(false);
			setValues({ ...values, [prop]: e.target.checked });
		};

	const renderSettings = () => {
		return settingsList.map((item, index) => (
			<label className={styles.item} key={index}>
				<div>
					<Input
						className={styles.checkbox}
						onChange={handleChange(settingsList[index].id)}
						checked={values[settingsList[index].id]}
						type='checkbox'
					/>
					<div className={styles.checkIconWrapper}>
						<SvgSprite
							className={styles.checkIcon}
							iconId='status-success-circle'
						/>
					</div>
				</div>
				<div className={styles.itemInfo}>
					<span className={styles.itemTitle}>{item.title}</span>
					<span className={styles.itemDesc}>{item.desc}</span>
				</div>
			</label>
		));
	};
	return (
		<form onSubmit={handleSubmit}>
			<div className={styles.form}>{renderSettings()}</div>
			<Button disabled={isDisabled}>Save</Button>
		</form>
	);
};

export const NotificationsSettings = () => {
	return (
		<div className='pageWrapper'>
			<Heading tag='h1'>Notifications Settings</Heading>
			<p className={styles.subtitle}>
				Select which notifications you would like to receive for 0xd696...1315
			</p>
			<NotificationsSettingsList />
		</div>
	);
};

import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';

import { usePagination } from '@core/utils/hooks';
import { Button, SvgSprite } from '@components/index';

import styles from './Pagination.module.scss';

export interface ContractsPaginationProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	totalCount: number;
	siblingCount: number;
	currentPage: number;
	pageSize: number;
	onPageChange: (page: number) => void;
}

export const Pagination = ({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	className
}: ContractsPaginationProps) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize
	});
	if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		currentPage !== lastPage && onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		currentPage > 1 && onPageChange(currentPage - 1);
	};

	const lastPage = paginationRange[paginationRange.length - 1];

	const renderRange = () => {
		return paginationRange.map((pageNumber, index) => {
			if (typeof pageNumber === 'string') {
				return (
					<li className={cn(styles.dots)} key={index}>
						...
					</li>
				);
			}

			return (
				<li key={index}>
					<Button
						className={cn(styles.paginationLink, {
							[styles.active]: pageNumber === currentPage
						})}
						disabled={pageNumber === currentPage}
						onClick={() => onPageChange(pageNumber)}
					>
						{pageNumber}
					</Button>
				</li>
			);
		});
	};

	return (
		<nav className={styles.pagination} data-testid='paginationComponent'>
			<Button
				className={cn(styles.arrowBtn, styles.prevBtn)}
				title='Previous page'
				onClick={onPrevious}
				disabled={currentPage === 1}
			>
				<SvgSprite width={24} height={24} iconId='arrow-down' />
			</Button>
			<ul className={cn(styles.paginationMenu, className)}>{renderRange()}</ul>
			<Button
				className={cn(styles.arrowBtn, styles.nextBtn)}
				disabled={currentPage === lastPage}
				title='Next page'
				onClick={onNext}
			>
				<SvgSprite width={24} height={24} iconId='arrow-down' />
			</Button>
		</nav>
	);
};

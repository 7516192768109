import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Users } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setWhoAmI } from '@core/store/auth/auth.slice';
import { isFileSizeLarger } from '@core/utils/helpers/isFileSizeLarger';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { emailPattern, nicknamePattern } from '@core/utils/regex';
import { FileInputs } from '@pages/Dashboard/Settings/Profile/FileInputs';
import { ITextInputsValues } from '@pages/Dashboard/Settings/Profile/ProfileSettings.props';
import { TextInputs } from '@pages/Dashboard/Settings/Profile/TextInputs';
import { Button, Heading, Tooltip } from '@components/index';

import styles from './ProfileSettings.module.scss';

export const ProfileSettings = () => {
	/** Redux hooks **/
	const auth = useAppSelector(({ auth }) => auth);
	const dispatch = useAppDispatch();

	/** React hooks **/
	const [isLoading, setIsLoading] = useState(false);
	const [isUseEmail] = useState(false);

	const [isDisabled, setIsDisabled] = useState(true);
	const [fields, setFields] = useState<ITextInputsValues>({
		nickname: auth.whoAmI.nickname,
		email: auth.whoAmI.email,
		bio: auth.whoAmI.bio,
		avatar: { file: null, url: auth.whoAmI.avatar || '' },
		cover: { file: null, url: auth.whoAmI.cover || '' }
	});

	useEffect(() => {
		setFields({
			nickname: auth.whoAmI.nickname,
			email: auth.whoAmI.email,
			avatar: { file: null, url: auth.whoAmI.avatar || '' },
			cover: { file: null, url: auth.whoAmI.cover || '' },
			bio: auth.whoAmI.bio
		});
	}, [auth.whoAmI]);

	/** Handlers **/
	const handleChange = () => setIsDisabled(false);

	const handleCancel = () => {
		setFields({
			nickname: auth.whoAmI.nickname,
			email: auth.whoAmI.email,
			bio: auth.whoAmI.bio,
			avatar: { file: null, url: auth.whoAmI.avatar || '' },
			cover: { file: null, url: auth.whoAmI.cover || '' }
		});
		setIsDisabled(true);
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData();

		if (fields.nickname?.length > 0) {
			if (fields.nickname?.match(nicknamePattern)) {
				if (fields.nickname.length > 0 && fields.nickname.length < 5) {
					return dispatch(
						addAlert({
							text: 'Minimum nickname length is 5 characters',
							type: 'error'
						})
					);
				}

				formData.append('nickname', fields.nickname);
			} else {
				dispatch(
					addAlert({
						text: 'Nickname must start and end with a letter and contain only letters, numbers, and underscores',
						type: 'error'
					})
				);
			}
		} else formData.append('nickname', fields.nickname || '');

		if (isUseEmail) {
			if (fields.email && fields.email.length > 0) {
				if (fields.email.match(emailPattern)) {
					formData.append('email', fields.email);
				} else {
					dispatch(
						addAlert({
							text: 'Invalid email',
							type: 'error'
						})
					);
				}
			} else formData.append('email', fields.email || '');
		}

		if (fields.bio && fields.bio.length > 0) {
			formData.append('bio', fields.bio.trim());
		} else formData.append('bio', fields.bio || '');

		if (fields.avatar.file) {
			if (isFileSizeLarger(fields.avatar.file, 1))
				return dispatch(
					addAlert({
						text: 'Profile image size more than 1 MB limit',
						type: 'error'
					})
				);
			formData.append('avatar', fields.avatar.file, fields.avatar.file.name);
		}

		if (fields.cover?.file) {
			if (isFileSizeLarger(fields.cover.file, 1))
				return dispatch(
					addAlert({
						text: 'Background image size is more than 1 MB limit',
						type: 'error'
					})
				);
			formData.append('cover', fields.cover.file, fields.cover.file.name);
		}

		setIsLoading(true);

		const res = await Users.patch({ id: auth.whoAmI.username, formData });

		if (res) {
			dispatch(setWhoAmI(res));
			setIsDisabled(true);
		}

		setIsLoading(false);
	};

	const toolTipHandleClick = (
		e: React.MouseEvent<SVGSVGElement, MouseEvent>
	) => {
		e.preventDefault();
	};

	return (
		<div className='pageWrapper'>
			<Heading tag='h1' className={cn(styles.title, 'h5')}>
				Profile settings
			</Heading>
			<form onSubmit={handleSubmit} className={cn('body-md', styles.form)}>
				<FileInputs
					onChange={handleChange}
					fields={fields}
					setFields={setFields}
					toolTipDataFor='profileSettings'
					toolTipHandleClick={toolTipHandleClick}
				/>
				<TextInputs
					onChange={handleChange}
					fields={fields}
					setFields={setFields}
					toolTipDataFor='profileSettings'
					toolTipHandleClick={toolTipHandleClick}
				/>
				<div className={styles.btns}>
					<Button
						disabled={isDisabled}
						onClick={handleCancel}
						variant='text'
						size='medium'
						color='primary'
						type='reset'
					>
						Cancel
					</Button>
					<Button
						disabled={isDisabled || auth.maintenance.isActive}
						variant='contained'
						size='medium'
						color='primary'
						isLoader={isLoading}
					>
						Save
					</Button>
				</div>
			</form>
			<Tooltip place='top' id='profileSettings' />
		</div>
	);
};

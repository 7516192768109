import React, { Fragment } from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import { Link, useHistory } from 'react-router-dom';

import { ItemModerationStatusEnums } from '@core/models/enums/itemEnums';
import { setItemAllFilters } from '@core/store/item/item.slice';
import { setItemByIdListThunk } from '@core/store/item/item.thunks';
import { getHoursBySeconds, getSearchParams } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { ItemsTableProps } from '@pages/Dashboard/Items/Item.props';
import { Badge, Button, SvgSprite, Switcher } from '@components/index';
import { BadgeProps } from '@components/MainComponents/Badge/Badge.props';
import { TableSkeleton } from '@skeletons/contracts/TableSkeleton';

import styles from './Items.module.scss';

// @TODO: below component same as ContactsTable need to generalize common styles and functions
export const ItemsTable = ({ list, isLoading }: ItemsTableProps) => {
	/* React Router hooks */
	const history = useHistory();

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [itemFilter, maintenance] = useAppSelector(({ itemsFilter, auth }) => [
		itemsFilter,
		auth.maintenance
	]);

	if (isLoading || !list) return <TableSkeleton />;
	if (list?.length === 0)
		return <p className={styles.notFoundText}>Items not found.</p>;

	/* Handlers */
	const handleClick = (ordering: string) => () => {
		if (itemFilter.ordering === ordering) {
			dispatch(
				setItemAllFilters({
					...itemFilter,
					offset: 0,
					ordering: `-${ordering}`
				})
			);
		} else {
			dispatch(
				setItemAllFilters({
					...itemFilter,
					offset: 0,
					ordering: ordering
				})
			);

			const params = new URLSearchParams(
				getSearchParams({ ...itemFilter, offset: 0, ordering })
			);
			history.push(`/items?${params}`);
		}
	};

	const handleSwitcherClick = (slug: string, is_active: boolean) => () => {
		dispatch(setItemByIdListThunk({ slug, is_active }));
	};

	const handleSwitcherKeyUp =
		(slug: string, is_active: boolean) => (e: React.KeyboardEvent) => {
			if (e.key === ' ' || e.key === 'Enter') {
				dispatch(setItemByIdListThunk({ slug, is_active }));
			}
		};

	const handleLinkClick = (e: React.MouseEvent) => {
		const { target } = e;

		if (target instanceof HTMLElement) {
			const regex = new RegExp('switcher', 'gi');

			if (regex.test(target.classList.value)) e.preventDefault();
		}
	};

	const handleLinkKeyDown = (e: React.KeyboardEvent) => {
		const { target } = e;

		if (target instanceof HTMLElement) {
			if (
				!target.classList.value.includes('tableLink') &&
				(e.key === ' ' || e.key === 'Enter')
			)
				e.preventDefault();
		}
	};

	const displayModerationStatus = (status: ItemModerationStatusEnums) => {
		let _color: BadgeProps['color'];
		switch (status) {
			case 'accepted':
				_color = 'success';
				break;
			case 'rejected':
				_color = 'danger';
				break;
			case 'pending':
				_color = 'await';
				break;
			default:
				_color = 'await';
		}

		return (
			<Badge color={_color} hasIcon text='md' className={styles.productStatus}>
				{status}
			</Badge>
		);
	};

	const renderLinks = () => {
		return list?.map(item => (
			<Link
				to={`/items/${item.slug}`}
				className={cn('subtitle', styles.tableLink, 'tableRowItem')}
				key={item.id}
				onClick={handleLinkClick}
				onKeyDown={handleLinkKeyDown}
			>
				<div className={cn(styles.productItem, styles.productTitleBlock)}>
					<span className={styles.productTitle}>Item ID</span>
					<span>#{item.id}</span>
				</div>

				<div className={cn(styles.productItem, styles.productPictureBlock)}>
					<span className={cn(styles.productPicture, styles.productTitle)}>
						Item picture
					</span>
					{item.image ? (
						<img src={item.image} alt='item picture' />
					) : (
						<SvgSprite iconId='image' width={52} height={52} />
					)}
				</div>

				<div className={styles.productItem}>
					<span className={styles.productTitle}>Item name</span>
					<p className='truncate'>{item.title}</p>
				</div>

				<div className={styles.productItem}>
					<span className={cn(styles.productDeliveryTime, styles.productTitle)}>
						Item delivery time
					</span>
					<span>{getHoursBySeconds(item.delivery_time)}</span>
				</div>

				<div className={cn(styles.productItem)}>
					<span className={cn(styles.productCreated, styles.productTitle)}>
						Created on
					</span>
					<div>
						<Moment format='DD/MM/YYYY HH:mm'>{item.created}</Moment>
					</div>
				</div>

				<div className={cn(styles.productItem)}>
					<span className={cn(styles.productPrice, styles.productTitle)}>
						Price
					</span>
					<span>{item.price}</span>
				</div>

				<div className={cn(styles.productItem)}>
					<span
						className={cn(styles.productModerationStatus, styles.productTitle)}
					>
						Moderation status
					</span>
					<span>{displayModerationStatus(item.moderation_status)}</span>
				</div>

				<div className={cn(styles.productItem)}>
					<span className={cn(styles.productState, styles.productTitle)}>
						Item state
					</span>
					<Button
						onClick={handleSwitcherClick(item.slug, item.is_active)}
						onKeyUp={handleSwitcherKeyUp(item.slug, item.is_active)}
						disabled={maintenance.isActive}
						className={styles.productSwitcherBtn}
					>
						<Switcher
							checked={item.is_active}
							disabled={maintenance.isActive}
						/>
					</Button>
				</div>
			</Link>
		));
	};

	const itemsTableNavs = [
		{ name: 'id', title: 'ID', details: '#' },
		{ name: 'title', title: 'Item name', details: '' },
		{ name: 'delivery_time', title: 'Delivery', details: '/ time' },
		{ name: 'created', title: 'Created on', details: '' },
		{ name: 'price', title: 'Price', details: '' },
		{ name: 'moderation_status', title: 'Moderation', details: 'Status' },
		{ name: 'is_active', title: 'Active', details: '' }
	];

	return (
		<div className={styles.tableGrid}>
			<div className={styles.tableHead}>
				{itemsTableNavs.map((n, i) => {
					if (i === 0) {
						return (
							<Fragment key={i}>
								<Button
									onClick={handleClick('id')}
									className={cn('tableNavBtn', styles.orderingByID, 'body-md', {
										['active']: itemFilter.ordering.includes('id'),
										['arrowReversed']: itemFilter.ordering === '-id'
									})}
									type='button'
								>
									<span className={styles.moreDetail}># </span>
									ID
									<SvgSprite iconId='arrow-down' />
								</Button>
								<Button key={22} />
							</Fragment>
						);
					}
					return (
						<Button
							key={i}
							onClick={handleClick(n.name)}
							className={cn('tableNavBtn', 'body-md', {
								['active']: itemFilter.ordering.includes(n.name),
								['arrowReversed']: itemFilter.ordering === `-${n.name}`,
								[styles.orderingByTitle]: 'title' === n.name,
								[styles.orderingByDelivery]: 'delivery_time' === n.name,
								[styles.orderingByModerationStatus]:
									'moderation_status' === n.name
							})}
							type='button'
						>
							{n.title}
							{n.details && (
								<span className={styles.moreDetail}>{' ' + n.details}</span>
							)}
							<SvgSprite iconId='arrow-down' />
						</Button>
					);
				})}
			</div>
			{renderLinks()}
		</div>
	);
};

import { API } from '@core/config/api';
import { FetchOptions } from '@core/models/interfaces';
import { IMainFilter } from '@core/models/interfaces/mainInterfaces/mainInterfaces';
import { getSearchParams } from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Referrals = {
	async getList(queryParams: Partial<IMainFilter>, options?: FetchOptions) {
		try {
			const assignedParams = new URLSearchParams(getSearchParams(queryParams));

			const response = await fetch(`${API.referrals}?${assignedParams}`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async getTransactions(
		queryParams: Partial<IMainFilter>,
		options?: FetchOptions
	) {
		try {
			const assignedParams = new URLSearchParams(getSearchParams(queryParams));

			const response = await fetch(
				`${API.referralTransactions}?${assignedParams}`,
				{
					headers: {
						'Content-Type': 'application/json',
						'X-CSRFToken': getCookie('csrftoken')
					},
					signal: options?.signal,
					credentials: 'include'
				}
			);

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async postTransaction(value: string) {
		try {
			const response = await fetch(API.referralTransactions, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify({ value })
			});

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { IMainFilter } from '@core/models/interfaces/mainInterfaces/mainInterfaces';
import { Referrals } from '@core/services/referrals/referrals';
import { setUserReferralBalance } from '@core/store/auth/auth.slice';
import {
	setReferralCountAction,
	setReferralListAction,
	setReferralLoadingAction,
	setReferralTransactionsAction,
	setReferralTransactionsCountAction
} from '@core/store/referral/referral.slice';
import { logErrors } from '@core/utils/logErrors';

export const setReferralsThunk = createAsyncThunk<
	void,
	Partial<IMainFilter> | null
>('referrals/setList', async (payload, { dispatch, signal }) => {
	if (!payload) payload = { offset: 0, limit: 10 };

	dispatch(setReferralLoadingAction(true));
	const foundReferrals = await Referrals.getList(payload, { signal });

	if (foundReferrals) {
		const { count, results } = foundReferrals;
		dispatch(setReferralListAction(results));
		dispatch(setReferralCountAction(count));
	}
});

export const setReferralsTransactionsThunk = createAsyncThunk<
	void,
	Partial<IMainFilter> | null
>('referrals/setTransactions', async (payload, { dispatch, signal }) => {
	if (!payload) payload = { offset: 0, limit: 10 };

	dispatch(setReferralLoadingAction(true));
	const foundReferralTransactions = await Referrals.getTransactions(payload, {
		signal
	});

	if (foundReferralTransactions) {
		const { count, results } = foundReferralTransactions;
		dispatch(setReferralTransactionsAction(results));
		dispatch(setReferralTransactionsCountAction(count));
	}
});

export const setReferralsTransactionThunk = createAsyncThunk<boolean, string>(
	'referrals/setTransaction',
	async (payload, { dispatch }) => {
		if (!payload) return false;
		const transaction = await Referrals.postTransaction(payload);

		if (transaction.id) {
			dispatch(setReferralLoadingAction(true));
			dispatch(setReferralTransactionsAction(transaction));
			dispatch(setUserReferralBalance(transaction.running_balance));
			return true;
		} else logErrors(transaction.value);
		return false;
	}
);

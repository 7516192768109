import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractStatusName } from '@core/models/enums';
import { ContractsCount, IContract } from '@core/models/interfaces';

import { InitialContractList, InitialFilterState } from './contract.types';

const initialFilterState: InitialFilterState = {
	limit: 10,
	offset: 0,
	status__in: '',
	search: '',
	ordering: ''
};

export const contractFilterSlice = createSlice({
	name: 'contractFilter',
	initialState: initialFilterState,
	reducers: {
		setContractAllFilters: (
			state,
			action: PayloadAction<InitialFilterState>
		) => {
			return {
				...state,
				...action.payload
			};
		},
		setLimitAction: (state, action: PayloadAction<number>) => {
			return { ...state, limit: action.payload };
		},
		setOffsetAction: (state, action: PayloadAction<number>) => {
			return { ...state, offset: action.payload };
		},
		setSearchAction: (state, action: PayloadAction<string>) => {
			return { ...state, search: action.payload };
		},
		setOrderingAction: (state, action: PayloadAction<string>) => {
			return { ...state, ordering: action.payload };
		},
		setStatusAction: (
			state,
			action: PayloadAction<ContractStatusName | ''>
		) => {
			return { ...state, status__in: action.payload };
		}
	}
});

const initialContractList: InitialContractList = {
	counts: {
		total_count: 0,
		count: 0,
		draft_count: 0,
		approved_count: 0,
		deployed_count: 0,
		active_count: 0,
		agent_invited_count: 0,
		disputed_count: 0,
		fulfilled_count: 0,
		dispute_finished_count: 0,
		executed_count: 0
	},
	results: null,
	isLoading: false,
	current: {
		data: null,
		isCurrentExpired: true,
		isCurrentLoaded: false,
		isCurrentSyncing: false,
		isNetworkNotMatched: false
	}
};

export const contractListSlice = createSlice({
	name: 'contractList',
	initialState: initialContractList,
	reducers: {
		setContractListAction: (state, action: PayloadAction<IContract[]>) => {
			return { ...state, results: action.payload, isLoading: false };
		},
		setContractAction: (state, action: PayloadAction<IContract | null>) => {
			if (!action.payload) {
				return {
					...state,
					current: {
						data: null,
						isCurrentExpired: false,
						isCurrentLoaded: false,
						isCurrentSyncing: false,
						isNetworkNotMatched: false
					}
				};
			}
			return {
				...state,
				current: {
					data: {
						...action.payload,
						agent: {
							...action.payload.agent,
							username: action.payload.agent.username.toLowerCase() || ''
						},
						buyer: {
							...action.payload.buyer,
							username: action.payload.buyer.username.toLowerCase()
						},
						seller: {
							...action.payload.seller,
							username: action.payload.seller.username.toLowerCase()
						}
					},
					isCurrentExpired:
						(action.payload.status === 'draft' ||
							action.payload.status === 'approved') &&
						new Date(action.payload.deadline).getTime() - Date.now() < 0,
					isCurrentLoaded: true,
					isCurrentSyncing: false,
					isNetworkNotMatched: false
				}
			};
		},
		setCountsAction: (state, action: PayloadAction<ContractsCount>) => {
			return { ...state, counts: { ...state.counts, ...action.payload } };
		},
		setIsContractLoading: (state, action: PayloadAction<boolean>) => {
			return { ...state, isLoading: action.payload };
		},
		setContractIsSyncing: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				current: { ...state.current, isCurrentSyncing: action.payload }
			};
		},
		setCurrentContractAgent: (state, action: PayloadAction<string>) => {
			if (state.current.data) {
				return {
					...state,
					current: {
						...state.current,
						data: {
							...state.current.data,
							agent: { id: 0, nickname: '', username: action.payload }
						}
					}
				};
			}
		},
		setNetworkNotMatched: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				current: { ...state.current, isNetworkNotMatched: action.payload }
			};
		}
	}
});

export const {
	reducer: contractListReducer,
	actions: {
		setContractListAction,
		setContractAction,
		setCountsAction,
		setIsContractLoading,
		setNetworkNotMatched,
		setCurrentContractAgent,
		setContractIsSyncing
	}
} = contractListSlice;

export const {
	reducer: contractFilterReducer,
	actions: { setContractAllFilters, setOffsetAction, setSearchAction }
} = contractFilterSlice;

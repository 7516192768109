import React, { useEffect, useState } from 'react';

import { WalletType } from '@core/models/enums';
import { setWalletFilters } from '@core/store/wallet/wallet.slice';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { FilterNav } from '@components/DashboardComponents';
import { Select } from '@components/MainComponents/Select/Select';

import styles from '@pages/Dashboard/Notifications/Notifications.module.scss';

export const WalletNav = () => {
	/* Redux hooks */
	const wallet = useAppSelector(({ wallet }) => wallet);
	const dispatch = useAppDispatch();

	/* Handlers */
	const handleFilterChange = (type: WalletType | '') => async () => {
		if (type) {
			dispatch(
				setWalletFilters({
					...wallet.filters,
					offset: 0,
					type
				})
			);
		} else {
			dispatch(
				setWalletFilters({
					...wallet.filters,
					offset: 0,
					type: null
				})
			);
		}
	};

	const renderFilterCount = (type?: WalletType) => {
		switch (type) {
			case WalletType.Debit:
				return wallet.transactions.debit_count;
			case WalletType.Credit:
				return wallet.transactions.credit_count;
			default:
				return wallet.transactions.total_count;
		}
	};

	const filterList: {
		name: string;
		value: WalletType | '';
		count: number;
	}[] = [
		{
			name: 'All',
			value: '',
			count: renderFilterCount()
		},
		{
			name: 'Deposit',
			value: WalletType.Debit,
			count: renderFilterCount(WalletType.Debit)
		},
		{
			name: 'Spent',
			value: WalletType.Credit,
			count: renderFilterCount(WalletType.Credit)
		}
	];

	const filterListDropdown: {
		name: string;
		value: WalletType | '';
	}[] = filterList.map(item => {
		return {
			name: `${item.name} <data>${item.count}</data>`,
			value: item.value
		};
	});

	/* React hooks */
	const [filterOption, setFilterOption] = useState(filterListDropdown[0]);
	useEffect(() => {
		setFilterOption(filterListDropdown[0]);
	}, [wallet.transactions.total_count]);

	/* Handler */
	const handleChangeFilterOptions = (option: {
		name: string;
		value: WalletType | '';
	}) => {
		handleFilterChange(option.value)();
		setFilterOption({ ...option, value: option.value });
	};

	return (
		<>
			<div className={styles.wideFilterNav}>
				<FilterNav<
					{
						name: string;
						value: WalletType | '';
						count: number;
					},
					WalletType | ''
				>
					filterList={filterList}
					handleChange={handleFilterChange}
					count={wallet.transactions.count}
					offset={wallet.filters.offset}
					status={wallet.filters.type || ''}
				/>
			</div>
			<Select<WalletType | ''>
				className={styles.narrowFilterDropdown}
				defaultOption={filterOption}
				options={filterListDropdown}
				setOption={handleChangeFilterOptions}
				listStartSide='end'
			/>
		</>
	);
};

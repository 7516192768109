import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
	ContractRole,
	ContractStatusName,
	ContractStatusNumber,
	TransactionType
} from '@core/models/enums';
import { transferContract } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { TransactionStatuses } from '@core/store/transaction/transaction.types';
import { getTxByType } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { CopiableLink } from '@components/DashboardComponents';
import { Badge, Button, SvgSprite } from '@components/index';

import { StepContainer } from './StepContainer';
import { StepProps } from './Steps.props';

import styles from './Steps.module.scss';

export const Transfer = ({ contract, auth }: StepProps) => {
	const { t } = useTranslation('common');
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [transaction, currentContractIsSyncing] = useAppSelector(
		({ transaction, contracts }) => [
			transaction,
			contracts.current.isCurrentSyncing
		]
	);

	/* React hooks */
	const [loading, setLoading] = useState(false);
	const [confirmingLoading, setConfirmingLoading] = useState<boolean>(false);
	const [isTransferEvent, setIsTransferEvent] = useState<boolean>(false);

	useEffect(() => {
		if (contract.transactions.length) {
			if (
				contract.transactions[0].status === TransactionStatuses.Pending &&
				contract.transactions[0].type === TransactionType.transferred
			) {
				setConfirmingLoading(true);
				setIsTransferEvent(true);
			}
		}

		if (isTransferEvent) {
			if (
				contract.status === ContractStatusName.Active &&
				transaction.status === TransactionStatuses.Confirmed &&
				confirmingLoading
			) {
				dispatch(
					addAlert({
						text: `You have transferred agreed amount ${contract.price} ${contract.token.name} into the contract #${contract.id}`,
						type: 'info',
						isSubmit: true
					})
				);
				setConfirmingLoading(false);
				setIsTransferEvent(false);
				setLoading(false);
			}

			if (
				transaction.status === TransactionStatuses.Pending &&
				contract.status !== ContractStatusName.Active
			) {
				setConfirmingLoading(true);
			}

			if (transaction.status === TransactionStatuses.Failed) {
				setConfirmingLoading(false);
				setLoading(false);
				dispatch(
					addAlert({
						text: 'Transaction for transfer to contract failed',
						type: 'error',
						isSubmit: true
					})
				);
				setIsTransferEvent(false);
			}
		}
	}, [transaction.status, contract.status]);

	const { role } = auth;
	const isBuyer = role === ContractRole.Buyer;
	const isDisabled =
		!isBuyer ||
		ContractStatusNumber[contract.status] !== ContractStatusNumber.deployed;
	const txByType = getTxByType(
		contract.transactions,
		TransactionType.transferred
	);

	const handleTransfer = async () => {
		if (isDisabled) {
			return dispatch(
				addAlert({
					text: `Contract is ${contract.status}, not deployed!`,
					type: 'error'
				})
			);
		}
		setLoading(true);
		setIsTransferEvent(true);

		const res = await transferContract(contract);
		if (!res) setLoading(false);
	};

	return (
		<StepContainer
			stepStatus={ContractStatusName.Deployed}
			contractStatus={contract.status}
			title='Transfer'
			tooltip={t('app.toolTips.contracts.transfer')}
		>
			<div className={styles.stepContent}>
				{ContractStatusNumber[contract.status] <
					ContractStatusNumber.deployed &&
					isBuyer && (
						<Badge text='sm' color='warn' hasIcon>
							Contract has not been deployed yet. Deploy the contract before
							transfer.
						</Badge>
					)}

				{ContractStatusNumber[contract.status] <
					ContractStatusNumber.active && (
					<>
						{isBuyer ? (
							<Button
								className={styles.stepBtn}
								onClick={handleTransfer}
								disabled={
									contract.isNetworkNotMatched ||
									isDisabled ||
									contract.is_syncing ||
									currentContractIsSyncing
								}
								isLoader={loading}
								isConfirming={confirmingLoading}
								variant='contained'
								size='medium'
								color='primary'
							>
								<SvgSprite
									iconId='metamask-fox-outlined'
									className={styles.metamaskIcon}
								/>{' '}
								Transfer
							</Button>
						) : (
							<Badge text='sm' color='warn' hasIcon>
								Agreed amount has not been transferred yet.
							</Badge>
						)}
					</>
				)}

				{ContractStatusNumber[contract.status] ===
					ContractStatusNumber.deployed &&
					isBuyer && (
						<span className={cn('caption', styles.transferAmount)}>
							Transfer amount: {contract.price} {contract.token.name}
						</span>
					)}

				{ContractStatusNumber[contract.status] >
					ContractStatusNumber.deployed && (
					<>
						<Badge text='sm' color='success' hasIcon>
							Agreed amount ({contract.price} {contract.token.name}) has been
							successfully transferred into the contract.
						</Badge>
						{txByType && (
							<div className={cn(styles.copyTextWrapper, 'subtitle')}>
								<span className={styles.bold}>Transaction ID: </span>
								<CopiableLink
									text={txByType?.tx_hash || ''}
									url={`${contract.network.explorer_url}/tx/${
										txByType?.tx_hash || ''
									}`}
								/>
							</div>
						)}
					</>
				)}
			</div>
		</StepContainer>
	);
};

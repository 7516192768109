import React from 'react';
import cn from 'classnames';

import { Heading } from '@components/index';

import styles from './Home.module.scss';

export const Home = () => {
	return (
		<div className={cn('container', styles.homeWrapper)}>
			<Heading tag='h1' className='h5'>
				Home
			</Heading>
			<div>
				<p>
					You are running this application in <b>{process.env.NODE_ENV} </b>
					mode.
				</p>
			</div>
		</div>
	);
};

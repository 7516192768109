import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser, IWhoAmIResponse } from '@core/models/interfaces';

const initialState: {
	isNotFound: boolean;
	user: IUser | IWhoAmIResponse | null;
} = {
	isNotFound: false,
	user: null
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setNotFound: (state, action: PayloadAction<boolean>) => {
			state.isNotFound = action.payload;
		},
		setUser: (state, action: PayloadAction<IUser | IWhoAmIResponse | null>) => {
			state.user = action.payload;
		}
	}
});

export const {
	reducer: userReducer,
	actions: { setNotFound, setUser }
} = userSlice;

import { NotificationModel } from '@core/models/enums';
import { INotification } from '@core/models/interfaces';
import { cropAddress } from '@core/utils/helpers/cropAddress';

export const getNotificationInfo = (data: INotification) => {
	switch (data.action_object_content_type?.model) {
		case NotificationModel.Contracts:
			return {
				title: 'Contract',
				id: data.action_object_object_id,
				url: `/contracts/${data.action_object_object_id}`,
				icon: 'user',
				name: data.actor.nickname || cropAddress(data.actor.username, 4, 3)
			};

		case NotificationModel.Wallet:
			return {
				title: 'Invoice',
				id: data.target_object_id,
				url: '/wallet?limit=10',
				icon: 'nav-wallet',
				name: 'Wallet'
			};

		case NotificationModel.ZenfTransaction:
			return {
				title: 'Zenf-Invoice',
				id: data.target_object_id,
				url: '/wallet?limit=10',
				icon: 'nav-wallet',
				name: 'Wallet'
			};

		case NotificationModel.Chat:
			return {
				title: 'Chat',
				id: data.target_object_id,
				url: data.data?.is_contract_chat
					? `/contracts/chat/${data.target_object_id}`
					: `/chats/${data.target_object_id}`,
				icon: 'user',
				name: data.actor.nickname || cropAddress(data.actor.username, 4, 3)
			};

		case NotificationModel.UserRating:
			return {
				title: 'Contract',
				id: data.target_object_id,
				url: `/contracts/${data.target_object_id}`,
				icon: 'user',
				name: data.actor.nickname || cropAddress(data.actor.username, 4, 3)
			};

		case NotificationModel.Transaction:
			return {
				title: 'Transaction',
				id: data.target_object_id,
				url: `/contracts/${data.target_object_id}`,
				icon: 'nav-wallet',
				name: 'Transaction'
			};

		case NotificationModel.Marketplace:
			return {
				title: 'Item',
				id: data.action_object_object_id,
				url: `/items/${data.data?.slug || data.action_object_object_id}`,
				icon: 'nav-marketplace',
				name: 'Marketplace'
			};

		case NotificationModel.Referrals: {
			const isReferralDeposited = data.data?.depositor;
			return {
				title: isReferralDeposited ? 'User' : 'Invoice',
				id: isReferralDeposited ? data.data.depositor : data.target_object_id,
				url: isReferralDeposited
					? '/referrals/list?limit=10'
					: '/referrals/balance?limit=10',
				icon: isReferralDeposited ? 'referrals' : 'nav-wallet',
				name: isReferralDeposited ? 'Referral' : 'From Referrals'
			};
		}

		default:
			return {
				title: '',
				id: 0,
				url: ''
			};
	}
};

import { API } from '@core/config/api';
import { ContractRole } from '@core/models/enums';
import { IAgent } from '@core/services/agents/models';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Agents = {
	async get(
		username: string,
		groupName: ContractRole.Agent | ContractRole.Zen_Agent = ContractRole.Agent
	) {
		try {
			const response = await fetch(
				`${API.agents}?username=${username}&groups__name=${groupName}`,
				{
					headers: {
						'Content-Type': 'application/json',
						'X-CSRFToken': getCookie('csrftoken')
					},
					credentials: 'include'
				}
			);

			if (!response.ok) logErrors(await response.json());

			const data = await response.json();
			const res: IAgent[] = data.results;
			return res;
		} catch (e) {
			logErrors(e);
		}
	}
};

import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { ContractStatusName } from '@core/models/enums';
import { useAppSelector } from '@core/utils/hooks';
import { ReviewAgent } from '@pages/Dashboard/Contracts/Contract/Steps/Review/ReviewAgent';
import { Tooltip } from '@components/index';

import { ReviewContract } from './Review/ReviewContract';
import { Approval } from './Approval';
import { Deployment } from './Deployment';
import { Fulfillment } from './Fulfillment';
import { StepProps } from './Steps.props';
import { Transfer } from './Transfer';

import styles from './Steps.module.scss';

export const Steps = ({ contract }: Omit<StepProps, 'auth'>) => {
	/* Redux hooks */
	const auth = useAppSelector(({ auth }) => auth);

	/* React hooks */
	useEffect(() => {
		ReactTooltip.rebuild();
	}, [contract, auth]);

	return (
		<div className={styles.stepsWrapper}>
			<Approval contract={contract} auth={auth} />
			<Deployment contract={contract} auth={auth} />
			<Transfer contract={contract} auth={auth} />
			<Fulfillment contract={contract} auth={auth} />
			<ReviewContract contract={contract} auth={auth} />
			{contract.status === ContractStatusName.DisputeFinished && (
				<ReviewAgent contract={contract} auth={auth} />
			)}
			<Tooltip id='contractStepTooltip' />
		</div>
	);
};

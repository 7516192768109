import { API } from '@core/config/api';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

import { ISignatures } from './models';

export const Login = {
	async get() {
		try {
			const response = await fetch(API.login, {
				headers: {
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok) return logErrors(await response.json());

			const data: { token: string; success: boolean } = await response.json(); // ! TOKEN

			if (data.success) return data.token;
		} catch (e) {
			logErrors(e);
			return false;
		}
	},

	async post({ address, message, signature }: ISignatures, isSilent = false) {
		try {
			const response = await fetch(API.login, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				body: JSON.stringify({ address, message, signature })
			});

			if (!response.ok) {
				if (!isSilent) logErrors(await response.json());
				return false;
			}

			const { success }: { success: boolean } = await response.json();

			return success;
		} catch (e) {
			return false;
		}
	}
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITransactions } from '@core/store/transaction/transaction.types';

const initialState: ITransactions = {} as ITransactions;

const transactionSlice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {
		setTransactionData: (
			state,
			action: PayloadAction<ITransactions | null>
		) => {
			return { ...state, ...action.payload };
		}
	}
});

export const {
	reducer: transactionReducer,
	actions: { setTransactionData }
} = transactionSlice;

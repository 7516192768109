import React, { FormEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { INetwork, ProductToken } from '@core/models/interfaces';
import { Contracts } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setContractAction } from '@core/store/contract/contract.slice';
import { setItemAction } from '@core/store/item/item.slice';
import { setItemThunk } from '@core/store/item/item.thunks';
import { getHoursBySeconds } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { InputLabel } from '@components/DashboardComponents/Forms/Form/InputLabel';
import { Button, Input, Tooltip } from '@components/index';
import { Item } from '@components/ProductComponents/ProductCard.props';

import { IItemNewContract } from './Forms.types';

import 'react-datetime/css/react-datetime.css';
import styles from './Forms.module.scss';

export const NewItemContractForm = () => {
	/* React-i18next hooks */
	const { t } = useTranslation('common');

	/* React router hooks */
	const match: { params: { id: string; slug: string } } | null = useRouteMatch(
		'/profile/:id/items/:slug/create'
	);

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [auth, item] = useAppSelector(({ auth, items }) => [
		auth,
		items.current.data
	]);

	/* React hooks */
	const [formData, setFormData] = useState<IItemNewContract | null>(null);
	const [itemData, setItemData] = useState<{
		buyer: string;
		seller: string;
		title: string;
		details: string;
		delivery_time: string;
		buyer_protection_time: string;
		agreed_amount: string;
	}>({
		buyer: '',
		seller: '',
		title: '',
		details: '',
		delivery_time: '',
		buyer_protection_time: '',
		agreed_amount: ''
	});
	const [btnText, setBtnText] = useState('Approve');
	const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
	const [createdId, setCreatedId] = useState<number>();

	useEffect(() => {
		return () => {
			if (localStorage.getItem(Item.data)) localStorage.removeItem(Item.data);
			dispatch(setItemAction(null));
		};
	}, []);

	useEffect(() => {
		const itemJson = localStorage.getItem(Item.data);
		const itemByLocalStorage = itemJson && JSON.parse(itemJson);

		if (
			itemByLocalStorage &&
			match &&
			match.params.id === itemByLocalStorage.seller &&
			match.params.slug === itemByLocalStorage.slug &&
			!formData
		) {
			dispatch(setItemThunk({ slug: itemByLocalStorage.slug }));
		}
		if (item && !formData) {
			setFormData({
				available_network: itemByLocalStorage.network,
				available_token: itemByLocalStorage.token,
				id: item.id
			});
			setItemData(prevState => ({
				...prevState,
				buyer: auth.whoAmI.username || auth.metamaskAddress,
				seller: item.seller,
				title: item.title,
				details: item.description,
				agreed_amount: item.price,
				buyer_protection_time: item.buyer_protection_time,
				delivery_time: item.delivery_time
			}));
		}
	}, [item]);

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		if (formData) {
			setIsBtnDisabled(true);
			setBtnText('Loading');

			const contract = await Contracts.post({
				product: formData.id,
				token: formData.available_token,
				network: formData.available_network,
				buyer: itemData.buyer,
				seller: itemData.seller,
				price: itemData.agreed_amount,
				buyer_protection_time: +itemData.buyer_protection_time,
				delivery_time: itemData.delivery_time
			});

			setTimeout(() => {
				setIsBtnDisabled(false);
				setBtnText('Approve');
			}, 2000);

			if (contract && contract.id) {
				dispatch(addAlert({ text: 'Created and sent', type: 'info' }));
				dispatch(setContractAction(contract));
				setCreatedId(contract.id);
			}
		}
	};

	return (
		<>
			<form
				className={cn('pageWrapper', 'body', styles.form)}
				onSubmit={handleSubmit}
			>
				<legend className={cn(styles.formTitle, 'h5')}>Contract of Item</legend>
				<InputLabel
					labelId='buyer'
					name='Contractee'
					additionalText='(buyer)'
					toolTipDataFor='itemContractCreationTooltip'
					toolTipData={t('app.toolTips.productContract.buyer')}
				/>
				<div className={styles.inputContainer}>
					<Input
						placeholder='Buyer*'
						name='buyerAddress'
						id='buyer'
						value={itemData.buyer}
						largeness='md'
						onChange={e => {
							setItemData(prevState => ({
								...prevState,
								buyer: e.target.value
							}));
						}}
						className={cn(styles.formInput, styles.formInputBillAddress)}
						required
						disabled
						readOnly
					/>
				</div>

				<InputLabel
					labelId='seller'
					name='Contractor'
					additionalText='(seller)'
					toolTipDataFor='itemContractCreationTooltip'
					toolTipData={t('app.toolTips.productContract.contractor')}
				/>
				<div className={styles.inputContainer}>
					<Input
						placeholder='Seller*'
						name='seller'
						id='seller'
						largeness='md'
						value={itemData.seller}
						onChange={e => {
							setItemData(prevState => ({
								...prevState,
								seller: e.target.value
							}));
						}}
						className={cn(styles.formInput, styles.formInputBillAddress)}
						required
						disabled
						readOnly
					/>
				</div>

				<InputLabel
					labelId='contractTitle'
					name='Contract name'
					additionalText='(optional)'
					toolTipDataFor='itemContractCreationTooltip'
					toolTipData={t('app.toolTips.productContract.contract_name')}
				/>
				<div className={styles.inputContainer}>
					<Input
						id='contractTitle'
						largeness='md'
						placeholder='Contract name'
						name='title'
						value={itemData.title}
						onChange={e =>
							setItemData(prevState => ({
								...prevState,
								title: e.target.value
							}))
						}
						className={cn(styles.formInput)}
						disabled
						readOnly
					/>
				</div>

				<InputLabel
					name='Contract details'
					additionalText='(optional)'
					toolTipDataFor='itemContractCreationTooltip'
					toolTipData={t('app.toolTips.productContract.contract_details')}
				/>
				<div className={cn(styles.formTextarea, styles.formInput)}>
					{parse(itemData.details)}
				</div>

				<InputLabel
					name='Due date'
					toolTipDataFor='itemContractCreationTooltip'
					toolTipData={t('app.toolTips.productContract.delivery_time')}
				/>
				<div className={styles.inputContainer}>
					<div className={cn(styles.formTextarea, styles.formInput)}>
						{moment()
							.add(+itemData.delivery_time * 1000)
							.format('DD.MM.YYYY kk:mm')}
					</div>
				</div>

				<InputLabel
					labelId='protectionTime'
					name='Buyer protection time'
					toolTipDataFor='itemContractCreationTooltip'
					toolTipData={t('app.toolTips.productContract.buyer_protection_time')}
				/>
				<div className={styles.inputContainer}>
					<div className={styles.protectionTimeInputWrapper}>
						<Input
							name='protectionTime'
							id='protectionTime'
							largeness='md'
							type='text'
							value={getHoursBySeconds(+itemData.buyer_protection_time)}
							onChange={e =>
								setItemData(prevState => ({
									...prevState,
									buyer_protection_time: e.target.value
								}))
							}
							autoComplete='off'
							className={cn(styles.formInput)}
							disabled
							readOnly
						/>
					</div>
				</div>

				<InputLabel
					labelId='price'
					name='Agreed amount'
					toolTipData={t('app.toolTips.productContract.agreed_amount')}
					className={styles.agreedPriceLabel}
					toolTipDataFor='itemContractCreationTooltip'
				>
					<span className={cn(styles.selectedNetwork)}>
						{item &&
							item.available_networks
								.filter(
									(available_network: INetwork) =>
										available_network.chain_id === formData?.available_network
								)
								.map(
									(available_network: INetwork) =>
										available_network.display_name
								)
								.join('')}
					</span>
				</InputLabel>
				<div className={styles.agreedAmountInputsContainer}>
					<div className={cn(styles.agreedAmountInputsWrapper)}>
						<div className={styles.inputContainer}>
							<Input
								id='price'
								placeholder='Amount*'
								type='number'
								largeness='md'
								value={itemData.agreed_amount}
								onChange={e =>
									setItemData(prevState => ({
										...prevState,
										agreed_amount: e.target.value
									}))
								}
								className={cn(styles.formInput)}
								autoComplete='off'
								disabled
								readOnly
							/>
							<Button
								className={cn(
									styles.tooltipSelect,
									styles.availableToken,
									'body-md'
								)}
								color=''
								size='medium'
								variant='contained'
								disabled
							>
								{item &&
									formData &&
									item.available_tokens
										.filter(
											(available_token: ProductToken) =>
												available_token.id ===
												(formData && +formData?.available_token)
										)
										.map(
											(available_token: ProductToken) => available_token.name
										)
										.join('')}
							</Button>
						</div>
					</div>
				</div>

				<div className={cn(styles.privacyInfoText, 'caption')}>
					Creating Zenland contract you agree to comply with our{' '}
					<a
						href='https://zen.land/terms-of-service/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Terms of Service
					</a>{' '}
					and{' '}
					<a
						href='https://zen.land/privacy-policy/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Privacy Policy
					</a>
					.
				</div>

				<div className={styles.formFooter}>
					<Button
						as='link'
						to={`/profile/${match?.params?.id}/items`}
						color=''
						size='large'
						variant='contained'
					>
						Back
					</Button>
					<Button
						disabled={isBtnDisabled}
						color='primary'
						size='large'
						variant='contained'
					>
						{btnText}
					</Button>
				</div>
			</form>
			{createdId && <Redirect to={`/contracts/${createdId}`} />}
			<Tooltip id='itemContractCreationTooltip' />
		</>
	);
};

import React from 'react';
import cn from 'classnames';

import { ModalProps } from './Modal.props';

import styles from './Modal.module.scss';

export const Modal = ({ children, className, ...props }: ModalProps) => {
	return (
		<div
			role='dialog'
			aria-modal
			className={cn(styles.modal, className)}
			{...props}
		>
			{children}
		</div>
	);
};

import React from 'react';

import { INetwork } from '@core/models/interfaces';
import { changeNetworkThunk } from '@core/store/auth/auth.thunks';
import { store } from '@core/store/store';

export const useNetworkChange = (
	networks: INetwork[] | null,
	network: { name: string; value: string },
	editableHandler: React.Dispatch<React.SetStateAction<boolean>>
) => {
	if (networks) {
		const foundNetwork = networks.find(
			n => n.chain_id.toLowerCase() === network.value.toLowerCase()
		);
		if (foundNetwork) {
			store.dispatch(changeNetworkThunk(foundNetwork));
			editableHandler(true);
		}
	}
};

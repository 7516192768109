import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	IWalletTransaction,
	IWalletTransactionsGetResponse,
	PaymentGateway
} from '@core/models/interfaces';

import { WalletState } from './wallet.types';

const initialState: WalletState = {
	transactions: {
		list: [],
		count: 0,
		credit_count: 0,
		total_count: 0,
		debit_count: 0,
		current: null
	},
	payment_gateway: null,
	isLoaded: false,
	filters: {
		offset: 0,
		limit: 10,
		ordering: '',
		type: null
	}
};

export const walletSlice = createSlice({
	name: 'waller',
	initialState,
	reducers: {
		receivedWalletTxs: (
			state,
			action: PayloadAction<IWalletTransactionsGetResponse>
		) => {
			return {
				...state,
				transactions: {
					...state.transactions,
					list: action.payload.results,
					count: action.payload.count,
					debit_count: action.payload.debit_count,
					credit_count: action.payload.credit_count,
					total_count: action.payload.total_count
				},
				isLoaded: true
			};
		},
		insertedWalletTx: (state, action) => {
			if (state.transactions.list.length === 0) {
				return {
					...state,
					transactions: {
						...state.transactions,
						list: [action.payload],
						count: 1,
						debit_count: action.payload.debit_count,
						credit_count: action.payload.credit_count,
						total_count: 1,
						current: action.payload
					}
				};
			}

			if (state.transactions.list.some(tx => tx.id === action.payload.id)) {
				return {
					...state,
					transactions: {
						...state.transactions,
						list: state.transactions.list.map(tx => {
							return tx.id === action.payload.id ? action.payload : tx;
						}),
						current: action.payload
					}
				};
			}

			return {
				...state,
				transactions: {
					...state.transactions,
					list: [action.payload, ...state.transactions.list.slice(0, 9)],
					current: action.payload,
					debit_count: state.transactions.debit_count + 1,
					total_count: state.transactions.total_count + 1
				}
			};
		},
		insetZenfTxIdToCurrentTransaction: (
			state,
			action: PayloadAction<number | null>
		) => {
			state.transactions.current = state.transactions.current && {
				...state.transactions.current,
				zenfTxId: action.payload
			};
		},
		receivedCurrentWalletTx: (
			state,
			action: PayloadAction<IWalletTransaction | null>
		) => {
			return {
				...state,
				transactions: {
					...state.transactions,
					current: action.payload
				}
			};
		},
		receivedPayment: (state, action: PayloadAction<PaymentGateway>) => {
			return {
				...state,
				payment_gateway: action.payload
			};
		},
		setWalletFilters: (state, action) => {
			return {
				...state,
				filters: action.payload
			};
		},
		setIsWalletLoaded: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				isLoaded: action.payload
			};
		}
	}
});

export const {
	reducer: walletReducer,
	actions: {
		receivedWalletTxs,
		receivedCurrentWalletTx,
		insertedWalletTx,
		setIsWalletLoaded,
		setWalletFilters,
		receivedPayment,
		insetZenfTxIdToCurrentTransaction
	}
} = walletSlice;

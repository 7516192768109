import { API } from '@core/config/api';
import { FetchOptions } from '@core/models/interfaces';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Reputation = {
	//*** address - can include username or nickname ***//
	async getData(address: string, options?: FetchOptions) {
		try {
			const response = await fetch(`${API.users}${address}/statistics/`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

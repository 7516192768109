import React from 'react';
import cn from 'classnames';

import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { BadgeProps } from './Badge.props';

import styles from './Badge.module.scss';

export const Badge = ({
	color = '',
	text = '',
	size = 'md',
	rounded = 'sm',
	hasIcon,
	className,
	children,
	...props
}: BadgeProps) => {
	let Icon = 'status-success-outlined';

	if (color === 'warn') {
		Icon = 'status-warning-outlined-circle';
	} else if (color === 'danger') {
		Icon = 'status-warning-outlined-triangle';
	} else if (color === 'await') {
		Icon = 'status-pending';
	}

	return (
		<div
			className={cn(
				styles.badge,
				styles[color],
				styles[`size-${size}`],
				styles[`rounded-${rounded}`],
				className,
				{
					'body-md': text === 'md',
					'body-sm': text === 'sm'
				}
			)}
			{...props}
		>
			{hasIcon && <SvgSprite width={20} height={20} iconId={Icon} />}
			{children}
		</div>
	);
};

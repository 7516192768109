import React, { useEffect, useState } from 'react';

import { TransactionType } from '@core/models/enums';
import { ITransactions } from '@core/store/transaction/transaction.types';
import { Timer } from '@components/index';

interface ProtectionTimeProps {
	transactions: ITransactions[];
	buyer_protection_time: number;
	setIsProtectionExpired?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProtectionTime = ({
	transactions,
	buyer_protection_time,
	setIsProtectionExpired
}: ProtectionTimeProps) => {
	/* React hooks */
	const [secondsLeft, setSecondsLeft] = useState<number>();

	useEffect(() => {
		const index = transactions.findIndex(
			transaction => transaction.type === TransactionType.fulfilled
		);
		if (index !== -1) {
			const workStartSecs = Math.round(
				new Date(transactions[index].created).getTime() / 1000
			);

			const protectionSecs = workStartSecs + buyer_protection_time;
			const nowSecs = Math.round(Date.now() / 1000);
			if (nowSecs > protectionSecs) {
				setSecondsLeft(0);
				if (setIsProtectionExpired) setIsProtectionExpired(true);
			} else {
				setSecondsLeft(protectionSecs - nowSecs);
			}
		}
	}, [buyer_protection_time, transactions]);
	return secondsLeft ? <Timer sec={secondsLeft} /> : <>0</>;
};

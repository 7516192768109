import React, { useEffect } from 'react';
import cn from 'classnames';

import { useAppSelector } from '@core/utils/hooks';
import { DashboardRoutes } from '@routes/DashboardRoutes';
import {
	Header,
	Main,
	MaintenanceInfo,
	Sidebar
} from '@components/DashboardComponents';
import { SkipLink, Textarea } from '@components/index';

import styles from './Layout.module.scss';

export const Layout = () => {
	/* Redux hooks */
	const [auth, maintenance] = useAppSelector(({ auth, notification }) => [
		auth,
		auth.maintenance,
		notification.list
	]);

	/* React hooks */
	useEffect(() => {
		if (localStorage.getItem('isSubmitClicked')) {
			localStorage.removeItem('isSubmitClicked');
		}

		if (localStorage.getItem('referralCode')) {
			localStorage.removeItem('referralCode');
		}
	}, []);

	const renderMaintenanceMessage = () => {
		if (maintenance.isActive) {
			return <MaintenanceInfo>{maintenance.message}</MaintenanceInfo>;
		}
	};

	return (
		<>
			<Textarea className='clipboard-field' id='clipboard-field' />
			<div className={styles.layout} data-testid='layout'>
				<SkipLink />
				<Header className={styles.header} />
				{auth.whoAmI.username && <Sidebar className={styles.sidebar} />}
				<Main
					className={cn(styles.main, {
						[styles.large]: !auth.whoAmI.username
					})}
					id='content'
				>
					{renderMaintenanceMessage()}
					<DashboardRoutes />
				</Main>
			</div>
		</>
	);
};

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { Redirect } from 'react-router-dom';

import { Tokens } from '@core/services';
import { Products } from '@core/services/products/products';
import { addAlert } from '@core/store/alert/alert.thunks';
import { saveReceivedTokens } from '@core/store/auth/auth.slice';
import { receiveNetworksThunk } from '@core/store/auth/auth.thunks';
import {
	checkIsAvailableTokenChecked,
	getURLFromFile,
	getZenCommission,
	isFileSizeLarger
} from '@core/utils/helpers';
import { useAppDispatch, useAppSelector, useForm } from '@core/utils/hooks';
import { timeOptions } from '@pages/Dashboard/Contracts/Contract/ContractDetails';
import { CheckboxTokens } from '@components/DashboardComponents/Forms/Form/CheckboxTokens';
import { InputLabel } from '@components/DashboardComponents/Forms/Form/InputLabel';
import { IItem } from '@components/DashboardComponents/Forms/Forms.types';
import {
	Button,
	Checkboxes,
	Input,
	Select,
	SvgSprite,
	Tooltip
} from '@components/index';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';

import 'react-quill/dist/quill.snow.css';
import styles from '@components/DashboardComponents/Forms/Forms.module.scss';

// TODO: in future refactor this component
export const NewItemForm = () => {
	/* React-i18next hooks */
	const { t } = useTranslation('common');

	// Redux hooks
	const dispatch = useAppDispatch();
	const [authNetworks, authTokens] = useAppSelector(({ auth }) => [
		auth.networks,
		auth.tokens
	]);

	interface INewItemForm
		extends Omit<IItem, 'available_networks' | 'available_tokens'> {
		available_networks: (string | number)[];
		available_tokens: (string | number)[];
	}

	// Custom hooks
	const { handleSubmit, handleChange, data, errors, setData } =
		useForm<INewItemForm>({
			validations: {
				title: {
					custom: {
						isValid: value =>
							!!(value && value.length >= 5 && value.length <= 100),
						message:
							'Product name must be more than 5 or equal and less than 100 characters'
					}
				},
				description: {
					// Get value without html tags at least one character
					custom: {
						isValid: value =>
							!!(
								value && value.replace(/(<([^>]+)>)/gi, '').trim().length >= 1
							),
						message: 'Product description must be more than 1 character'
					}
				},
				price: {
					custom: {
						isValid: value => !!(value && +value >= 0.01 && value.length < 40),
						message:
							'Agreed amount must be greater than $0.01 Make sure there are no more than 40 digits in total in the agreed amount.'
					}
				},
				buyer_protection_time: {
					custom: {
						isValid: value => !!(value && +value >= 1),
						message:
							'Enter a valid buyer protection time (greater than or equal to 1).'
					}
				},
				delivery_time: {
					custom: {
						isValid: value => !!(value && +value >= 1),
						message: 'Enter a valid delivery time (greater than or equal to 1).'
					}
				},
				available_networks: {
					custom: {
						isValid: value => !!(value && value.length),
						message: 'Please choose one network at least'
					}
				},
				available_tokens: {
					custom: {
						isValid: value => !!(value && value.length),
						message: 'Please choose one token at least'
					}
				}
			},
			initialValues: {},
			onSubmit: async () => {
				const formData = new FormData();

				for (const key in data) {
					if (
						key === 'image' ||
						key === 'delivery_time' ||
						key === 'buyer_protection_time' ||
						key === 'available_tokens' ||
						key === 'available_networks' ||
						key === 'is_approval_needed'
					)
						continue;
					formData.append(key, data[key]);
				}

				formData.append(
					'is_approval_needed',
					JSON.stringify(!data.is_approval_needed)
				);

				if (data.image && data.image.file) {
					if (isFileSizeLarger(data.image.file, 2))
						return dispatch(
							addAlert({
								text: 'Item image size is more than 2 MB limit',
								type: 'error'
							})
						);
					formData.append('image', data.image.file, data.image.file.name);
				}

				if (data.buyer_protection_time) {
					const seconds = (
						+data.buyer_protection_time * +protectionTimeOption.value
					).toString();

					if (+seconds > 2147483647)
						return dispatch(
							addAlert({
								text: 'Too much buyer protection time',
								type: 'error'
							})
						);

					if (+seconds < 86400)
						return dispatch(
							addAlert({
								text: 'Ensure buyer protection time is greater than or equal to 24 hour',
								type: 'error'
							})
						);

					formData.append('buyer_protection_time', seconds);
				}

				if (data.delivery_time) {
					const seconds = (
						+data.delivery_time * +deliveryTimeOption.value
					).toString();

					if (+seconds > 2147483647)
						return dispatch(
							addAlert({
								text: 'Too much buyer delivery time',
								type: 'error'
							})
						);

					formData.append('delivery_time', seconds);
				}

				if (data.available_networks) {
					for (const network of data.available_networks) {
						formData.append('available_networks', network.toString());
					}
				}

				if (data.available_tokens) {
					checkIsAvailableTokenChecked.clearSelectedTokens();
					if (authNetworks && authTokens && data.available_networks) {
						checkIsAvailableTokenChecked.checkAvailableTokens(
							authNetworks,
							authTokens,
							data.available_networks,
							data.available_tokens
						);
					}
					if (checkIsAvailableTokenChecked.isHasUncheckedToken() >= 0) return;

					for (const token of data.available_tokens) {
						formData.append('available_tokens', token.toString());
					}
				}

				setBtnText('Wait...');
				setIsBtnDisabled(true);
				const response = await Products.post(formData);

				if (response && response.id) {
					dispatch(addAlert({ text: 'Item created and sent', type: 'info' }));
					setBtnText('Add');
					setIsBtnDisabled(false);
					setCreatedId(response.id);

					/* clear all data */
					setNetworks([]);
					setTokens([]);
					setAdditionalSettings([]);
					setData(prevState => ({ ...prevState, ...clearFormData }));
				} else {
					setBtnText('Add');
					setIsBtnDisabled(false);
				}
			}
		});

	// React hooks
	const [btnText, setBtnText] = useState('Add');
	const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
	const [deliveryTimeOption, setDeliveryTimeOption] = useState(
		timeOptions.hours
	);
	const [protectionTimeOption, setProtectionTimeOption] = useState(
		timeOptions.hours
	);
	const [networks, setNetworks] = useState<TCheckboxList[]>(
		[] as TCheckboxList[]
	);
	const [checkedNetworks, setCheckedNetworks] = useState<TCheckboxList[]>(
		[] as TCheckboxList[]
	);
	const [tokens, setTokens] = useState<TCheckboxList[]>([] as TCheckboxList[]);

	const [checkedTokens, setCheckedTokens] = useState<TCheckboxList[]>(
		[] as TCheckboxList[]
	);
	const [additionalSettings, setAdditionalSettings] = useState<TCheckboxList[]>(
		[
			{ value: 'one_off', name: 'One off product' },
			{
				value: 'is_approval_needed',
				name: 'Pre approved item'
			}
		]
	);
	const [checkedAdditionalSettings, setCheckedAdditionalSettings] = useState<
		TCheckboxList[]
	>([] as TCheckboxList[]);
	const [createdId, setCreatedId] = useState<number>();

	useEffect(() => {
		(async () => {
			if (authNetworks && authNetworks.length && !networks.length) {
				const networks = authNetworks.map(network => {
					return {
						name: network.display_name,
						value: network.chain_id,
						image: network.image
					};
				});
				setNetworks(networks);
			} else {
				dispatch(receiveNetworksThunk());
			}

			if (!authTokens) {
				const allTokens = await Tokens.getAll({ is_active: true });
				if (allTokens) dispatch(saveReceivedTokens(allTokens));
			}
		})();
	}, [authNetworks?.length, authTokens?.length]);

	useEffect(() => {
		if (checkedNetworks.length) {
			const checkedNetworksId = checkedNetworks.map(network => network.value);
			setData(prevState => ({
				...prevState,
				available_networks: checkedNetworksId
			}));

			if (authTokens) {
				const tokens = authTokens
					?.filter(authToken =>
						checkedNetworksId.includes(authToken.network.chain_id)
					)
					.map(authToken => ({
						value: authToken.id,
						name: authToken.name,
						image: authToken.network.image
					}));

				setTokens(tokens);
			}
		} else {
			setTokens([]);
			setData(prevState => ({
				...prevState,
				available_networks: [],
				available_tokens: []
			}));
		}
	}, [checkedNetworks]);

	useEffect(() => {
		if (checkedTokens.length) {
			const checkedTokensId = checkedTokens.map(token => token.value);
			setData(prevState => ({
				...prevState,
				available_tokens: checkedTokensId
			}));
		} else
			setData(prevState => ({
				...prevState,
				available_tokens: []
			}));
	}, [checkedTokens]);

	useEffect(() => {
		const value = checkedAdditionalSettings.map(
			additionalSetting => additionalSetting.value
		);
		setData(prevState => ({
			...prevState,
			is_approval_needed: value.includes('is_approval_needed'),
			one_off: value.includes('one_off')
		}));
	}, [checkedAdditionalSettings]);

	/* Handlers */
	const handleDayTimeOption =
		(select: 'delivery' | 'protection') =>
		(option: { name: string; value: number }) => {
			switch (select) {
				case 'delivery':
					setDeliveryTimeOption(option);
					break;
				case 'protection':
					setProtectionTimeOption(option);
					break;
			}
		};

	const handleRemoveImage = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		e.preventDefault();
		setData(prevState => ({
			...prevState,
			image: { file: null, url: '' }
		}));
	};

	const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files;
		if (!file?.length) return;

		const url = await getURLFromFile(file[0]);
		if (typeof url === 'string') {
			setData(prevState => ({ ...prevState, image: { file: file[0], url } }));
		}
		e.target.value = '';
	};

	const handleSetData = async () => {
		if (authNetworks) {
			const networks = authNetworks.map(network => {
				return {
					name: network.display_name,
					value: network.chain_id,
					image: network.image
				};
			});
			await setNetworks(networks);
		}

		await setAdditionalSettings([
			{ value: 'one_off', name: 'One off product' },
			{
				value: 'is_approval_needed',
				name: 'Pre approved item'
			}
		]);
	};

	const clearFormData: INewItemForm = {
		title: '',
		image: {} as unknown as IItem['image'],
		description: '' as unknown as IItem['description'],
		delivery_time: '',
		buyer_protection_time: '',
		is_approval_needed: false,
		one_off: false,
		is_active: false,
		price: '',
		available_networks: [],
		available_tokens: []
	};

	return (
		<>
			<form
				className={cn('pageWrapper', 'body', 'newItemForm', styles.form)}
				onSubmit={handleSubmit}
			>
				<legend className={cn(styles.formTitle, 'h5')}>New Item</legend>
				<InputLabel
					labelId='title'
					name='Item name'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.name')}
				/>
				<div className={styles.inputContainer}>
					<Input
						placeholder='Pineapple*'
						name='title'
						id='title'
						value={data.title || ''}
						largeness='md'
						onChange={handleChange('title')}
						className={cn(
							styles.formInput,
							{ [styles.error]: errors.title },
							styles.formInputBillAddress
						)}
						minLength={5}
						maxLength={100}
						required
					/>
					{errors.title && <p className={styles.errorText}>{errors.title}</p>}
				</div>

				<InputLabel
					labelId='image'
					name='Add an image'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.image')}
				/>
				<div className={styles.inputContainer}>
					<Input
						id='image'
						data-testid='image'
						accept='image/*'
						multiple={false}
						onChange={handleImageChange}
						className='imgInput'
						type='file'
					/>
					<label
						htmlFor='image'
						className={cn(styles.imageAttachment, {
							[styles.withoutBorder]: data.image?.url
						})}
					>
						{data.image?.url ? (
							<>
								<img
									src={data.image.url}
									alt='item image'
									className={styles.itemImage}
								/>
								<Button
									type='button'
									className={cn(
										'imageRemoveCross',
										styles.itemImageRemoveCross
									)}
									onClick={handleRemoveImage}
								/>
							</>
						) : (
							<SvgSprite size={64} iconId='image' />
						)}
					</label>
				</div>

				<InputLabel
					labelId='description'
					name='Description'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.description')}
				/>
				<div className={styles.inputContainer}>
					<ReactQuill
						theme='snow'
						modules={{
							toolbar: [
								[{ size: ['small', false, 'large', 'huge'] }],
								['bold', 'italic', 'underline'],
								[{ list: 'ordered' }, { list: 'bullet' }],
								['link'],
								[{ align: [] }],
								['clean']
							]
						}}
						formats={[
							'size',
							'bold',
							'italic',
							'underline',
							'list',
							'bullet',
							'link',
							'align'
						]}
						value={data.description}
						onChange={(html: string) => {
							setData(prevState => {
								return { ...prevState, description: html };
							});
						}}
						onKeyDown={e => {
							const isTabbingInEditor =
								e.key === 'Tab' &&
								e.target.className === 'ql-editor focus-visible';

							if (isTabbingInEditor) {
								e.preventDefault();
								e.target.blur();
								return false;
							}
						}}
						className={cn(styles.formInput, styles.descriptionInput, {
							[styles.error]: errors.description
						})}
						placeholder='Describe your item'
					/>
					{errors.description && (
						<p className={styles.errorText}>{errors.description}</p>
					)}
				</div>

				<InputLabel
					labelId='deliveryTime'
					name='Delivery time'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.deliveryTime')}
				/>
				<div className={styles.inputContainer}>
					<Input
						placeholder='1*'
						type='number'
						name='deliveryTime'
						id='deliveryTime'
						value={data.delivery_time || ''}
						largeness='md'
						onChange={handleChange('delivery_time')}
						className={cn(
							styles.formInput,
							{ [styles.error]: errors.delivery_time },
							styles.formInputBillAddress
						)}
						step='0.01'
						min='0.01'
						lang='en'
						required
					/>
					<Select
						options={Object.values(timeOptions)}
						defaultOption={deliveryTimeOption}
						setOption={handleDayTimeOption('delivery')}
						listStartSide='end'
						className={styles.tooltipSelect}
						color='secondary'
						bg='secondary'
					/>
					{errors.delivery_time && (
						<p className={styles.errorText}>{errors.delivery_time}</p>
					)}
				</div>

				<InputLabel
					labelId='protectionTime'
					name='Buyer protection time'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.protectionTime')}
				/>
				<div className={styles.inputContainer}>
					<Input
						placeholder='24*'
						type='number'
						name='protectionTime'
						id='protectionTime'
						value={data.buyer_protection_time || ''}
						largeness='md'
						onChange={handleChange('buyer_protection_time')}
						className={cn(
							styles.formInput,
							{ [styles.error]: errors.buyer_protection_time },
							styles.formInputBillAddress
						)}
						required
					/>
					<Select
						options={Object.values(timeOptions)}
						defaultOption={protectionTimeOption}
						setOption={handleDayTimeOption('protection')}
						listStartSide='end'
						className={styles.tooltipSelect}
						color='secondary'
						bg='secondary'
					/>
					{errors.buyer_protection_time && (
						<p className={styles.errorText}>{errors.buyer_protection_time}</p>
					)}
				</div>

				<InputLabel
					labelId='price'
					name='Price'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.price')}
				/>
				<div className={styles.agreedAmountInputsContainer}>
					<div
						className={cn(styles.agreedAmountInputsWrapper, {
							[styles.error]: errors.price
						})}
					>
						<div className={styles.inputContainer}>
							<Input
								id='price'
								placeholder='Amount*'
								type='number'
								largeness='md'
								value={data.price || ''}
								onChange={handleChange('price')}
								className={cn(styles.formInput, {
									[styles.error]: errors.price
								})}
								autoComplete='off'
								required
							/>
						</div>
						<hr className={styles.divider} />
						<div
							className={cn(styles.inputContainer, styles.feeInputContainer)}
						>
							<div className={styles.feePayerBlock}>
								<span className={styles.feePayerText}>
									Zenland fee per sale
									<SvgSprite
										data-for='itemCreationTooltip'
										data-html
										data-tip={t('app.toolTips.newItem.zenland_fee')}
									/>
								</span>
								<span className={styles.feePayerValue}>
									{'$' +
										(+data.price > 0 && +data.price <= 1
											? 0.01
											: getZenCommission(+data.price))}
								</span>
							</div>
						</div>
					</div>
					{errors.price && <p className={styles.errorText}>{errors.price}</p>}
				</div>

				<InputLabel
					name='Choose network(s)'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.networks')}
				/>
				<div className={styles.inputContainer}>
					<Checkboxes
						list={networks}
						setCheckedList={setCheckedNetworks}
						borderHidden
						isDisplayAsIcons
						labelHasTitle
						placeholder='Please restart the page to get networks'
					/>
					{errors.available_networks && (
						<p className={styles.errorText}>{errors.available_networks}</p>
					)}
				</div>

				<InputLabel
					name='Choose token(s)'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.tokens')}
				/>
				<div className={styles.inputContainer}>
					<CheckboxTokens
						list={tokens}
						setCheckedList={setCheckedTokens}
						checkedNetworks={checkedNetworks}
						isAddIcons
						borderless
						placeholder='Please, select the network(s) first'
					/>
					{errors.available_tokens && (
						<p className={styles.errorText}>{errors.available_tokens}</p>
					)}
				</div>

				<InputLabel
					name='Additional settings'
					toolTipDataFor='itemCreationTooltip'
					toolTipData={t('app.toolTips.newItem.settings')}
				/>
				<div className={styles.inputContainer}>
					<Checkboxes
						list={additionalSettings}
						setCheckedList={setCheckedAdditionalSettings}
						size='md'
					/>
				</div>

				<div className={cn(styles.privacyInfoText, 'caption')}>
					Creating Zenland contract you agree to comply with our{' '}
					<a
						href='https://zen.land/terms-of-service/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Terms of Service
					</a>{' '}
					and{' '}
					<a
						href='https://zen.land/privacy-policy/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Privacy Policy
					</a>
					.
				</div>

				<div className={styles.formFooter}>
					<Button
						color=''
						size='large'
						variant='contained'
						type='reset'
						onClick={async () => {
							setNetworks([]);
							setTokens([]);
							setAdditionalSettings([]);
							setData(prevState => ({ ...prevState, ...clearFormData }));

							/* set networks and additional settings data */
							await handleSetData();
						}}
					>
						Clear
					</Button>
					<Button
						disabled={isBtnDisabled}
						color='primary'
						size='large'
						variant='contained'
					>
						{btnText}
					</Button>
				</div>
			</form>
			{createdId && <Redirect to='/items?limit=10' />}
			<Tooltip id='itemCreationTooltip' />
		</>
	);
};

import { setLogged } from '@core/store/auth/auth.slice';
import { store } from '@core/store/store';
import { checkMetamaskConnection } from '@core/utils/helpers';

export const maybeFixMetamaskConnection = async () => {
	if (!checkMetamaskConnection().isConnected) {
		store.dispatch(setLogged(false));
		while (!window.ethereum?._state.initialized) {
			await new Promise(resolve => setTimeout(resolve, 5000));
			if (checkMetamaskConnection().isInstalled) window.location.reload();
		}
	}
};

import React, { useState } from 'react';
import cn from 'classnames';

import { Button, Textarea } from '@components/index';

import { RatingFormProps } from './Forms.types';

import styles from './Forms.module.scss';

export const RatingForm = ({
	onSubmit,
	initValue,
	setReviews
}: RatingFormProps) => {
	/* React hooks */
	const [value, setValue] = useState(initValue);

	/* Handlers */
	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
	};

	const handleClear = () => {
		setValue('');
		setReviews(prev => ({ ...prev, mine: { score: 0, text: '' } }));
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onSubmit(value.trim());
	};

	return (
		<form onSubmit={handleSubmit} className={styles.ratingForm}>
			<div className={styles.ratingTextareaWrapper}>
				<label
					htmlFor='ratingTextarea'
					className={cn('body-md', styles.ratingLabel)}
				>
					Write a review
				</label>
				<Textarea
					id='ratingTextarea'
					value={value}
					onChange={handleChange}
					className={styles.ratingTextarea}
					placeholder='Text'
				/>
			</div>
			<div className={styles.ratingBtns}>
				<Button
					onClick={handleClear}
					variant='text'
					size='medium'
					color='primary'
					className={styles.ratingBtn}
					type='reset'
				>
					Clear
				</Button>
				<Button
					variant='contained'
					size='medium'
					color='primary'
					className={styles.ratingBtn}
				>
					Submit
				</Button>
			</div>
		</form>
	);
};

export const emailPattern = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/gm;
export const linkPattern =
	/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi;
export const ethAddressPatternString = '^0x[a-fA-F0-9]{40}$';
export const ethAddressPattern = /^0x[a-fA-F0-9]{40}$/;
export const numbersPattern = '[0-9]*.?[0-9]*';
export const nicknamePattern = /^[a-zA-Z0-9]+[\w]*[A-Za-z0-9]+$/g;

export const findAddressPattern = /0x\w+/gim;

export const unregisteredUserPattern =
	/(object)\s(with)\s(username)(.*?)(does)\s(not)\s(exist)/gim;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ThemeState } from './theme.types';

export const initialTheme: ThemeState = {
	mode: null,
	isSidebarOpen: false
};

export const themeSlice = createSlice({
	name: 'theme',
	initialState: initialTheme,
	reducers: {
		setTheme: (state, action: PayloadAction<string>) => {
			return { ...state, mode: action.payload };
		},
		showSidebar: (state, action: PayloadAction<boolean>) => {
			return { ...state, isSidebarOpen: action.payload };
		}
	}
});

export const {
	reducer: themeReducer,
	actions: { setTheme, showSidebar }
} = themeSlice;

import { Dispatch } from 'redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChatCommand, WSReadyState } from '@core/models/enums';
import { WSChatResponse } from '@core/models/interfaces';
import { ChatSocket } from '@core/websocket/chatWebsocket';

import {
	chatMessagesReceived,
	chatNewMessagesCountReceived,
	chatReadIdsReceived,
	chatStatusChanged,
	newMessageReceived
} from './wsChat.slice';

let _newMessageHandler: ((messages: WSChatResponse) => void) | null = null;

const newMessageHandlerCreator = (dispatch: Dispatch) => {
	if (!_newMessageHandler) {
		_newMessageHandler = data => {
			switch (data.command) {
				case ChatCommand.FetchMessages:
					return dispatch(chatMessagesReceived(data.messages));

				case ChatCommand.NewMessage:
					return dispatch(newMessageReceived(data.message));

				case ChatCommand.MarkAsRead:
					return dispatch(
						chatReadIdsReceived({ ids: data.ids, success: data.success })
					);

				case ChatCommand.GetNewMessageCount:
					return dispatch(chatNewMessagesCountReceived(data.count));
			}
		};
	}

	return _newMessageHandler;
};

let _statusHandler: ((status: WSReadyState) => void) | null = null;

const statusHandlerCreator = (dispatch: Dispatch) => {
	if (!_statusHandler) {
		_statusHandler = data => {
			dispatch(chatStatusChanged(data));
		};
	}

	return _statusHandler;
};

export const startChatMessagesListening = createAsyncThunk<void, number>(
	'wsChat/startChatMessagesListening',
	async (roomId, thunkAPI) => {
		ChatSocket.start(roomId);
		ChatSocket.subscribe(
			'message',
			newMessageHandlerCreator(thunkAPI.dispatch)
		);
		ChatSocket.subscribe('status', statusHandlerCreator(thunkAPI.dispatch));
	}
);

export const stopChatMessagesListening = createAsyncThunk(
	'wsChat/stopChatMessagesListening',
	async (_, thunkAPI) => {
		ChatSocket.stop();
		thunkAPI.dispatch(chatMessagesReceived([]));
	}
);

export const sendChatMessage = createAsyncThunk<void, string>(
	'wsChat/sendChatMessage',
	async message => {
		ChatSocket.sendMessage(message);
	}
);

export const markChatMessageAsReadThunk = createAsyncThunk<void, number[]>(
	'wsChat/markChatMessageAsReadThunk',
	async message => {
		ChatSocket.markAsRead(message);
	}
);

export const getChatMessages = createAsyncThunk<
	void,
	{ offset: number; cutoff: number } | void
>('wsChat/getChatMessages', async payload => {
	if (!payload) payload = { offset: 0, cutoff: 100 };
	ChatSocket.fetchMessages(payload.offset, payload.cutoff);
});

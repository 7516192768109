import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IChatRoom } from '@core/store/chat/chat.types';

export const initialRoom: IChatRoom | null = null;

export const roomSlice = createSlice({
	name: 'room',
	initialState: initialRoom as IChatRoom | null,
	reducers: {
		setRoom: (state, action: PayloadAction<IChatRoom | null>) => {
			if (action.payload) return { ...action.payload };
			return null;
		}
	}
});

export const {
	reducer: roomReducer,
	actions: { setRoom }
} = roomSlice;

import React from 'react';

import { Checkboxes } from '@components/MainComponents/Checkboxes/Checkboxes';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';

import styles from '@components/ProductComponents/Product/Product.module.scss';

export const CheckboxesTokens = ({
	availableTokenList,
	setCheckedToken,
	availableSupportedTokenList
}: {
	availableTokenList: TCheckboxList[];
	setCheckedToken: React.Dispatch<React.SetStateAction<TCheckboxList[]>>;
	availableSupportedTokenList: number[] | null | undefined;
}) => {
	return (
		<Checkboxes
			list={availableTokenList}
			setCheckedList={setCheckedToken}
			availableSupportedCheckList={availableSupportedTokenList}
			className={styles.tokensCheckboxes}
			isAddIcons
			borderless
			isSingleSelect
			placeholder='Please choose the network'
		/>
	);
};

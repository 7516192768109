import React from 'react';
import cn from 'classnames';

import { Rating } from '@components/MainComponents/Rating/Rating';

import styles from './RatingWithCounter.module.scss';

export const RatingWithCounter = ({
	total,
	count,
	starsSize = 'sm',
	fontSize = 'caption'
}: {
	total: number;
	count: number;
	starsSize?: 'sm' | 'md';
	fontSize?: 'caption' | 'body-sm';
}) => {
	return (
		<div className={styles.ratingWrapper}>
			<Rating
				rating={total}
				className={styles.rating}
				data-stars-size={starsSize}
			/>
			<p className={cn(styles.ratingCounter, fontSize)}>{count}</p>
		</div>
	);
};

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
	ContractStatusName,
	ContractStatusNumber,
	TransactionType
} from '@core/models/enums';
import { confirmFulfillment } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { TransactionStatuses } from '@core/store/transaction/transaction.types';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { StepProps } from '@pages/Dashboard/Contracts/Contract/Steps/Steps.props';
import { Button, SvgSprite } from '@components/index';

import styles from '@pages/Dashboard/Contracts/Contract/Steps/Steps.module.scss';

export const Complete = ({ contract }: Omit<StepProps, 'auth'>) => {
	const { t } = useTranslation('common');
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [transaction, currentContractIsSyncing] = useAppSelector(
		({ transaction, contracts }) => [
			transaction,
			contracts.current.isCurrentSyncing
		]
	);

	/* React hooks */
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState('');
	const [confirmingLoading, setConfirmingLoading] = useState<boolean>(false);
	const [isCompleteEvent, setIsCompleteEvent] = useState<boolean>(false);

	useEffect(() => {
		if (contract.transactions.length) {
			if (
				contract.transactions[0].status === TransactionStatuses.Pending &&
				contract.transactions[0].type === TransactionType.fulfilled
			) {
				setConfirmingLoading(true);
				setIsCompleteEvent(true);
			}
		}

		if (isCompleteEvent) {
			if (
				transaction.status === TransactionStatuses.Confirmed &&
				confirmingLoading
			) {
				dispatch(
					addAlert({
						text: `You have confirmed the completion of the terms of contract #${contract.id}.`,
						type: 'info',
						isSubmit: true
					})
				);
				setConfirmingLoading(false);
				setIsCompleteEvent(false);
				setLoading(false);
			}

			if (
				transaction.status === TransactionStatuses.Pending &&
				contract.status !== ContractStatusName.Fulfilled
			) {
				setConfirmingLoading(true);
			}

			if (transaction.status === TransactionStatuses.Failed) {
				setConfirmingLoading(false);
				setLoading(false);
				dispatch(
					addAlert({
						text: 'Transaction for complete contract failed',
						type: 'error',
						isSubmit: true
					})
				);
				setIsCompleteEvent(false);
			}
		}
	}, [transaction.status, contract.status]);

	const isConfirmDisabled =
		ContractStatusNumber[contract.status] !== ContractStatusNumber.active;

	const handleConfirmContract = async () => {
		if (isConfirmDisabled) {
			return dispatch(
				addAlert({
					text: `Contract is ${contract.status}, not fulfilled!`,
					type: 'error',
					isSubmit: true
				})
			);
		}

		setLoading(true);
		setIsCompleteEvent(true);

		const res = await confirmFulfillment(
			JSON.parse(JSON.stringify(contract)),
			value
		);
		if (!res) setLoading(false);
	};

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		if (e.target.value.length > 200) {
			return dispatch(
				addAlert({
					text: 'Ensure this field has no more than 200 characters.',
					type: 'error'
				})
			);
		}
		setValue(e.target.value);
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const trimmedMsg = value.trim();
		if (!trimmedMsg) {
			return dispatch(addAlert({ text: 'Message is empty', type: 'error' }));
		}

		await handleConfirmContract();
	};

	return (
		<form className={styles.confirmForm} onSubmit={handleSubmit}>
			<label
				htmlFor='confirmTextarea'
				className={cn('body-md', styles.confirmFormTitle)}
			>
				Contract completion message
			</label>
			<textarea
				id='confirmTextarea'
				placeholder='Please, enter order details and/or the notes for the Buyer.'
				className={styles.confirmTextarea}
				value={value}
				disabled={isConfirmDisabled || confirmingLoading}
				onChange={handleChange}
			/>
			<Button
				className={styles.stepBtn}
				disabled={
					contract.isNetworkNotMatched ||
					isConfirmDisabled ||
					contract.is_syncing ||
					currentContractIsSyncing
				}
				isLoader={loading}
				isConfirming={confirmingLoading}
				variant='contained'
				size='medium'
				color='primary'
			>
				<SvgSprite
					iconId='metamask-fox-outlined'
					className={styles.metamaskIcon}
				/>{' '}
				Complete
			</Button>
			<span className={cn('caption', styles.completeHint)}>
				Complete activates buyer protection time.{' '}
				<SvgSprite
					size={20}
					data-for='contractStepTooltip'
					data-tip={t('app.toolTips.contracts.dataTip.complete')}
					data-html
				/>
			</span>
		</form>
	);
};

import React, { FormEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { INetwork, IToken, ProductToken } from '@core/models/interfaces';
import { Tokens } from '@core/services';
import { Products } from '@core/services/products/products';
import { addAlert } from '@core/store/alert/alert.thunks';
import { saveReceivedTokens } from '@core/store/auth/auth.slice';
import { setItemAction } from '@core/store/item/item.slice';
import {
	checkIsAvailableTokenChecked,
	getHoursBySeconds,
	getSecondsByInputData,
	getURLFromFile,
	getZenCommission,
	isFileSizeLarger
} from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { timeOptions } from '@pages/Dashboard/Contracts/Contract/ContractDetails';
import {
	IItemList,
	ItemEditProps,
	ItemForm
} from '@pages/Dashboard/Items/Item.props';
import {
	Button,
	Checkboxes,
	Input,
	Select,
	SvgSprite,
	Tooltip
} from '@components/index';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';

import { CheckboxNetworksItemDetail } from './Partials/CheckboxNetworksItemDetail';
import { CheckboxTokensItemDetail } from './Partials/CheckboxTokensItemDetail';

import 'react-quill/dist/quill.snow.css';
import styles from '../Items.module.scss';

export const ItemDetails = ({ item }: { item: IItemList }) => {
	/* React-i18next hooks */
	const { t } = useTranslation('common');

	/* Redux hooks */
	const dispatch = useAppDispatch();
	/* TODO: item available_tokens has large interface need to refactor below code  */
	const [networks, tokens, maintenance] = useAppSelector(({ auth }) => [
		auth.networks,
		auth.tokens,
		auth.maintenance
	]);

	/* React hooks */
	const [formValues, setFormValues] = useState<ItemForm>({
		title: '',
		description: '',
		price: 0,
		delivery_time: 0,
		buyer_protection_time: 0,
		available_networks: [],
		available_tokens: [],
		image: { file: null, url: item.image },
		is_approval_needed: true
	});
	const [isFormsEditable, setIsFormsEditable] = useState(false);
	const [deliveryTimeOption, setDeliveryTimeOption] = useState(
		timeOptions.hours
	);
	const [protectionTimeOption, setProtectionTimeOption] = useState(
		timeOptions.hours
	);
	const [checkedNetworks, setCheckedNetworks] = useState<TCheckboxList[]>([
		{ value: '', name: '' }
	]);
	const [checkedTokens, setCheckedTokens] = useState<TCheckboxList[]>([
		{ value: '', name: '' }
	]);
	const [approvedItem, setApprovedItem] = useState<TCheckboxList[]>([
		{ value: '', name: '' }
	]);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Escape') setIsFormsEditable(false);
		};

		document.addEventListener('keydown', handleKeyDown);

		(async () => {
			if (!tokens) {
				const allTokens = await Tokens.getAll({
					is_active: true
				});
				if (allTokens) dispatch(saveReceivedTokens(allTokens));
			}
		})();

		return () => {
			setIsFormsEditable(false);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		setFormValues(prevState => ({
			...prevState,
			available_networks: checkedNetworks.map(
				checkedNetwork => checkedNetwork.value
			)
		}));
	}, [checkedNetworks]);

	useEffect(() => {
		setFormValues(prevState => ({
			...prevState,
			available_tokens: checkedTokens.map(checkedToken => +checkedToken.value)
		}));
	}, [checkedTokens]);

	useEffect(() => {
		setFormValues(prevState => ({
			...prevState,
			title: item.title,
			description: item.description,
			price: +item.price,
			delivery_time: Math.floor(item.delivery_time / 3600),
			buyer_protection_time: Math.floor(item.buyer_protection_time / 3600),
			available_networks: item.available_networks,
			available_tokens: item.available_tokens,
			image: { file: null, url: item.image },
			is_approval_needed: item.is_approval_needed
		}));

		setDeliveryTimeOption(timeOptions.hours);
		setProtectionTimeOption(timeOptions.hours);
	}, [item, isFormsEditable]);

	/* Handlers */
	const handleFormSubmit = async (e: FormEvent) => {
		e.preventDefault();
		const formData = new FormData();

		if (formValues.title.length < 5 || formValues.title.length >= 100) {
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Product title length must be more than 5 or equal and less than 100 characters'
				})
			);
		}
		formData.append('title', formValues.title);

		if (formValues.image && formValues.image.url) {
			if (formValues.image.file && isFileSizeLarger(formValues.image.file, 2)) {
				return dispatch(
					addAlert({
						text: 'Item image size is more than 2 MB limit',
						type: 'error'
					})
				);
			}
			formValues.image.file &&
				formData.append(
					'image',
					formValues.image.file,
					formValues.image.file.name
				);
		} else {
			formData.append('image', '');
		}

		if (formValues.description.replace(/(<([^>]+)>)/gi, '').trim().length < 1) {
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Product description length must be more than 1 symbol'
				})
			);
		}
		formData.append('description', formValues.description);

		if (formValues.delivery_time < 1) {
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Delivery time must be more than 1 or equal to 1'
				})
			);
		}
		formData.append(
			'delivery_time',
			getSecondsByInputData(formValues.delivery_time, deliveryTimeOption.value)
		);

		if (formValues.buyer_protection_time < 1)
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Buyer protection time must be more than 1 or equal to 1'
				})
			);
		if (
			+getSecondsByInputData(
				formValues.buyer_protection_time,
				protectionTimeOption.value
			) < 86400
		)
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Ensure buyer protection time is greater than or equal to 24 hour'
				})
			);
		formData.append(
			'buyer_protection_time',
			getSecondsByInputData(
				formValues.buyer_protection_time,
				protectionTimeOption.value
			)
		);

		if (formValues.price < 0.01) {
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Price must be more than 0.01 or equal to 0.01'
				})
			);
		}
		formData.append('price', formValues.price.toString());

		if (formValues.available_networks) {
			if (!formValues.available_networks.length) {
				return dispatch(
					addAlert({
						type: 'error',
						text: 'Please check some networks or at least one network'
					})
				);
			}

			for (const available_network of formValues.available_networks) {
				formData.append('available_networks', available_network.toString());
			}
		}

		const availableTokensByNetworks =
			tokens &&
			tokens
				.filter(token =>
					formValues.available_networks.includes(token.network.chain_id)
				)
				.map(token => token.id);

		if (formValues.available_tokens && availableTokensByNetworks) {
			if (!formValues.available_tokens.length) {
				return dispatch(
					addAlert({
						type: 'error',
						text: 'Please check some tokens or at least one token'
					})
				);
			}

			checkIsAvailableTokenChecked.clearSelectedTokens();
			if (networks && tokens && formValues.available_networks) {
				checkIsAvailableTokenChecked.checkAvailableTokens(
					networks,
					tokens,
					formValues.available_networks,
					formValues.available_tokens
				);
			}
			if (checkIsAvailableTokenChecked.isHasUncheckedToken() >= 0) return;

			for (const available_token of formValues.available_tokens) {
				/* Include only available tokens by networks */
				if (availableTokensByNetworks.includes(available_token))
					formData.append('available_tokens', available_token.toString());
			}
		}

		if (formValues.is_approval_needed) {
			const index = approvedItem.findIndex(
				item => item.value === 'not_pre_approved'
			);
			if (index >= 0) {
				formData.append(
					'is_approval_needed',
					JSON.stringify(!item.is_approval_needed)
				);
			}
		}

		if (!formValues.is_approval_needed) {
			const index = approvedItem.findIndex(
				item => item.value === 'pre_approved'
			);
			if (index < 0) {
				formData.append(
					'is_approval_needed',
					JSON.stringify(!item.is_approval_needed)
				);
			}
		}

		const res = await Products.patch({ slug: item.slug, formData });

		if (res && res.id) {
			dispatch(setItemAction(res));
			setIsFormsEditable(false);
			dispatch(
				addAlert({
					type: 'info',
					text: 'Successful changed'
				})
			);
		}
	};

	const handleInputsChange =
		(prop: ItemEditProps) => (e: React.ChangeEvent<HTMLInputElement>) => {
			setFormValues(prevState => ({
				...prevState,
				[prop]: e.target.value
			}));
		};

	const handleDescriptionChange = (html: string) => {
		setFormValues(prevState => ({
			...prevState,
			description: html
		}));
	};

	const handleDeliveryOption =
		(props: 'delivery_time' | 'buyer_protection_time') =>
		(option: { name: string; value: number }) => {
			switch (props) {
				case 'buyer_protection_time':
					setProtectionTimeOption(option);
					break;
				case 'delivery_time':
					setDeliveryTimeOption(option);
					break;
			}

			const currentFormatValue = Math.floor(item[props] / +option.value);

			setFormValues({
				...formValues,
				[props]: currentFormatValue
			});
		};

	const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files;
		if (!file?.length) return;

		const url = await getURLFromFile(file[0]);
		if (typeof url === 'string') {
			setFormValues(prevState => ({
				...prevState,
				image: { file: file[0], url }
			}));
		}
		e.target.value = '';
	};

	const handleRemoveImage = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		e.preventDefault();
		setFormValues(prevState => ({
			...prevState,
			image: { file: null, url: '' }
		}));
	};

	/* Variables */
	const approveItemList = [
		{
			value: item.one_off ? 'one_off' : 'not_one_off',
			name: 'One off product'
		},
		{
			value: item.is_approval_needed ? 'not_pre_approved' : 'pre_approved',
			name: 'Pre-approved item'
		}
	];

	const defaultApproveItemList = [
		{
			value: item.one_off ? 'one_off' : '',
			name: 'One off product'
		},
		{
			value: item.is_approval_needed ? '' : 'pre_approved',
			name: 'Pre-approved item'
		}
	];

	return (
		<form onSubmit={handleFormSubmit} className='itemDetails'>
			<div className={styles.detailsTitleWrapper}>
				<h1 className={cn(styles.detailsTitle, 'h5')}>Item review</h1>
				{isFormsEditable ? (
					<div>
						<Button
							color='primary'
							variant='text'
							size='small'
							title='Cancel changes'
							type='button'
							className={styles.detailsBtn}
							onClick={() => setIsFormsEditable(false)}
						>
							Cancel
						</Button>
						<Button
							color='primary'
							size='small'
							variant='contained'
							title='Save changes'
							className={styles.detailsBtn}
						>
							Save
						</Button>
					</div>
				) : (
					<Button
						color='primary'
						size='small'
						variant='contained'
						disabled={maintenance.isActive}
						className={styles.detailsBtn}
						type='button'
						title='Edit item'
						onClick={() => {
							isFormsEditable
								? setIsFormsEditable(false)
								: setIsFormsEditable(true);
						}}
					>
						Edit
					</Button>
				)}
			</div>
			<div className={styles.details}>
				<dl className={cn(styles.detailsList, styles.detailsListThumbnail)}>
					{isFormsEditable ? (
						<>
							<Input
								id='image'
								accept='image/*'
								multiple={false}
								onChange={handleImageChange}
								className='imgInput'
								type='file'
							/>
							<label
								htmlFor='image'
								className={cn(styles.imageAttachment, {
									[styles.withoutBorder]: formValues.image?.url
								})}
							>
								{formValues.image?.url ? (
									<>
										<img
											src={formValues.image.url}
											alt='item picture'
											className={styles.itemImage}
										/>
										<Button
											type='button'
											className={cn(
												'imageRemoveCross',
												styles.itemImageRemoveCross
											)}
											onClick={handleRemoveImage}
										/>
									</>
								) : (
									<SvgSprite iconId='image' />
								)}
							</label>
						</>
					) : item.image ? (
						<img src={item.image} alt='item picture' />
					) : (
						<div className={styles.imagePlaceHolderWrapper}>
							<SvgSprite iconId='image' />
						</div>
					)}
				</dl>
				<dl className={cn(styles.detailsList, styles.detailsListProperties)}>
					<div>
						<dt className={cn(styles.infoTitle, 'subtitle-md')}>Item name:</dt>
						{isFormsEditable ? (
							<Input
								onChange={handleInputsChange('title')}
								value={formValues.title}
								disabled={!isFormsEditable}
								className={cn('editInput')}
								type='text'
								id='title'
								minLength={5}
								maxLength={100}
							/>
						) : (
							<dd
								className={cn(
									styles.infoContent,
									styles.infoContentTitle,
									'body'
								)}
							>
								{item.title}
							</dd>
						)}
					</div>
					<div>
						<dt className={cn(styles.infoTitle, 'body-md')}>Price</dt>
						{isFormsEditable ? (
							<Input
								onChange={handleInputsChange('price')}
								disabled={!isFormsEditable}
								value={formValues.price}
								className={cn('editInput', 'body-sm')}
								type='number'
								id='price'
							/>
						) : (
							<dd className={cn(styles.infoContent, 'body-sm')}>
								{item.price}
							</dd>
						)}
					</div>
					<div>
						<dt className={cn(styles.infoTitle, 'body-md')}>Delivery time:</dt>
						{isFormsEditable ? (
							<div className={styles.editInputWrapper}>
								<Input
									onChange={handleInputsChange('delivery_time')}
									disabled={!isFormsEditable}
									value={formValues.delivery_time}
									className={cn('editInput', 'body-sm')}
									type='number'
									id='delivery_time'
								/>
								<Select
									options={Object.values(timeOptions)}
									defaultOption={deliveryTimeOption}
									setOption={handleDeliveryOption('delivery_time')}
									listStartSide='end'
									size='sm'
									bg='secondary'
									className={styles.editingSelect}
								/>
							</div>
						) : (
							<dd className={cn(styles.infoContent, 'body-sm')}>
								{getHoursBySeconds(item.delivery_time)}
							</dd>
						)}
					</div>
					<div>
						<dt className={cn(styles.infoTitle, 'body-md')}>
							Buyer protection time:
						</dt>
						{isFormsEditable ? (
							<div className={styles.editInputWrapper}>
								<Input
									onChange={handleInputsChange('buyer_protection_time')}
									disabled={!isFormsEditable}
									value={formValues.buyer_protection_time}
									className={cn('editInput', 'body-sm')}
									type='number'
									id='buyer_protection_time'
								/>
								<Select
									options={Object.values(timeOptions)}
									defaultOption={protectionTimeOption}
									setOption={handleDeliveryOption('buyer_protection_time')}
									listStartSide='end'
									size='sm'
									bg='secondary'
									className={styles.editingSelect}
								/>
							</div>
						) : (
							<dd className={cn(styles.infoContent, 'body-sm')}>
								{getHoursBySeconds(item.buyer_protection_time)}
							</dd>
						)}
					</div>
					<div>
						<dt className={cn(styles.infoTitle, 'body-md')}>Zenland fee:</dt>
						<dd className={cn(styles.infoContent, 'body-sm')}>
							{isFormsEditable
								? formValues.price <= 1
									? '0.01'
									: getZenCommission(formValues.price) + '$'
								: item.price <= 1
								? '0.01'
								: getZenCommission(item.price) + '$'}
						</dd>
					</div>
					<div>
						<dt className={cn(styles.infoTitle, 'body-md')}>
							Accepted networks:{' '}
							<SvgSprite
								data-for='itemDetailsTooltip'
								data-tip={t('app.toolTips.itemDetails.networks')}
							/>
						</dt>
						{isFormsEditable ? (
							networks && (
								<CheckboxNetworksItemDetail
									networks={networks}
									checkedNetworksChainId={item.available_networks.map(
										(availableNetwork: INetwork) => availableNetwork.chain_id
									)}
									setCheckedNetworks={setCheckedNetworks}
								/>
							)
						) : (
							<dd
								className={cn(
									styles.infoContent,
									styles.infoContentNetworks,
									styles.infoContentMarked,
									'body-md'
								)}
							>
								{networks ? (
									item.available_networks.map((n: INetwork) => {
										return (
											<span key={n.id} title={n.display_name}>
												<img
													src={n.image}
													alt={n.display_name}
													className={styles.networkIcon}
												/>
											</span>
										);
									})
								) : (
									<p>Please reload the page to get networks after minute</p>
								)}
							</dd>
						)}
					</div>
					<div>
						<dt className={cn(styles.infoTitle, 'body-md')}>
							Accepted tokens:{' '}
							<SvgSprite
								data-for='itemDetailsTooltip'
								data-tip={t('app.toolTips.itemDetails.tokens')}
							/>
						</dt>
						{isFormsEditable ? (
							tokens && (
								<CheckboxTokensItemDetail
									tokens={tokens}
									checkedTokensId={item.available_tokens.map(
										(availableToken: IToken) => availableToken.id
									)}
									checkedNetworks={checkedNetworks}
									setCheckedTokens={setCheckedTokens}
								/>
							)
						) : (
							<dd
								className={cn(
									styles.infoContent,
									styles.infoContentWithBorder,
									styles.infoContentMarked,
									'body-md'
								)}
							>
								{item.available_tokens.map((token: ProductToken) => {
									const networkImage = item.available_networks.filter(
										(n: INetwork) => n.id === token.network
									);

									return (
										<span key={token.id} className={styles.tokenIconWrapper}>
											<img
												src={networkImage[0].image}
												alt={token.name}
												className={styles.tokenIcon}
											/>
											{token.name}
										</span>
									);
								})}
							</dd>
						)}
					</div>
					<div>
						<dt className={cn(styles.infoTitle, 'body-md')}>
							Additional settings{' '}
							<SvgSprite
								data-for='itemDetailsTooltip'
								data-tip={t('app.toolTips.itemDetails.settings')}
							/>
						</dt>
						{isFormsEditable ? (
							<Checkboxes
								list={approveItemList}
								setCheckedList={setApprovedItem}
								defaultCheckedList={defaultApproveItemList}
								borderless
								size='md'
								disableByIndex={0}
							/>
						) : (
							<div className={styles.infoContentWrapper}>
								<dd
									className={cn(
										styles.infoContent,
										styles.infoContentWithBorder,
										styles.infoContentFullWidth,
										{ [styles.infoContentMarked]: item.one_off },
										'body'
									)}
								>
									<span>One off product</span>
								</dd>
								<dd
									className={cn(
										styles.infoContent,
										styles.infoContentWithBorder,
										styles.infoContentFullWidth,
										{ [styles.infoContentMarked]: !item.is_approval_needed },
										'body'
									)}
								>
									<span>Pre-approved item</span>
								</dd>
							</div>
						)}
					</div>
				</dl>
				<dl className={cn(styles.detailsList, styles.detailsListDescription)}>
					<div>
						{isFormsEditable ? (
							<ReactQuill
								theme='snow'
								value={formValues.description}
								modules={{
									toolbar: [
										[{ size: ['small', false, 'large', 'huge'] }],
										['bold', 'italic', 'underline'],
										[{ list: 'ordered' }, { list: 'bullet' }],
										['link'],
										[{ align: [] }],
										['clean']
									]
								}}
								formats={[
									'size',
									'bold',
									'italic',
									'underline',
									'list',
									'bullet',
									'link',
									'align'
								]}
								onChange={handleDescriptionChange}
								onKeyDown={e => {
									const isTabbingInEditor =
										e.key === 'Tab' &&
										e.target.className === 'ql-editor focus-visible';

									if (isTabbingInEditor) {
										e.preventDefault();
										e.target.blur();
										return false;
									}
								}}
								className={cn(styles.formInput, styles.descriptionInput)}
								placeholder='Describe your item'
							/>
						) : (
							<>
								<dt className={cn(styles.infoTitle, 'subtitle-md')}>
									Description:
								</dt>
								<dd className={cn(styles.infoContent, 'body-md', 'ql-editor')}>
									{parse(item.description)}
								</dd>
							</>
						)}
					</div>
				</dl>
			</div>
			<Tooltip id='itemDetailsTooltip' />
		</form>
	);
};

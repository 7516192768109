import React from 'react';

import { SkipLinkProps } from './SkipLink.props';

import styles from './SkipLink.module.scss';

export const SkipLink = ({ ...props }: SkipLinkProps) => {
	return (
		<a className={styles.skipLink} href='#content' {...props}>
			Skip to main content
		</a>
	);
};

import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { IItemList } from '@pages/Dashboard/Items/Item.props';

export interface ProductCardProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
	product: IItemList;
	address: string;
	hasHeader?: boolean;
}

export enum Item {
	data = 'item_create_contract_data'
}

import { IUserRating } from '@core/models/interfaces';

export const getFilteredRatings = (
	ratings: IUserRating[],
	username: string
) => {
	const index = ratings.findIndex(
		r => r.author.username.toLowerCase() === username.toLowerCase()
	);

	if (index === -1) {
		return {
			IsEditable: true,
			HasOwnComment: false,
			Reviews: (prev: {
				list: IUserRating[];
				mine: { text: string; score: number };
			}) => ({
				list: ratings,
				mine: prev.mine
			})
		};
	} else {
		return {
			IsEditable: false,
			HasOwnComment: true,
			Reviews: {
				list: ratings,
				mine: {
					text: ratings[index].text || '',
					score: ratings[index].score
				}
			}
		};
	}
};

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ContractStatusName, TransactionType } from '@core/models/enums';
import { releaseContract } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { TransactionStatuses } from '@core/store/transaction/transaction.types';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { StepProps } from '@pages/Dashboard/Contracts/Contract/Steps/Steps.props';
import { Button, Checkboxes, SvgSprite } from '@components/index';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';

import styles from '@pages/Dashboard/Contracts/Contract/Steps/Steps.module.scss';

interface ReleaseProps extends Omit<StepProps, 'auth'> {
	isReleaseDisabled: boolean;
	setIsDisableWhileConfirming: Dispatch<SetStateAction<boolean>>;
	isUnConfirmedReleaseActive?: boolean;
}

export const Release = ({
	contract,
	isReleaseDisabled,
	isUnConfirmedReleaseActive,
	setIsDisableWhileConfirming
}: ReleaseProps) => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [transaction, currentContractIsSyncing] = useAppSelector(
		({ transaction, contracts }) => [
			transaction,
			contracts.current.isCurrentSyncing
		]
	);

	/* React hooks */
	const [loading, setLoading] = useState(false);
	const [isReleaseBtnDisabled, setIsReleaseBtnDisabled] = useState(true);
	const [isReleaseEvent, setIsReleaseEvent] = useState<boolean>(false);
	const [confirmingLoading, setConfirmingLoading] = useState<boolean>(false);
	const [checkedValue, setCheckedValues] = useState<TCheckboxList[]>(
		[] as TCheckboxList[]
	);

	useEffect(() => {
		setIsReleaseBtnDisabled(isReleaseDisabled);
		if (isUnConfirmedReleaseActive) setIsReleaseBtnDisabled(false);
	}, [isUnConfirmedReleaseActive, isReleaseDisabled]);
	useEffect(() => {
		if (contract.transactions.length) {
			if (
				contract.transactions[0].status === TransactionStatuses.Pending &&
				contract.transactions[0].type === TransactionType.released
			) {
				setConfirmingLoading(true);
				setIsReleaseEvent(true);
				setIsDisableWhileConfirming(true);
			}
		}

		if (isReleaseEvent) {
			if (
				transaction.status === TransactionStatuses.Confirmed &&
				confirmingLoading
			) {
				dispatch(
					addAlert({
						text: `You have released agreed amount ($${contract.price}) from the contract #${contract.id}.`,
						type: 'info',
						isSubmit: true
					})
				);
				setConfirmingLoading(false);
				setIsReleaseEvent(false);
				setLoading(false);
				setIsDisableWhileConfirming(false);
			}

			if (
				transaction.status === TransactionStatuses.Pending &&
				contract.status !== ContractStatusName.Executed
			) {
				setConfirmingLoading(true);
				setIsDisableWhileConfirming(true);
			}

			if (transaction.status === TransactionStatuses.Failed) {
				setConfirmingLoading(false);
				setLoading(false);
				dispatch(
					addAlert({
						text: 'Transaction for release contract failed',
						type: 'error',
						isSubmit: true
					})
				);
				setIsReleaseEvent(false);
			}
		}
	}, [transaction.status, contract.status]);

	const list = [
		{
			value: 1,
			name: "Release funds even though Seller hasn't confirmed fulfillment yet"
		}
	];

	const handleReleaseContract = async () => {
		if (isReleaseBtnDisabled) {
			return dispatch(
				addAlert({
					text: `Contract is ${contract.status}, not active!`,
					type: 'error',
					isSubmit: true
				})
			);
		}

		setLoading(true);
		setIsReleaseEvent(true);

		const res = await releaseContract(JSON.parse(JSON.stringify(contract)));
		if (!res) setLoading(false);
	};

	return (
		<>
			<Button
				className={styles.stepBtn}
				onClick={handleReleaseContract}
				disabled={
					contract.isNetworkNotMatched ||
					contract.is_syncing ||
					currentContractIsSyncing ||
					isReleaseBtnDisabled ||
					(isUnConfirmedReleaseActive && !checkedValue.length)
				}
				isLoader={loading}
				isConfirming={confirmingLoading}
				variant='contained'
				size='medium'
				color='primary'
			>
				<SvgSprite
					iconId='metamask-fox-outlined'
					className={styles.metamaskIcon}
				/>{' '}
				Release
			</Button>
			{isUnConfirmedReleaseActive && !confirmingLoading && (
				<Checkboxes
					list={list}
					setCheckedList={setCheckedValues}
					size='extra-sm'
					borderHidden
					isReverseLayout
				/>
			)}
		</>
	);
};

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { NotificationModel } from '@core/models/enums';
import {
	setUserBalance,
	setUserReferralBalance
} from '@core/store/auth/auth.slice';
import { loginThunk } from '@core/store/auth/auth.thunks';
import { lastNewNotificationReceived } from '@core/store/notification/notification.slice';
import { setReferralTransactionStatusAction } from '@core/store/referral/referral.slice';
import { showSidebar } from '@core/store/theme/theme.slice';
import { getNotificationInfo } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Button, SvgSprite } from '@components/index';

import { NotificationDropdownList } from './NotificationsDropdown/NotificationDropdownList';
import { HeaderProps } from './Header.props';
import { NetworkList } from './NetworkList';
import { ProfileMenu } from './ProfileMenu';

import styles from './Header.module.scss';

export const Header = ({ className }: HeaderProps) => {
	/* Redux hooks */
	const [auth, notification] = useAppSelector(({ auth, notification }) => [
		auth,
		notification.lastNewNotification
	]);
	const dispatch = useAppDispatch();

	/* React hooks */
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (notification) {
			const isWalletChanged =
				getNotificationInfo(notification).title === 'Invoice';

			const isReferralWalletChanged =
				notification?.action_object_content_type?.model ===
				NotificationModel.Referrals;

			/** Check internal wallet transaction **/
			if (isWalletChanged && notification.data.balance) {
				dispatch(setUserBalance(notification.data.balance));
				dispatch(lastNewNotificationReceived(null));
			}

			/** Check referral transaction **/
			if (isReferralWalletChanged && notification.data?.running_balance) {
				dispatch(setUserReferralBalance(notification.data.running_balance));

				/** Check failed referral transaction **/
				if (!notification.data.success && notification.target_object_id)
					dispatch(
						setReferralTransactionStatusAction({
							id: notification.target_object_id,
							status: 'failed'
						})
					);

				dispatch(lastNewNotificationReceived(null));
			}
		}
	}, [notification]);

	/* Handlers */
	const handleConnect = async () => {
		setIsLoading(true);
		await dispatch(loginThunk());
		setIsLoading(false);
	};

	return (
		<header className={className}>
			<nav className={styles.nav}>
				<NavLink className={styles.logo} to='/'>
					<SvgSprite
						className={styles.logoIcon}
						color='#1A82FB'
						iconId='logo-icon'
						width={49}
						height={48}
					/>
					<SvgSprite
						className={styles.desktopOnly}
						iconId='logo-text'
						width={142}
						height={18}
					/>
				</NavLink>
				{auth.isLogged || auth.whoAmI.username ? (
					<ul className={styles.navControls}>
						<li>
							<NetworkList />
						</li>
						<li>
							<NavLink
								to='/wallet?limit=10'
								className={cn(styles.userBalance, 'subtitle')}
							>
								<SvgSprite
									height={24}
									width={24}
									color='#1A82FB'
									iconId='logo-icon'
								/>
								$ {auth.whoAmI.balance || 0}
							</NavLink>
						</li>
						<li>
							<NotificationDropdownList />
						</li>
						<li className={styles.desktopOnly}>
							<ProfileMenu />
						</li>
						<li className={styles.mobileOnly}>
							<BurgerButton />
						</li>
					</ul>
				) : (
					<Button
						onClick={handleConnect}
						variant='contained'
						size='medium'
						color='primary'
						isLoader={isLoading}
					>
						Connect
					</Button>
				)}
			</nav>
		</header>
	);
};

const BurgerButton = () => {
	/* Redux hooks */
	const isSidebarOpen = useAppSelector(({ theme }) => theme.isSidebarOpen);
	const dispatch = useAppDispatch();

	/* Handlers */
	const handleClick = () => dispatch(showSidebar(!isSidebarOpen));

	return (
		<Button
			className={cn(styles.hamburger, styles.mobileOnly, {
				[styles.active]: isSidebarOpen
			})}
			onClick={handleClick}
			type='button'
		>
			<div className={styles.hamburgerInner} />
		</Button>
	);
};

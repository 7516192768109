import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import { NotificationModel } from '@core/models/enums';
import {
	setMyItemsAction,
	setOffsetAction,
	setSearchAction
} from '@core/store/item/item.slice';
import { getItemsThunk } from '@core/store/item/item.thunks';
import { getSearchParams } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { IItemList } from '@pages/Dashboard/Items/Item.props';
import { ItemsTable } from '@pages/Dashboard/Items/ItemsTable';
import { Pagination, SearchForm } from '@components/DashboardComponents';
import { Button } from '@components/MainComponents/Button/Button';
import { Heading } from '@components/MainComponents/Heading/Heading';

import styles from '@pages/Dashboard/Items/Items.module.scss';

export const Items = () => {
	// Router hooks
	const history = useHistory();
	const location = useLocation();

	// Redux hooks
	const dispatch = useAppDispatch();
	const [items, itemsFilter, username, lastNewNotification] = useAppSelector(
		({ items, itemsFilter, auth, notification }) => [
			items,
			itemsFilter,
			auth.whoAmI.username,
			notification.lastNewNotification
		]
	);

	// React hooks
	const [currentPage, setCurrentPage] = useState(
		(itemsFilter.offset + itemsFilter.limit) / 10
	);
	const [params, setParams] = useState<{
		limit: string;
		offset: string;
		ordering: string;
		search: string;
	}>();

	useEffect(() => {
		const params = new URLSearchParams(getSearchParams(itemsFilter));
		history.push(`/items?${params}`);
	}, [itemsFilter]);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const currentParams = Object.fromEntries(urlParams) as {
			limit: string;
			offset: string;
			ordering: string;
			search: string;
		};
		setParams(currentParams);

		const promise = dispatch(
			getItemsThunk({
				params: {
					...currentParams,
					limit: +currentParams.limit,
					offset: +currentParams.offset ? +currentParams.offset : 0,
					seller__username: username
				},
				isMyItems: true
			})
		);
		return () => {
			promise.abort();
			dispatch(setMyItemsAction(null));
		};
	}, [location.search, username]);

	useEffect(() => {
		const isItemNotificationReceived =
			lastNewNotification &&
			lastNewNotification.action_object_content_type?.model ===
				NotificationModel.Marketplace;

		if (isItemNotificationReceived && params) {
			const promise = dispatch(
				getItemsThunk({
					params: {
						...params,
						limit: +params.limit,
						offset: +params.offset ? +params.offset : 0,
						seller__username: username
					},
					isMyItems: true
				})
			);
			return () => {
				promise.abort();
			};
		}
	}, [lastNewNotification]);

	/* Handlers */
	const handlePageChange = () => (page: number) => {
		const endOffset = itemsFilter.limit * page;
		const startOffset = endOffset - itemsFilter.limit;
		dispatch(setOffsetAction(startOffset));
		const params = new URLSearchParams(
			getSearchParams({ ...itemsFilter, offset: startOffset })
		);
		history.push(`/items?${params}`);
		dispatch(setOffsetAction(startOffset));
		setCurrentPage(page);
	};

	const handleSearchFormSubmit = (value: string) => {
		setCurrentPage(1);
		dispatch(setOffsetAction(0));
		dispatch(setSearchAction(value));
	};
	const currentTableData: IItemList[] | null = useMemo(() => {
		return items.myItems;
	}, [items]);

	return (
		<div className={cn('pageWrapper', styles.pageFlex)}>
			<div className='pageHead'>
				<Heading tag='h1' className={cn(styles.pageTitle, 'h5')}>
					My items
				</Heading>
				<Button
					as='link'
					to='/item/new'
					variant='contained'
					size='medium'
					color='primary'
				>
					Add Item
				</Button>
			</div>
			<SearchForm
				onSubmit={handleSearchFormSubmit}
				className={styles.searchForm}
				initialValue={itemsFilter.search}
			/>
			<ItemsTable isLoading={items.isLoading} list={currentTableData} />
			<div className={styles.pagination}>
				<Pagination
					currentPage={currentPage}
					totalCount={items.counts.total_count}
					pageSize={itemsFilter.limit}
					onPageChange={handlePageChange()}
					siblingCount={1}
				/>
			</div>
		</div>
	);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMainFilter } from '@core/models/interfaces/mainInterfaces/mainInterfaces';
import { initialFilterState } from '@core/store/item/item.slice';
import {
	ReferralListDataTypes,
	ReferralListTransactionStatusTypes,
	ReferralListTransactionTypes,
	ReferralListTypes
} from '@core/store/referral/referral.types';

export const referralFilterSlice = createSlice({
	name: 'referral/Filter',
	initialState: initialFilterState,
	reducers: {
		setReferralAllFilters: (state, action: PayloadAction<IMainFilter>) => {
			return {
				...state,
				...action.payload
			};
		},
		setOffsetAction: (state, action: PayloadAction<number>) => {
			return { ...state, offset: action.payload };
		}
	}
});

export const {
	reducer: ReferralFilterReducer,
	actions: { setReferralAllFilters, setOffsetAction }
} = referralFilterSlice;

const initialReferralList: ReferralListTypes = {
	count: { list: 0, transactions: 0 },
	isLoading: false,
	list: null,
	transactions: null
};

export const referralListSlice = createSlice({
	name: 'referral/List',
	initialState: initialReferralList,
	reducers: {
		setReferralListAction: (
			state,
			action: PayloadAction<ReferralListDataTypes[] | null>
		) => {
			state.list = action.payload;
			state.isLoading = false;
		},
		setReferralTransactionsAction: (
			state,
			action: PayloadAction<
				ReferralListTransactionTypes | ReferralListTransactionTypes[] | null
			>
		) => {
			if (Array.isArray(action.payload)) {
				state.transactions = action.payload;
			} else {
				if (state.transactions && action.payload) {
					const transactions = state.transactions.slice(0, -1);
					state.transactions = [action.payload, ...transactions];
				}
			}
			state.isLoading = false;
		},
		setReferralTransactionStatusAction: (
			state,
			action: PayloadAction<{
				id: number;
				status: ReferralListTransactionStatusTypes;
			}>
		) => {
			state.transactions =
				state.transactions?.map(transaction => {
					if (transaction.id === action.payload.id) {
						return { ...transaction, status: action.payload.status };
					} else return transaction;
				}) || state.transactions;
		},
		setReferralCountAction: (state, action: PayloadAction<number>) => {
			state.count.list = action.payload;
		},
		setReferralTransactionsCountAction: (
			state,
			action: PayloadAction<number>
		) => {
			state.count.transactions = action.payload;
		},
		setReferralLoadingAction: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		}
	}
});

export const {
	reducer: ReferralListReducer,
	actions: {
		setReferralListAction,
		setReferralCountAction,
		setReferralLoadingAction,
		setReferralTransactionsAction,
		setReferralTransactionsCountAction,
		setReferralTransactionStatusAction
	}
} = referralListSlice;

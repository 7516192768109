import { API } from '@core/config/api';
import { ISignup } from '@core/services/auth/models';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Signup = {
	async post({ address, proceedLogin = true, referralCode }: ISignup) {
		const apiURL = proceedLogin ? API.proceedLogin : API.signup;
		const addressLowerCase = address.toLowerCase();
		const payload = referralCode
			? {
					username: addressLowerCase,
					referral_code: referralCode
			  }
			: {
					username: addressLowerCase
			  };

		try {
			const response = await fetch(apiURL, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				body: JSON.stringify(payload)
			});

			if (!response.ok) {
				logErrors(await response.json());
				return false;
			}

			const { success } = await response.json();
			if (typeof success === 'boolean') return success;
			return false;
		} catch (e) {
			logErrors(e);
			return false;
		}
	}
};

import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';

import { markChatMessageAsReadThunk } from '@core/store/ws/chat/wsChat.thunks';
import { cropAddress } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector, useOnScreen } from '@core/utils/hooks';
import { linkPattern } from '@core/utils/regex';
import { Badge, SvgSprite } from '@components/index';

import { Bubble } from './Bubble/Bubble';
import { MessageProps } from './Chat.props';

import styles from './Chat.module.scss';

export const Message = React.memo(
	({ item, agentAddress, ...props }: MessageProps) => {
		/* Constants */
		const isLink = new RegExp(linkPattern);
		const author = item.author.toLowerCase();

		/* Redux hooks */
		const [myAddress, contract] = useAppSelector(({ auth, contracts }) => [
			auth.whoAmI.username,
			contracts.current.data
		]);
		const dispatch = useAppDispatch();

		/* React hooks */
		const elementRef = useRef<HTMLLIElement>(null);

		/* Custom hooks */
		const isOnScreen = useOnScreen(elementRef);

		/* React hooks */
		useEffect(() => {
			if (
				item.unread &&
				isOnScreen &&
				myAddress !== author &&
				author !== 'calendar'
			) {
				dispatch(markChatMessageAsReadThunk([item.id]));
			}
		}, [isOnScreen, item.unread, myAddress, author]);

		/* Custom functions */
		const message = () => {
			return item.content
				.toString()
				.split(' ')
				.map((w, i) => {
					switch (true) {
						case isLink.test(w):
							return (
								<a
									href={w}
									target='_blank'
									rel='noopener noreferrer'
									className={styles.link}
									key={i}
								>
									{' '}
									{w}
								</a>
							);
						default:
							return ' ' + w;
					}
				});
		};
		const renderByAuthor = () => {
			if (item.is_fulfillment) {
				return (
					<Bubble className='body-sm' color='success' position='center'>
						<span className={styles.fulfillmentTitle}>Fulfillment message</span>
						<span className={styles.fulfillmentText}>
							{message()}
							<Moment
								className={cn('caption', styles.fulfillmentDate)}
								format='HH:mm'
							>
								{item.created}
							</Moment>
						</span>
					</Bubble>
				);
			}

			switch (author) {
				case 'calendar':
					return (
						<Badge
							className={styles.badge}
							color='secondary'
							text='sm'
							size='sm'
							rounded='md'
						>
							<Moment format='MMMM DD, YYYY'>{item.created}</Moment>
						</Badge>
					);

				case 'system':
					return (
						<Badge
							className={styles.badge}
							color='success'
							rounded='md'
							size='sm'
							text='sm'
						>
							{item.content}
							<Moment
								className={cn('caption', styles.systemDate)}
								format='HH:mm'
							>
								{item.created}
							</Moment>
						</Badge>
					);

				case myAddress:
					return (
						<Bubble date={item.created} hasIcon unread={item.unread}>
							{message()}
						</Bubble>
					);

				case agentAddress:
					return (
						<Bubble
							className={styles.agentBubble}
							position='start'
							color='danger'
							date={item.created}
						>
							{message()}
							<SvgSprite iconId='justice' className={styles.justiceIcon} />
						</Bubble>
					);

				default:
					return (
						<Bubble position='start' color='primary' date={item.created}>
							{contract && myAddress === agentAddress && (
								<NavLink
									to={`/profile/${item.author}`}
									className={cn('body-md', styles.msgAuthor, {
										[styles.buyer]: contract.buyer.username === author
									})}
								>
									{contract.buyer.username === author ? 'Buyer' : 'Seller'} (
									{cropAddress(author, 3, 3)})
								</NavLink>
							)}
							{message()}
						</Bubble>
					);
			}
		};

		return (
			<li
				className={cn(styles.msg, {
					[styles.rightMsg]: author === myAddress && !item.is_fulfillment,
					[styles.centerMsg]: author === 'system' || item.is_fulfillment,
					[styles.centerCalendar]: author === 'calendar'
				})}
				ref={elementRef}
				{...props}
			>
				{renderByAuthor()}
			</li>
		);
	}
);

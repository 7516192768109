import React, { useEffect, useState } from 'react';

import { INetwork } from '@core/models/interfaces';
import { Checkboxes } from '@components/MainComponents/Checkboxes/Checkboxes';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';

export const CheckboxNetworksItemDetail = ({
	networks,
	checkedNetworksChainId,
	setCheckedNetworks
}: {
	networks: INetwork[];
	checkedNetworksChainId: (string | number)[];
	setCheckedNetworks: React.Dispatch<React.SetStateAction<TCheckboxList[]>>;
}) => {
	const [checkedList, setCheckedList] = useState<TCheckboxList[]>([
		{ value: '', name: '' }
	]);
	useEffect(() => {
		setCheckedNetworks(checkedList);
	}, [checkedList]);

	const list = networks.map(network => ({
		value: network.chain_id,
		name: network.display_name,
		image: network.image
	}));

	const defaultCheckedList = networks
		.filter(network => checkedNetworksChainId.includes(network.chain_id))
		.map(network => ({
			value: network.chain_id,
			name: network.display_name
		}));

	return (
		<Checkboxes
			list={list}
			setCheckedList={setCheckedList}
			defaultCheckedList={defaultCheckedList}
			borderHidden
			isDisplayAsIcons
			labelHasTitle
		/>
	);
};

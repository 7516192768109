import { API } from '@core/config/api';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Logout = {
	async get() {
		try {
			// Clear all cookies
			document.cookie.split(';').forEach(c => {
				document.cookie = c
					.replace(/^ +/, '')
					.replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
			});

			const response = await fetch(API.logout, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			const data: Record<string, string> = await response.json();

			if (data.detail.includes('Successfully logged out')) return true;
		} catch (e) {
			logErrors(e);
			window.location.reload();
		}
	}
};

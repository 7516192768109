import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';

import {
	ContractStatusName,
	ContractStatusNumber,
	TransactionType
} from '@core/models/enums';
import { disputeContract } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { TransactionStatuses } from '@core/store/transaction/transaction.types';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { StepProps } from '@pages/Dashboard/Contracts/Contract/Steps/Steps.props';
import { Button, Countdown, SvgSprite } from '@components/index';

import styles from '@pages/Dashboard/Contracts/Contract/Steps/Steps.module.scss';

interface DisputeProps extends Omit<StepProps, 'auth'> {
	isDisputeDisabled: boolean;
	setIsDisableWhileConfirming: Dispatch<SetStateAction<boolean>>;
}

export const Dispute = ({
	contract,
	isDisputeDisabled,
	setIsDisableWhileConfirming
}: DisputeProps) => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [transaction, currentContractIsSyncing] = useAppSelector(
		({ transaction, contracts }) => [
			transaction,
			contracts.current.isCurrentSyncing
		]
	);

	/* React hooks */
	const [loading, setLoading] = useState(false);
	const [confirmingLoading, setConfirmingLoading] = useState<boolean>(false);
	const [isDisputeEvent, setIsDisputeEvent] = useState<boolean>(false);

	useEffect(() => {
		if (contract.transactions.length) {
			if (
				contract.transactions[0].status === TransactionStatuses.Pending &&
				contract.transactions[0].type === TransactionType.disputed
			) {
				setConfirmingLoading(true);
				setIsDisputeEvent(true);
				setIsDisableWhileConfirming(true);
			}
		}

		if (isDisputeEvent) {
			if (
				transaction.status === TransactionStatuses.Confirmed &&
				confirmingLoading
			) {
				dispatch(
					addAlert({
						text: `You have disputed the contract #${contract.id}`,
						type: 'info',
						isSubmit: true
					})
				);
				setConfirmingLoading(false);
				setIsDisputeEvent(false);
				setLoading(false);
				setIsDisableWhileConfirming(false);
			}
			if (
				transaction.status === TransactionStatuses.Pending &&
				contract.status !== ContractStatusName.Disputed
			) {
				setConfirmingLoading(true);
				setIsDisableWhileConfirming(true);
			}

			if (transaction.status === TransactionStatuses.Failed) {
				setConfirmingLoading(false);
				setLoading(false);
				dispatch(
					addAlert({
						text: 'Transaction for dispute contract failed',
						type: 'error',
						isSubmit: true
					})
				);
				setIsDisputeEvent(false);
			}
		}
	}, [transaction.status, contract.status]);

	const handleDisputeContract = async () => {
		if (isDisputeDisabled) {
			return dispatch(
				addAlert({
					text: `Contract is ${contract.status}, not active!`,
					type: 'error',
					isSubmit: true
				})
			);
		}
		setLoading(true);
		setIsDisputeEvent(true);

		const res = await disputeContract(JSON.parse(JSON.stringify(contract)));
		if (!res) setLoading(false);
	};

	return (
		<>
			<Button
				className={styles.stepBtn}
				onClick={handleDisputeContract}
				disabled={
					contract.isNetworkNotMatched ||
					isDisputeDisabled ||
					contract.is_syncing ||
					currentContractIsSyncing
				}
				isLoader={loading}
				loaderColor='#3c97ff'
				isConfirming={confirmingLoading}
				variant='outlined'
				size='medium'
				color='danger'
			>
				<SvgSprite
					iconId='metamask-fox-outlined'
					className={styles.metamaskIcon}
				/>{' '}
				Dispute
			</Button>

			{ContractStatusNumber[contract.status] >= ContractStatusNumber.active &&
				(moment(contract.deadline).isAfter(moment()) ? (
					<span className={cn('caption', styles.disputeHint)}>
						{ContractStatusNumber[contract.status] ===
							ContractStatusNumber.active && (
							<>
								Dispute is available only after the due date has passed.{' '}
								<Countdown
									date={contract.deadline}
									format='d [days] h [hrs] m [mins] s [sec]'
								/>{' '}
								is left.
							</>
						)}

						{ContractStatusNumber[contract.status] ===
							ContractStatusNumber.fulfilled && (
							<>
								If not satisfied, dispute to stop the countdown and set new
								terms and due date with the Seller via Contract Chat.
							</>
						)}
						{ContractStatusNumber[contract.status] ===
							ContractStatusNumber.disputed && (
							<>
								Inviting an Agent you will grant the Agent a full control over
								your case and agree with its resolution. The agent fee is
								0.75-2.25 USDT depending on the complexity of the case.
							</>
						)}
					</span>
				) : (
					<span className={cn('caption', styles.disputeHint)}>
						Due date has passed. Dispute is now available.
					</span>
				))}
		</>
	);
};

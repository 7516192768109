import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { IChatRoomMember } from '@core/store/chat/chat.types';
import { cropAddress } from '@core/utils/helpers';

import {
	StackedAvatarProps,
	StackedAvatarsProps
} from './StackedAvatars.props';

import styles from './StackedAvatars.module.scss';

export const StackedAvatars = ({ list }: StackedAvatarsProps) => {
	if (!list.length) return null;
	if (list.length === 1) return <AvatarInfo item={list[0]} />;

	const renderList = () => {
		return list.map(item => (
			<StackedAvatar item={item} key={item.id} id={`${item.id}`} />
		));
	};

	return <ul className={styles.stackedList}>{renderList()}</ul>;
};

const StackedAvatar = ({ item, id, ...props }: StackedAvatarProps) => (
	<li className={styles.stackedListItem} {...props}>
		{item.avatar ? (
			<img
				data-tip={true}
				data-for={`stackedAvatarTooltip_${id}`}
				src={item.avatar}
				alt=''
				className={styles.avatar}
			/>
		) : (
			<div
				data-tip={true}
				data-for={`stackedAvatarTooltip_${id}`}
				className={cn(styles.avatar, styles.avatarPlaceholder)}
			>
				{item.nickname ? item.nickname[0] : 'A'}
			</div>
		)}
		<ReactTooltip
			type='info'
			effect='solid'
			offset={{ top: -7 }}
			id={`stackedAvatarTooltip_${id}`}
			place='top'
			arrowColor='#fff'
			className={cn('tooltip', styles.tooltip)}
		>
			<AvatarInfo item={item} />
		</ReactTooltip>
	</li>
);

export const AvatarInfo = ({ item }: { item: IChatRoomMember }) => (
	<Link
		to={`/profile/${item.nickname || item.username}/items`}
		className={styles.link}
	>
		{item.avatar ? (
			<img src={item.avatar} alt='' className={styles.avatar} />
		) : (
			<div className={cn(styles.avatar, styles.avatarPlaceholder)}>
				{item.nickname ? item.nickname[0] : 'A'}
			</div>
		)}
		<div className={styles.info}>
			<p className={cn(styles.nickname, 'subtitle-md')}>
				{item.nickname || 'Anonymous'}
			</p>

			<p className={cn(styles.username, 'body-sm')}>
				{cropAddress(item.username, 3, 3)}
			</p>
		</div>
	</Link>
);

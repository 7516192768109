import { API } from '@core/config/api';
import { INetwork } from '@core/models/interfaces';
import { setAppInfo } from '@core/store/app/app.slice';
import { store } from '@core/store/store';
import { logErrors } from '@core/utils/logErrors';

export const Network = {
	async getAll() {
		try {
			const response = await fetch(`${API.networks}?is_active=true`, {
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			store.dispatch(
				setAppInfo({
					version: response.headers.get('app-version') || '',
					buildTimestamp: response.headers.get('app-build-timestamp') || ''
				})
			);

			const { results }: { results: INetwork[] } = await response.json();
			return results;
		} catch (e) {
			logErrors(e);
			return false;
		}
	},
	async getZenfPrice(id: number) {
		try {
			const response = await fetch(
				`${API.networks}${id}/get_zen_token_price/`,
				{
					credentials: 'include'
				}
			);

			if (!response.ok) return logErrors(await response.json());

			const { price }: { price: string } = await response.json();
			return price;
		} catch (e) {
			logErrors(e);
			return false;
		}
	}
};

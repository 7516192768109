import { Dispatch } from 'redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { NotificationCommand, WSReadyState } from '@core/models/enums';
import { WSNotificationResponse } from '@core/models/interfaces';
import {
	lastNewNotificationReceived,
	newNotificationReceived
} from '@core/store/notification/notification.slice';
import { getUniqSystemEvents } from '@core/store/notification/notification.thunks';
import { store } from '@core/store/store';
import { NotificationSocket } from '@core/websocket/notificationSocket';

import { NotificationStatusChanged } from './wsNotification.slice';

let _newMessageHandler: ((messages: WSNotificationResponse) => void) | null =
	null;

const storeDispatch = store.dispatch;

const newMessageHandlerCreator = (dispatch: Dispatch) => {
	if (!_newMessageHandler) {
		_newMessageHandler = data => {
			if (data.command === NotificationCommand.NewNotification) {
				if (data.notification.is_system) {
					storeDispatch(getUniqSystemEvents(data.notification));
				} else {
					dispatch(newNotificationReceived(data.notification));
					dispatch(lastNewNotificationReceived(data.notification));
				}
			}
		};
	}

	return _newMessageHandler;
};

let _statusHandler: ((status: WSReadyState) => void) | null = null;

const statusHandlerCreator = (dispatch: Dispatch) => {
	if (!_statusHandler) {
		_statusHandler = data => {
			dispatch(NotificationStatusChanged(data));
		};
	}

	return _statusHandler;
};

export const startNotificationsListening = createAsyncThunk(
	'wsNotification/startNotificationsListening',
	async (roomId, thunkAPI) => {
		NotificationSocket.start();
		NotificationSocket.subscribe(
			'message',
			newMessageHandlerCreator(thunkAPI.dispatch)
		);
		NotificationSocket.subscribe(
			'status',
			statusHandlerCreator(thunkAPI.dispatch)
		);
	}
);

export const stopNotificationsListening = createAsyncThunk(
	'wsNotification/stopNotificationsListening',
	async (_, thunkAPI) => {
		NotificationSocket.unsubscribe(
			'message',
			newMessageHandlerCreator(thunkAPI.dispatch)
		);
		NotificationSocket.unsubscribe(
			'status',
			statusHandlerCreator(thunkAPI.dispatch)
		);
		NotificationSocket.stop();
	}
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMainFilter } from '@core/models/interfaces/mainInterfaces/mainInterfaces';
import { InitialItemList, ItemCounts } from '@core/store/item/item.types';
import { IItemList } from '@pages/Dashboard/Items/Item.props';

export const initialFilterState: IMainFilter = {
	limit: 10,
	offset: 0,
	search: '',
	ordering: ''
};

export const itemFilterSlice = createSlice({
	name: 'item/Filter',
	initialState: initialFilterState,
	reducers: {
		setItemAllFilters: (state, action: PayloadAction<IMainFilter>) => {
			return {
				...state,
				...action.payload
			};
		},
		setLimitAction: (state, action: PayloadAction<number>) => {
			return { ...state, limit: action.payload };
		},
		setOffsetAction: (state, action: PayloadAction<number>) => {
			return { ...state, offset: action.payload };
		},
		setSearchAction: (state, action: PayloadAction<string>) => {
			return { ...state, search: action.payload };
		},
		setOrderingAction: (state, action: PayloadAction<string>) => {
			return { ...state, ordering: action.payload };
		}
	}
});

const initialItemList: InitialItemList = {
	counts: {
		total_count: 0
	},
	products: null,
	myItems: null,
	isLoading: false,
	isRedirecting: false,
	current: {
		data: null
	}
};

const itemListSlice = createSlice({
	name: 'item/List',
	initialState: initialItemList,
	reducers: {
		setItemListAction: (state, action: PayloadAction<IItemList[] | null>) => {
			return { ...state, products: action.payload, isLoading: false };
		},
		setItemByIdListAction: (state, action: PayloadAction<IItemList | null>) => {
			return {
				...state,
				myItems:
					state.myItems?.map(item => {
						if (item.id === action.payload?.id) {
							return action.payload;
						}
						return item;
					}) || null
			};
		},
		setMyItemsAction: (state, action: PayloadAction<IItemList[] | null>) => {
			return { ...state, myItems: action.payload, isLoading: false };
		},
		setItemAction: (state, action: PayloadAction<IItemList | null>) => {
			if (!action.payload) {
				return state.isRedirecting
					? { ...state, isRedirecting: false }
					: {
							...state,
							current: {
								data: null
							}
					  };
			}
			return {
				...state,
				current: {
					data: {
						...action.payload
					}
				}
			};
		},
		setCountsAction: (state, action: PayloadAction<ItemCounts>) => {
			return {
				...state,
				counts: {
					...state.counts,
					...action.payload
				}
			};
		},
		setIsItemLoading: (state, action: PayloadAction<boolean>) => {
			return { ...state, isLoading: action.payload };
		},
		setIsRedirecting: (state, action: PayloadAction<boolean>) => {
			state.isRedirecting = action.payload;
		}
	}
});

export const {
	reducer: ItemFilterReducer,
	actions: {
		setItemAllFilters,
		setOffsetAction,
		setSearchAction,
		setLimitAction
	}
} = itemFilterSlice;

export const {
	reducer: ItemListReducer,
	actions: {
		setItemListAction,
		setItemAction,
		setIsItemLoading,
		setCountsAction,
		setItemByIdListAction,
		setMyItemsAction,
		setIsRedirecting
	}
} = itemListSlice;

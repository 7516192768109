import React from 'react';

import { useClipboardCopy } from '@core/utils/hooks';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { Button } from './Button';

import styles from './Button.module.scss';

interface CopyButtonProps {
	text: string;
	size?: number;
}

export const CopyButton = ({
	text,
	size = 20,
	handleClicked
}: CopyButtonProps & {
	handleClicked?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	/* Custom hooks */
	const { copyToClipboard } = useClipboardCopy();

	/* Handlers */
	const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		copyToClipboard(text);
		if (handleClicked) handleClicked(true);
	};
	return (
		<Button onClick={handleClick} type='button'>
			<SvgSprite
				width={size}
				height={size}
				className={styles.copyIcon}
				iconId='copy'
			/>
		</Button>
	);
};

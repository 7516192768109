import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	setNotificationOffsetAction,
	setNotificationSearchAction
} from '@core/store/notification/notification.slice';
import {
	getNotifications,
	markNotificationsAsReadThunk
} from '@core/store/notification/notification.thunks';
import { getSearchParams } from '@core/utils/helpers/getSearchParams';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { NotificationsList } from '@pages/Dashboard/Notifications/NotificationsList';
import { NotificationsNav } from '@pages/Dashboard/Notifications/NotificationsNav';
import { SearchForm } from '@components/DashboardComponents';
import { Button, Heading } from '@components/index';

import styles from './Notifications.module.scss';

export const Notifications = () => {
	/* React Router hooks */
	const history = useHistory();

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [notification, maintenance] = useAppSelector(
		({ notification, auth }) => [notification, auth.maintenance]
	);

	/* React hooks */
	const [checkedIds, setCheckedIds] = useState<Record<number, boolean>>({});
	const [currentPage, setCurrentPage] = useState(
		(notification.filters.offset + notification.filters.limit) / 10
	);

	useEffect(() => {
		let params: URLSearchParams;
		if (notification.filters.action_object_content_type__model === 'unread') {
			params = new URLSearchParams(
				getSearchParams({
					...notification.filters,
					action_object_content_type__model: ''
				})
			);
		} else {
			params = new URLSearchParams(getSearchParams(notification.filters));
		}
		history.push(`/notifications?${params}`);
	}, [notification.filters]);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const params = Object.fromEntries(urlParams);
		const promise = dispatch(
			getNotifications({
				...params,
				limit: +params.limit,
				offset: +params.offset
			})
		);

		return () => promise.abort();
	}, [location.search]);

	/* Handlers */
	const handleMarkAsRead =
		(prop = '') =>
		() => {
			const ids: number[] = [];
			for (const [key, value] of Object.entries(checkedIds)) {
				if (value) ids.push(+key);
			}
			if (prop === 'all') {
				dispatch(
					markNotificationsAsReadThunk(ids.length > 0 ? ids : undefined)
				);
			} else if (ids.length > 0) {
				dispatch(markNotificationsAsReadThunk(ids));
			}
			setCheckedIds({});
		};

	const handleSearchSubmit = async (value: string) => {
		dispatch(setNotificationOffsetAction(0));
		setCurrentPage(1);
		dispatch(setNotificationSearchAction(value));
	};

	return (
		<div className='pageWrapper'>
			<div className='pageHead'>
				<Heading className='h5' tag='h2'>
					Notifications
				</Heading>
				<NotificationsNav setCurrentPage={setCurrentPage} />
			</div>
			<SearchForm onSubmit={handleSearchSubmit} />
			<div className={styles.markAsReadBtns}>
				<Button
					variant='contained'
					size='small'
					color='primary'
					onClick={handleMarkAsRead()}
					type='button'
					disabled={
						!notification.count.regular_unread_count ||
						!Object.values(checkedIds).filter(id => id).length ||
						maintenance.isActive
					}
				>
					Mark as read
				</Button>
				<Button
					variant='contained'
					size='small'
					color='primary'
					onClick={handleMarkAsRead('all')}
					type='button'
					disabled={
						!notification.count.regular_unread_count || maintenance.isActive
					}
				>
					Mark all as read
				</Button>
			</div>
			<NotificationsList
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				setIsChecked={setCheckedIds}
				isChecked={checkedIds}
			/>
		</div>
	);
};

export const checkMetamaskConnection = () => {
	let isConnected = false;
	let isInstalled = false;
	if (typeof window.ethereum !== 'undefined') {
		isInstalled = true;
		isConnected = !!(window.ethereum?.isMetaMask || window.ethereum?._metamask);
	} else {
		isConnected = false;
	}

	if (isConnected) {
		console.log(
			'%c Metamask is initialized',
			'color:#69ff82;border:1px solid dodgerblue;padding-right:5px'
		);
	} else {
		console.log(
			'%c Metamask is not initialized',
			'color:red;border:1px solid dodgerblue;padding-right:5px'
		);
	}

	return { isConnected, isInstalled };
};

import { forwardRef, KeyboardEvent, useEffect, useState } from 'react';
import cn from 'classnames';

import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { ForwardedRef, RatingProps } from './Rating.props';

import styles from './Rating.module.scss';

export const Rating = forwardRef(
	(
		{
			isEditable = false,
			rating,
			setRating,
			size = 'xl',
			...props
		}: RatingProps,
		ref: ForwardedRef<HTMLDivElement>
	): JSX.Element => {
		const [ratingArray, setRatingArray] = useState([
			<></>,
			<></>,
			<></>,
			<></>,
			<></>
		]);

		useEffect(() => {
			const constructRating = (currentRating: number) => {
				const changeDisplay = (i: number) => {
					if (!isEditable) return;
					constructRating(i);
				};

				const onClick = (i: number) => {
					if (!isEditable || !setRating) return;
					setRating(i);
				};

				const handleSpace = (i: number, e: KeyboardEvent<HTMLSpanElement>) => {
					if (!setRating) return;

					if (e.key !== undefined) {
						if (e.key === 'Enter' || e.key === ' ') {
							setRating(i);
						}
					}
				};

				const updatedArray = [<></>, <></>, <></>, <></>, <></>].map(
					(r: JSX.Element, i: number) => {
						const percentage = rating - currentRating;
						const isHalf = percentage >= 0.5 && percentage < 0.75,
							isLess = percentage >= 0.25 && percentage < 0.5,
							isGreater = percentage >= 0.75 && percentage < 1;
						return (
							<span
								className={cn(styles.star, {
									[styles.filled]: i < currentRating,
									[styles.editable]: isEditable,
									[styles.lessFilled]: i === currentRating && isLess,
									[styles.halfFilled]: i === currentRating && isHalf,
									[styles.greaterFilled]: i === currentRating && isGreater,
									[styles.sm]: size === 'sm',
									[styles.md]: size === 'md',
									[styles.xl]: size === 'xl'
								})}
								onMouseEnter={() => changeDisplay(i + 1)}
								onMouseLeave={() => changeDisplay(rating)}
								onClick={() => onClick(i + 1)}
								role={isEditable ? 'slider' : ''}
								aria-label={isEditable ? 'Choose rating' : 'Rating: ' + rating}
								// aria-invalid={error ? true : false}
								aria-valuenow={rating}
								aria-valuemax={5}
								aria-valuemin={1}
								onKeyDown={e => isEditable && handleSpace(i + 1, e)}
							>
								<SvgSprite
									iconId='star'
									size={44}
									tabIndex={isEditable ? 0 : -1}
								/>
							</span>
						);
					}
				);
				setRatingArray(updatedArray);
			};

			constructRating(Math.floor(rating));
		}, [isEditable, rating, setRating]);

		const renderRating = () => {
			return ratingArray.map((r, i) => <span key={i}>{r}</span>);
		};
		return (
			<div className={styles.ratingWrapper} ref={ref} {...props}>
				{renderRating()}
			</div>
		);
	}
);

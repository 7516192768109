import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';

import { SvgSprite } from '@components/index';

import styles from './UnreadCountIcon.module.scss';

interface UnreadCountIconProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
	unreadCount: number;
	type?: 'sm' | 'md';
}

export const UnreadCountIcon = ({
	unreadCount,
	type = 'md',
	className,
	...props
}: UnreadCountIconProps) => {
	return (
		<span className={cn(styles.newMessagesCountWrapper, className)} {...props}>
			<SvgSprite iconId='nav-notification' />
			{unreadCount > 0 && (
				<span
					className={cn(styles.newMessagesCount, {
						[styles.newMessagesCountPlus]: unreadCount > 9,
						[styles.sm]: type === 'sm' || [styles.md]
					})}
				>
					{unreadCount > 9 ? '9+' : unreadCount}
				</span>
			)}
		</span>
	);
};

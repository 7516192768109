import React from 'react';
import i18next from 'i18next';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from '@core/store/store';
import common_en from '@assets/translations/en.json';
import PiwikPro from '@piwikpro/react-piwik-pro';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

import './styles/index.scss';

import 'focus-visible';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing()],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
	environment: process.env.NODE_ENV
});

i18next.init({
	interpolation: { escapeValue: false },
	lng: 'en',
	resources: {
		en: {
			common: common_en
		}
		// Add here other languages
		// ru: {
		// 	common: common_ru
		// }
	}
});

PiwikPro.initialize(
	process.env.REACT_APP_PIWIK_ID || '',
	'https://zenland.containers.piwik.pro'
);

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<I18nextProvider i18n={i18next}>
					<App />
				</I18nextProvider>
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { useHistory } from 'react-router-dom';

import { NotificationLevel } from '@core/models/enums';
import { setNotificationOffsetAction } from '@core/store/notification/notification.slice';
import { getSearchParams } from '@core/utils/helpers/getSearchParams';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { NotificationsListItem } from '@pages/Dashboard/Notifications/NotificationListItem';
import { NotificationsListProps } from '@pages/Dashboard/Notifications/Notifications.props';
import { Pagination } from '@components/DashboardComponents';
import { NotificationsListSkeleton } from '@skeletons/notifications/NotificationsListSkeleton';

import styles from '@pages/Dashboard/Notifications/Notifications.module.scss';

export const NotificationsList = ({
	setIsChecked,
	setCurrentPage,
	currentPage,
	isChecked
}: NotificationsListProps) => {
	/* React Router hooks */
	const history = useHistory();

	/* Redux hooks */
	const notification = useAppSelector(({ notification }) => notification);
	const dispatch = useAppDispatch();

	/* Handlers */
	const handleCheckboxChange = (id: number) => () => {
		setIsChecked(prevState => ({
			...prevState,
			[id]: !prevState[id]
		}));
	};

	const handlePageChange = (page: number) => {
		const endOffset = notification.filters.limit * page;
		const startOffset = endOffset - notification.filters.limit;
		dispatch(setNotificationOffsetAction(startOffset));
		const params = new URLSearchParams(
			getSearchParams({ ...notification.filters, offset: startOffset })
		);
		history.push(`/notifications?${params}`);
		setCurrentPage(page);
	};

	const renderNotifications = () => {
		return notification.list.map(item => {
			if (item.level === NotificationLevel.Info) {
				return (
					<NotificationsListItem
						isChecked={isChecked}
						handleCheckboxChange={handleCheckboxChange}
						data={item}
						key={item.id}
					/>
				);
			}
		});
	};
	return (
		<div>
			<div className={styles.list}>
				{notification.isLoaded ? (
					renderNotifications()
				) : (
					<NotificationsListSkeleton />
				)}
			</div>
			<Pagination
				currentPage={currentPage}
				totalCount={notification.count.count}
				pageSize={notification.filters.limit}
				onPageChange={handlePageChange}
				siblingCount={1}
			/>
		</div>
	);
};

import React, { DetailedHTMLProps, HTMLAttributes, useState } from 'react';
import cn from 'classnames';

import { CopyButton } from '@components/index';

import styles from './CopiableLink.module.scss';

interface CopiableLinkProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	text: string;
	asLink?: boolean;
	url?: string | '#';
	size?: 'body' | 'body-sm' | 'body-md';
	copyText?: string;
	iconSize?: number;
}

export const CopiableLink = ({
	text,
	url = '#',
	asLink = true,
	size = 'body',
	copyText = '',
	iconSize,
	className,
	...props
}: CopiableLinkProps) => {
	const [copyAnimation, setCopyAnimation] = useState(false);

	return (
		<div
			className={cn(
				styles.linkWrapper,
				{ [styles.copyAnimation]: copyAnimation },
				size,
				className
			)}
			onAnimationEnd={() => {
				setCopyAnimation(false);
			}}
			{...props}
		>
			{asLink ? (
				<a target='_blank' rel='noopener noreferrer' href={url}>
					{text}
				</a>
			) : (
				<span>{text}</span>
			)}
			<CopyButton
				text={copyText || text}
				handleClicked={setCopyAnimation}
				size={iconSize || 20}
			/>
		</div>
	);
};

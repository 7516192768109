import { createAsyncThunk } from '@reduxjs/toolkit';

import { WalletTransactions } from '@core/services';

import {
	insertedWalletTx,
	receivedPayment,
	receivedWalletTxs,
	setIsWalletLoaded
} from './wallet.slice';
import { WalletFilters } from './wallet.types';

export const getWalletTxsThunk = createAsyncThunk<void, WalletFilters | void>(
	'wallet/getWalletTxs',
	async (filters, { dispatch, signal }) => {
		if (!filters) return;
		dispatch(setIsWalletLoaded(false));
		const wallet = await WalletTransactions.getAll({ ...filters }, { signal });
		if (wallet) dispatch(receivedWalletTxs(wallet));
	},
	{
		dispatchConditionRejection: true
	}
);

export const getWalletTxThunk = createAsyncThunk<void, number>(
	'wallet/getWalletTx',
	async (txId, thunkAPI) => {
		const wallet = await WalletTransactions.get(txId);
		if (wallet) thunkAPI.dispatch(insertedWalletTx(wallet));
	}
);

export const getLastTxFromWalletTxsThunk = createAsyncThunk(
	'wallet/getLastTxFromWalletTxs',
	async (txId, { dispatch }) => {
		const wallet = await WalletTransactions.getAll({ limit: 10 });
		if (wallet) {
			dispatch(insertedWalletTx(wallet.results[0]));
			dispatch(insertedWalletTx(wallet.results[1]));
		}
	}
);

export const postWalletTxThunk = createAsyncThunk<void, string>(
	'wallet/postWalletTx',
	async (val, { dispatch }) => {
		const wallet = await WalletTransactions.post(val);
		if (wallet) {
			const { payment_gateway, ...data } = wallet;
			dispatch(insertedWalletTx(data));
			dispatch(receivedPayment(payment_gateway));
		}
	}
);

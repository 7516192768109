import React from 'react';

import { Checkboxes } from '@components/MainComponents/Checkboxes/Checkboxes';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';

import styles from './../Product.module.scss';

export const CheckboxesNetworks = ({
	availableNetworksList,
	setCheckedNetworks
}: {
	availableNetworksList: TCheckboxList[];
	setCheckedNetworks: React.Dispatch<React.SetStateAction<TCheckboxList[]>>;
}) => {
	return (
		<Checkboxes
			list={availableNetworksList}
			setCheckedList={setCheckedNetworks}
			className={styles.networksCheckboxes}
			borderHidden
			isDisplayAsIcons
			isSingleSelect
			labelHasTitle
		/>
	);
};

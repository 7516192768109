import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { Coins, WalletStatus } from '@core/models/enums';
import { transferZenf } from '@core/services/wallet/web3Calls';
import { getZenfAllowedNetworks } from '@core/utils/helpers/getZenfAllowedNetworks';
import { useAppSelector, useClipboardCopy } from '@core/utils/hooks';
import { useNetworkChange } from '@core/utils/hooks/useNetworkChange';
import {
	CurrentInfoState,
	PaymentInfoProps
} from '@pages/Dashboard/Wallet/Wallet.props';
import { Badge, Button, Input, Select, SvgSprite } from '@components/index';
import { AmountInputSkeleton } from '@skeletons/wallet/AmountInput.skeleton';

import styles from '@pages/Dashboard/Wallet/Wallet.module.scss';

const zenfTokenPricingPlaceholder = { amount: '', currency: Coins.Zenf }; // placeholder
export const PaymentInfo = ({ setCurrentStep }: PaymentInfoProps) => {
	const { t } = useTranslation('common');

	/*** Redux hooks ***/
	const [
		payment,
		currentTx,
		metamaskAddress,
		username,
		networkChainId,
		networks
	] = useAppSelector(({ wallet, auth }) => [
		wallet.payment_gateway,
		wallet.transactions.current,
		auth.metamaskAddress,
		auth.whoAmI.username,
		auth.chain_id,
		auth.networks
	]);

	/*** React hooks ***/
	const [crypto, setCrypto] = useState<Coins>();
	const [currentInfo, setCurrentInfo] = useState<CurrentInfoState>();
	const [isTransferStarted, setIsTransferStarted] = useState(false);
	const [isTransferLoading, setIsTransferLoading] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [network, setNetwork] = useState({
		name: 'Choose the network',
		value: ''
	});

	const allowedNetworks = getZenfAllowedNetworks(
		networks,
		payment?.zenf.pricing
	);

	useEffect(() => {
		if (!currentTx) setCurrentInfo(undefined);
	}, [currentTx]);

	useEffect(() => {
		if (isTransferStarted) {
			if (
				currentTx?.status === WalletStatus.Confirmed ||
				currentTx?.status === WalletStatus.Failed
			) {
				setIsTransferLoading(false);
				setIsTransferStarted(false);
			} else setIsTransferLoading(true);
		}
	}, [currentTx?.status]);

	const zenf = useMemo(() => {
		let _isSupportByNetwork = false;

		if (payment?.zenf) {
			_isSupportByNetwork = Object.keys(payment?.zenf?.pricing).includes(
				networkChainId
			);
			if (_isSupportByNetwork && currentInfo?.pricing.currency === Coins.Zenf)
				selectZenfRadio();
		}

		return {
			checked: currentInfo?.pricing.currency === Coins.Zenf,
			supportedByNetwork: _isSupportByNetwork
		};
	}, [currentInfo?.pricing.currency, networkChainId, payment?.zenf]);

	useEffect(() => {
		if (!zenf.checked) ReactTooltip.rebuild();
	}, [zenf.checked]);

	useEffect(() => {
		if (!networks?.length || !networkChainId || !zenf.checked) return;

		const foundNetwork = allowedNetworks?.find(n => {
			return n.value.toLowerCase() === networkChainId.toLowerCase();
		});

		if (foundNetwork) {
			setNetwork(foundNetwork);
		} else {
			setNetwork({
				name: 'Choose the network',
				value: ''
			});
		}
	}, [networkChainId, zenf.checked]);

	/* Custom hooks */
	const { copyToClipboard } = useClipboardCopy();

	/* Handlers */
	function selectZenfRadio() {
		if (payment?.zenf && currentTx) {
			const zenfAmount =
				(1 / payment?.zenf.pricing[networkChainId]) * +currentTx.value;
			setCurrentInfo({
				address: payment?.zenf.token_addresses[networkChainId],
				pricing: { amount: zenfAmount.toFixed(4), currency: Coins.Zenf }
			});
		}
	}
	const handleRadioChange = (prop: Coins) => () => {
		if (payment && currentTx) {
			setCrypto(prop);
			setCurrentStep(2);
			if (Coins.Zenf === prop) {
				setCurrentInfo({
					address: '',
					pricing: zenfTokenPricingPlaceholder
				});
				selectZenfRadio();
			} else
				setCurrentInfo({
					address: payment.coinbase.addresses[prop],
					pricing: payment.coinbase.pricing[prop]
				});
		}
	};

	const handleTransferZenf = () => {
		if (
			currentTx &&
			zenf.checked &&
			zenf.supportedByNetwork &&
			networks &&
			payment?.zenf &&
			currentInfo
		) {
			setIsTransferStarted(true);
			setIsTransferLoading(true);

			(async () => {
				const res = await transferZenf(
					payment?.zenf.token_addresses[networkChainId],
					payment?.zenf.receiver_addresses[networkChainId],
					+currentInfo?.pricing.amount,
					metamaskAddress || username,
					networkChainId,
					currentTx.id
				);
				if (!res) {
					setIsTransferLoading(false);
				}
			})();
		}
	};

	const handleCopyClick = (text: string) => () => {
		if (payment && currentTx && currentInfo && !zenf.checked) {
			setCurrentStep(3);
			copyToClipboard(text);
		}
	};

	const handleNetworkChange = (option: { name: string; value: string }) => {
		useNetworkChange(networks, option, setIsEditable);
	};

	return (
		<div>
			<div
				className={cn(styles.label, {
					[styles.disabled]: !currentTx
				})}
			>
				<p className={cn(styles.titleWithHint, 'body-md')}>
					Cryptocurrency type
					<SvgSprite
						size={20}
						data-tip={t('app.toolTips.wallet.cryptocurrency_type')}
						data-for='walletTooltip'
					/>
				</p>
				<div className={cn('subtitle-md', styles.radios)}>
					<label
						className={cn({
							[styles.disabled]: !currentTx
						})}
					>
						<Input
							className={styles.radioInput}
							type='radio'
							name='coin'
							checked={crypto === Coins.Zenf}
							onChange={handleRadioChange(Coins.Zenf)}
						/>
						<span className={styles.radioCard}>
							<SvgSprite
								size={64}
								className={styles.zenf}
								iconId='coins-zenf'
							/>
							<span className={styles.radioCardDescription}>
								ZENF
								<Badge
									text='sm'
									size='sm'
									color='primary'
									className={styles.radioCardBadge}
								>
									+ 50%
								</Badge>
							</span>
						</span>
					</label>
					<label className={styles.disabled}>
						<Input
							className={styles.radioInput}
							type='radio'
							name='coin'
							checked={crypto === Coins.Bitcoin}
							onChange={handleRadioChange(Coins.Bitcoin)}
						/>
						<span className={styles.radioCard}>
							<SvgSprite
								size={64}
								className={styles.bitcoin}
								iconId='coins-bitcoin'
							/>
							<span>Bitcoin</span>
						</span>
					</label>
					<label className={styles.disabled}>
						<Input
							className={styles.radioInput}
							type='radio'
							name='coin'
							checked={crypto === Coins.BitcoinCash}
							onChange={handleRadioChange(Coins.BitcoinCash)}
						/>
						<span className={cn('truncate', styles.radioCard)}>
							<SvgSprite
								size={64}
								className={styles.bitcoinCash}
								iconId='coins-bitcoin'
							/>
							<span>Bitcoin Cash</span>
						</span>
					</label>
					<label className={styles.disabled}>
						<Input
							className={styles.radioInput}
							type='radio'
							name='coin'
							checked={crypto === Coins.Dai}
							onChange={handleRadioChange(Coins.Dai)}
						/>
						<span className={styles.radioCard}>
							<SvgSprite size={64} className={styles.dai} iconId='coins-dai' />
							<span>Dai</span>
						</span>
					</label>
					<label className={styles.disabled}>
						<Input
							className={styles.radioInput}
							type='radio'
							name='coin'
							checked={crypto === Coins.Dogecoin}
							onChange={handleRadioChange(Coins.Dogecoin)}
						/>
						<span className={styles.radioCard}>
							<SvgSprite
								size={64}
								className={styles.dogecoin}
								iconId='coins-dogecoin'
							/>
							<span>Dogecoin</span>
						</span>
					</label>
					<label className={styles.disabled}>
						<Input
							className={styles.radioInput}
							type='radio'
							name='coin'
							checked={crypto === Coins.Ethereum}
							onChange={handleRadioChange(Coins.Ethereum)}
						/>
						<span className={styles.radioCard}>
							<SvgSprite
								size={64}
								className={styles.ethereum}
								iconId='coins-ethereum'
							/>
							<span>Ethereum</span>
						</span>
					</label>
					<label className={styles.disabled}>
						<Input
							className={styles.radioInput}
							type='radio'
							name='coin'
							checked={crypto === Coins.Litecoin}
							onChange={handleRadioChange(Coins.Litecoin)}
						/>
						<span className={styles.radioCard}>
							<SvgSprite
								size={64}
								className={styles.litecoin}
								iconId='coins-liteCoin'
							/>
							<span>Litecoin</span>
						</span>
					</label>
					<label className={styles.disabled}>
						<Input
							className={styles.radioInput}
							type='radio'
							name='coin'
							checked={crypto === Coins.Usdc}
							onChange={handleRadioChange(Coins.Usdc)}
						/>
						<span className={styles.radioCard}>
							<SvgSprite
								size={64}
								className={styles.usdcoin}
								iconId='coins-usdCoin'
							/>
							<span>USD Coin</span>
						</span>
					</label>
				</div>
			</div>

			<hr className={styles.divider} />

			<div className={styles.disabledInputs}>
				{zenf.checked || (
					<label
						className={cn(styles.label, { [styles.disabled]: !currentInfo })}
					>
						<p className={cn(styles.titleWithHint, 'body-md')}>
							Transaction address
							<SvgSprite
								size={20}
								data-tip={t('app.toolTips.wallet.transaction_address')}
								data-for='walletTooltip'
								onClick={e => e.preventDefault()}
							/>
						</p>
						<div className={styles.readonlyInputWrapper}>
							<Input
								value={currentInfo ? currentInfo.address : ''}
								readOnly
								largeness='md'
								disabled={!currentInfo}
								className={styles.readonlyInput}
								type='text'
								onClick={handleCopyClick(
									currentInfo ? currentInfo.address : ''
								)}
							/>
							<SvgSprite className={styles.copyIcon} iconId='copy' />
						</div>
					</label>
				)}
				<label
					className={cn(styles.label, { [styles.disabled]: !currentInfo })}
				>
					<p
						className={cn(
							styles.titleWithHint,
							styles.titleWithHintAmount,
							'body-md'
						)}
					>
						Amount
						<SvgSprite
							size={20}
							data-tip={t('app.toolTips.wallet.sent_amount')}
							data-for='walletTooltip'
							onClick={e => e.preventDefault()}
						/>
					</p>
					<div className={styles.readonlyInputWrapper}>
						<Input
							value={
								currentInfo
									? zenf.checked
										? currentInfo.pricing.amount + ' ZENF'
										: currentInfo.pricing.amount
									: ''
							}
							readOnly
							largeness='md'
							disabled={!currentInfo}
							className={cn(styles.readonlyInput, {
								[styles.copyDisabled]: zenf.checked
							})}
							type='text'
							onClick={handleCopyClick(
								currentInfo ? currentInfo.pricing.amount : ''
							)}
						/>
						{zenf.checked && currentTx ? (
							<>
								<Badge color='success' className={styles.inputBadge}>
									+ ${+currentTx.value / 2}
								</Badge>
								{zenf.supportedByNetwork || (
									<AmountInputSkeleton className={styles.amountInputSkeleton} />
								)}
								{allowedNetworks && (
									<Select
										setOption={handleNetworkChange}
										options={allowedNetworks}
										defaultOption={network}
										className={cn(styles.allowedNetworksSelect, {
											[styles.unknown]: !network.value
										})}
										size='lg'
										isEditable={isEditable}
									/>
								)}
							</>
						) : (
							<SvgSprite className={styles.copyIcon} iconId='copy' />
						)}
					</div>
				</label>
				{zenf.checked && (
					<Button
						variant='contained'
						size='large'
						color='primary'
						onClick={handleTransferZenf}
						disabled={!zenf.supportedByNetwork}
						isLoader={isTransferLoading}
					>
						Transfer
					</Button>
				)}
			</div>
		</div>
	);
};

import { API } from '@core/config/api';
import { FetchOptions } from '@core/models/interfaces';
import { IChatRoom } from '@core/store/chat/chat.types';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Chat = {
	async get(id: number, options?: FetchOptions): Promise<IChatRoom | void> {
		try {
			const response = await fetch(`${API.rooms}${id}/`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async getDialogs(options?: FetchOptions) {
		/*** @TODO: in future get by limit and offset  */
		try {
			const response = await fetch(
				`${API.rooms}?contract__isnull=true&messages__isnull=false`,
				{
					headers: {
						'Content-Type': 'application/json',
						'X-CSRFToken': getCookie('csrftoken')
					},
					signal: options?.signal,
					credentials: 'include'
				}
			);

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async post(companion: string) {
		try {
			const response = await fetch(API.rooms, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify({ companion })
			});

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { ContractRole } from '@core/models/enums';
import { WalletStatus, WalletType } from '@core/models/enums';
import { receivedCurrentWalletTx } from '@core/store/wallet/wallet.slice';
import { getWalletTxsThunk } from '@core/store/wallet/wallet.thunks';
import { getSearchParams } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { HistoryList } from '@pages/Dashboard/Wallet/HistoryList';
import { WalletModal } from '@pages/Dashboard/Wallet/WalletModal';
import { WalletNav } from '@pages/Dashboard/Wallet/WalletNav';
import { Heading, SvgSprite, Tooltip } from '@components/index';
import { HistoryListSkeleton } from '@skeletons/wallet/HistoryListSkeleton';

import { AmountForm } from './AmountForm';
import { PaymentInfo } from './PaymentInfo';
import { ProgressBar } from './ProgressBar';

import styles from './Wallet.module.scss';

export const Wallet = () => {
	/* React router hooks */
	const history = useHistory();

	/* Redux hooks */
	const [auth, wallet] = useAppSelector(({ auth, wallet }) => [auth, wallet]);
	const currentTx = wallet.transactions.current;
	const dispatch = useAppDispatch();

	/* React hooks */
	const [currentStep, setCurrentStep] = useState(0);

	useEffect(() => {
		return () => {
			dispatch(receivedCurrentWalletTx(null));
		};
	}, []);

	useEffect(() => {
		const params = new URLSearchParams(getSearchParams(wallet.filters));
		history.push(`/wallet?${params}`);
	}, [wallet.filters]);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const params = Object.fromEntries(urlParams);

		const promise = dispatch(
			getWalletTxsThunk({
				...params,
				limit: +params.limit,
				offset: +params.offset,
				type: params.type as WalletType
			})
		);

		return () => promise.abort();
	}, [location.search]);

	return auth.role === ContractRole.Agent ? (
		<div className={cn('pageWrapper', styles.pageWrapper)}>
			<div className={styles.agentWalletLayout}>
				<article className={styles.layoutEnd}>
					<div className={styles.layoutEndTitleWrapper}>
						<Heading tag='h2' className={cn('h5', styles.title)}>
							My Commissions
							<SvgSprite
								size={20}
								data-tip='Zenland wallet is your internal wallet from where the Zenland fee is paid before a contract is deployed. Once the funds are added or deducted, your in-app balance at the top will reflect the running balance.'
								data-for='walletTooltip'
							/>
							<span className={cn('h6', styles.balance)}>
								$ {auth.whoAmI.balance}
							</span>
						</Heading>
						<WalletNav />
					</div>

					{wallet.isLoaded ? <HistoryList /> : <HistoryListSkeleton />}
				</article>
			</div>
			<Tooltip id='walletTooltip' />
		</div>
	) : (
		<div className={cn('pageWrapper', styles.pageWrapper)}>
			<div className={styles.walletLayout}>
				<article className={styles.layoutStartWrapper}>
					<div
						className={cn(styles.layoutStart, {
							[styles.hidden]:
								currentTx && currentTx.status !== WalletStatus.Created
						})}
					>
						<Heading tag='h2' className={cn('h5', styles.title)}>
							My Wallet
							<SvgSprite
								size={20}
								data-tip='Zenland wallet is your internal wallet from where the Zenland fee is paid before a contract is deployed. Once the funds are added or deducted, your in-app balance at the top will reflect the running balance.'
								data-for='walletTooltip'
							/>
							<span className={cn('h6', styles.balance)}>
								$ {auth.whoAmI.balance}
							</span>
						</Heading>
						<div className={styles.progressWrapper}>
							<ProgressBar currentStep={currentStep} />
							<AmountForm setCurrentStep={setCurrentStep} />
							<hr className={styles.divider} />
							<PaymentInfo setCurrentStep={setCurrentStep} />
						</div>
					</div>
					<WalletModal currentTx={currentTx} />
				</article>
				<article className={styles.layoutEnd}>
					<div className={styles.layoutEndTitleWrapper}>
						<Heading tag='h2' className='h5'>
							History
						</Heading>
						<WalletNav />
					</div>

					{wallet.isLoaded ? <HistoryList /> : <HistoryListSkeleton />}
				</article>
			</div>
			<Tooltip id='walletTooltip' />
		</div>
	);
};

import React from 'react';
import cn from 'classnames';
import ContentLoader from 'react-content-loader';

import styles from './ProductsSkeleton.module.scss';

export const ReputationsSkeleton = () => {
	const renderReputation = () => {
		return (
			<>
				<div className={styles.reputationSkeleton}>
					<ContentLoader
						preserveAspectRatio='none'
						backgroundColor='#e3e9ef'
						foregroundColor='#fff'
						height={315}
						speed={2}
						width='100%'
					>
						<rect x='0' y='0' rx='8' ry='8' width='100%' height='80' />
						<rect x='0' y='88' rx='8' ry='8' width='100%' height='80' />
						<rect x='0' y='176' rx='8' ry='8' width='100%' height='138' />
					</ContentLoader>
				</div>
				<div
					className={cn(
						styles.reputationSkeleton,
						styles.reputationProtectionSkeleton
					)}
				>
					<ContentLoader
						preserveAspectRatio='none'
						backgroundColor='#e3e9ef'
						foregroundColor='#fff'
						height={315}
						speed={2}
						width='100%'
					>
						<rect x='0' y='0' rx='8' ry='8' width='100%' height='155' />
						<rect x='0' y='161' rx='8' ry='8' width='100%' height='155' />
					</ContentLoader>
				</div>
				<div className={styles.reputationSkeleton}>
					<ContentLoader
						preserveAspectRatio='none'
						backgroundColor='#e3e9ef'
						foregroundColor='#fff'
						height={210}
						speed={2}
						width='100%'
					>
						<rect x='0' y='0' rx='8' ry='8' width='100%' height='80' />
						<rect x='0' y='92' rx='8' ry='8' width='32%' height='110' />
						<rect x='34%' y='92' rx='8' ry='8' width='32%' height='110' />
						<rect x='68%' y='92' rx='8' ry='8' width='32%' height='110' />
					</ContentLoader>
				</div>
			</>
		);
	};

	return <div className={styles.reputationsSkeleton}>{renderReputation()}</div>;
};

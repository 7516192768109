export const baseURL = `https://${process.env.REACT_APP_API_DOMAIN_NAME}:${process.env.REACT_APP_API_PORT}`;
export const siteURL = `https://${process.env.REACT_APP_API_DOMAIN_NAME}`;

export const API = {
	login: `${baseURL}/login_api/`,
	signup: `${baseURL}/signup_api/?proceed_login=false`,
	proceedLogin: `${baseURL}/signup_api/?proceed_login=true`,
	logout: `${baseURL}/logout_api/`,
	contracts: `${baseURL}/api/v1/contracts/`,
	agents: `${baseURL}/api/v1/agents/`,
	contractTypes: `${baseURL}/api/v1/contract_types/`,
	products: `${baseURL}/api/v1/products/`,
	referrals: `${baseURL}/api/v1/referrals/`,
	referralTransactions: `${baseURL}/api/v1/referral_transactions/`,
	networks: `${baseURL}/api/v1/networks/`,
	tokens: `${baseURL}/api/v1/tokens/`,
	users: `${baseURL}/api/v1/users/`,
	whoAmI: `${baseURL}/api/v1/users/who_am_i/`,
	rooms: `${baseURL}/api/v1/rooms/`,
	transactions: `${baseURL}/api/v1/transactions/`,
	userRatings: `${baseURL}/api/v1/user_ratings/`,
	walletTransactions: `${baseURL}/api/v1/wallet_transactions/`,
	wallets: `${baseURL}/api/v1/wallets/`,
	zenf: `${baseURL}/api/v1/zen_token_wallet_transactions/`,
	notifications: `${baseURL}/api/v1/notifications/`,
	markAllAsRead: `${baseURL}/api/v1/notifications/mark_all_as_read/`,
	markAsRead: `${baseURL}/api/v1/notifications/mark_as_read/`
};

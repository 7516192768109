import { createAsyncThunk } from '@reduxjs/toolkit';

import { IContractFilter } from '@core/models/interfaces';
import { Contracts } from '@core/services';
import { RootState } from '@core/store/store.types';
import { setTransactionData } from '@core/store/transaction/transaction.slice';

import {
	setContractAction,
	setContractIsSyncing,
	setContractListAction,
	setCountsAction,
	setIsContractLoading
} from './contract.slice';

export const getContractsThunk = createAsyncThunk<
	void,
	Partial<IContractFilter> | void,
	{
		state: RootState;
	}
>(
	'contractList/getContracts',
	async (payload, { dispatch, signal }) => {
		if (!payload) payload = { offset: 0, limit: 10 };

		dispatch(setIsContractLoading(true));

		const foundContracts = await Contracts.getAll(payload, { signal });

		if (foundContracts) {
			const { results, ...counts } = foundContracts;

			dispatch(
				setCountsAction({
					...counts,
					approved_count: counts.approved_count,
					disputed_count: counts.disputed_count + counts.agent_invited_count,
					executed_count: counts.executed_count + counts.dispute_finished_count
				})
			);
			dispatch(setContractListAction(results));
		}
	},
	{
		dispatchConditionRejection: true
	}
);

export const getContractBySyncThunk = createAsyncThunk<void, string>(
	'contractList/syncWithBlockchain',
	async (id, { signal, dispatch }) => {
		dispatch(setContractIsSyncing(true));

		await Contracts.syncWithBlockchain(id, { signal: signal });
	},
	{
		dispatchConditionRejection: true
	}
);

export const getContractThunk = createAsyncThunk<void, number>(
	'contractList/getContract',
	async (id, { dispatch, signal }) => {
		const foundContract = await Contracts.get(id, true, { signal: signal });
		if (foundContract) {
			if (foundContract.transactions[0])
				dispatch(setTransactionData(foundContract.transactions[0]));
			else dispatch(setTransactionData(null));
			dispatch(setContractAction(foundContract));
		}
	},
	{
		dispatchConditionRejection: true
	}
);

import { zenfABI } from '@core/config/zenfABI';
import { WalletTransactions, web3 } from '@core/services';
import { store } from '@core/store/store';
import { insetZenfTxIdToCurrentTransaction } from '@core/store/wallet/wallet.slice';
import { toHex } from '@core/utils/helpers';
import { logErrors } from '@core/utils/logErrors';

export const transferZenf = async (
	zenfContractAddress: string | undefined,
	receiverAddress: string,
	amount: number,
	currentAddress: string,
	networkChainId: string,
	walletTx: number
) => {
	try {
		const zenfInstance = new web3.eth.Contract(zenfABI, zenfContractAddress);
		const zenfTokenDecimals = 18;

		await zenfInstance.methods
			.transfer(receiverAddress, toHex(amount * 10 ** zenfTokenDecimals))
			.send({ from: currentAddress })
			.on('transactionHash', async (txHash: string) => {
				const zenfTransactionPost = await WalletTransactions.zenfPost(
					networkChainId,
					txHash,
					walletTx
				);
				if (zenfTransactionPost)
					store.dispatch(
						insetZenfTxIdToCurrentTransaction(zenfTransactionPost.id)
					);
			})
			.on('error', (error: Error) => {
				logErrors(error);
				return false;
			});
	} catch (e) {
		logErrors(e);
		return false;
	}
};

import React from 'react';
import ContentLoader from 'react-content-loader';

export const ItemSkeleton = () => (
	<ContentLoader
		preserveAspectRatio='none'
		backgroundColor='#e3e9ef'
		foregroundColor='#fff'
		width='100%'
		height={1000}
		speed={2}
	>
		<rect x='0' y='0' rx='8' ry='8' width='280' height='34' />
		<rect x='85%' y='0' rx='8' ry='8' width='15%' height='34' />
		<rect x='0' y='52' rx='24' ry='24' width='49%' height='515' />
		<rect x='51%' y='52' rx='24' ry='24' width='49%' height='515' />
		<rect x='0' y='590' rx='24' ry='24' width='100%' height='100%' />
	</ContentLoader>
);

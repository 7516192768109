import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './../ProductsSkeleton.module.scss';

export const ProductSkeleton = () => (
	<ContentLoader
		preserveAspectRatio='none'
		backgroundColor='#e3e9ef'
		foregroundColor='#fff'
		width='100%'
		height={1000}
		speed={2}
	>
		<rect
			x='0'
			y='0'
			rx='24'
			ry='24'
			width='32%'
			height='32%'
			className={styles.productImage}
		/>
		<rect
			x='34%'
			y='0'
			rx='24'
			ry='24'
			width='40%'
			height='80%'
			className={styles.productDescription}
		/>
		<rect
			x='76%'
			y='0'
			rx='24'
			ry='24'
			width='24%'
			height='515'
			className={styles.productDetails}
		/>
	</ContentLoader>
);

import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';

import { ContractStatusName } from '@core/models/enums';
import { useAppSelector } from '@core/utils/hooks';
import { Button, SvgSprite } from '@components/index';

import { StepContainerProps } from './Steps.props';

import styles from './Steps.module.scss';

export const StepContainer = ({
	title,
	tooltip,
	children,
	stepStatus,
	contractStatus
}: StepContainerProps) => {
	/* Redux hooks */
	const [contract, isCurrentExpired] = useAppSelector(({ contracts }) => [
		contracts.current,
		contracts.current.isCurrentExpired
	]);

	const isCurrentStep = useCallback(() => {
		if (!contract || isCurrentExpired) return false;

		// Exception, current block is open if status is in range [fulfilled, disputed, agent_invited]
		const stepActive = [
			ContractStatusName.Fulfilled,
			ContractStatusName.Disputed,
			ContractStatusName.AgentInvited
		];

		const stepDeployed = [ContractStatusName.Approved];

		return (
			contractStatus === stepStatus ||
			(stepStatus === ContractStatusName.Active &&
				stepActive.includes(contractStatus)) ||
			(stepStatus === ContractStatusName.Approved &&
				stepDeployed.includes(contractStatus))
		);
	}, [stepStatus, contractStatus]);

	const [isActive, setIsActive] = useState(false);
	const [height, setHeight] = useState(isActive ? 'auto' : 0);

	useEffect(() => {
		setHeight(isActive ? 'auto' : 0);
	}, [isActive]);

	useEffect(() => {
		if (!isCurrentExpired) setIsActive(isCurrentStep);
	}, [isCurrentStep, isCurrentExpired]);

	/* Handlers */
	const toggleStep = () => {
		if (isCurrentExpired) return;
		setIsActive(!isActive);
		setHeight(isActive ? 'auto' : 0);
	};

	return (
		<div className={styles.stepItem}>
			<Button className={cn('h5', styles.openStep)} onClick={toggleStep}>
				<p className={styles.stepTitle}>
					{title}
					{tooltip && (
						<SvgSprite
							size={20}
							onClick={e => e.stopPropagation()}
							data-for='contractStepTooltip'
							data-tip={tooltip}
							data-html
							className={styles.tooltipIcon}
						/>
					)}
				</p>
				<SvgSprite
					iconId='arrow-down'
					className={cn(styles.stepIcon, { [styles.rotate]: isActive })}
				/>
			</Button>
			<AnimateHeight
				duration={600}
				height={height}
				className={styles.stepContentWrapper}
			>
				<div className={styles.stepText}>{children}</div>
			</AnimateHeight>
		</div>
	);
};

import React from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';

import { NotificationModel } from '@core/models/enums';
import { INotification } from '@core/models/interfaces';
import { markNotificationsAsReadThunk } from '@core/store/notification/notification.thunks';
import { cropAddress } from '@core/utils/helpers';
import { useAppDispatch } from '@core/utils/hooks';
import { useNotificationData } from '@core/utils/hooks/useNotificationData';
import { Input, SvgSprite } from '@components/index';

import styles from '@pages/Dashboard/Notifications/Notifications.module.scss';

export const NotificationsListItem = ({
	data,
	isChecked,
	handleCheckboxChange
}: {
	data: INotification;
	isChecked: Record<string | number, boolean>;
	handleCheckboxChange: (
		id: number
	) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
	/* Redux hooks */
	const dispatch = useAppDispatch();

	/* Custom hooks */
	const notification = useNotificationData(data);
	/* Handlers */
	const handleClick = () => dispatch(markNotificationsAsReadThunk([data.id]));

	const showIcon = (type: string) => {
		switch (type) {
			case NotificationModel.Marketplace:
			case NotificationModel.Wallet:
			case NotificationModel.Referrals:
				return (
					<SvgSprite
						className={styles.avatar}
						iconId={notification.iconType}
						width={38}
						height={38}
					/>
				);
			default:
				return (
					<img
						className={cn(styles.avatar, styles.roundedFull)}
						src={data.actor.avatar}
						alt='user avatar'
					/>
				);
		}
	};

	return (
		<article
			className={cn(styles.item, 'subtitle-md', {
				[styles.read]: !data.unread,
				[styles.checked]: isChecked[data.id]
			})}
		>
			<div className={styles.checkboxToggleItem}>
				<Input
					checked={isChecked[data.id] || false}
					onChange={handleCheckboxChange(data.id)}
					type='checkbox'
				/>
			</div>
			<NavLink
				onClick={handleClick}
				to={notification.link}
				className={styles.link}
			>
				<div className={cn(styles.linkItem, styles.profileData)}>
					{data.action_object_content_type &&
						showIcon(data.action_object_content_type?.model)}
					<span>
						{notification.name || cropAddress(data.actor.username, 3, 3)}
					</span>
				</div>
				<div className={styles.linkItem}>
					<span>
						{notification.actionTitle}
						{notification.id}
					</span>
				</div>
				<div className={cn(styles.linkItem, 'subtitle')}>{data.verb}</div>
				<div className={cn(styles.linkItem, styles.modifiedData, 'body-sm')}>
					<Moment fromNow>{data.modified}</Moment>
				</div>
			</NavLink>
		</article>
	);
};

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ContractRole } from '@core/models/enums';
import { IContract } from '@core/models/interfaces';
import { useAppSelector } from '@core/utils/hooks';
import { ContractDetails } from '@pages/Dashboard/Contracts/Contract/ContractDetails';
import { AgentView } from '@pages/Dashboard/Contracts/Contract/ContractView/AgentView';
import { ClientView } from '@pages/Dashboard/Contracts/Contract/ContractView/ClientView';
import { ContractChat } from '@components/DashboardComponents';

interface ContractRoutesProps {
	contract: IContract;
}

export const ContractRoutes = ({ contract }: ContractRoutesProps) => {
	const role = useAppSelector(({ auth }) => auth.role);
	return (
		<Switch>
			<Route exact path='/contracts/:id'>
				<ContractDetails contract={contract} />
				{role === ContractRole.Agent ? (
					<AgentView contract={contract} />
				) : (
					<ClientView contract={contract} />
				)}
			</Route>
			<Route exact path='/contracts/chat/:id' component={ContractChat} />
		</Switch>
	);
};

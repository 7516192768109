import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './ProductsSkeleton.module.scss';

interface ProductsSkeletonProps {
	hasHeader: boolean;
}

export const ProductWidgetsSkeleton = () => (
	<ContentLoader
		preserveAspectRatio='none'
		backgroundColor='#e3e9ef'
		foregroundColor='#fff'
		speed={2}
		width='100%'
		height={48}
	>
		<rect x='0' y='0' rx='10' ry='10' width='100%' height='48' />
	</ContentLoader>
);

export const ProductHeaderSkeleton = () => (
	<ContentLoader
		preserveAspectRatio='none'
		backgroundColor='#e3e9ef'
		foregroundColor='#fff'
		height={52}
		width={200}
	>
		<circle cx='40' cy='24' r='24' />
		<rect x='74' y='0' rx='8' ry='8' width='82' height='21' />
		<rect x='74' y='27' rx='8' ry='8' width='105' height='18' />
	</ContentLoader>
);

export const ProductsSkeleton = ({ hasHeader }: ProductsSkeletonProps) => {
	const renderProducts = () => {
		return [1, 2, 3, 4, 5].map((item, index) => (
			<div className={styles.productsGridItem} key={index}>
				{hasHeader && <ProductHeaderSkeleton />}
				<ContentLoader
					preserveAspectRatio='none'
					backgroundColor='#e3e9ef'
					foregroundColor='#fff'
					height={365}
					speed={2}
					width='100%'
				>
					<rect x='0' y='16' rx='12' ry='12' width='100%' height='235' />
					<rect x='0' y='267' rx='8' ry='8' width='50%' height='22' />
					<rect x='0' y='292' rx='8' ry='8' width='35%' height='22' />
					<rect x='0' y='317' rx='8' ry='8' width='100%' height='36' />
				</ContentLoader>
			</div>
		));
	};
	return (
		<>
			<ProductWidgetsSkeleton />
			<div className={styles.productsGrid}>{renderProducts()}</div>
		</>
	);
};

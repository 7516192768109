import React from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';

import { NotificationModel } from '@core/models/enums';
import { markNotificationsAsReadThunk } from '@core/store/notification/notification.thunks';
import { useAppDispatch } from '@core/utils/hooks';
import { useNotificationData } from '@core/utils/hooks/useNotificationData';
import { SvgSprite } from '@components/index';

import { NotificationItemProps } from './NotificationsDropdown.props';

import styles from './NotificationsDropdown.module.scss';

export const NotificationDropdownItem = ({ data }: NotificationItemProps) => {
	const notificationName = data.action_object_content_type?.app_label || '';
	/* Redux hooks */
	const dispatch = useAppDispatch();

	/* Custom hooks */
	const notification = useNotificationData(data);
	/* Handlers */
	const handleClick = () => {
		if (data.id && data.unread) {
			dispatch(markNotificationsAsReadThunk([data.id]));
		}
	};

	const showIcon = (type: string) => {
		switch (type) {
			case NotificationModel.Marketplace:
			case NotificationModel.Wallet:
			case NotificationModel.Referrals:
				return (
					<SvgSprite iconId={notification.iconType} width={38} height={38} />
				);
			default:
				return (
					<img
						className={styles.avatar}
						src={data.actor.avatar}
						alt='user avatar'
					/>
				);
		}
	};

	if (!notificationName) return null;

	return (
		<li
			className={cn(styles.notificationsItem, {
				[styles.unRead]: data.unread
			})}
			key={data.id}
		>
			<NavLink
				to={notification.link}
				onClick={handleClick}
				activeClassName={styles.active}
			>
				<div className={styles.notificationsItemStart}>
					{data.action_object_content_type &&
						showIcon(data.action_object_content_type?.model)}
					<div className={styles.notificationsItemInfo}>
						<span className={cn(styles.notificationsName, 'body-md')}>
							{notification.name.length > 0
								? notification.name
								: notificationName[0].toUpperCase() +
								  notificationName.substring(1)}
						</span>
						<span className={cn(styles.notificationsTime, 'caption')}>
							<Moment fromNow>{data.modified}</Moment>
						</span>
					</div>
				</div>
				<div className={styles.notificationsItemEnd}>
					<p className={cn('body-md', styles.actionTitle)}>
						{notification.actionTitle}
						{notification.id}
					</p>
					<p className='body-sm, truncate'>{data.verb}</p>
				</div>
			</NavLink>
		</li>
	);
};

import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import {
	setOffsetAction,
	setSearchAction
} from '@core/store/contract/contract.slice';
import { getContractsThunk } from '@core/store/contract/contract.thunks';
import { getSearchParams } from '@core/utils/helpers/getSearchParams';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Pagination, SearchForm } from '@components/DashboardComponents';
import { Heading } from '@components/index';

import { ContractsNav } from './ContractsNav';
import { ContractsTable } from './ContractsTable';

import styles from './Contract.module.scss';

export const Contracts = () => {
	/* React Router hooks */
	const history = useHistory();
	const location = useLocation();

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [contracts, contractsFilter] = useAppSelector(
		({ contracts, contractsFilter }) => [contracts, contractsFilter]
	);

	/* React hooks */
	const [currentPage, setCurrentPage] = useState(
		(contractsFilter.offset + contractsFilter.limit) / 10
	);

	useEffect(() => {
		const params = new URLSearchParams(getSearchParams(contractsFilter));
		history.push(`/contracts?${params}`);
	}, [contractsFilter]);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const params = Object.fromEntries(urlParams);
		const promise = dispatch(
			getContractsThunk({
				...params,
				limit: +params.limit,
				offset: +params.offset
			})
		);
		return () => promise.abort();
	}, [location.search]);

	/* Handlers */
	const handlePageChange = () => (page: number) => {
		const endOffset = contractsFilter.limit * page;
		const startOffset = endOffset - contractsFilter.limit;
		dispatch(setOffsetAction(startOffset));
		const params = new URLSearchParams(
			getSearchParams({ ...contractsFilter, offset: startOffset })
		);
		history.push(`/contracts?${params}`);
		dispatch(setOffsetAction(startOffset));
		setCurrentPage(page);
	};

	const handleSearchFormSubmit = (value: string) => {
		setCurrentPage(1);
		dispatch(setOffsetAction(0));
		dispatch(setSearchAction(value));
	};
	const currentTableData = useMemo(() => {
		return contracts.results;
	}, [contracts]);

	return (
		<div className={cn('pageWrapper', styles.pageFlex)}>
			<div className='pageHead'>
				<Heading tag='h1' className={cn(styles.pageTitle, 'h5')}>
					Contracts
				</Heading>
				<ContractsNav setCurrentPage={setCurrentPage} />
			</div>
			<SearchForm
				onSubmit={handleSearchFormSubmit}
				className={styles.searchForm}
				initialValue={contractsFilter.search}
			/>
			<ContractsTable isLoading={contracts.isLoading} list={currentTableData} />
			<div className={styles.pagination}>
				<Pagination
					currentPage={currentPage}
					totalCount={contracts.counts.count}
					pageSize={contractsFilter.limit}
					onPageChange={handlePageChange()}
					siblingCount={1}
				/>
			</div>
		</div>
	);
};

import React from 'react';
import ContentLoader from 'react-content-loader';

export const AmountInputSkeleton = ({ className }: { className: string }) => {
	return (
		<ContentLoader
			backgroundColor='#e3e9ef'
			foregroundColor='white'
			preserveAspectRatio='none'
			speed={2}
			height={64}
			width='100%'
			viewBox='0 0 100 100'
			style={{ borderRadius: '10px' }}
			className={className}
		>
			<rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
		</ContentLoader>
	);
};

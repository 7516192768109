import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WSReadyState } from '@core/models/enums';
import { IMessage } from '@core/models/interfaces';

import { WsChatState } from './../ws.types';

export const initialState: WsChatState & { newMessagesCount: number } = {
	status: WSReadyState.Closed,
	messages: [],
	newMessagesCount: 0,
	me: ''
};

export const wsChatSlice = createSlice({
	name: 'wsChat',
	initialState,
	reducers: {
		chatMessagesReceived: (state, action: PayloadAction<IMessage[] | null>) => {
			if (action.payload) {
				if (state.messages) {
					const existMessagesId = state.messages.map(message => message.id);
					state.messages = [
						...state.messages,
						...action.payload.filter(m => !existMessagesId.includes(m.id))
					].sort((a, b) => a.id - b.id);
				} else state.messages = [...action.payload, ...state.messages];
			} else state.messages = [];
		},
		newMessageReceived: (state, action: PayloadAction<IMessage>) => {
			return {
				...state,
				messages: [...state.messages, action.payload],
				newMessagesCount:
					state.me.toLowerCase() === action.payload.author.toLowerCase()
						? state.newMessagesCount
						: state.newMessagesCount + 1
			};
		},
		chatReadIdsReceived: (
			state,
			action: PayloadAction<{
				ids: number[];
				success: boolean;
			}>
		) => {
			if (!action.payload.success) return state;
			const index = state.messages.findIndex(
				message => message.id === action.payload?.ids[0]
			);
			if (index === -1) return state;

			return {
				...state,
				messages: [
					...state.messages.slice(0, index),
					{
						...state.messages[index],
						unread: false
					},
					...state.messages.slice(index + 1)
				],
				newMessagesCount:
					state.newMessagesCount - 1 < 0 ? 0 : state.newMessagesCount - 1
			};
		},
		chatStatusChanged: (state, action: PayloadAction<WSReadyState>) => {
			return { ...state, status: action.payload };
		},
		chatNewMessagesCountReceived: (state, action: PayloadAction<number>) => {
			return { ...state, newMessagesCount: action.payload };
		},
		setChatAuthor: (state, action: PayloadAction<string>) => {
			return { ...state, me: action.payload };
		}
	}
});

export const {
	reducer: wsChatReducer,
	actions: {
		chatMessagesReceived,
		chatNewMessagesCountReceived,
		chatReadIdsReceived,
		newMessageReceived,
		chatStatusChanged,
		setChatAuthor
	}
} = wsChatSlice;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { IItemFilter } from '@core/models/interfaces/itemInterfaces/itemInterfaces';
import { Products } from '@core/services/products/products';
import {
	setCountsAction,
	setIsItemLoading,
	setItemAction,
	setItemByIdListAction,
	setItemListAction,
	setMyItemsAction
} from '@core/store/item/item.slice';

export const getItemsThunk = createAsyncThunk<
	void,
	{ params: Partial<IItemFilter>; isMyItems?: boolean } | void
>(
	'itemList/getItems',
	async (payload, { dispatch, signal }) => {
		let params: Partial<IItemFilter> = {};

		if (payload && payload.params) {
			if (!payload.params.seller__username) return;
			if (
				payload.params.seller__username &&
				payload.params.seller__username.startsWith('0x')
			) {
				params = {
					...payload.params,
					limit: payload.params.limit,
					offset: payload.params.offset,
					seller__username: payload.params.seller__username
				};
			} else {
				for (const [key, value] of Object.entries(payload.params)) {
					if (key === 'seller__username') continue;
					params[key] = value;
				}

				params = {
					...params,
					limit: payload.params.limit,
					offset: payload.params.offset,
					seller__nickname: payload.params.seller__username
				};
			}
		}

		dispatch(setIsItemLoading(true));
		const foundItems = await Products.getAll(params, { signal });

		if (foundItems) {
			const { results, count } = foundItems;

			dispatch(setCountsAction({ total_count: count }));

			if (payload && payload.isMyItems) {
				dispatch(setMyItemsAction(results));
			} else {
				dispatch(setItemListAction(results));
			}
		}
	},
	{
		dispatchConditionRejection: true
	}
);

export const setItemByIdListThunk = createAsyncThunk<
	void,
	{ slug: string; is_active: boolean }
>('itemList/setItemById', async (payload, { dispatch }) => {
	if (payload) {
		const { slug, is_active } = payload;
		const formData = new FormData();
		formData.append('is_active', JSON.stringify(!is_active));

		const res = await Products.patch({ slug, formData });

		if (res) dispatch(setItemByIdListAction(res));
	}
});

export const setItemThunk = createAsyncThunk<void, { slug: string }>(
	'itemList/setItem',
	async (payload, { dispatch, signal }) => {
		const { slug } = payload;

		const foundItem = await Products.get(slug, { signal });
		if (foundItem) dispatch(setItemAction(foundItem));
	},
	{
		dispatchConditionRejection: true
	}
);

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { NavLink, Redirect } from 'react-router-dom';

import { WSReadyState } from '@core/models/enums';
import {
	setChatRoomActivity,
	setFoundChatRoom
} from '@core/store/chat/chat.slice';
import { IChatRoomMember } from '@core/store/chat/chat.types';
import { setRoom } from '@core/store/room/room.slice';
import { chatMessagesReceived } from '@core/store/ws/chat/wsChat.slice';
import {
	getChatMessages,
	stopChatMessagesListening
} from '@core/store/ws/chat/wsChat.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { AvatarInfo } from '@components/DashboardComponents';
import { Tooltip } from '@components/index';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { AddMessageForm } from './AddMessageForm';
import { ChatProps } from './Chat.props';
import { Messages } from './Messages';

import styles from './Chat.module.scss';

export const Chat = ({
	className,
	chatId,
	isCollapseMode
}: ChatProps & { chatId: number; isCollapseMode?: boolean }) => {
	/* Redux hooks */
	const [chatStatus, messages, room, whoAmI, rooms, foundRoom] = useAppSelector(
		({ wsChat, room, auth, chat }) => [
			wsChat.status,
			wsChat.messages,
			room,
			auth.whoAmI,
			chat.rooms,
			chat.foundRoom
		]
	);
	const dispatch = useAppDispatch();

	/* React hooks */
	const [member, setMember] = useState<IChatRoomMember | null>();
	const [isHasExistRoom, setIsHasExistRoom] = useState(true);

	const [messagesOffset, setMessagesOffset] = useState(0);
	const [messageCounter] = useState(100);
	const [isFetchOldMessages, setIsFetchOldMessages] = useState(false);

	useEffect(() => {
		return () => {
			dispatch(stopChatMessagesListening());
			dispatch(setRoom(null));
			setMember(null);
			setIsHasExistRoom(true);
		};
	}, [chatId]);

	useEffect(() => {
		if (isFetchOldMessages && !(messages.length % 100)) {
			if (chatStatus === WSReadyState.Open) {
				dispatch(getChatMessages({ offset: messagesOffset, cutoff: 100 }));
				setMessagesOffset(prevState => prevState + messageCounter);
			}
			setIsFetchOldMessages(false);
		}
	}, [isFetchOldMessages]);

	useEffect(() => {
		if (messages && messages.length && member) {
			const lastMessages = messages[messages.length - 1];
			const { unread, content, modified, author } = lastMessages;

			dispatch(
				setChatRoomActivity({
					id: chatId,
					activity: {
						last_message: content,
						last_message_is_unread: unread,
						unread_count: 0,
						last_message_timestamp: modified,
						is_message_owner: whoAmI.username === author
					}
				})
			);
		}
	}, [messages]);

	useEffect(() => {
		const findPresentRoom = rooms.data?.findIndex(dialog => {
			return dialog.id === room?.id;
		});

		if (room && findPresentRoom && findPresentRoom < 0) {
			setIsHasExistRoom(false);
			setTimeout(() => dispatch(setRoom(null)), 100);
		}

		if (room?.id) {
			const filteredMembers = room.companions.filter(
				member => member.username !== whoAmI.username
			);
			setMember(filteredMembers[0]);
		}

		return () => {
			dispatch(chatMessagesReceived(null));
			setMessagesOffset(0);
			setIsFetchOldMessages(false);
			if (foundRoom) dispatch(setFoundChatRoom(null));
		};
	}, [room]);

	useEffect(() => {
		if (chatStatus === WSReadyState.Open && chatId === room?.id) {
			dispatch(getChatMessages({ offset: messagesOffset, cutoff: 100 }));
			setMessagesOffset(prevState => prevState + messageCounter);
		}
	}, [chatStatus, room]);

	if (!isHasExistRoom && room) return <Redirect to='/chats' />;
	if (!room) return <p>Select a chat to start messaging or wait</p>;

	return (
		<div className={cn(styles.chat, className)} data-testid='chat'>
			<header className={styles.header}>
				{isCollapseMode && (
					<NavLink to='/chats' className={styles.toChatsLink}>
						<SvgSprite iconId='arrow-left' />
					</NavLink>
				)}
				{member && <AvatarInfo item={member} />}
			</header>
			<Messages fetchMessages={setIsFetchOldMessages} />
			<AddMessageForm />
			<Tooltip id='chatTooltip' place='top' />
		</div>
	);
};

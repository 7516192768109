import React, { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
	NavLink,
	Route,
	Switch,
	useHistory,
	useLocation
} from 'react-router-dom';

import { useAppSelector } from '@core/utils/hooks';
import { ReferralsBalance } from '@pages/Dashboard/Referrals/Partials/ReferralsBalance';
import { ReferralsDetails } from '@pages/Dashboard/Referrals/Partials/ReferralsDetails';
import { Heading } from '@components/MainComponents/Heading/Heading';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';
import { Tooltip } from '@components/MainComponents/Tooltip/Tooltip';

import { ReferralsTable } from './Partials/ReferralsTable';

import styles from './Referrals.module.scss';

export const Referrals = () => {
	/** React-i18next hooks **/
	const { t } = useTranslation('common');

	/** React-Router hooks **/
	const location = useLocation();
	const history = useHistory();

	/** Redux hooks **/
	const [balance, code, list, count, isLoading, username, nickname, avatar] =
		useAppSelector(({ auth, referralsList }) => [
			auth.whoAmI.referral_balance,
			auth.whoAmI.referral_code,
			referralsList.list,
			referralsList.count,
			referralsList.isLoading,
			auth.whoAmI.username,
			auth.whoAmI.nickname,
			auth.whoAmI.avatar
		]);

	/** React hooks **/
	useEffect(() => {
		const { search, pathname } = location;
		if (!pathname.includes('list') && !pathname.includes('balance'))
			history.replace(`/referrals/list${search}`);
	}, [location]);

	return (
		<div className={cn('pageWrapper', styles.pageWrapper)}>
			<div className='pageHead'>
				<Heading tag='h1' className={cn(styles.pageTitle, 'h5')}>
					Referral Program{' '}
					<SvgSprite
						iconId={'status-info-outlined-circle'}
						width={20}
						height={20}
						data-html
						data-for='referralTooltip'
						data-tip={t('app.toolTips.newItem.name')}
					/>
				</Heading>
			</div>
			<ReferralsDetails
				referralNumbers={count.list}
				referralCode={code}
				isLoading={isLoading}
				referralBalance={balance}
				nickname={nickname}
				username={username}
				userAvatarSrc={avatar}
			/>
			<nav className={styles.referralsNav}>
				<ul className='subNavBtns'>
					<li>
						<NavLink
							to='/referrals/list?limit=10'
							className='subNavBtn'
							activeClassName='active'
						>
							Referrals
						</NavLink>
					</li>
					<li>
						<NavLink
							to='/referrals/balance?limit=10'
							className='subNavBtn'
							activeClassName='active'
						>
							Balance
						</NavLink>
					</li>
				</ul>
			</nav>
			<Switch>
				<Route
					path='/referrals/list'
					render={() => <ReferralsTable list={list} isLoading={isLoading} />}
				/>
				<Route path='/referrals/balance' component={ReferralsBalance} />
			</Switch>
			<Tooltip id='referralTooltip' />
		</div>
	);
};

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
	ContractRole,
	ContractStatusName,
	ContractStatusNumber
} from '@core/models/enums';
import { Contracts } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setContractAction } from '@core/store/contract/contract.slice';
import { useAppDispatch } from '@core/utils/hooks';
import { Badge, Button } from '@components/index';

import { StepContainer } from './StepContainer';
import { StepProps } from './Steps.props';

import styles from './Steps.module.scss';

export const Approval = ({ contract, auth }: StepProps) => {
	const { t } = useTranslation('common');
	const { role } = auth;
	/* Redux hooks */
	const dispatch = useAppDispatch();

	/* React hooks */
	const [isDisabled, setIsDisabled] = useState(true);
	const [roles, setRoles] = useState({ isBuyer: false, isSeller: true });
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setRoles({
			isBuyer: role === ContractRole.Buyer,
			isSeller: role === ContractRole.Seller
		});

		setIsDisabled(
			ContractStatusNumber[contract.status] !== ContractStatusNumber.draft
		);

		if (role === ContractRole.Buyer && contract.buyer_approved) {
			setIsDisabled(true);
		} else if (role === ContractRole.Seller && contract.seller_approved) {
			setIsDisabled(true);
		}
	}, [role, contract]);

	/* Handlers */
	const approveContract = (id: number) => async () => {
		if (ContractStatusNumber[contract.status] !== ContractStatusNumber.draft) {
			return dispatch(
				addAlert({
					text: `Contract is ${contract.status}!`,
					type: 'error',
					isSubmit: true
				})
			);
		}
		setIsLoading(true);
		if (roles.isBuyer) {
			const buyerApprovedContract = await Contracts.patch({
				id,
				body: { buyer_approved: true }
			});

			if (buyerApprovedContract && buyerApprovedContract.buyer_approved) {
				dispatch(
					setContractAction({
						...contract,
						...buyerApprovedContract,
						status: buyerApprovedContract.status
					})
				);
			}
		} else {
			const sellerApprovedContract = await Contracts.patch({
				id,
				body: { seller_approved: true }
			});

			if (sellerApprovedContract) {
				dispatch(
					setContractAction({
						...contract,
						...sellerApprovedContract,
						status: sellerApprovedContract.status
					})
				);
			}
		}
		setIsLoading(false);
	};

	return (
		<StepContainer
			stepStatus={ContractStatusName.Draft}
			contractStatus={contract.status}
			title='Approval'
			tooltip={t('app.toolTips.contracts.approval')}
		>
			<div className={styles.stepContent}>
				{ContractStatusNumber[contract.status] ===
				ContractStatusNumber.draft ? (
					<Badge text='sm' color='warn' hasIcon>
						{isDisabled
							? `Waiting for the ${
									contract.buyer_approved ? 'Seller' : 'Buyer'
							  } to approve the contract.`
							: 'Review the contract details, and approve if agree.'}
					</Badge>
				) : (
					<Badge text='sm' color='success' hasIcon>
						Both parties have approved the contract.{' '}
						{role === ContractRole.Seller &&
							ContractStatusNumber[contract.status] ===
								ContractStatusNumber.approved &&
							'Wait until the Buyer deploys the contract.'}
					</Badge>
				)}

				<div className={styles.approvalInfo}>
					<div className={styles.approvalInfoItem}>
						<span className={cn('subtitle-md', styles.approvalInfoTitle)}>
							Buyer:
						</span>
						<Badge
							text='sm'
							color={contract.buyer_approved ? 'success' : 'danger'}
						>
							{contract.buyer_approved ? 'Approved' : 'Not approved'}
						</Badge>
					</div>
					<div className={styles.approvalInfoItem}>
						<span className={cn('subtitle-md', styles.approvalInfoTitle)}>
							Seller:
						</span>
						<Badge
							text='sm'
							color={contract.seller_approved ? 'success' : 'danger'}
						>
							{contract.seller_approved ? 'Approved' : 'Not approved'}
						</Badge>
					</div>
				</div>

				{!isDisabled && (
					<Button
						onClick={approveContract(contract.id)}
						disabled={contract.isNetworkNotMatched || isDisabled}
						isLoader={isLoading}
						variant='contained'
						size='medium'
						color='primary'
						className={styles.stepBtn}
					>
						Approve
					</Button>
				)}
			</div>
		</StepContainer>
	);
};

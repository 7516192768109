import { web3 } from '@core/services';
import { logErrors } from '@core/utils/logErrors';

export const getWeb3Account = async () => {
	try {
		const [address]: string[] = await window.ethereum.request({
			method: 'eth_requestAccounts'
		});
		return web3.utils.toChecksumAddress(address);
	} catch (e) {
		logErrors(e);
	}
};

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import { useHistory, useLocation } from 'react-router-dom';

import {
	setOffsetAction,
	setReferralAllFilters,
	setReferralListAction
} from '@core/store/referral/referral.slice';
import { setReferralsThunk } from '@core/store/referral/referral.thunk';
import { getSearchParams } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { ReferralTableType } from '@pages/Dashboard/Referrals/Referral.props';
import { Pagination } from '@components/DashboardComponents';
import { Button, SvgSprite } from '@components/index';
import { TableSkeleton } from '@skeletons/contracts/TableSkeleton';

import styles from '../Referrals.module.scss';

export const ReferralsTable = ({ list, isLoading }: ReferralTableType) => {
	/** React Router hooks **/
	const history = useHistory();
	const location = useLocation();

	/** Redux hooks **/
	const dispatch = useAppDispatch();
	const [referralsFilter, count] = useAppSelector(
		({ referralsFilter, referralsList }) => [
			referralsFilter,
			referralsList.count
		]
	);

	/** React hooks **/
	const [currentPage, setCurrentPage] = useState(
		(referralsFilter.offset + referralsFilter.limit) / 10
	);

	useEffect(() => {
		return () => {
			dispatch(setReferralListAction(null));
		};
	}, []);

	useEffect(() => {
		const params = new URLSearchParams(getSearchParams(referralsFilter));
		history.push(`/referrals/list?${params}`);
	}, [referralsFilter]);

	useEffect(() => {
		const params = Object.fromEntries(new URLSearchParams(location.search));
		const promise = dispatch(setReferralsThunk(params));

		return () => promise.abort();
	}, [location.search]);

	if (isLoading || !list) return <TableSkeleton />;
	if (list.length === 0) return <p>Sorry not found.</p>;

	/** Handlers **/
	const handlePageChange = () => (page: number) => {
		const endOffset = referralsFilter.limit * page;
		const startOffset = endOffset - referralsFilter.limit;
		dispatch(setOffsetAction(startOffset));
		const params = new URLSearchParams(
			getSearchParams({ ...referralsFilter, offset: startOffset })
		);
		history.push(`/referrals/list?${params}`);
		dispatch(setOffsetAction(startOffset));
		setCurrentPage(page);
	};

	const handleClick = (ordering: string) => () => {
		if (referralsFilter.ordering === ordering) {
			dispatch(
				setReferralAllFilters({
					...referralsFilter,
					offset: 0,
					ordering: `-${ordering}`
				})
			);
		} else {
			dispatch(
				setReferralAllFilters({
					...referralsFilter,
					offset: 0,
					ordering: ordering
				})
			);

			const params = new URLSearchParams(
				getSearchParams({ ...referralsFilter, offset: 0, ordering })
			);
			history.push(`/referrals/list?${params}`);
		}
	};

	const referralsTableNavs = [
		{ name: 'id', title: 'ID' },
		{ name: 'created', title: 'Date' },
		{ name: 'total_amount_received', title: 'Received amount' },
		{ name: 'invited__username', title: 'Address' }
	];

	const renderRows = () => {
		return list.map(l => {
			return (
				<div
					key={l.id}
					className={cn('tableRowItem', styles.referralRowItem, 'subtitle')}
				>
					<div>
						<span className={styles.referralRowTitle}>ID</span>
						<span>#{l.id}</span>
					</div>
					<div>
						<span className={cn(styles.referralRowTitle)}>Date</span>
						<span className={cn(styles.referralRowDescriptionDate, 'body-md')}>
							<Moment format='DD/MM/YYYY'>{l.created}</Moment>
							<Moment
								format='HH:mm:ss'
								className={cn(styles.referralRowDescriptionTime, 'caption')}
							>
								{l.created}
							</Moment>
						</span>
					</div>
					<div>
						<span className={styles.referralRowTitle}>Received amount</span>
						<span
							className={cn('subtitle', {
								[styles.referralRowDescriptionAmount]:
									l.total_amount_received !== '0.00'
							})}
						>
							$ {l.total_amount_received}
						</span>
					</div>
					<div>
						<span className={styles.referralRowTitle}>Address</span>
						<span className='subtitle'>{l.invited}</span>
					</div>
				</div>
			);
		});
	};

	return (
		<>
			<div className={styles.tableGrid}>
				<div className={styles.tableHead}>
					{referralsTableNavs.map(r => {
						return (
							<Button
								key={r.name}
								onClick={handleClick(r.name)}
								className={cn('tableNavBtn', 'body-md', {
									['active']: referralsFilter.ordering.includes(r.name),
									['arrowReversed']: referralsFilter.ordering === `-${r.name}`,
									[styles.tableNavBtnDate]: r.name === 'created'
								})}
								type='button'
							>
								{r.name === 'id' && (
									<span className={styles.moreDetail}># </span>
								)}
								{r.title}
								<SvgSprite iconId='arrow-down' />
							</Button>
						);
					})}
				</div>
				{renderRows()}
			</div>
			<div className={styles.pagination}>
				<Pagination
					currentPage={currentPage}
					totalCount={count.list}
					pageSize={referralsFilter.limit}
					onPageChange={handlePageChange()}
					siblingCount={1}
				/>
			</div>
		</>
	);
};

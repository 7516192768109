import { useEffect, useState } from 'react';

import { INotification } from '@core/models/interfaces';
import { getNotificationInfo } from '@core/utils/helpers';

export const useNotificationData = (data: INotification) => {
	const [notificationData, setNotificationData] = useState<{
		link: string;
		actionTitle: string;
		id: string | number;
	}>({
		link: '#',
		actionTitle: '#',
		id: 0
	});

	const [iconType, setIconType] = useState<string>('user');
	const [name, setName] = useState('');

	useEffect(() => {
		const info = getNotificationInfo(data);
		setNotificationData({
			link: info.url,
			actionTitle: `${info.title} #`,
			id: info.id || 0
		});

		if (info.icon) setIconType(info.icon);
		if (info.name) setName(info.name);
	}, [data.action_object_content_type?.model]);
	return { ...notificationData, iconType, name };
};

import React from 'react';

import { useAppSelector } from '@core/utils/hooks';
import { DetailsProps } from '@pages/Dashboard/Contracts/Contract.props';
import { Steps } from '@pages/Dashboard/Contracts/Contract/Steps/Steps';

export const ClientView = ({ contract }: DetailsProps) => {
	const isNetworkNotMatched = useAppSelector(
		({ contracts }) => contracts.current.isNetworkNotMatched
	);
	return (
		<div>
			<Steps contract={{ ...contract, isNetworkNotMatched }} />
		</div>
	);
};

import { IUser, IWhoAmIResponse } from '@core/models/interfaces';

export enum ProfileTabTypes {
	Products = 'products',
	NFT = 'nft',
	Reviews = 'reviews',
	Ratings = 'ratings'
}

export interface ProfileTabProps {
	handleNavBtnClick: (prop: ProfileTabTypes) => void;
}

export interface ReviewsProps extends ProfileTabProps {
	user: IUser | IWhoAmIResponse;
}

export interface ProductsProps extends ProfileTabProps {
	address: string;
}

export interface ReputationProps extends ProfileTabProps {
	address: string;
	isCurrentUserAgent?: boolean | null;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	ReputationDataTypes,
	ReputationInitialState
} from '@core/store/reputation/reputation.types';

const initialState: ReputationInitialState = {
	data: null,
	isLoading: true
};

export const reputationSlice = createSlice({
	name: 'reputation',
	initialState,
	reducers: {
		addReputationData: (
			state,
			action: PayloadAction<ReputationDataTypes | null>
		) => {
			state.data = action.payload;
			state.isLoading = false;
		},
		addLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		}
	}
});

export const {
	reducer: ReputationReducer,
	actions: { addReputationData, addLoading }
} = reputationSlice;

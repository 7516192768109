import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { TextareaProps } from './Textarea.props';

import styles from './Textarea.module.scss';

export const Textarea = ({
	autoHeight = false,
	height = 'auto',
	className,
	onChange,
	required,
	...props
}: TextareaProps) => {
	/* React hooks */
	const ref = useRef<HTMLTextAreaElement>(null);
	const [value, setValue] = useState('');

	useEffect(() => {
		if (autoHeight && ref.current) {
			if (ref.current.scrollHeight > ref.current.clientHeight) {
				ref.current.style.height = `${ref.current.scrollHeight}px`;
			}
		} else if (ref.current) {
			ref.current.style.height = height;
		}
	}, [value, autoHeight, height]);

	/* Handlers */
	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
		onChange?.(e);
	};

	return (
		<textarea
			ref={ref}
			onChange={handleChange}
			value={value}
			required={required}
			className={cn(styles.textarea, className)}
			{...props}
		/>
	);
};

import React from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import { NavLink } from 'react-router-dom';

import { INetwork } from '@core/models/interfaces';
import { Author, Heading } from '@components/index';
import { RatingWithCounter } from '@components/MainComponents/RatingWithCounter/RatingWithCounter';
import { ImageSkeleton } from '@skeletons/product/Image.skeleton';

import { ProductCardProps } from '../ProductCard.props';

import styles from '../ProductCard.module.scss';

export const ProductCard = ({
	product,
	address,
	hasHeader = false,
	className,
	...props
}: ProductCardProps) => {
	/* Custom hooks */
	const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });

	return (
		<article className={styles.cardWrapper} {...props} ref={ref}>
			<NavLink
				className={cn(styles.card, className)}
				to={`/profile/${address}/items/${product.slug}`}
			>
				{hasHeader && (
					<Author
						isLink={false}
						avatar={product.avatar}
						nickname={product.nickname}
						username={product.username}
					/>
				)}
				<div className={styles.imgWrapper}>
					{inView ? (
						<img src={product.image} alt='product image' />
					) : (
						<ImageSkeleton />
					)}
				</div>
				<div className={styles.info}>
					<Heading tag='h3' className={cn(styles.title, 'h6', 'truncate')}>
						{product.title}
					</Heading>
					<RatingWithCounter
						count={product.rating.count}
						total={+product.rating.average}
					/>
					<dl className={styles.priceWrapper}>
						<dd className={cn(styles.price, 'subtitle-md')}>
							${product.price}
							<div className={styles.productNetworks}>
								{product.available_networks.map((n: INetwork) => {
									return (
										<img
											key={n.id}
											src={n.image}
											alt={n.display_name}
											className={styles.networkIcon}
										/>
									);
								})}
							</div>
						</dd>
					</dl>
				</div>
			</NavLink>
		</article>
	);
};

import React from 'react';
import cn from 'classnames';

import { SwitcherProps } from './SwitcherProps';

import styles from './Switcher.module.scss';

export const Switcher = ({ checked, disabled, className }: SwitcherProps) => {
	return (
		<div
			className={cn('switcher', styles.track, className, {
				[styles.active]: checked,
				[styles.disabled]: disabled
			})}
		>
			<div className={styles.lever} />
		</div>
	);
};

import { configureStore } from '@reduxjs/toolkit';

import { chatReducer } from '@core/store/chat/chat.slice';
import { ReferralBalanceFilterReducer } from '@core/store/referral/referral.balance.slice';
import {
	ReferralFilterReducer,
	ReferralListReducer
} from '@core/store/referral/referral.slice';
import { ReputationReducer } from '@core/store/reputation/reputation.slice';
import { transactionReducer } from '@core/store/transaction/transaction.slice';
import { userReducer } from '@core/store/user/user.slice';
import * as Sentry from '@sentry/react';

import { alertReducer } from './alert/alert.slice';
import { appReducer } from './app/app.slice';
import { authReducer } from './auth/auth.slice';
import {
	contractFilterReducer,
	contractListReducer
} from './contract/contract.slice';
import { ItemFilterReducer, ItemListReducer } from './item/item.slice';
import { notificationReducer } from './notification/notification.slice';
import { roomReducer } from './room/room.slice';
import { themeReducer } from './theme/theme.slice';
import { walletReducer } from './wallet/wallet.slice';
import { wsChatReducer } from './ws/chat/wsChat.slice';
import { wsNotificationReducer } from './ws/notification/wsNotification.slice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	// Optionally pass options listed below
});

export const store = configureStore({
	reducer: {
		auth: authReducer || (() => ({})),
		alert: alertReducer,
		app: appReducer,
		contracts: contractListReducer,
		contractsFilter: contractFilterReducer,
		chat: chatReducer,
		items: ItemListReducer,
		itemsFilter: ItemFilterReducer,
		referralsList: ReferralListReducer,
		referralsFilter: ReferralFilterReducer,
		referralsBalanceFilter: ReferralBalanceFilterReducer,
		notification: notificationReducer,
		room: roomReducer,
		theme: themeReducer,
		reputation: ReputationReducer,
		wsChat: wsChatReducer,
		wsNotification: wsNotificationReducer,
		wallet: walletReducer,
		transaction: transactionReducer,
		user: userReducer
	},
	enhancers: [sentryReduxEnhancer]
});

import React, { useEffect, useState } from 'react';

import { NotificationModel } from '@core/models/enums';
import {
	setIsNotificationsLoaded,
	setNotificationModelAction,
	setNotificationOffsetAction,
	setNotificationUnreadAction
} from '@core/store/notification/notification.slice';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { FilterNav } from '@components/DashboardComponents';
import { Select } from '@components/MainComponents/Select/Select';

import styles from '@pages/Dashboard/Notifications/Notifications.module.scss';

interface NotificationsNavProps {
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const NotificationsNav = ({ setCurrentPage }: NotificationsNavProps) => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const notification = useAppSelector(({ notification }) => notification);

	/* Handlers*/
	const handleClick = (value: NotificationModel | '' | 'unread') => () => {
		dispatch(setNotificationOffsetAction(0));
		dispatch(setIsNotificationsLoaded(false));
		setCurrentPage(1);
		if (value === 'unread') {
			dispatch(setNotificationUnreadAction(true));
		} else {
			dispatch(setNotificationUnreadAction(false));
		}
		dispatch(setNotificationModelAction(value));
	};

	const renderFilterCount = (type?: NotificationModel | 'unread') => {
		switch (type) {
			case NotificationModel.Contracts:
				return notification.count.contract_count;
			case NotificationModel.Chat:
				return notification.count.chat_count;
			case NotificationModel.Wallet:
				return notification.count.wallet_transaction_count;
			case NotificationModel.UserRating:
				return notification.count.user_rating_count;
			case 'unread':
				return notification.count.regular_unread_count;
			default:
				return notification.count.regular_count;
		}
	};
	const navItems: {
		name: string;
		value: NotificationModel | '' | 'unread';
		count: number;
	}[] = [
		{
			name: 'All',
			value: '',
			count: renderFilterCount()
		},
		{
			name: 'Contracts',
			value: NotificationModel.Contracts,
			count: renderFilterCount(NotificationModel.Contracts)
		},
		{
			name: 'Wallet',
			value: NotificationModel.Wallet,
			count: renderFilterCount(NotificationModel.Wallet)
		},
		{
			name: 'Chats',
			value: NotificationModel.Chat,
			count: renderFilterCount(NotificationModel.Chat)
		},
		{
			name: 'Reviews',
			value: NotificationModel.UserRating,
			count: renderFilterCount(NotificationModel.UserRating)
		},
		{
			name: 'Unread',
			value: 'unread',
			count: renderFilterCount('unread')
		}
	];

	const filterListDropdown: {
		name: string;
		value: NotificationModel | '' | 'unread';
	}[] = navItems.map(item => {
		return {
			name: `${item.name} <data>${item.count}</data>`,
			value: item.value
		};
	});

	/* React hooks */
	const [filterOption, setFilterOption] = useState(filterListDropdown[0]);
	useEffect(() => {
		setFilterOption(filterListDropdown[0]);
	}, [notification.count.total_count]);

	/* Handler */
	const handleChangeFilterOptions = (option: {
		name: string;
		value: NotificationModel | '' | 'unread';
	}) => {
		handleClick(option.value)();
		setFilterOption({ ...option, value: option.value });
	};
	return (
		<>
			<div className={styles.wideFilterNav}>
				<FilterNav<
					{
						name: string;
						value: NotificationModel | '' | 'unread';
						count: number;
					},
					NotificationModel | '' | 'unread'
				>
					filterList={navItems}
					handleChange={handleClick}
					count={notification.count.count}
					offset={notification.filters.offset}
					status={notification.filters.action_object_content_type__model}
				/>
			</div>
			<Select<NotificationModel | '' | 'unread'>
				className={styles.narrowFilterDropdown}
				defaultOption={filterOption}
				options={filterListDropdown}
				setOption={handleChangeFilterOptions}
				listStartSide='end'
			/>
		</>
	);
};

import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

import { setReferralBalanceAllFilters } from '@core/store/referral/referral.balance.slice';
import { setOffsetAction } from '@core/store/referral/referral.balance.slice';
import {
	setReferralsThunk,
	setReferralsTransactionsThunk
} from '@core/store/referral/referral.thunk';
import { ReferralListTransactionStatusTypes } from '@core/store/referral/referral.types';
import { getSearchParams } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Pagination } from '@components/DashboardComponents';
import { Badge } from '@components/MainComponents/Badge/Badge';
import { BadgeProps } from '@components/MainComponents/Badge/Badge.props';
import { Button } from '@components/MainComponents/Button/Button';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';
import { TableSkeleton } from '@skeletons/contracts/TableSkeleton';

import styles from '@pages/Dashboard/Referrals/Referrals.module.scss';

export const ReferralsBalance = () => {
	/** React Router hooks **/
	const history = useHistory();

	/** Redux hooks **/
	const dispatch = useAppDispatch();
	const [
		referralsBalanceFilter,
		transactions,
		isLoading,
		transactionsCount,
		listCount
	] = useAppSelector(({ referralsBalanceFilter, referralsList }) => [
		referralsBalanceFilter,
		referralsList.transactions,
		referralsList.isLoading,
		referralsList.count.transactions,
		referralsList.count.list
	]);

	/** React hooks **/
	const [currentPage, setCurrentPage] = useState(
		(referralsBalanceFilter.offset + referralsBalanceFilter.limit) / 10
	);

	useEffect(() => {
		if (!listCount) {
			const promise = dispatch(setReferralsThunk(null));
			return () => {
				promise.abort();
			};
		}
	}, []);

	useEffect(() => {
		const params = new URLSearchParams(getSearchParams(referralsBalanceFilter));
		history.push(`/referrals/balance?${params}`);
	}, [referralsBalanceFilter]);

	useEffect(() => {
		const params = Object.fromEntries(new URLSearchParams(location.search));
		const promise = dispatch(setReferralsTransactionsThunk(params));

		return () => {
			promise.abort();
		};
	}, [location.search]);

	if (isLoading || !transactions) return <TableSkeleton />;
	if (transactions.length === 0) return <p>Sorry not found.</p>;

	/** Handlers **/
	const handlePageChange = () => (page: number) => {
		const endOffset = referralsBalanceFilter.limit * page;
		const startOffset = endOffset - referralsBalanceFilter.limit;
		dispatch(setOffsetAction(startOffset));
		const params = new URLSearchParams(
			getSearchParams({ ...referralsBalanceFilter, offset: startOffset })
		);
		history.push(`/referrals/balance?${params}`);
		dispatch(setOffsetAction(startOffset));
		setCurrentPage(page);
	};
	const handleClick = (ordering: string) => () => {
		if (referralsBalanceFilter.ordering === ordering) {
			dispatch(
				setReferralBalanceAllFilters({
					...referralsBalanceFilter,
					offset: 0,
					ordering: `-${ordering}`
				})
			);
		} else {
			dispatch(
				setReferralBalanceAllFilters({
					...referralsBalanceFilter,
					offset: 0,
					ordering: ordering
				})
			);

			const params = new URLSearchParams(
				getSearchParams({ ...referralsBalanceFilter, offset: 0, ordering })
			);
			history.push(`/referrals/balance?${params}`);
		}
	};

	const referralsBalanceNavs = [
		{ name: 'id', title: 'ID' },
		{ name: 'created', title: 'Date' },
		{ name: 'value', title: 'Amount' },
		{ name: 'type', title: 'Type' },
		{ name: 'status', title: 'Status' }
	];

	const showStatus = (
		status: ReferralListTransactionStatusTypes
	): { className: string; name: BadgeProps['color'] } => {
		switch (status) {
			case 'confirmed':
				return { name: 'success', className: 'referralRowDescriptionAmount' };
			case 'pending':
				return {
					name: 'await',
					className: 'referralRowDescriptionAmountPending'
				};
			case 'created':
				return {
					name: 'primary',
					className: 'referralRowDescriptionAmountCreated'
				};
			case 'failed':
				return {
					name: 'danger',
					className: 'referralRowDescriptionAmountFailed'
				};
		}
	};

	const renderRows = () => {
		return transactions.map(t => {
			return (
				<div
					key={t.id}
					className={cn(
						'tableRowItem',
						styles.referralTransactionRowItem,
						'subtitle'
					)}
				>
					<div>
						<span className={styles.referralRowTitle}>ID</span>
						<span>#{t.id}</span>
					</div>
					<div>
						<span className={cn(styles.referralRowTitle)}>Date</span>
						<span className={cn(styles.referralRowDescriptionDate, 'body-md')}>
							<Moment format='DD/MM/YYYY'>{t.created}</Moment>
							<Moment
								format='HH:mm:ss'
								className={cn(styles.referralRowDescriptionTime, 'caption')}
							>
								{t.created}
							</Moment>
						</span>
					</div>
					<div>
						<span className={styles.referralRowTitle}>Address</span>
						<span
							className={cn('subtitle', styles[showStatus(t.status).className])}
						>
							$ {t.type === 'debit' ? '+' : '-'}
							{t.value}
						</span>
					</div>
					<div>
						<span className={styles.referralRowTitle}>Type</span>
						<span className='subtitle'>{t.type}</span>
					</div>
					<div>
						<span
							className={cn(
								styles.referralRowTitle,
								styles.referralRowTitleStatus
							)}
						>
							Status
						</span>
						<Badge
							text='md'
							className={cn('subtitle', styles.referralRowDescriptionStatus)}
							color={showStatus(t.status).name}
						>
							{t.status}
						</Badge>
					</div>
				</div>
			);
		});
	};

	return (
		<div className={styles.tableGrid}>
			<div className={cn(styles.tableHead, styles.tableHeadBalance)}>
				{referralsBalanceNavs.map(r => {
					return (
						<Button
							key={r.name}
							onClick={handleClick(r.name)}
							className={cn('tableNavBtn', 'body-md', {
								['active']: referralsBalanceFilter.ordering.includes(r.name),
								['arrowReversed']:
									referralsBalanceFilter.ordering === `-${r.name}`,
								[styles.tableNavBtnDate]: r.name === 'created'
							})}
							type='button'
						>
							{r.name === 'id' && <span className={styles.moreDetail}># </span>}
							{r.title}
							<SvgSprite iconId='arrow-down' />
						</Button>
					);
				})}
			</div>
			{renderRows()}
			<div className={styles.pagination}>
				<Pagination
					currentPage={currentPage}
					totalCount={transactionsCount}
					pageSize={referralsBalanceFilter.limit}
					onPageChange={handlePageChange()}
					siblingCount={1}
				/>
			</div>
		</div>
	);
};

export enum ContractStatusNumber {
	draft,
	approved,
	deployed,
	active,
	fulfilled,
	disputed,
	agent_invited,
	executed,
	dispute_finished
}

export enum ContractStatusName {
	Draft = 'draft',
	Approved = 'approved',
	Deployed = 'deployed',
	Active = 'active',
	Fulfilled = 'fulfilled',
	Disputed = 'disputed',
	AgentInvited = 'agent_invited',
	Executed = 'executed',
	DisputeFinished = 'dispute_finished'
}

export enum CommissionPayer {
	Buyer = 'buyer',
	Seller = 'seller',
	Fifty = 'fifty_fifty'
}

export enum ContractType {
	Escrow = 'Escrow'
}

export enum RatingRole {
	Buyer = 'buyer',
	Seller = 'seller',
	Agent = 'agent'
}

export enum ContractRole {
	Buyer = 'buyer',
	Seller = 'seller',
	// Custom agents (from another company)
	Agent = 'agents',
	// Zenland agents (agents selected by owner)
	Zen_Agent = 'zen_agents'
}

import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { useAppSelector } from '@core/utils/hooks';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { ButtonProps } from './Button.props';

import styles from './Button.module.scss';

export const Button = ({
	children,
	className,
	size = '',
	variant = '',
	color = '',
	arrow = '',
	isLoader = false,
	isConfirming = false,
	loaderPosition = 'prepend',
	loaderColor = '#fff',
	...props
}: ButtonProps) => {
	/* Redux hooks */
	const maintenance = useAppSelector(({ auth }) => auth.maintenance);

	if (props.as === 'link') {
		return (
			<Link
				className={cn(
					styles.btn,
					styles[size],
					styles[color],
					styles[arrow],
					className,
					{
						[styles[variant]]: variant !== 'subNavBtn',
						subNavBtn: variant === 'subNavBtn',
						[styles.loader]: isLoader || isConfirming,
						[styles[size]]: size !== ''
					}
				)}
				{...props}
			>
				{arrow && (
					<SvgSprite className={styles.arrowIcon} iconId='arrow-down' />
				)}
				{!isConfirming && isLoader && loaderPosition === 'prepend' && (
					<div className={styles.loaderIconWrapper}>
						<SvgSprite
							className={cn(styles.loaderIcon, 'rotateAnimation')}
							iconId='loader-wallet'
							color={loaderColor}
							size={48}
						/>
					</div>
				)}
				{isConfirming && (
					<div className={styles.confirmingText}>
						Confirming{''}
						<span className={styles.points}>
							<span className={styles.point}>.</span>
							<span className={styles.point}>.</span>
							<span className={styles.point}>.</span>
						</span>
					</div>
				)}
				{children}
				{!isConfirming && isLoader && loaderPosition === 'append' && (
					<div className={cn(styles.loaderIconWrapper, styles.loaderPrepend)}>
						<SvgSprite
							className={cn(styles.loaderIcon, 'rotateAnimation')}
							iconId='loader-wallet'
							color={loaderColor}
							size={48}
						/>
					</div>
				)}
			</Link>
		);
	} else if (props.as === 'externalLink') {
		return (
			<a
				target='_blank'
				rel='noopener noreferrer'
				className={cn(
					styles.btn,
					styles[size],
					styles[color],
					styles[arrow],
					className,
					{
						[styles[variant]]: variant !== 'subNavBtn',
						subNavBtn: variant === 'subNavBtn',
						[styles.loader]: isLoader || isConfirming,
						[styles[size]]: size !== ''
					}
				)}
				{...props}
			>
				{arrow && (
					<SvgSprite className={styles.arrowIcon} iconId='arrow-down' />
				)}
				{!isConfirming && isLoader && loaderPosition === 'prepend' && (
					<div className={styles.loaderIconWrapper}>
						<SvgSprite
							className={cn(styles.loaderIcon, 'rotateAnimation')}
							iconId='loader-wallet'
							color={loaderColor}
							size={48}
						/>
					</div>
				)}
				{isConfirming && (
					<div className={styles.confirmingText}>
						Confirming{''}
						<span className={styles.points}>
							<span className={styles.point}>.</span>
							<span className={styles.point}>.</span>
							<span className={styles.point}>.</span>
						</span>
					</div>
				)}
				{children}
				{!isConfirming && isLoader && loaderPosition === 'append' && (
					<div className={cn(styles.loaderIconWrapper, styles.loaderPrepend)}>
						<SvgSprite
							className={cn(styles.loaderIcon, 'rotateAnimation')}
							iconId='loader-wallet'
							color={loaderColor}
							size={48}
						/>
					</div>
				)}
			</a>
		);
	} else {
		return (
			<button
				{...props}
				className={cn(
					styles.btn,
					styles[size],
					styles[color],
					styles[arrow],
					className,
					{
						[styles[variant]]: variant !== 'subNavBtn',
						subNavBtn: variant === 'subNavBtn',
						[styles.loader]: isLoader || isConfirming,
						[styles[size]]: size !== ''
					}
				)}
				{...props}
				/* @TODO need to remove maintenance active mode below and left only in necessary places */
				disabled={maintenance.isActive || props.disabled}
			>
				{arrow && (
					<SvgSprite className={styles.arrowIcon} iconId='arrow-down' />
				)}
				{!isConfirming && isLoader && loaderPosition === 'prepend' && (
					<div className={styles.loaderIconWrapper}>
						<SvgSprite
							className={cn(styles.loaderIcon, 'rotateAnimation')}
							iconId='loader-wallet'
							color={loaderColor}
							size={48}
						/>
					</div>
				)}
				{isConfirming && (
					<div className={styles.confirmingText}>
						Confirming{''}
						<span className={styles.points}>
							<span className={styles.point}>.</span>
							<span className={styles.point}>.</span>
							<span className={styles.point}>.</span>
						</span>
					</div>
				)}
				{children}
				{!isConfirming && isLoader && loaderPosition === 'append' && (
					<div className={cn(styles.loaderIconWrapper, styles.loaderPrepend)}>
						<SvgSprite
							className={cn(styles.loaderIcon, 'rotateAnimation')}
							iconId='loader-wallet'
							color={loaderColor}
							size={48}
						/>
					</div>
				)}
			</button>
		);
	}
};

import React from 'react';
import cn from 'classnames';

import { InputLabelProps } from '@components/DashboardComponents/Forms/Form/Form.props';
import { SvgSprite } from '@components/index';

import styles from '@components/DashboardComponents/Forms/Forms.module.scss';

export const InputLabel = ({
	labelId = '',
	name,
	additionalText,
	toolTipDataFor,
	toolTipData,
	children,
	className
}: InputLabelProps) => {
	return (
		<div className={cn(styles.labelWrapper, className)}>
			<label htmlFor={labelId} className='body-md'>
				{name}
				{additionalText && (
					<span className={styles.additionalText}>{additionalText}</span>
				)}
			</label>
			<SvgSprite data-html data-for={toolTipDataFor} data-tip={toolTipData} />
			{children}
		</div>
	);
};

import ContentLoader from 'react-content-loader';

export const ReferralCountList = () => (
	<ContentLoader
		preserveAspectRatio='none'
		backgroundColor='#e3e9ef'
		foregroundColor='#fff'
		height={52}
		width={200}
	>
		<rect x='0' y='0' rx='2' ry='2' width='50' height='20' />
	</ContentLoader>
);

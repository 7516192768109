import { API } from '@core/config/api';
import {
	FetchOptions,
	IWalletTransaction,
	IWalletTransactionsGetParams,
	IWalletTransactionsGetResponse,
	IWalletTransactionsPostResponse,
	IZenfTransactionsPostResponse
} from '@core/models/interfaces';
import { getSearchParams } from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const WalletTransactions = {
	async getAll(
		queryParams?: Partial<IWalletTransactionsGetParams>,
		options?: FetchOptions
	): Promise<IWalletTransactionsGetResponse | void> {
		let params: URLSearchParams | string = '';
		if (queryParams) params = new URLSearchParams(getSearchParams(queryParams));

		try {
			const response = await fetch(`${API.walletTransactions}?${params}`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async get(id: number): Promise<IWalletTransaction | void> {
		try {
			const response = await fetch(`${API.walletTransactions}${id}/`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async post(value: string): Promise<IWalletTransactionsPostResponse | void> {
		try {
			const response = await fetch(API.walletTransactions, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify({ value })
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async zenfPost(
		network: string,
		tx_hash: string,
		wallet_tx: number
	): Promise<IZenfTransactionsPostResponse | void> {
		try {
			const response = await fetch(API.zenf, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include',
				method: 'POST',
				body: JSON.stringify({ network, tx_hash, wallet_tx })
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

import { tokenABI } from '@core/config/tokenABI';
import { TransactionType } from '@core/models/enums';
import { Contracts, Transactions, web3 } from '@core/services';
import { setCurrentContractAgent } from '@core/store/contract/contract.slice';
import { store } from '@core/store/store';
import { setTransactionData } from '@core/store/transaction/transaction.slice';
import { ITransactions } from '@core/store/transaction/transaction.types';
import { getWeb3Account, toHex } from '@core/utils/helpers';
import { getEstimateGas } from '@core/utils/helpers/web3/getEstimatedGas';
import { getGasPrice } from '@core/utils/helpers/web3/getGasPrice';
import { logErrors } from '@core/utils/logErrors';

import {
	ConfirmContract,
	DeployContract,
	DisputeContract,
	InviteAgentContract,
	ReleaseContract,
	TransferContract
} from './models';

const gasOptions = (
	gas: number,
	gasPrice: string | undefined,
	step: string
) => {
	console.log(`%c ${step} gas estimation ` + gas + ' units', 'color:green');

	if (gasPrice) {
		console.log(
			`%c ${step} gas price ${gasPrice} wei`,
			'color:brown;border:1px solid dodgerblue;padding-right:5px'
		);
		const gasEstimation = gas * +gasPrice;
		console.log(
			`%c ${step} gas cost estimation ${gasEstimation} wei`,
			'color:purple;border:1px solid dodgerblue;padding-right:5px'
		);
		return { gas, gasPrice };
	} else {
		return { gas };
	}
};

export const deployContract: DeployContract = async contract => {
	try {
		const contractInterface = await Contracts.getInterface(contract.id);
		if (!contractInterface) return;

		store.dispatch(setCurrentContractAgent(contractInterface.agent));

		const myContract = new web3.eth.Contract(contractInterface.abi);
		let _res: void | ITransactions | undefined | Error;

		const gas = await getEstimateGas(
			contractInterface.abi,
			contractInterface.bytecode,
			contract,
			contractInterface.agent
		);

		const gasPrice = await getGasPrice(contract.network.id);

		await myContract
			.deploy({
				data: contractInterface.bytecode,
				arguments: [
					web3.utils.toChecksumAddress(contract.buyer.username),
					web3.utils.toChecksumAddress(contract.seller.username),
					web3.utils.toChecksumAddress(contractInterface.agent),
					contract.token.contract_address,
					toHex(+contract.price * 10 ** contract.token.decimals),
					contract.buyer_protection_time
				]
			})
			.send({
				from: web3.utils.toChecksumAddress(contract.buyer.username),
				...gasOptions(gas, gasPrice?.toString(), 'Deploy')
			})
			.on('transactionHash', async txHash => {
				_res = await Transactions.post({
					contractId: contract.id,
					type: TransactionType.deployed,
					txHash
				});
				if (_res) store.dispatch(setTransactionData(_res));
			})
			.on('error', (error: Error) => {
				logErrors(error);
			});

		if (_res) return _res;
	} catch (e) {
		console.log(e);
	}
};

export const transferContract: TransferContract = async contract => {
	try {
		const currentAddress = await getWeb3Account();

		if (!contract || !contract.token || !currentAddress) {
			return logErrors('One of the required fields is missing');
		}

		const tokenInstance = new web3.eth.Contract(
			tokenABI,
			contract.token.contract_address
		);

		let _res: void | ITransactions | undefined;

		const gas = await tokenInstance.methods
			.transfer(
				contract.address,
				toHex(+contract.price * 10 ** contract.token.decimals)
			)
			.estimateGas(
				{ from: web3.utils.toChecksumAddress(contract.buyer.username) },
				(err: Error, gas: number) => {
					return gas;
				}
			);

		const gasPrice = await getGasPrice(contract.network.id);

		await tokenInstance.methods
			.transfer(
				contract.address,
				toHex(+contract.price * 10 ** contract.token.decimals)
			)
			.send({
				from: currentAddress,
				...gasOptions(gas, gasPrice?.toString(), 'Transfer')
			})
			.on('transactionHash', async (txHash: string) => {
				_res = await Transactions.post({
					txHash,
					contractId: contract.id,
					type: TransactionType.transferred
				});
				if (_res) store.dispatch(setTransactionData(_res));
			})
			.on('error', (error: Error) => {
				logErrors(error);
			});
		if (_res) return _res;
	} catch (e) {
		logErrors(e);
	}
};

export const confirmFulfillment: ConfirmContract = async (
	contract,
	message
) => {
	try {
		let _res: ITransactions | void | undefined;

		if (!contract || !contract.address || !contract.interface) {
			return logErrors('One of the required fields is missing');
		}

		const confirmingContract = new web3.eth.Contract(
			contract.interface.abi,
			contract.address
		);

		const gas = await confirmingContract.methods
			.confirmFulfillment()
			.estimateGas(
				{ from: web3.utils.toChecksumAddress(contract.seller.username) },
				(err: Error, gas: number) => {
					return gas;
				}
			);

		const gasPrice = await getGasPrice(contract.network.id);

		await confirmingContract.methods
			.confirmFulfillment()
			.send({
				from: web3.utils.toChecksumAddress(contract.seller.username),
				...gasOptions(gas, gasPrice?.toString(), 'Confirm')
			})
			.on('transactionHash', async (txHash: string) => {
				_res = await Transactions.post({
					message,
					contractId: contract.id,
					type: TransactionType.fulfilled,
					txHash
				});
				if (_res) store.dispatch(setTransactionData(_res));
			})
			.on('error', (error: Error) => {
				logErrors(error);
			});

		if (_res) return _res;
	} catch (e) {
		logErrors(e);
	}
};

export const releaseContract: ReleaseContract = async contract => {
	try {
		const currentAddress = await getWeb3Account();
		let _res: ITransactions | void | undefined;

		if (!contract || !contract.address || !currentAddress) {
			return logErrors('One of the required fields is missing');
		}

		const releasingContract = new web3.eth.Contract(
			contract.interface.abi,
			contract.address
		);

		const gas = await releasingContract.methods
			.release()
			.estimateGas(
				{ from: web3.utils.toChecksumAddress(currentAddress) },
				(err: Error, gas: number) => {
					return gas;
				}
			);

		const gasPrice = await getGasPrice(contract.network.id);

		await releasingContract.methods
			.release()
			.send({
				from: currentAddress,
				...gasOptions(gas, gasPrice?.toString(), 'Release')
			})
			.on('transactionHash', async (txHash: string) => {
				_res = await Transactions.post({
					contractId: contract.id,
					type: TransactionType.released,
					txHash
				});
				if (_res) store.dispatch(setTransactionData(_res));
			})
			.on('error', (error: Error) => {
				logErrors(error);
			});
		if (_res) return _res;
	} catch (e) {
		logErrors(e);
	}
};

export const disputeContract: DisputeContract = async contract => {
	try {
		const currentAddress = await getWeb3Account();
		let _res: ITransactions | void | undefined;

		if (!contract || !contract.address || !currentAddress) {
			return logErrors('One of the required fields is missing');
		}

		const disputingContract = new web3.eth.Contract(
			contract.interface.abi,
			contract.address
		);

		const gas = await disputingContract.methods
			.openDispute()
			.estimateGas(
				{ from: web3.utils.toChecksumAddress(currentAddress) },
				(err: Error, gas: number) => {
					return gas;
				}
			);

		const gasPrice = await getGasPrice(contract.network.id);

		await disputingContract.methods
			.openDispute()
			.send({
				from: currentAddress,
				...gasOptions(gas, gasPrice?.toString(), 'Dispute')
			})
			.on('transactionHash', async (txHash: string) => {
				_res = await Transactions.post({
					contractId: contract.id,
					type: TransactionType.disputed,
					txHash
				});
				if (_res) store.dispatch(setTransactionData(_res));
			})
			.on('error', (error: Error) => {
				logErrors(error);
			});

		if (_res) return _res;
	} catch (e) {
		logErrors(e);
	}
};

export const inviteAgent: InviteAgentContract = async contract => {
	try {
		const currentAddress = await getWeb3Account();
		let _res: ITransactions | void | undefined;

		if (!contract || !contract.address || !currentAddress) {
			return logErrors('One of the required fields is missing');
		}

		const invitingContract = new web3.eth.Contract(
			contract.interface.abi,
			web3.utils.toChecksumAddress(contract.address)
		);

		const gas = await invitingContract.methods
			.inviteAgent()
			.estimateGas({ from: currentAddress }, (err: Error, gas: number) => {
				return gas;
			});

		const gasPrice = await getGasPrice(contract.network.id);
		await invitingContract.methods
			.inviteAgent()
			.send({
				from: currentAddress,
				...gasOptions(gas, gasPrice?.toString(), 'InviteAgent')
			})
			.on('transactionHash', async (txHash: string) => {
				_res = await Transactions.post({
					contractId: contract.id,
					type: TransactionType.agent_invited,
					txHash
				});
				if (_res) store.dispatch(setTransactionData(_res));
			})
			.on('error', (error: Error) => {
				logErrors(error);
			});

		if (_res) return _res;
	} catch (e) {
		logErrors(e);
	}
};

export const sendMoney = async (
	contractId: number,
	agentVal: number,
	buyerVal: number,
	sellerVal: number
) => {
	try {
		const contract = await Contracts.get(contractId, true);
		const currentAddress = await getWeb3Account();
		let _res: ITransactions | void | undefined;

		if (!contract || !contract.address || !currentAddress) {
			return logErrors('One of the required fields is missing');
		}

		const sendingContract = new web3.eth.Contract(
			contract.interface.abi,
			web3.utils.toChecksumAddress(contract.address)
		);

		const gas = await sendingContract.methods
			.sendMoney(agentVal, buyerVal, sellerVal)
			.estimateGas(
				{ from: web3.utils.toChecksumAddress(currentAddress) },
				(err: Error, gas: number) => {
					return gas;
				}
			);

		const gasPrice = await getGasPrice(contract.network.id);

		await sendingContract.methods
			.sendMoney(agentVal, buyerVal, sellerVal)
			.send({
				from: currentAddress,
				...gasOptions(gas, gasPrice?.toString(), 'SendMoney')
			})
			.on('transactionHash', async (txHash: string) => {
				_res = await Transactions.post({
					contractId,
					type: TransactionType.dispute_finished,
					txHash
				});
				if (_res) store.dispatch(setTransactionData(_res));
			})
			.on('error', (error: Error) => {
				logErrors(error);
			});

		if (_res) return _res;
	} catch (e) {
		logErrors(e);
	}
};

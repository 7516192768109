import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
	ContractRole,
	ContractStatusName,
	ContractStatusNumber,
	TransactionType
} from '@core/models/enums';
import { getTxByType } from '@core/utils/helpers';
import { ProtectionTime } from '@pages/Dashboard/Contracts/Contract/ProtectionTime';
import { Complete } from '@pages/Dashboard/Contracts/Contract/Steps/Fulfillment/Complete';
import { Dispute } from '@pages/Dashboard/Contracts/Contract/Steps/Fulfillment/Dispute';
import { InviteAgent } from '@pages/Dashboard/Contracts/Contract/Steps/Fulfillment/InviteAgent';
import { Release } from '@pages/Dashboard/Contracts/Contract/Steps/Fulfillment/Release';
import { CopiableLink } from '@components/DashboardComponents';
import { Badge, Countdown, SvgSprite } from '@components/index';

import { StepContainer } from '../StepContainer';
import { StepProps } from '../Steps.props';

import styles from '../Steps.module.scss';

export const Fulfillment = ({ contract, auth }: StepProps) => {
	const { t } = useTranslation('common');

	const { role } = auth;
	const isBuyer = role === ContractRole.Buyer;

	let isReleaseDisabled =
		ContractStatusNumber[contract.status] < ContractStatusNumber.fulfilled ||
		ContractStatusNumber[contract.status] > ContractStatusNumber.disputed;

	const isUnConfirmedReleaseActive =
		ContractStatusNumber[contract.status] === ContractStatusNumber.active;

	const isDisputeDisabled =
		!isBuyer ||
		isReleaseDisabled ||
		(moment(contract.deadline).isAfter(moment()) &&
			ContractStatusNumber[contract.status] !== ContractStatusNumber.fulfilled);

	const isAgentInviteDisabled =
		ContractStatusNumber[contract.status] !== ContractStatusNumber.disputed;

	const txByType =
		getTxByType(contract.transactions, TransactionType.released) ||
		getTxByType(contract.transactions, TransactionType.dispute_finished);

	/* React hooks */
	const [canSellerRelease, setCanSellerRelease] = useState(false);
	const [isProtectionExpired, setIsProtectionExpired] = useState(false);
	const [isDisableWhileConfirming, setIsDisableWhileConfirming] =
		useState<boolean>(false);

	useEffect(() => {
		if (isProtectionExpired) {
			isReleaseDisabled = false;
			setCanSellerRelease(true);
		}

		if (
			ContractStatusNumber[contract.status] >= ContractStatusNumber.disputed
		) {
			setCanSellerRelease(false);
		}
	}, [isProtectionExpired, contract.status]);

	return (
		<StepContainer
			stepStatus={ContractStatusName.Active}
			contractStatus={contract.status}
			title='Fulfillment'
			tooltip={t('app.toolTips.contracts.fulfillment')}
		>
			<div className={styles.stepContent}>
				{ContractStatusNumber[contract.status] <
					ContractStatusNumber.active && (
					<Badge text='sm' color='warn' hasIcon>
						Contract is not active yet.
					</Badge>
				)}

				{ContractStatusNumber[contract.status] ===
					ContractStatusNumber.active && (
					<>
						{isBuyer && (
							<Badge color='success' hasIcon>
								Contract is active. Wait for the Seller to fulfill the
								conditions.
							</Badge>
						)}

						{!isBuyer && (
							<>
								{moment(contract.deadline).isAfter(moment()) ? (
									<>
										<Badge text='sm' color='success' hasIcon>
											Contract is active. You may proceed with the order now.
										</Badge>
										<Badge text='sm' color='warn' hasIcon>
											<span>
												You have{' '}
												<Countdown
													date={contract.deadline}
													format='d [days] h [hrs] m [mins] s [sec]'
												/>{' '}
												to fulfill the order. Once the due date passes, a
												dispute will be available to the Buyer.
											</span>
										</Badge>
									</>
								) : (
									<Badge color='warn' hasIcon>
										Due date has passed. Dispute is now available for the Buyer.
									</Badge>
								)}
								<Complete contract={contract} />
							</>
						)}
					</>
				)}

				{ContractStatusNumber[contract.status] ===
					ContractStatusNumber.fulfilled &&
					(isBuyer ? (
						<>
							<Badge color='success' hasIcon>
								Seller has fulfilled the conditions. Check the delivery, and
								release the funds if satisfied.
							</Badge>
							{!isProtectionExpired && (
								<div className={styles.fulfilledSellerTimer}>
									<Badge color='warn' hasIcon>
										Seller will be able to release the funds after{' '}
										<ProtectionTime
											setIsProtectionExpired={setIsProtectionExpired}
											buyer_protection_time={contract.buyer_protection_time}
											transactions={contract.transactions}
										/>
									</Badge>
									<SvgSprite
										size={20}
										className={styles.mobileHidden}
										data-for='contractStepTooltip'
										data-tip={t('app.toolTips.contracts.dataTip.fulfillment')}
										data-html
									/>
								</div>
							)}
						</>
					) : (
						<>
							<Badge color='success' hasIcon>
								{isProtectionExpired
									? 'Buyer protection time has passed. Now, you can release the agreed amount.'
									: 'You have completed your part of the contract. Wait for the Buyer to release the agreed amount.'}
							</Badge>
							{!isProtectionExpired && (
								<Badge color='warn' hasIcon>
									<ProtectionTime
										setIsProtectionExpired={setIsProtectionExpired}
										buyer_protection_time={contract.buyer_protection_time}
										transactions={contract.transactions}
									/>{' '}
									left before you are able to release the agreed amount
									yourself.
								</Badge>
							)}
						</>
					))}

				{ContractStatusNumber[contract.status] ===
					ContractStatusNumber.disputed &&
					(isBuyer ? (
						<Badge color='warn' hasIcon>
							Contract is disputed. Settle the issue with the Seller or invite
							an Agent.
						</Badge>
					) : (
						<>
							<Badge color='danger' hasIcon>
								Buyer has opened a dispute.
							</Badge>
							<Badge color='warn' hasIcon>
								Settle the issue with the Buyer or invite an Agent.
							</Badge>
						</>
					))}

				{isBuyer
					? ContractStatusNumber[contract.status] <
							ContractStatusNumber.agent_invited && (
							<Release
								contract={contract}
								isReleaseDisabled={
									isReleaseDisabled || isDisableWhileConfirming
								}
								isUnConfirmedReleaseActive={isUnConfirmedReleaseActive}
								setIsDisableWhileConfirming={setIsDisableWhileConfirming}
							/>
					  )
					: ContractStatusNumber[contract.status] ===
							ContractStatusNumber.fulfilled && (
							<Release
								contract={contract}
								isReleaseDisabled={
									!canSellerRelease || isDisableWhileConfirming
								}
								setIsDisableWhileConfirming={setIsDisableWhileConfirming}
							/>
					  )}

				{ContractStatusNumber[contract.status] <=
					ContractStatusNumber.fulfilled &&
					isBuyer && (
						<Dispute
							isDisputeDisabled={isDisputeDisabled || isDisableWhileConfirming}
							contract={contract}
							setIsDisableWhileConfirming={setIsDisableWhileConfirming}
						/>
					)}

				{ContractStatusNumber[contract.status] ===
					ContractStatusNumber.disputed && (
					<InviteAgent
						contract={contract}
						isAgentInviteDisabled={
							isAgentInviteDisabled || isDisableWhileConfirming
						}
						setIsDisableWhileConfirming={setIsDisableWhileConfirming}
					/>
				)}

				{ContractStatusNumber[contract.status] ===
					ContractStatusNumber.agent_invited && (
					<>
						<Badge text='sm' color='success' hasIcon>
							The Agent is working on resolving the dispute.
						</Badge>
						<Badge text='sm' color='warn' hasIcon>
							Provide case details and proof in the Chat. Messaging on time
							helps the Agent make the right decision without delay.
						</Badge>
					</>
				)}

				{ContractStatusNumber[contract.status] >
					ContractStatusNumber.agent_invited && (
					<>
						<Badge text='sm' color='success' hasIcon>
							The contract has been fulfilled, and funds have been released.
						</Badge>
						{txByType && (
							<div className={cn(styles.copyTextWrapper, 'subtitle')}>
								<span className={styles.bold}>Transaction ID: </span>
								<CopiableLink
									text={txByType?.tx_hash || ''}
									url={`${contract.network.explorer_url}/tx/${
										txByType?.tx_hash || ''
									}`}
								/>
							</div>
						)}
					</>
				)}
			</div>
		</StepContainer>
	);
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { Users } from '@core/services';

import { setNotFound, setUser } from './user.slice';

export const getUserIdThunk = createAsyncThunk<void, string>(
	'user/getUserById',
	async (id, { signal, dispatch }) => {
		try {
			dispatch(setNotFound(false));
			const userData = await Users.get(id, { signal });

			if (userData) {
				dispatch(setUser(userData));
			}
		} catch (e) {
			dispatch(setNotFound(true));
		}
	}
);

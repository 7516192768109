import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	useParams
} from 'react-router-dom';

import { UserGroupName } from '@core/models/enums';
import { Users } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setWhoAmI } from '@core/store/auth/auth.slice';
import { setNotFound, setUser } from '@core/store/user/user.slice';
import { getUserIdThunk } from '@core/store/user/user.thunks';
import { cropAddress } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { nicknamePattern } from '@core/utils/regex';
import { Products } from '@pages/Dashboard/Profile/Products/Products';
import { ProfileTabTypes } from '@pages/Dashboard/Profile/Profile.types';
import { Reputation } from '@pages/Dashboard/Profile/Reputation/Reputation';
import { Reviews } from '@pages/Dashboard/Profile/Reviews/Reviews';
import { NotFound } from '@pages/NotFound/NotFound';
import { CopiableLink } from '@components/DashboardComponents';
import { Button, Input, SvgSprite } from '@components/index';
import { Product } from '@components/ProductComponents';
import { ProfileHeadSkeleton } from '@skeletons/profile/ProfileHeadSkeleton';
import bg from '@assets/img/bg.png';

import styles from './Profile.module.scss';

export const Profile = () => {
	/** React router hooks **/
	const location = useLocation();
	const params: { id: string } = useParams();

	/** Redux hooks **/
	const [auth, isNotFound, user] = useAppSelector(({ auth, user }) => [
		auth,
		user.isNotFound,
		user.user
	]);

	/** Redux hooks  - Changed by Dior to remove warnings **/
	// const [auth, isAgent, isNotFound, user] = useAppSelector(({ auth, user }) => [
	// 	auth,
	// 	auth.whoAmI.isAgent,
	// 	user.isNotFound,
	// 	user.user
	// ]);
	const dispatch = useAppDispatch();

	/** React hooks **/
	const [address, setAddress] = useState<string>('');
	const [isEditNickname, setIsEditNickname] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [nickname, setNickname] = useState('');
	const [isProfileOwner, setIsProfileOwner] = useState(false);
	const [stateNavBtns, setStateNavBtns] = useState({
		products: true,
		nft: false,
		reviews: false,
		ratings: false
	});

	const isCurrentUserAgent = useMemo(() => {
		const isFetchedUserAgent =
			user && user?.groups.findIndex(g => g.name === UserGroupName.Agent) >= 0;
		return isFetchedUserAgent;
	}, [user]);

	// @TODO: fix below function for unmount (abort profile request)
	useEffect(() => {
		if (params && params.id) {
			setAddress(params.id);
			if (
				!auth.whoAmI.username ||
				(params.id.startsWith('0x') &&
					auth.whoAmI.username &&
					params.id.toLowerCase() !== auth.whoAmI.username.toLowerCase()) ||
				(!params.id.startsWith('0x') &&
					auth.whoAmI.nickname &&
					params.id.toLowerCase() !== auth.whoAmI.nickname.toLowerCase())
			) {
				dispatch(getUserIdThunk(params.id));
			} else {
				dispatch(setNotFound(false));
				dispatch(setUser(auth.whoAmI));
				setIsProfileOwner(true);
			}
		}
		return () => {
			dispatch(setUser(null));
		};
	}, [params.id, auth.whoAmI]);

	const isOnlyProfilePage = useMemo(() => {
		return (
			!location.pathname.includes('/items') &&
			!location.pathname.includes('/reputation') &&
			!location.pathname.includes('/reviews')
		);
	}, [location.pathname]);

	/** Handlers **/
	const handleNicknameEdit = () => {
		setIsEditNickname(true);
		user && user.nickname && setNickname(user?.nickname);
	};

	const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (value.length > 42) {
			return dispatch(
				addAlert({
					text: 'Nickname must start and end with a letter or number. Limit 42 characters',
					type: 'error'
				})
			);
		}
		setNickname(value);
	};

	const handleNicknameSubmit = async () => {
		setIsDisabled(true);

		if (auth.whoAmI.nickname === nickname) {
			setIsDisabled(false);
			setIsEditNickname(false);
			return;
		}

		const formData = new FormData();

		if (nickname.length > 0) {
			if (nickname?.match(nicknamePattern)) {
				if (nickname.length > 0 && nickname.length < 5) {
					setIsDisabled(false);
					return dispatch(
						addAlert({
							text: 'Minimum nickname length is 5 characters',
							type: 'error'
						})
					);
				}

				formData.append('nickname', nickname);
			} else {
				setIsDisabled(false);
				dispatch(
					addAlert({
						text: 'Nickname must start and end with a letter and contain only letters, numbers, and underscores',
						type: 'error'
					})
				);
			}
		} else formData.append('nickname', nickname || '');

		const res = await Users.patch({ id: auth.whoAmI.username, formData });

		if (res) dispatch(setWhoAmI(res));

		setIsDisabled(false);
		setIsEditNickname(false);
	};

	const handleNavBtnClick = (prop: ProfileTabTypes) => {
		setStateNavBtns({
			...{ products: false, nft: false, reviews: false, ratings: false },
			[prop]: true
		});
	};

	if (isNotFound) return <NotFound />;
	return (
		<>
			{isCurrentUserAgent
				? params.id && <Redirect to={`/profile/${params.id}/reputation`} />
				: isOnlyProfilePage &&
				  params.id && <Redirect to={`/profile/${params.id}/reputation`} />}
			<div className={cn('pageWrapper', styles.pageWrapper)}>
				{isProfileOwner && (
					<Button
						as='link'
						to='/settings/profile'
						className={styles.settingsLink}
					>
						<SvgSprite iconId='pencil' />
						<span className='srOnly'>Go to profile settings</span>
					</Button>
				)}
				{user && (
					<div className={styles.bgImgWrapper}>
						<img
							className={styles.bgImg}
							src={user.cover || bg}
							alt='user banner'
						/>
					</div>
				)}
				<div className={styles.head}>
					{user ? (
						<>
							<div className={styles.avatarWrapper}>
								<img src={user.avatar || ''} alt='user avatar' />
							</div>
							<div className={styles.headMeta}>
								<span className={cn('h5', styles.username)}>
									{user.nickname || 'Anonymous'}
									{isCurrentUserAgent && <SvgSprite iconId='circle-justice' />}
								</span>
								<CopiableLink
									text={cropAddress(user.username, 6, 6)}
									asLink={false}
									size='body-sm'
									copyText={user.username}
									className={styles.address}
								/>
								<div className={cn(styles.nicknameDataWrapper, 'body-sm')}>
									{isEditNickname ? (
										<>
											https://zen.land/@
											<Input
												value={nickname}
												onChange={handleNicknameChange}
												className={styles.nicknameInput}
												maxLength={42}
											/>
										</>
									) : (
										<>
											<CopiableLink
												text={
													'https://zen.land/@' +
													(user.nickname ? user.nickname : user.username)
												}
												asLink={false}
												size='body-sm'
												copyText={
													'https://zen.land/@' +
													(user.nickname ? user.nickname : user.username)
												}
												className={styles.address}
											/>
										</>
									)}
									{isProfileOwner &&
										(isEditNickname ? (
											<Button
												onClick={handleNicknameSubmit}
												disabled={isDisabled}
											>
												<SvgSprite
													iconId='checked'
													className={styles.submitIcon}
												/>
											</Button>
										) : (
											<Button
												onClick={handleNicknameEdit}
												className={styles.address}
											>
												<SvgSprite
													className={styles.editIcon}
													iconId='pencil'
												/>
											</Button>
										))}
								</div>

								{user.bio && (
									<p className={cn('body', styles.bio)}>{user.bio}</p>
								)}
							</div>
							<div className={styles.profileActionButtons}>
								{isCurrentUserAgent || (
									<>
										<Button
											as='link'
											to={`/new/${user.username}`}
											variant='contained'
											size='large'
											color='primary'
										>
											Create Contract
										</Button>
										{isProfileOwner || (
											<Button
												as='link'
												to={`/chats/new/${user.username}`}
												variant='contained'
												size='large'
												color='primary'
												className={styles.createChatButton}
											>
												Message
												<SvgSprite iconId='chat' />
											</Button>
										)}
									</>
								)}
							</div>
						</>
					) : (
						<ProfileHeadSkeleton />
					)}
					{isCurrentUserAgent ? (
						<nav className={styles.profileNav}>
							<ul className='subNavBtns'>
								<li>
									<Button
										as='link'
										to={'/profile/' + params.id + '/reputation'}
										className={cn({
											active: stateNavBtns.ratings
										})}
										type='button'
										variant='subNavBtn'
									>
										Reputation
									</Button>
								</li>
								<li>
									<Button
										as='link'
										to={'/profile/' + params.id + '/reviews'}
										className={cn({
											active: stateNavBtns.reviews
										})}
										type='button'
										variant='subNavBtn'
									>
										Reviews
									</Button>
								</li>
							</ul>
						</nav>
					) : (
						<nav className={styles.profileNav}>
							<ul className='subNavBtns'>
								<li>
									<Button
										as='link'
										to={'/profile/' + params.id + '/items'}
										className={cn({
											active: stateNavBtns.products
										})}
										type='button'
										variant='subNavBtn'
									>
										Items
									</Button>
								</li>
								<li>
									<Button
										as='link'
										to={'/profile/' + params.id + '/reviews'}
										className={cn({
											active: stateNavBtns.reviews
										})}
										type='button'
										variant='subNavBtn'
									>
										Reviews
									</Button>
								</li>
								<li>
									<Button
										as='link'
										to={'/profile/' + params.id + '/reputation'}
										className={cn({
											active: stateNavBtns.ratings
										})}
										type='button'
										variant='subNavBtn'
									>
										Reputation
									</Button>
								</li>
							</ul>
						</nav>
					)}
				</div>
				<hr className={styles.divider} />
				{isCurrentUserAgent ? (
					<Switch>
						<Route
							exact
							path='/profile/:id/reputation'
							render={() => (
								<Reputation
									address={address}
									handleNavBtnClick={handleNavBtnClick}
									isCurrentUserAgent={isCurrentUserAgent}
								/>
							)}
						/>
						{user && (
							<Route
								exact
								path='/profile/:id/reviews'
								render={() => (
									<Reviews user={user} handleNavBtnClick={handleNavBtnClick} />
								)}
							/>
						)}
					</Switch>
				) : (
					<Switch>
						<Route
							exact
							path='/profile/:id/items'
							render={() => (
								<Products
									address={address}
									handleNavBtnClick={handleNavBtnClick}
								/>
							)}
						/>
						<Route
							exact
							path='/profile/:id/items/:slug'
							render={() => <Product handleNavBtnClick={handleNavBtnClick} />}
						/>
						<Route
							exact
							path='/profile/:id/reputation'
							render={() => (
								<Reputation
									address={address}
									handleNavBtnClick={handleNavBtnClick}
									isCurrentUserAgent={isCurrentUserAgent}
								/>
							)}
						/>
						{user && (
							<Route
								exact
								path='/profile/:id/reviews'
								render={() => (
									<Reviews user={user} handleNavBtnClick={handleNavBtnClick} />
								)}
							/>
						)}
					</Switch>
				)}
			</div>
		</>
	);
};

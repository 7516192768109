import { TransactionType } from '@core/models/enums';

export enum TransactionStatuses {
	Pending = 'pending',
	Confirmed = 'confirmed',
	Failed = 'failed'
}

export interface ITransactions {
	id: number;
	author: string;
	created: string;
	modified: string;
	type: TransactionType;
	address: string;
	tx_hash: string;
	status: TransactionStatuses;
	contract: number;
}

import React, { useEffect } from 'react';
import cn from 'classnames';
import { Redirect, Route, Switch } from 'react-router-dom';

import { setLogged } from '@core/store/auth/auth.slice';
import {
	checkMetamaskConnection,
	getURLSearchParams,
	maybeFixMetamaskConnection
} from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Alert } from '@components/index';

import { Layout, Login } from '../pages';

export const LoginRoutes = () => {
	/* Redux hooks */
	const [auth, theme] = useAppSelector(({ auth, theme }) => [auth, theme]);
	const dispatch = useAppDispatch();

	const authCookie = getCookie('is_authenticated');
	const urlQueryParams = getURLSearchParams();

	/* React hooks */
	useEffect(() => {
		if (!auth.isLogged && urlQueryParams && urlQueryParams['invite']) {
			localStorage.setItem('referralCode', urlQueryParams['invite']);
		}
	}, []);

	useEffect(() => {
		if (checkMetamaskConnection()) {
			if (authCookie) {
				dispatch(setLogged(true));
			} else {
				dispatch(setLogged(false));
			}
		} else {
			setTimeout(async () => {
				await maybeFixMetamaskConnection();
			}, 1000);
		}
	}, [authCookie]);

	return (
		<div className={cn({ dark: theme && theme.mode === 'dark' })}>
			<Alert />
			<Switch>
				<Route path='/login'>
					{auth.isLogged ? <Redirect to='/' /> : <Login />}
				</Route>

				<Route exact path='/items/:slug' component={Layout} />
				<Route exact path='/profile/:id/items/:slug' component={Layout} />
				<Route exact path='/profile/:id/items' component={Layout} />
				<Route exact path='/profile/:id/reviews' component={Layout} />
				<Route exact path='/profile/:id/reputation' component={Layout} />
				<Route exact path='/profile/:id' component={Layout} />

				<Route path='/'>
					{auth.isLogged ? <Layout /> : <Redirect from='*' to='/login' />}
				</Route>

				<Route path='*'>
					{auth.isLogged ? <Redirect to='/new' /> : <Redirect to='/login' />}
				</Route>
			</Switch>
		</div>
	);
};

import React, { useEffect } from 'react';
import cn from 'classnames';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';

import { ContractRole, WSReadyState } from '@core/models/enums';
import { setMaintenance } from '@core/store/auth/auth.slice';
import { logoutThunk } from '@core/store/auth/auth.thunks';
import { showSidebar } from '@core/store/theme/theme.slice';
import { NotificationStatusChanged } from '@core/store/ws/notification/wsNotification.slice';
import { cropAddress, cropUsername } from '@core/utils/helpers';
import { getSearchParams } from '@core/utils/helpers/getSearchParams';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { CopiableLink } from '@components/DashboardComponents';
import { Button } from '@components/index';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { SidebarProps } from './Sidebar.props';

import styles from './Sidebar.module.scss';

export const Sidebar = ({ ...props }: SidebarProps) => {
	/* Constants */
	const socialLinks = [
		{ link: 'https://t.me/zenlandapp', icon: 'nav-telegram' },
		{ link: 'https://discord.gg/gXdd8bd6Ez', icon: 'nav-discord' }
	];

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [
		auth,
		contractsFilter,
		itemsFilter,
		referralsFilter,
		isSidebarOpen,
		systemEvents,
		app
	] = useAppSelector(
		({
			auth,
			contractsFilter,
			itemsFilter,
			referralsFilter,
			theme,
			notification,
			app
		}) => [
			auth,
			contractsFilter,
			itemsFilter,
			referralsFilter,
			theme.isSidebarOpen,
			notification.systemEvents,
			app
		]
	);

	/* React hooks */
	useEffect(() => {
		const maintenanceEvent = systemEvents.length
			? systemEvents.find(n => n.data.is_maintenance_related)
			: null;

		dispatch(
			setMaintenance({
				message: maintenanceEvent?.verb || '',
				isActive: !!maintenanceEvent?.data.is_on_maintenance
			})
		);

		if (maintenanceEvent && maintenanceEvent.data.is_on_maintenance)
			dispatch(NotificationStatusChanged(WSReadyState.Closed));
	}, [systemEvents]);

	/* Handlers */
	const handleClose = () => dispatch(showSidebar(false));

	const handleLogout = () => dispatch(logoutThunk());

	return (
		<aside {...props}>
			<div
				className={cn(styles.layer, { [styles.active]: isSidebarOpen })}
				onClick={handleClose}
			/>
			<div
				className={cn(styles.sidebarLayout, { [styles.active]: isSidebarOpen })}
			>
				{auth.whoAmI.username && (
					<NavLink
						to={`/profile/${
							auth.whoAmI.nickname || auth.whoAmI.username
						}/items`}
						onClick={handleClose}
						className={styles.profileWrapper}
					>
						<img
							className={styles.avatar}
							src={auth.whoAmI.avatar || ''}
							alt='user avatar'
						/>
						<span className={styles.profileDetails}>
							<span className={cn(styles.username, 'h6')}>
								{cropUsername(auth.whoAmI.nickname) || 'Anonymous'}
							</span>
							<CopiableLink
								asLink={false}
								text={cropAddress(auth.whoAmI.username, 5, 5)}
								copyText={auth.whoAmI.username}
								className={cn(styles.address, 'body-md')}
								iconSize={18}
								onClick={e => e.stopPropagation()}
							/>
						</span>
					</NavLink>
				)}
				<nav className={styles.sidebarNav}>
					<ul className={cn(styles.sidebarMenu, 'subtitle-md')}>
						{auth.role === ContractRole.Agent ||
						auth.role === ContractRole.Zen_Agent ? (
							<>
								<li>
									<NavLink
										to={`/contracts?${new URLSearchParams(
											getSearchParams(contractsFilter)
										)}`}
										onClick={handleClose}
										activeClassName={styles.active}
									>
										<SvgSprite iconId='nav-doc' />
										Contracts
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/profile/${
											auth.whoAmI.username || auth.metamaskAddress
										}/reputation`}
										onClick={handleClose}
										activeClassName={styles.active}
									>
										<SvgSprite iconId='user' />
										My reputation
									</NavLink>
								</li>
								<li>
									<NavLink
										to='/wallet?limit=10'
										onClick={handleClose}
										activeClassName={styles.active}
									>
										<SvgSprite iconId='nav-wallet' />
										My commissions
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/chats`}
										onClick={handleClose}
										activeClassName={styles.active}
									>
										<SvgSprite iconId='chat' />
										Support chat
									</NavLink>
								</li>
								<hr className={styles.divider} />
								<BetaTestLink
									name='Agents handbook'
									link='https://zen.land/agentshandbook.pdf'
									icon='bookDoc'
								/>
							</>
						) : (
							<>
								<li>
									<NavLink
										to='/'
										exact
										onClick={handleClose}
										activeClassName={styles.active}
									>
										<SvgSprite iconId='nav-new-doc' height={25} />
										Create Contract
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/contracts?${new URLSearchParams(
											getSearchParams(contractsFilter)
										)}`}
										onClick={handleClose}
										activeClassName={styles.active}
									>
										<SvgSprite iconId='nav-doc' height={25} />
										Contracts
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/items?${new URLSearchParams(
											getSearchParams(itemsFilter)
										)}`}
										onClick={handleClose}
										activeClassName={styles.active}
									>
										<SvgSprite iconId='nav-marketplace' />
										My items
									</NavLink>
								</li>
								<li>
									<NavLink
										to={`/chats`}
										onClick={handleClose}
										activeClassName={styles.active}
									>
										<SvgSprite iconId='chat' />
										Chat
									</NavLink>
								</li>
							</>
						)}
						<li>
							<NavLink
								to={`/profile/${
									auth.whoAmI.username || auth.metamaskAddress
								}/items`}
								onClick={handleClose}
								activeClassName={styles.active}
								className={styles.mobileOnly}
							>
								<SvgSprite iconId='user' />
								Profile
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/wallet?limit=10'
								onClick={handleClose}
								activeClassName={styles.active}
								className={styles.mobileOnly}
							>
								<SvgSprite iconId='nav-wallet' />
								My wallet
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/notifications'
								onClick={handleClose}
								activeClassName={styles.active}
								className={styles.mobileOnly}
							>
								<SvgSprite iconId='nav-notification' />
								Notifications
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/settings/profile'
								onClick={handleClose}
								activeClassName={styles.active}
								className={styles.mobileOnly}
							>
								<SvgSprite iconId='nav-settings' />
								Settings
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/'
								onClick={handleLogout}
								className={styles.mobileOnly}
							>
								<SvgSprite iconId='nav-door' />
								Log out
							</NavLink>
						</li>
						<hr className={styles.divider} />
						<BetaTestLink
							name='Documentation'
							link='https://docs.zen.land/'
							icon='bookDoc'
						/>
						<li className={styles.socialLinks}>
							{socialLinks.map(({ link, icon }, i) => {
								return (
									<Button as='externalLink' key={i} href={link}>
										<SvgSprite iconId={icon} />
									</Button>
								);
							})}
						</li>
					</ul>
				</nav>
				<div className={cn('caption', styles.sidebarMenuBottom)}>
					<p className={styles.updateWrapper}>
						<span>
							Last Update:{' '}
							<Moment format='MMMM DD, YYYY'>{app.buildTimestamp}</Moment>
						</span>
						<span> Version: {app.version}</span>
					</p>
					<p>Copyright © {new Date().getFullYear()} Zenland</p>
				</div>
			</div>
		</aside>
	);
};

function BetaTestLink({
	name,
	link,
	icon = 'nav-bugreport'
}: {
	name: string;
	link: string;
	icon?: string;
}) {
	return (
		<li>
			<a href={link} target='_blank' rel='noopener noreferrer'>
				<SvgSprite iconId={icon} />
				<span className={styles.linkTextWrapper}>
					{name}
					<SvgSprite iconId='arrow-right-top' />
				</span>
			</a>
		</li>
	);
}

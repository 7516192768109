import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './HistoryListSkeleton.module.scss';

export const HistoryListSkeleton = () => {
	const renderRows = () => {
		return [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
			<ContentLoader
				backgroundColor='#e3e9ef'
				foregroundColor='white'
				preserveAspectRatio='none'
				speed={2}
				height={64}
				width='100%'
				viewBox='0 0 100 100'
				style={{ borderRadius: '16px' }}
				key={index}
			>
				<rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
			</ContentLoader>
		));
	};

	return (
		<div className={styles.list}>
			<ContentLoader
				preserveAspectRatio='none'
				backgroundColor='#e3e9ef'
				foregroundColor='#fff'
				width='100%'
				height={20}
				viewBox='0 0 100 100'
				speed={2}
			>
				<rect x='0' y='0' rx='4' ry='4' width='10' height='100%' />
				<rect x='20%' y='0' rx='4' ry='4' width='12' height='100%' />
				<rect x='50%' y='0' rx='4' ry='4' width='10' height='100%' />
				<rect x='85%' y='0' rx='4' ry='4' width='12' height='100%' />
			</ContentLoader>
			{renderRows()}
		</div>
	);
};

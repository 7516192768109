import React, { FormEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';

import { INetwork, IUserRating, ProductToken } from '@core/models/interfaces';
import { UserRatings } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { setItemAction } from '@core/store/item/item.slice';
import { setItemThunk } from '@core/store/item/item.thunks';
import { getHoursBySeconds } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import {
	ProfileTabProps,
	ProfileTabTypes
} from '@pages/Dashboard/Profile/Profile.types';
import {
	Badge,
	Button,
	Comment,
	Heading,
	RatingWithCounter,
	SvgSprite,
	Tooltip
} from '@components/index';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';
import { CheckboxesNetworks } from '@components/ProductComponents/Product/Partials/CheckboxesNetworks';
import { CheckboxesTokens } from '@components/ProductComponents/Product/Partials/CheckboxesTokens';
import { Item } from '@components/ProductComponents/ProductCard.props';
import { ProductSkeleton } from '@skeletons/profile/product/ProductSkeleton';

import styles from './Product.module.scss';

export const Product = ({ handleNavBtnClick }: ProfileTabProps) => {
	/* React-i18next hooks */
	const { t } = useTranslation('common');

	/* Router hooks */
	const params: { id: string; slug?: string } = useParams();

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const item = useAppSelector(({ items }) => items.current.data);

	/* React hooks */
	const [checkedNetworks, setCheckedNetworks] = useState<TCheckboxList[]>([]);
	const [checkedToken, setCheckedToken] = useState<TCheckboxList[]>([]);
	const [isCreateContract, setIsCreatedContact] = useState<boolean>(false);
	const [reviews, setReviews] = useState<IUserRating[] | null>(null);

	useEffect(() => {
		if (localStorage.getItem(Item.data)) localStorage.removeItem(Item.data);
		handleNavBtnClick(ProfileTabTypes.Products);

		return () => {
			dispatch(setItemAction(null));
		};
	}, []);

	useEffect(() => {
		if (!item) {
			const promise =
				params &&
				params.slug &&
				params.id &&
				dispatch(setItemThunk({ slug: params.slug }));

			return () => {
				promise && promise.abort();
			};
		}

		if (params && item && !reviews) {
			(async () => {
				let reviews;
				if (params.id.startsWith('0x')) {
					reviews = await UserRatings.getAll({
						rating__object_id: item.id,
						related_object_content_type__model: 'product',
						rating__content_type__model: 'user'
					});
				} else {
					reviews = await UserRatings.getAll({
						rating__object_id: item.id,
						related_object_content_type__model: 'product',
						rating__content_type__model: 'user'
					});
				}

				if (reviews) {
					setReviews(reviews.results);
				}
			})();
		}
	}, [item]);

	const availableNetworksList =
		item &&
		item.available_networks.map((availableNetwork: INetwork) => {
			return {
				name: availableNetwork.display_name,
				value: availableNetwork.chain_id,
				image: availableNetwork.image
			};
		});

	const availableTokenList =
		checkedNetworks.length &&
		item &&
		item.available_tokens.map((availableToken: ProductToken) => {
			const networkImage = item.available_networks.filter(
				(n: INetwork) => n.id === availableToken.network
			);

			return {
				name: availableToken.name,
				value: availableToken.id,
				image: networkImage[0].image
			};
		});

	const availableCheckedNetworkId = item?.available_networks
		.filter((availableNetwork: INetwork) =>
			checkedNetworks
				.map(checkedNetwork => checkedNetwork.value)
				.includes(availableNetwork.chain_id)
		)
		.map((availableNetwork: INetwork) => availableNetwork.id);

	const availableSupportedTokenList =
		item &&
		item.available_tokens
			.filter((availableToken: ProductToken) =>
				availableCheckedNetworkId.includes(availableToken.network)
			)
			.map((token: ProductToken) => token.id);

	/* Handlers  */
	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();

		if (!checkedNetworks.length)
			return dispatch(
				addAlert({ type: 'error', text: 'Please select network' })
			);

		const isHasAvailableToken = checkedToken.findIndex(checkedToken =>
			availableSupportedTokenList?.includes(checkedToken.value)
		);

		if (!checkedToken.length || isHasAvailableToken < 0)
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Please select token based on your network'
				})
			);

		const data = item && {
			slug: item.slug,
			seller: item.seller,
			network: checkedNetworks
				.map(checkedNetwork => checkedNetwork.value)
				.join(''),
			token: checkedToken.map(checkedToken => checkedToken.value).join('')
		};

		localStorage.setItem(Item.data, JSON.stringify(data));
		setIsCreatedContact(true);
	};

	return item ? (
		<>
			{item.is_active || <Redirect to='/404-page-not-found' />}
			<article className={styles.product}>
				<div className={styles.thumbnail}>
					{item.image ? (
						<img src={item.image} alt='product thumbnail' />
					) : (
						<SvgSprite iconId='image' width={180} height={180} />
					)}
				</div>
				<div className={styles.titleWrapper}>
					<Heading tag='h2' className={cn(styles.title, 'h5')}>
						{item.title}
					</Heading>
					<RatingWithCounter
						count={item.rating.count}
						total={+item.rating.average}
						starsSize='md'
						fontSize='body-sm'
					/>
				</div>
				<dl className={styles.description}>
					<dt className={cn('subtitle-md', styles.detailTitle)}>Description</dt>
					<dd>{parse(item.description)}</dd>
				</dl>
				<dl className={styles.details}>
					<form className={styles.detailsForm} onSubmit={handleSubmit}>
						<div className={cn(styles.detailPrice, styles.detailItem)}>
							<dt className='h6'>Price:</dt>
							<dd className='h6'>&nbsp;${item.price}</dd>
						</div>
						<div className={cn(styles.detailApprove, styles.detailItem)}>
							{item.is_approval_needed ? (
								<Badge
									text='md'
									color='warn'
									hasIcon
									className={styles.detailNotApproved}
								>
									Not-approved by seller
								</Badge>
							) : (
								<Badge text='md' color='primary' hasIcon>
									Pre-approved by seller
								</Badge>
							)}
							<SvgSprite
								width={20}
								height={20}
								data-for='productTooltip'
								data-tip={t('app.toolTips.product.approvement')}
								data-html
							/>
						</div>
						<div className={styles.detailItem}>
							<dt className='body-md'>
								Delivery time:
								<SvgSprite
									data-for='productTooltip'
									data-tip={t('app.toolTips.product.delivery_time')}
								/>
							</dt>
							<dd className='body-sm'>
								{getHoursBySeconds(item.delivery_time)}
							</dd>
						</div>
						<div className={styles.detailItem}>
							<dt className='body-md'>
								Buyer protection time:
								<SvgSprite
									data-for='productTooltip'
									data-tip={t('app.toolTips.product.buyer_protection_time')}
									data-html
								/>
							</dt>
							<dd className='body-sm'>
								{getHoursBySeconds(item.buyer_protection_time)}
							</dd>
						</div>
						<div className={styles.detailItem}>
							<dt className='body-md'>
								Accepted network:
								<SvgSprite
									data-for='productTooltip'
									data-tip={t('app.toolTips.product.networks')}
								/>
							</dt>
							<dd>
								<CheckboxesNetworks
									setCheckedNetworks={setCheckedNetworks}
									availableNetworksList={availableNetworksList}
								/>
							</dd>
						</div>
						<div className={styles.detailItem}>
							<dt className='body-md'>
								Accepted token:
								<SvgSprite
									data-for='productTooltip'
									data-tip={t('app.toolTips.product.tokens')}
								/>
							</dt>
							<dd>
								<CheckboxesTokens
									availableTokenList={availableTokenList || []}
									availableSupportedTokenList={availableSupportedTokenList}
									setCheckedToken={setCheckedToken}
								/>
							</dd>
						</div>
						<Button
							className={styles.btn}
							variant='contained'
							size='medium'
							color='primary'
							disabled={!checkedToken.length || !checkedNetworks.length}
						>
							Buy Now
						</Button>
					</form>
				</dl>
			</article>
			<div className={styles.reviews}>
				{reviews &&
					reviews.map((review, index) => (
						<Comment comment={review} key={index} />
					))}
			</div>
			{isCreateContract && (
				<Redirect to={`/profile/${item.seller}/items/${item.slug}/create`} />
			)}
			<Tooltip id='productTooltip' />
		</>
	) : (
		<ProductSkeleton />
	);
};

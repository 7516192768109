import React from 'react';
import ContentLoader from 'react-content-loader';

import { getRandomNumberByInterval } from '@core/utils/helpers/getRandomNumberByInterval';

import styles from './DialogSkeleton.module.scss';

export const DialogSkeleton = () => {
	const renderSkeleton = () => {
		return Array.from({ length: 3 }, (x, i) => i).map((item, index) => {
			const userNameWidth = getRandomNumberByInterval(18, 27) + '%';
			const userMessageWidth = getRandomNumberByInterval(55, 75) + '%';

			return (
				<ContentLoader
					preserveAspectRatio='none'
					backgroundColor='#e3e9ef'
					foregroundColor='#fff'
					width='100%'
					height='56'
					speed={2}
					key={index}
				>
					<rect x='0' y='0' rx='50%' ry='50%' width='56' height='56' />
					<rect x='72' y='0' rx='4' ry='4' width={userNameWidth} height='24' />
					<rect
						x='72'
						y='30'
						rx='4'
						ry='4'
						width={userMessageWidth}
						height='24'
					/>
					<rect x='85%' y='0' rx='4' ry='4' width='15%' height='20' />
				</ContentLoader>
			);
		});
	};

	return <div className={styles.dialogs}>{renderSkeleton()}</div>;
};

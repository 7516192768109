import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import cn from 'classnames';

import svgSprite from '@assets/img/icons/sprite.svg';

import styles from './SvgIcon.module.scss';

interface ISvgSprite
	extends DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement> {
	iconId?: string;
	width?: number;
	height?: number;
	size?: number;
	color?: string;
}

export const SvgSprite = ({
	iconId = 'status-info-outlined-circle',
	width,
	height,
	size,
	className,
	color,
	...props
}: ISvgSprite) => {
	const iconWidth = width || size || 24;
	const iconHeight = height || size || 24;

	return (
		<svg
			className={cn(styles.icon, className, {
				[styles.infoIcon]: iconId === 'status-info-outlined-circle' && !size
			})}
			width={iconWidth}
			height={iconHeight}
			viewBox={`0 0 ${iconWidth} ${iconHeight}`}
			style={{ color }}
			{...props}
		>
			<use xlinkHref={`${svgSprite}#${iconId}`} />
		</svg>
	);
};

import { ContractStatusName } from '@core/models/enums';

interface ICases {
	draft: () => 'draft_count';
	approved: () => 'approved_count';
	deployed: () => 'deployed_count';
	active: () => 'active_count';
	disputed: () => 'disputed_count';
	executed: () => 'executed_count';
	total: () => 'total_count';
	dispute_finished: () => 'dispute_finished_count';
	fulfilled: () => 'fulfilled_count';
	agent_invited: () => 'agent_invited_count';
	count: () => 'count';
}

const cases: ICases = {
	draft: () => 'draft_count',
	approved: () => 'approved_count',
	deployed: () => 'deployed_count',
	active: () => 'active_count',
	fulfilled: () => 'fulfilled_count',
	disputed: () => 'disputed_count',
	executed: () => 'executed_count',
	dispute_finished: () => 'dispute_finished_count',
	total: () => 'total_count',
	agent_invited: () => 'agent_invited_count',
	count: () => 'count'
};

export const getCountsByStatus = (status: ContractStatusName) => {
	return cases[status]();
};

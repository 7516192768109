import React from 'react';
import cn from 'classnames';

import { ProgressBarProps } from './Wallet.props';

import styles from './Wallet.module.scss';

export const ProgressBar = ({ currentStep }: ProgressBarProps) => {
	return (
		<div className={styles.progressBarWrapper}>
			<div className={styles.progressBar}>
				<div
					className={cn(styles.progressFilled, styles[`filled${currentStep}`])}
				/>
			</div>
			<span
				className={cn('body-md', styles.progressNumberWrapper, {
					[styles.filledStep]: currentStep >= 1
				})}
			>
				<span className={styles.progressNumber}>1</span>
			</span>
			<span
				className={cn('body-md', styles.progressNumberWrapper, {
					[styles.filledStep]: currentStep >= 2
				})}
			>
				<span className={styles.filledTopDecor} />
				<span className={styles.progressNumber}>2</span>
			</span>
			<span
				className={cn('body-md', styles.progressNumberWrapper, {
					[styles.filledStep]: currentStep === 3
				})}
			>
				<span className={styles.filledTopDecor} />
				<span className={styles.progressNumber}>3</span>
			</span>
		</div>
	);
};

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { WSReadyState } from '@core/models/enums';
import { sendChatMessage } from '@core/store/ws/chat/wsChat.thunks';
import { isMobile } from '@core/utils/helpers';
import {
	useAppDispatch,
	useAppSelector,
	useWindowSize
} from '@core/utils/hooks';
import { Button, SvgSprite, Textarea } from '@components/index';

import styles from './Chat.module.scss';

export const AddMessageForm = () => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [status, maintenance] = useAppSelector(({ wsChat, auth }) => [
		wsChat.status,
		auth.maintenance
	]);

	/* Custom hooks */
	const { width } = useWindowSize();

	/* React hooks */
	const [newMessage, setNewMessage] = useState('');
	const [height, setHeight] = useState('48px');
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	useEffect(() => {
		width < 480 ? setHeight('40px') : setHeight('48px');
	}, [width]);
	useEffect(() => {
		setIsButtonDisabled(status !== WSReadyState.Open || maintenance.isActive);
	}, [status, maintenance]);

	/* Handlers */
	const sendMessage = () => {
		const trimmedMsg = newMessage.trim();

		if (trimmedMsg.length) {
			dispatch(sendChatMessage(trimmedMsg));
			setNewMessage('');
		}
	};

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		sendMessage();
	};

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setNewMessage(e.target.value);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.shiftKey) return;
		if (e.key === 'Enter' && isMobile()) return;
		if (e.key !== undefined && e.key === 'Enter') {
			e.preventDefault();
			sendMessage();
		}
	};

	return (
		<form className={styles.form} onSubmit={handleSubmit}>
			<Textarea
				autoHeight={newMessage.length > 0}
				height={height}
				value={isButtonDisabled ? 'No connection ...' : newMessage}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				className={styles.textarea}
				placeholder='Message'
				autoFocus={!isMobile()}
			/>

			<Button
				disabled={isButtonDisabled}
				className={styles.sendBtn}
				title='Send message'
			>
				<SvgSprite iconId='send' />
			</Button>
		</form>
	);
};

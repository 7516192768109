import React from 'react';
import ContentLoader from 'react-content-loader';

export const ImageSkeleton = () => {
	return (
		<ContentLoader
			backgroundColor='#e3e9ef'
			foregroundColor='white'
			preserveAspectRatio='none'
			speed={2}
			height='100%'
			width='100%'
			viewBox='0 0 100 100'
			style={{ borderRadius: '16px' }}
		>
			<rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
		</ContentLoader>
	);
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { Chat } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import {
	setChatRoom,
	setChatRooms,
	setFoundChatRoom,
	setRoomsLoading
} from '@core/store/chat/chat.slice';
import { RootState } from '@core/store/store.types';

export const roomsThunk = createAsyncThunk(
	'chat/setRooms',
	async (_, { dispatch, signal }) => {
		dispatch(setRoomsLoading(true));

		const res = await Chat.getDialogs({ signal });
		if (res) dispatch(setChatRooms(res.results));
	}
);

export const roomIdThunk = createAsyncThunk<void, number>(
	'chats/setRoom',
	async (id, { dispatch }) => {
		const res = await Chat.get(id);

		if (res) dispatch(setChatRoom(res));
	}
);

export const foundRoomThunk = createAsyncThunk<
	void,
	string,
	{ state: RootState }
>('chat/setFoundRoom', async (companion, { dispatch, getState }) => {
	const state = getState();
	const companionLower = companion.toLowerCase();
	const room = state.chat.rooms.data?.find(room => {
		return (
			room.companions[0].username.toLowerCase() === companionLower ||
			room.companions[0].nickname?.toLowerCase() === companionLower
		);
	});

	if (room) {
		dispatch(setFoundChatRoom(room));
		dispatch(
			addAlert({
				text: `You have dialog with this user`,
				type: 'info'
			})
		);
	} else {
		const res = await Chat.post(companion);
		if (res) {
			dispatch(setChatRoom(res));
			dispatch(setFoundChatRoom(res));
		}
	}
});

export const cropUsername = (
	username: string,
	start = 7,
	end = 3,
	cropCondition = 13
): string => {
	if (username && username.length && cropCondition <= username.length) {
		const first = username.slice(0, start);
		const last = username.slice(username.length - end, username.length);
		return `${first}...${last}`;
	}
	return username;
};

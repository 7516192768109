import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './NotificationsListSkeleton.module.scss';

export const NotificationsListSkeleton = () => {
	const renderNotifications = () => {
		return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
			<ContentLoader
				backgroundColor='#e3e9ef'
				foregroundColor='white'
				preserveAspectRatio='none'
				speed={2}
				height={50}
				width='100%'
				viewBox='0 0 100 100'
				style={{ borderRadius: '16px' }}
				key={index}
			>
				<rect x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
			</ContentLoader>
		));
	};

	return <div className={styles.tableGrid}>{renderNotifications()}</div>;
};

import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/utils/hooks';
import { Checkboxes } from '@components/MainComponents/Checkboxes/Checkboxes';
import {
	TCheckboxes,
	TCheckboxList
} from '@components/MainComponents/Checkboxes/Checkboxes.types';

export const CheckboxTokens = ({
	setCheckedList,
	checkedNetworks,
	...props
}: TCheckboxes & { checkedNetworks: TCheckboxList[] }) => {
	const [authTokens] = useAppSelector(({ auth }) => [auth.tokens]);

	const [checkedTokens, setCheckedTokens] = useState<TCheckboxList[]>(
		[] as TCheckboxList[]
	);
	const [availableTokens, setAvailableTokens] = useState<number[]>();

	useEffect(() => {
		const checkedNetworksChainId = checkedNetworks.map(
			checkedNetwork => checkedNetwork.value
		);
		if (authTokens) {
			const foundTokensId = authTokens
				.filter(authToken =>
					checkedNetworksChainId.includes(authToken?.network.chain_id)
				)
				.map(authToken => authToken.id);

			// send available tokens to Checkboxes for removing token from checkedElements
			setAvailableTokens(foundTokensId);

			// remove tokens using foundTokensId if network unchecked for setData
			setCheckedList(prevState =>
				prevState.filter(el => foundTokensId.includes(+el.value))
			);
		}
	}, [checkedNetworks]);

	useEffect(() => {
		setCheckedList(checkedTokens);
	}, [checkedTokens]);

	return (
		<Checkboxes
			{...props}
			setCheckedList={setCheckedTokens}
			availableCheckList={availableTokens}
		/>
	);
};

import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';
import moment from 'moment';
import Moment from 'react-moment';

import { siteURL } from '@core/config/api';
import {
	CommissionPayer,
	ContractRole,
	ContractStatusNumber
} from '@core/models/enums';
import { INetwork } from '@core/models/interfaces';
import { Contracts } from '@core/services';
import { removeAlertAction } from '@core/store/alert/alert.slice';
import { addAlert } from '@core/store/alert/alert.thunks';
import {
	setContractAction,
	setNetworkNotMatched
} from '@core/store/contract/contract.slice';
import { getContractBySyncThunk } from '@core/store/contract/contract.thunks';
import { getLocaleISOString, getZenCommission } from '@core/utils/helpers';
import {
	timeConvert,
	useAppDispatch,
	useAppSelector,
	useOnClickOutside
} from '@core/utils/hooks';
import { CopiableLink, payerOptions } from '@components/DashboardComponents';
import {
	Button,
	Heading,
	Input,
	Select,
	SvgSprite,
	Tooltip
} from '@components/index';
import { DateTime } from '@components/MainComponents/DateTime/DateTime';

import { DetailsProps } from '../Contract.props';
import { ProtectionTime } from './ProtectionTime';

import styles from './ContractDetails.module.scss';

type EditForms = 'price' | 'protection' | 'deadline' | 'payer';

const payerOptionsObject = {
	fifty_fifty: payerOptions[CommissionPayer.Fifty],
	buyer: payerOptions[CommissionPayer.Buyer],
	seller: payerOptions[CommissionPayer.Seller]
};

export const timeOptions = {
	hours: { name: 'Hours', value: 3600 },
	days: { name: 'Days', value: 86400 }
};

export const ContractDetails = ({ contract }: DetailsProps) => {
	/* Redux hooks */
	const [
		role,
		network,
		maintenance,
		currentContractIsExpired,
		currentContractIsSyncing,
		alert
	] = useAppSelector(({ auth, contracts, alert }) => [
		auth.role,
		auth.network,
		auth.maintenance,
		contracts.current.isCurrentExpired,
		contracts.current.isCurrentSyncing,
		alert
	]);
	const dispatch = useAppDispatch();

	const isApproved =
		(role === ContractRole.Buyer && contract.buyer_approved) ||
		(role === ContractRole.Seller && contract.seller_approved);
	/* React hooks */
	const [scroll, setScroll] = useState({ isTop: true, isBottom: false });
	const [isFormsEditable, setIsFormsEditable] = useState(false);
	const [formsValue, setFormsValue] = useState({
		price: '',
		protection: '',
		deadline: '',
		payer: contract.zen_commission_payer
	});
	const [protectionOption, setProtectionOption] = useState<{
		name: string;
		value: number;
	}>(timeOptions.hours);
	const [payerOption, setPayerOption] = useState(
		payerOptionsObject[contract.zen_commission_payer]
	);
	const [isSyncBtnLoading, setIsSyncBtnLoading] = useState<boolean>(false);

	const formRef = useRef(null);
	const descRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const hours = Math.floor(contract.buyer_protection_time / 3600);
		setFormsValue(prev => ({
			...prev,
			price: contract.price,
			protection: `${hours}`,
			deadline: getLocaleISOString(
				new Date(contract.deadline).toLocaleString('en-GB')
			),
			payer: contract.zen_commission_payer
		}));

		if (currentContractIsSyncing || isSyncBtnLoading) {
			if (!currentContractIsSyncing) {
				setIsSyncBtnLoading(false);
			}
		}
	}, [contract, currentContractIsSyncing]);

	useEffect(() => {
		let _id = '';
		if (network && contract.network.display_name !== network) {
			dispatch(setNetworkNotMatched(true));
			dispatch(
				addAlert({
					text: `Please, change your network to ${contract.network.display_name}.`,
					type: 'error',
					isSubmit: true
				})
			);
		} else {
			if (!maintenance.isActive) dispatch(setNetworkNotMatched(false));
		}

		if (alert.length) {
			alert.forEach(a => {
				if (a.text.includes('Please, change your network to')) {
					_id = a.id;
				}
			});
		}

		return () => {
			if (_id) dispatch(removeAlertAction(_id));
			if (!maintenance.isActive) dispatch(setNetworkNotMatched(false));
		};
	}, [network, alert]);

	/* Custom hooks */
	const { days, hours, minutes } = timeConvert(contract.buyer_protection_time);
	useOnClickOutside(formRef, () => setIsFormsEditable(false));

	/* Handlers */
	const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const inputSeconds = +formsValue.protection * protectionOption.value;
		if (!isFormsEditable) return;

		if (+formsValue.price < 0.01) {
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Agreed amount value must be greater than 0.01'
				})
			);
		}

		if (+formsValue.protection < 1)
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Protection time value must be greater than 1'
				})
			);

		if (inputSeconds < 86400)
			return dispatch(
				addAlert({
					type: 'error',
					text: 'Ensure buyer protection time is greater than or equal to 24 hour'
				})
			);

		const result = await Contracts.patch({
			id: contract.id,
			body: {
				buyer_protection_time: inputSeconds,
				deadline: new Date(formsValue.deadline).toISOString(),
				price: formsValue.price,
				zen_commission_payer: formsValue.payer
			}
		});
		if (result) {
			dispatch(setContractAction({ ...contract, ...result, id: result.id }));
		} else {
			setFormsValue({
				...formsValue,
				protection: `${contract.buyer_protection_time}`,
				deadline: getLocaleISOString(
					new Date(contract.deadline).toLocaleString('en-GB')
				),
				price: contract.price
			});
		}

		setIsFormsEditable(false);
	};

	const handleInputsChange =
		(prop: EditForms) => (e: React.ChangeEvent<HTMLInputElement>) => {
			setFormsValue({ ...formsValue, [prop]: e.target.value });
		};

	const handleProtectionOption =
		() => (option: { name: string; value: number }) => {
			setProtectionOption(option);
			const currentFormatValue = Math.floor(
				contract.buyer_protection_time / option.value
			);

			setFormsValue({
				...formsValue,
				protection: `${currentFormatValue}`
			});
		};

	const handleFormsEditable = (isEditable: boolean) => () => {
		if (!isEditable) {
			setIsFormsEditable(isEditable);
			setProtectionOption(timeOptions.hours);
			setFormsValue({
				...formsValue,
				price: contract.price,
				protection: `${contract.buyer_protection_time / 3600}`,
				deadline: getLocaleISOString(
					new Date(contract.deadline).toLocaleString('en-GB')
				)
			});
		} else setIsFormsEditable(isEditable);
	};

	const handlePayerChange = (option: { name: string; value: string }) => {
		setPayerOption({ ...option, value: option.value as CommissionPayer });
		setFormsValue({ ...formsValue, payer: option.value as CommissionPayer });
	};

	const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
		if (scrollTop === 0) {
			setScroll({ ...scroll, isTop: true });
		} else if (scrollTop + clientHeight === scrollHeight) {
			setScroll({ ...scroll, isBottom: true });
		} else {
			setScroll({ isTop: false, isBottom: false });
		}
	};

	const handleContractSync = async (id: number) => {
		if (
			ContractStatusNumber[contract.status] >= ContractStatusNumber.deployed
		) {
			setIsSyncBtnLoading(true);
			await dispatch(getContractBySyncThunk(id.toString()));
		}
	};

	const ShowZenlandFee = ({
		payer,
		commission
	}: {
		payer: string;
		commission: string | number;
	}) => {
		let _commission = +commission;
		if (+formsValue.price <= 1) _commission = 0.01;

		return payer === CommissionPayer.Fifty ? (
			<>
				Buyer: ${(_commission / 2).toFixed(2)} Seller: $
				{(_commission / 2).toFixed(2)}
			</>
		) : (
			<>
				<span className={styles.feePayer}>{payer}</span>: ${_commission}
			</>
		);
	};

	return (
		<form className={styles.details} onSubmit={handleFormSubmit} ref={formRef}>
			<div className={styles.detailsTitleWrapper}>
				<Heading tag='h1' className={cn(styles.detailsTitle, 'h5')}>
					Contract #{contract.id}
				</Heading>
				<div className={styles.editBtns}>
					{isApproved
						? ContractStatusNumber[contract.status] <
								ContractStatusNumber.executed && (
								<div className={styles.syncBtnWrapper}>
									<Button
										variant='text'
										size='small'
										disabled={
											ContractStatusNumber[contract.status] <
												ContractStatusNumber.deployed ||
											contract.is_syncing ||
											currentContractIsSyncing
										}
										isLoader={isSyncBtnLoading}
										loaderColor='#1A82FB'
										onClick={() => handleContractSync(contract.id)}
										title='Sync with blockchain'
										type='button'
										className={cn(styles.syncBtn, {
											[styles.loading]: isSyncBtnLoading
										})}
									>
										Sync
									</Button>
									<SvgSprite
										size={20}
										onClick={e => e.stopPropagation()}
										data-for='contractDetailsTooltip'
										data-tip={
											'You can use it only one per minute and after transfer contract'
										}
										data-html
										className={styles.tooltipIcon}
									/>
								</div>
						  )
						: role !== ContractRole.Agent &&
						  role !== ContractRole.Zen_Agent &&
						  !contract.product &&
						  (isFormsEditable ? (
								<>
									<Button
										variant='text'
										size='small'
										color='primary'
										onClick={handleFormsEditable(false)}
										title='Cancel changes'
										type='button'
									>
										Cancel
									</Button>
									<Button
										variant='contained'
										size='small'
										color='primary'
										title='Save changes'
									>
										Save
									</Button>
								</>
						  ) : (
								<Button
									onClick={handleFormsEditable(true)}
									className={styles.editBtn}
									title='Click to edit'
									type='button'
								>
									<SvgSprite iconId='pencil' />
								</Button>
						  ))}
				</div>
			</div>
			<dl
				className={cn(
					styles.detailsList,
					{ [styles.detailsItemList]: contract.product },
					'body'
				)}
			>
				{contract.product &&
					(contract.product.image ? (
						<img
							src={contract.product.image}
							alt='item picture'
							className={styles.detailsItemPicture}
						/>
					) : (
						<SvgSprite iconId='image' width={150} height={150} />
					))}
				<div
					className={cn(styles.detailsListParts, {
						[styles.detailsListItemParts]: contract.product
					})}
				>
					<div className={styles.detailsListPart}>
						<ContractDetailItem
							title={
								<>
									Contractee{' '}
									<span className={styles.infoSubtitle}>(buyer)</span>:
								</>
							}
							data={
								<CopiableLink text={contract.buyer.username} asLink={false} />
							}
						/>
						<ContractDetailItem
							title={
								<>
									Contractor{' '}
									<span className={styles.infoSubtitle}>(seller)</span>:
								</>
							}
							data={
								<CopiableLink text={contract.seller.username} asLink={false} />
							}
						/>
						<ContractDetailItem
							title='Contract address:'
							data={
								contract.address ? (
									<CopiableLink
										text={contract.address}
										url={`${contract.network.explorer_url}/address/${contract.address}`}
									/>
								) : (
									''
								)
							}
						/>
						<ContractDetailItem title='Contract name:' data={contract.title} />
						<ContractDetailItem
							title='Contract details:'
							data={
								<div
									ref={descRef}
									className={cn(styles.shadowsDescription, {
										[styles.shadow]: descRef.current?.clientHeight === 120,
										[styles.top]: scroll.isTop,
										[styles.bottom]: scroll.isBottom
									})}
								>
									<div
										className={styles.detailsDescription}
										onScroll={handleScroll}
									>
										{contract.description ? parse(contract.description) : 'N/A'}
									</div>
								</div>
							}
						/>
						<ContractDetailItem
							title='Zenland agent:'
							data={
								contract?.agent ? (
									contract?.agent.nickname ? (
										<CopiableLink
											text={contract?.agent.nickname}
											url={`${siteURL}/profile/${contract?.agent.nickname}`}
										/>
									) : (
										<CopiableLink
											text={contract.agent.username}
											url={`${siteURL}/profile/${contract.agent.username}`}
										/>
									)
								) : (
									''
								)
							}
						/>
					</div>
					<div className={styles.detailsListPart}>
						<ContractDetailItem
							title='Network:'
							data={contract.network.display_name}
						/>
						<ContractDetailItem
							title='Token:'
							data={contract.token.name}
							isHasLink
							contractTokenAddress={contract.token.contract_address}
							contractNetwork={contract.network}
							contractBuyer={contract.buyer.username}
						/>
						<ContractDetailItem
							title='Contract creation date:'
							data={
								<Moment format='MMMM DD, YYYY HH:mm'>{contract.created}</Moment>
							}
						/>

						<ContractDetailItem
							title='Buyer protection time:'
							data={
								isFormsEditable ? (
									<div
										className={cn(
											styles.editInputWrapper,
											styles.protectionInputWrapper
										)}
									>
										<Input
											onChange={handleInputsChange('protection')}
											value={formsValue.protection}
											disabled={!isFormsEditable}
											className={cn(
												'editInput',
												styles.editProtectionTimeInput
											)}
											type='number'
											id='protection'
										/>
										<Select
											options={Object.values(timeOptions)}
											defaultOption={protectionOption}
											setOption={handleProtectionOption()}
											listStartSide='end'
											size='sm'
											bg='secondary'
											className={styles.editingSelect}
										/>
									</div>
								) : contract.transactions.length === 3 ? (
									<ProtectionTime
										buyer_protection_time={contract.buyer_protection_time}
										transactions={contract.transactions}
									/>
								) : (
									<>
										{days} days {hours} hours {minutes} minutes
									</>
								)
							}
						/>

						<ContractDetailItem
							className={cn({
								[styles.expired]:
									currentContractIsExpired &&
									ContractStatusNumber[contract.status] ===
										ContractStatusNumber.draft,
								[styles.unavailable]:
									currentContractIsExpired &&
									ContractStatusNumber[contract.status] !==
										ContractStatusNumber.draft
							})}
							title={
								<>
									Due date:{' '}
									{currentContractIsExpired && (
										<span className={styles.deadlineInfo}>
											{ContractStatusNumber[contract.status] ===
											ContractStatusNumber.draft ? (
												<span>The date has expired. Please update.</span>
											) : (
												<span>
													The contract is no longer available because of expired
													due date.
												</span>
											)}
										</span>
									)}
								</>
							}
							data={
								isFormsEditable ? (
									<div className={styles.editInputWrapper}>
										<DateTime
											className={styles.formInputWrapper}
											initialValue={new Date(
												formsValue.deadline
											).toLocaleString('en-GB', {
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
												hour: '2-digit',
												minute: '2-digit'
											})}
											onChange={e => {
												if (moment(e).isValid()) {
													setFormsValue(prev => ({
														...prev,
														deadline: new Date(e.toLocaleString()).toISOString()
													}));
												}
											}}
											inputProps={{
												className: cn('editInput', styles.dateInput),
												onInput: e => {
													if (e.currentTarget.value.length > 15 && +e) {
														setFormsValue(prev => ({
															...prev,
															deadline: moment(e.currentTarget.value).format(
																'DD-MM-YYYYTkk:mm'
															)
														}));
													}
												}
											}}
										/>
									</div>
								) : (
									<>
										<p className={styles.deadline}>
											<Moment format='MMMM DD, YYYY HH:mm'>
												{contract.deadline}
											</Moment>
										</p>
									</>
								)
							}
						/>
						<ContractDetailItem
							title='Zenland fee payer:'
							data={
								isFormsEditable ? (
									<div className={styles.payersSelect}>
										{
											<>
												<ShowZenlandFee
													payer={formsValue.payer}
													commission={getZenCommission(+formsValue.price)}
												/>
												<Select
													defaultOption={payerOption}
													options={Object.values(payerOptions)}
													setOption={handlePayerChange}
													className={styles.editingSelect}
												/>
											</>
										}
									</div>
								) : (
									<ShowZenlandFee
										payer={contract.zen_commission_payer}
										commission={contract.zen_commission}
									/>
								)
							}
						/>
						<ContractDetailItem
							title='Agreed amount:'
							data={
								isFormsEditable ? (
									<div className={styles.editInputWrapper}>
										<Input
											onChange={handleInputsChange('price')}
											value={formsValue.price}
											disabled={!isFormsEditable}
											className='editInput'
											type='number'
											id='contract-value'
										/>
									</div>
								) : (
									contract.price + ' ' + contract.token.name
								)
							}
						/>
					</div>
				</div>
			</dl>
			<Tooltip id='contractDetailsTooltip' />
		</form>
	);
};

const ContractDetailItem = ({
	title,
	data,
	className = '',
	isUppercase = false,
	isHasLink = false,
	contractNetwork,
	contractTokenAddress,
	contractBuyer
}: {
	title: string | JSX.Element;
	data: string | JSX.Element;
	className?: string;
	isUppercase?: boolean;
	isHasLink?: boolean;
	contractNetwork?: INetwork;
	contractTokenAddress?: string;
	contractBuyer?: string;
}) => {
	return (
		<div className={className}>
			<dt className={cn(styles.infoTitle, 'body-md')}>{title}</dt>
			<dd
				className={cn(styles.infoContent, {
					[styles.upperCase]: isUppercase
				})}
			>
				{data || 'N/A'}
				{isHasLink && contractNetwork && (
					<a
						href={`${contractNetwork.explorer_url}/token/${contractTokenAddress}?a=${contractBuyer}`}
						target='_blank'
						rel='noopener noreferrer'
						className={styles.contractTokenAddress}
					>
						<SvgSprite iconId='tokenAddress' />
					</a>
				)}
			</dd>
		</div>
	);
};

import { API } from '@core/config/api';
import { FetchOptions, NotificationResponse } from '@core/models/interfaces';
import { getSearchParams } from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Notifications = {
	async getAll<T>(
		filters: {
			[K in keyof T]: string | number | boolean;
		},
		isSystem = false,
		options?: FetchOptions
	): Promise<NotificationResponse | void> {
		let url = `${API.notifications}?is_system=${isSystem}`;
		try {
			if (filters) {
				const assignedParams = new URLSearchParams(getSearchParams(filters));
				url += `&${assignedParams}`;
			}

			const response = await fetch(url, {
				credentials: 'include',
				headers: {
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},

	async markAsRead(
		ids?: number[]
	): Promise<{ ids: number[]; success: boolean } | void> {
		let response: Response;
		try {
			if (ids && ids.length) {
				response = await fetch(API.markAsRead, {
					method: 'POST',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'X-CSRFToken': getCookie('csrftoken')
					},
					body: JSON.stringify({ ids })
				});
			} else {
				response = await fetch(API.markAllAsRead, {
					method: 'POST',
					credentials: 'include',
					headers: {
						'X-CSRFToken': getCookie('csrftoken')
					}
				});
			}

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

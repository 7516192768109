import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import {
	TCheckboxes,
	TCheckboxList
} from '@components/MainComponents/Checkboxes/Checkboxes.types';

import styles from './Checkboxes.module.scss';

export const Checkboxes = ({
	list,
	defaultCheckedList,
	setCheckedList,
	placeholder,
	availableCheckList,
	availableSupportedCheckList,
	size = 'sm',
	borderless,
	isDisableDefaultChecked = false,
	disableByIndex = -1,
	isSingleSelect = false,
	isDisplayAsIcons = false,
	isAddIcons = false,
	isReverseLayout = false,
	borderHidden = false,
	labelHasTitle = false,
	className
}: TCheckboxes) => {
	// React hooks
	const [checkedElements, setCheckedElements] = useState<TCheckboxList[]>(
		[] as TCheckboxList[]
	);
	const [defaultCheckedElements, setDefaultCheckedElements] = useState<
		TCheckboxList[] | null | undefined
	>();
	const [focused, setFocused] = useState<TCheckboxList['value']>('');

	useEffect(() => {
		if (defaultCheckedList?.length) {
			setDefaultCheckedElements(defaultCheckedList);
		}
	}, []);

	useEffect(() => {
		setCheckedList(checkedElements);

		if (!list.length && checkedElements.length) {
			setCheckedElements([]);
			setFocused('');

			if (!defaultCheckedElements?.length) setDefaultCheckedElements([]);
			else setCheckedElements(defaultCheckedElements);
		}
	}, [checkedElements, list]);

	useEffect(() => {
		if (defaultCheckedElements?.length)
			setCheckedElements(defaultCheckedElements);
	}, [defaultCheckedElements]);

	// remove tokens using availableCheckList if network unchecked
	useEffect(() => {
		if (availableCheckList?.length)
			setCheckedElements(prevState =>
				prevState.filter(el => availableCheckList.includes(+el.value))
			);
	}, [availableCheckList]);

	// Handlers
	const handleChange = (el: TCheckboxList) => () => {
		if (isSingleSelect) return setCheckedElements([el]);
		const _index = checkedElements.findIndex(
			element => element.value === el.value
		);
		if (_index >= 0)
			setCheckedElements(checkedElements.filter((_, i) => i !== _index));
		else setCheckedElements([...checkedElements, el]);
	};

	const handleKeyUp =
		(value: TCheckboxList['value']) => (e: React.KeyboardEvent) => {
			if (e.key === 'Tab') setFocused(value);
		};

	const handleBlur = () => {
		setFocused('');
	};

	return (
		<div
			className={cn(
				styles.container,
				{
					[styles.containerMd]: size === 'md'
				},
				className
			)}
		>
			{list.length > 0 ? (
				list.map((el, i) => {
					const isAvailableSupportedCheckList =
						availableSupportedCheckList?.length &&
						!availableSupportedCheckList.includes(+el.value);
					const isChecked =
						checkedElements.findIndex(element => element.value === el.value) >=
						0;
					const isCheckedByDefault =
						defaultCheckedElements &&
						defaultCheckedElements.findIndex(
							element => element.value === el.value
						) >= 0;
					const isDisabledByIndex = disableByIndex >= 0 && disableByIndex === i;

					if (isAvailableSupportedCheckList) return;
					return (
						<label
							className={cn(styles.label, {
								[styles.isReverseLayout]: isReverseLayout,
								[styles.checked]: isChecked,
								[styles.focused]: focused === el.value,
								[styles.md]: size === 'md',
								[styles.borderLess]: borderless,
								[styles.borderHidden]: borderHidden,
								[styles.iconShape]: isDisplayAsIcons,
								[styles.disabled]: isCheckedByDefault && isDisableDefaultChecked
							})}
							key={el.value}
							title={labelHasTitle ? el.name : ''}
						>
							{isAddIcons || isDisplayAsIcons ? (
								isDisplayAsIcons ? (
									<img
										src={el.image}
										alt={el.name}
										className={cn(styles.icon, {
											[styles.iconChecked]: isChecked
										})}
									/>
								) : (
									<>
										<img
											src={el.image}
											alt={el.name}
											className={cn(styles.icon, styles.iconWithText, {
												[styles.iconChecked]: isChecked
											})}
										/>
										<p
											className={cn({
												['body']: size === 'md',
												['body-md']: size === 'sm',
												['caption']: size === 'extra-sm'
											})}
										>
											{el.name}
										</p>
									</>
								)
							) : (
								<p
									className={cn({
										['body']: size === 'md',
										['body-md']: size === 'sm'
									})}
								>
									{el.name}
								</p>
							)}
							<div
								className={cn(styles.inputWrapper, {
									[styles.inputWrapperMd]: size === 'md',
									[styles.inputHidden]: isAddIcons || isDisplayAsIcons
								})}
							>
								<input
									type='checkbox'
									value={el.value}
									onChange={handleChange(el)}
									onKeyUp={handleKeyUp(el.value)}
									onBlur={handleBlur}
									checked={isChecked}
									className={cn(styles.input, {
										[styles.unCheckedDisable]: isDisabledByIndex
									})}
									disabled={
										(!!isCheckedByDefault && isDisableDefaultChecked) ||
										isDisabledByIndex
									}
								/>
								<div className={styles.layer} />
							</div>
						</label>
					);
				})
			) : (
				<p className='body-md'>{placeholder}</p>
			)}
		</div>
	);
};

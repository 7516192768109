import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { addAlert } from '@core/store/alert/alert.thunks';
import { setItemListAction } from '@core/store/item/item.slice';
import { getItemsThunk } from '@core/store/item/item.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { IItemList } from '@pages/Dashboard/Items/Item.props';
import { SearchForm } from '@components/DashboardComponents';
import { ProductCard } from '@components/ProductComponents';
import { ProductsSkeleton } from '@skeletons/profile/ProductsSkeleton';

import { ProductsProps, ProfileTabTypes } from '../Profile.types';

import styles from '@pages/Dashboard/Profile/Profile.module.scss';

export const Products = ({ address, handleNavBtnClick }: ProductsProps) => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [items, totalCount] = useAppSelector(({ items }) => [
		items.products,
		items.counts.total_count
	]);

	/* React hooks */
	const [profileProducts, setProfileProducts] = useState<IItemList[] | null>(
		null
	);
	const [searchText, setSearchText] = useState('');
	const [offset, setOffset] = useState(0);
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		handleNavBtnClick(ProfileTabTypes.Products);
		document.addEventListener('scroll', handleScroll, true);
		return () => {
			document.removeEventListener('scroll', handleScroll, true);

			setOffset(0);
			setProfileProducts(null);
			dispatch(setItemListAction(null));
		};
	}, []);

	useEffect(() => {
		if (items && isFetching && !searchText) {
			setIsFetching(false);
			setProfileProducts(prevState => {
				if (prevState) return [...prevState, ...items];
				return [...items];
			});
		} else {
			setProfileProducts(items);
		}
	}, [items]);

	useEffect(() => {
		setProfileProducts(null);

		const res = dispatch(
			getItemsThunk({
				params: { seller__username: address, is_active: true, limit: 10 }
			})
		);
		setOffset(0);

		return () => res.abort();
	}, [address]);

	useEffect(() => {
		if (isFetching && profileProducts) {
			if (totalCount > profileProducts.length && !searchText) {
				dispatch(
					getItemsThunk({
						params: {
							offset,
							limit: 10,
							seller__username: address,
							is_active: true
						}
					})
				);
			} else {
				dispatch(addAlert({ text: 'All products loaded', type: 'info' }));
			}
		}
	}, [isFetching]);

	useEffect(() => {
		profileProducts && setOffset(profileProducts.length);
	}, [profileProducts]);

	/* Handlers */
	const handleSearchSubmit = async (value: string) => {
		if (value) {
			setSearchText(value);
			await dispatch(
				getItemsThunk({
					params: { search: value, seller__username: address, is_active: true }
				})
			);
		} else {
			setSearchText('');
			setProfileProducts(null);

			const res = dispatch(
				getItemsThunk({
					params: { seller__username: address, is_active: true, limit: 10 }
				})
			);
			setOffset(0);

			return () => res.abort();
		}
	};

	if (!profileProducts) return <ProductsSkeleton hasHeader={false} />;

	const renderProducts = () => {
		return profileProducts.length ? (
			profileProducts.map((product, index) => (
				<ProductCard
					product={product}
					hasHeader={false}
					key={index}
					address={address}
				/>
			))
		) : (
			<p className={cn('h5', styles.emptyText)}>No products to display</p>
		);
	};

	function handleScroll(e: Event) {
		const { target } = e;
		if (target instanceof HTMLElement) {
			if (target.scrollHeight - (target.scrollTop + window.innerHeight) < 5) {
				setIsFetching(true);
			}
		}
	}

	return (
		<>
			<div className={styles.profileWidgets}>
				<SearchForm
					onSubmit={handleSearchSubmit}
					className={styles.searchForm}
				/>
			</div>
			<div className={styles.products}>{renderProducts()}</div>
		</>
	);
};

import ContentLoader from 'react-content-loader';

export const ProfileHeadSkeleton = () => (
	<ContentLoader
		speed={2}
		width={126}
		height={254}
		viewBox='0 0 126 254'
		backgroundColor='#e3e9ef'
		foregroundColor='#fff'
	>
		<circle cx='65' cy='62' r='59' />
		<rect x='0' y='134' rx='10' ry='10' width='100%' height='24' />
		<rect x='0' y='169' rx='10' ry='10' width='100%' height='18' />
	</ContentLoader>
);

export enum NotificationCommand {
	NewNotification = 'new_notification'
}

export enum NotificationModel {
	Contracts = 'contract',
	Chat = 'message',
	Wallet = 'wallet',
	UserRating = 'userrating',
	Transaction = 'transaction',
	Marketplace = 'product',
	ZenfTransaction = 'zentokenwallettransaction',
	Referrals = 'referralwallet'
}

export enum NotificationLevel {
	Info = 'info',
	Warning = 'warning',
	Error = 'error',
	Success = 'success'
}

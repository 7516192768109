import React from 'react';
import cn from 'classnames';
import Moment from 'react-moment';

import { WalletStatus, WalletType } from '@core/models/enums';
import { IWalletTransaction } from '@core/models/interfaces';
import { setWalletFilters } from '@core/store/wallet/wallet.slice';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { Pagination } from '@components/DashboardComponents';
import { Badge, Button, SvgSprite } from '@components/index';

import styles from './Wallet.module.scss';

export const HistoryList = () => {
	/* Redux hooks */
	const wallet = useAppSelector(({ wallet }) => wallet);
	const dispatch = useAppDispatch();

	/* Handlers */
	const handlePageChange = (page: number) => {
		const endOffset = wallet.filters.limit * page;
		const startOffset = endOffset - wallet.filters.limit;

		dispatch(setWalletFilters({ ...wallet.filters, offset: startOffset }));
	};

	const handleSortClick = (ordering: string) => () => {
		if (wallet.filters.ordering === ordering) {
			dispatch(
				setWalletFilters({
					...wallet.filters,
					ordering: `-${ordering}`,
					offset: 0
				})
			);
		} else {
			dispatch(
				setWalletFilters({
					...wallet.filters,
					offset: 0,
					ordering
				})
			);
		}
	};

	if (!wallet.transactions.count) return <p data-testid='empty'>Not found</p>;

	const mappedList = wallet.transactions.list.map(item => {
		return <HistoryItem item={item} key={item.id} />;
	});

	return (
		<>
			<div className={cn(styles.historyHead, 'body-md')}>
				<Button
					onClick={handleSortClick('created')}
					className={cn({
						[styles.active]: wallet.filters.ordering === 'created'
					})}
					type='button'
				>
					ID
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleSortClick('value')}
					className={cn({
						[styles.active]: wallet.filters.ordering === 'value'
					})}
					type='button'
				>
					Amount
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleSortClick('running_balance')}
					className={cn(
						{
							[styles.active]: wallet.filters.ordering === 'running_balance'
						},
						styles.sortingByBalance
					)}
					type='button'
				>
					Running Balance
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleSortClick('created')}
					className={cn({
						[styles.active]: wallet.filters.ordering === 'created'
					})}
					type='button'
				>
					Date
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleSortClick('type')}
					className={cn({
						[styles.active]: wallet.filters.ordering === 'type'
					})}
					type='button'
				>
					Type
					<SvgSprite iconId='arrow-down' />
				</Button>
				<Button
					onClick={handleSortClick('status')}
					className={cn(
						{
							[styles.active]: wallet.filters.ordering === 'status'
						},
						styles.sortingByStatus
					)}
					type='button'
				>
					Status
					<SvgSprite iconId='arrow-down' />
				</Button>
			</div>
			<ul className={cn('subtitle', styles.list)}>{mappedList}</ul>
			<div className={styles.pagination}>
				<Pagination
					currentPage={wallet.filters.offset / wallet.filters.limit + 1}
					totalCount={wallet.transactions.count}
					pageSize={wallet.filters.limit}
					onPageChange={handlePageChange}
					siblingCount={1}
				/>
			</div>
		</>
	);
};

const HistoryItem = ({ item }: { item: IWalletTransaction }) => {
	const itemStatusColor = (status: WalletStatus) => {
		switch (status) {
			case WalletStatus.Created:
				return 'primary';
			case WalletStatus.Confirmed:
				return 'success';
			case WalletStatus.Failed:
				return 'warn';
			case WalletStatus.Pending:
				return 'await';
		}
	};
	return (
		<li className={styles.listItem} key={item.id}>
			<div className={styles.listItemInfo}>
				<p className={styles.listTitle}>ID</p>
				<span>{item.id}</span>
			</div>
			<hr className={cn(styles.divider, styles.dividerAfterId)} />
			<div className={styles.listItemInfo}>
				<p className={styles.listTitle}>Amount</p>
				<span>$ {item.value}</span>
			</div>
			<div className={styles.listItemInfo}>
				<p className={styles.listTitle}>Running Balance</p>
				<span>$ {item.running_balance}</span>
			</div>
			<div className={styles.listItemInfo}>
				<p className={styles.listTitle}>Date</p>
				<span className={styles.dateCell}>
					<Moment className='body-md' format='DD/MM/YYYY'>
						{item.created}
					</Moment>
					<Moment className='caption' format='HH:mm'>
						{item.created}
					</Moment>
				</span>
			</div>
			<div className={cn(styles.listItemInfo)}>
				<p className={styles.listTitle}>Type</p>
				<span className='body'>
					{item.type === WalletType.Debit ? 'Deposit' : 'Spent'}
				</span>
			</div>
			<Badge
				color={itemStatusColor(item.status)}
				className={cn(styles.badge, 'body-md')}
			>
				{item.status}
			</Badge>
		</li>
	);
};

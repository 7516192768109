import React, { useState } from 'react';
import cn from 'classnames';

import { Button, Input } from '@components/index';

import styles from './Widget.module.scss';

export const AddressSearchWidget = ({
	onSubmit,
	minLength,
	className,
	isLoading
}: {
	onSubmit: (value: string) => void;
	minLength: number;
	className?: string;
	isLoading: boolean;
}) => {
	/* React hooks */
	const [value, setValue] = useState('');

	/* Handlers */
	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onSubmit(value.trim());
	};

	/* Handlers */
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		if (!e.target.value) onSubmit('');
	};

	return (
		<form className={cn(className, styles.searchForm)} onSubmit={handleSubmit}>
			<Input
				onChange={handleChange}
				value={value}
				className={styles.searchInput}
				type='search'
				largeness='md'
				placeholder='Search'
			/>
			<Button
				variant='contained'
				size='small'
				color='primary'
				disabled={value.length < minLength}
				className={styles.searchBtn}
				isLoader={isLoading}
			>
				Start Chat
			</Button>
		</form>
	);
};

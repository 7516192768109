import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { ContractStatusNumber, WSReadyState } from '@core/models/enums';
import { IChatRoomMember } from '@core/store/chat/chat.types';
import { chatMessagesReceived } from '@core/store/ws/chat/wsChat.slice';
import { getChatMessages } from '@core/store/ws/chat/wsChat.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { AvatarInfo, StackedAvatars } from '@components/DashboardComponents';
import { Badge, SvgSprite, Tooltip } from '@components/index';

import { AddMessageForm } from './AddMessageForm';
import { ChatProps } from './Chat.props';
import { Messages } from './Messages';

import styles from './Chat.module.scss';

export const ContractChat = ({ className }: ChatProps) => {
	/* Redux hooks */
	const [contract, chatStatus, room, whoAmI] = useAppSelector(
		({ contracts, wsChat, room, auth }) => [
			contracts.current,
			wsChat.status,
			room,
			auth.whoAmI
		]
	);
	const dispatch = useAppDispatch();

	/* React hooks */
	const [members, setMembers] = useState<IChatRoomMember[] | undefined>();

	useEffect(() => {
		return () => {
			dispatch(chatMessagesReceived(null));
		};
	}, []);

	useEffect(() => {
		if (room?.contract) {
			const filteredMembers = room.companions.filter(
				member =>
					member.username !== whoAmI.username && member.username !== 'system'
			);
			setMembers(filteredMembers);
		}
	}, [room]);

	useEffect(() => {
		if (chatStatus === WSReadyState.Open) dispatch(getChatMessages());
	}, [chatStatus]);

	if (!contract.data) return <>Loading</>;
	return (
		<div className={cn(styles.chat, className)} data-testid='chat'>
			<header className={styles.header}>
				{ContractStatusNumber[contract.data.status] >=
					ContractStatusNumber.agent_invited &&
				whoAmI.username === contract.data.agent.username ? (
					members && <StackedAvatars list={members} />
				) : (
					<>
						{members && (
							<AvatarInfo
								item={
									members.find(
										m => m.username !== contract.data?.agent.username
									) || members[0]
								}
							/>
						)}
						{ContractStatusNumber[contract.data.status] ===
						ContractStatusNumber.agent_invited ? (
							<Badge
								className={styles.agentInvitedBadge}
								text='md'
								color='danger'
							>
								Agent invited
								<SvgSprite iconId='justice' />
							</Badge>
						) : (
							''
						)}
					</>
				)}
			</header>
			<Messages agentAddress={contract.data.agent.username} />
			{ContractStatusNumber[contract.data.status] >=
				ContractStatusNumber.executed || contract.isCurrentExpired ? (
				<p className={cn('subtitle', styles.closedInputInfo)}>
					Contract has been executed or expired. Messaging is now disabled.
				</p>
			) : ContractStatusNumber[contract.data.status] !=
					ContractStatusNumber.agent_invited &&
			  whoAmI.username === contract.data.agent.username ? (
				<p className={cn('subtitle', styles.closedInputInfo)}>
					Awaiting Invitation for Escrow Settlement
				</p>
			) : (
				<AddMessageForm />
			)}
			<Tooltip id='chatTooltip' place='top' />
		</div>
	);
};

import React, { useRef } from 'react';
import moment, { Moment } from 'moment';
import Datetime from 'react-datetime';

import { DateTimeProps } from './DateTime.props';

export const DateTime = ({
	dateFormat = 'DD.MM.YYYY',
	timeFormat = 'HH:mm',
	className,
	onChange,
	inputProps,
	...props
}: DateTimeProps) => {
	// ref for check input value
	const inputDataTime = useRef<HTMLInputElement | null>(null);

	const checkDateTimeInput = (current: Moment) => {
		const yesterday = moment().subtract(1, 'day');
		return current.isAfter(yesterday);
	};

	const handleChangeInputDataTime = () => {
		if (!inputDataTime.current) return;
		const inputValue = inputDataTime.current.value
			.replace(/\D/g, '')
			.match(/(\d{0,2})(\d{0,2})(\d{0,4})(\d{0,2})(\d{0,2})/);
		if (!inputValue) return;
		inputDataTime.current.value = !inputValue[2]
			? inputValue[1]
			: `${inputValue[1]}.${inputValue[2]}${`${
					inputValue[3] ? `.${inputValue[3]}` : ''
			  }`}${`${inputValue[4] ? ` ${inputValue[4]}:${inputValue[5]}` : ''}`}`;
	};

	const handleKeyDown = (e: { key: string }) => {
		if (
			inputDataTime.current &&
			e.key === 'Backspace' &&
			inputDataTime.current.value.length > 12
		) {
			inputDataTime.current.value = inputDataTime.current.value.substring(
				0,
				inputDataTime.current.value.length - 1
			);
		}
	};
	return (
		<Datetime
			dateFormat={dateFormat}
			timeFormat={timeFormat}
			isValidDate={checkDateTimeInput}
			className={className}
			onChange={onChange}
			inputProps={{
				maxLength: 16,
				name: 'date',
				id: 'date',
				title: 'datetime-local',
				type: 'text',
				ref: inputDataTime,
				onKeyDown: handleKeyDown,
				onChange: handleChangeInputDataTime,
				...inputProps
			}}
			{...props}
		/>
	);
};

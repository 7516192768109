import React from 'react';
import cn from 'classnames';

import { ContractRole } from '@core/models/enums';
import { useAppSelector } from '@core/utils/hooks';
import { ShowCount } from '@components/DashboardComponents';
import { Button } from '@components/index';

import { FilterNavProps } from './FilterNav.props';

import styles from './FilterNav.module.scss';

export const FilterNav = <
	ObjectType extends {
		name: ObjectType[keyof ObjectType];
		value: FuncParamType;
		count: number;
	},
	FuncParamType
>({
	filterList,
	handleChange,
	count,
	offset,
	status
}: FilterNavProps<ObjectType, FuncParamType>) => {
	const { role } = useAppSelector(state => state.auth);
	const currentCountsStart = count && offset + 1;
	const currentCountsEnd =
		count - currentCountsStart > 10 ? currentCountsStart + 9 : count;

	const renderFilters = () => {
		// Conditional logic based on role
		if (
			(filterList.length > 6 && role === ContractRole.Agent) ||
			role === ContractRole.Zen_Agent
		) {
			return filterList
				.filter((_, index) => [0, 6, 7].includes(index))
				.map((item, index) => {
					const originalIndex = filterList.findIndex(
						originalItem => originalItem === item
					);

					return (
						<li key={`${originalIndex}`}>
							<Button
								onClick={handleChange(item.value)}
								className={cn(styles.filterBtn, 'body-md', {
									[styles.active]:
										item.value === status || (!status && !item.value)
								})}
								disabled={item.value === status || (!status && !item.value)}
								type='button'
							>
								{originalIndex === 6
									? 'Open Cases'
									: originalIndex === 7
									? 'Close Cases'
									: 'All Contracts'}
								<span className={styles.filterCount}>{item.count}</span>
							</Button>
						</li>
					);
				});
		} else {
			return filterList.map((item, index) => {
				return (
					<li key={`${index}`}>
						<Button
							onClick={handleChange(item.value)}
							className={cn(styles.filterBtn, 'body-md', {
								[styles.active]:
									item.value === status || (!status && !item.value)
							})}
							disabled={item.value === status || (!status && !item.value)}
							type='button'
						>
							{item.name}
							<span className={styles.filterCount}>{item.count}</span>
						</Button>
					</li>
				);
			});
		}
	};

	return (
		<nav className={styles.contractNav}>
			<ul className={styles.contractFilters}>{renderFilters()}</ul>
			<ShowCount start={currentCountsStart} end={currentCountsEnd} />
		</nav>
	);
};

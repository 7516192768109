import { TransactionType } from '@core/models/enums';
import { ITransactions } from '@core/store/transaction/transaction.types';

export const getTxByType = (
	transactions: ITransactions[],
	type: TransactionType
) => {
	const index = transactions.findIndex(t => t.type === type);
	return index > -1 ? transactions[index] : null;
};

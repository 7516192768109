import { API } from '@core/config/api';
import { FetchOptions } from '@core/models/interfaces';
import { IItemFilter } from '@core/models/interfaces/itemInterfaces/itemInterfaces';
import { getSearchParams } from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';
import { IItemList } from '@pages/Dashboard/Items/Item.props';

export const Products = {
	async post(formData: FormData): Promise<void | IItemList> {
		try {
			const response = await fetch(API.products, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'X-CSRFToken': getCookie('csrftoken')
				},
				body: formData
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async get(slug: string, options?: FetchOptions): Promise<IItemList | void> {
		try {
			const response = await fetch(`${API.products}${slug}/`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async getAll(queryParams: Partial<IItemFilter> = {}, options?: FetchOptions) {
		const assignedParams = new URLSearchParams(getSearchParams(queryParams));
		try {
			const response = await fetch(`${API.products}?${assignedParams}`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());
			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async patch({
		slug,
		formData
	}: {
		slug: string;
		formData: FormData;
		options?: FetchOptions;
	}): Promise<void | IItemList> {
		try {
			const response = await fetch(`${API.products}${slug}/`, {
				method: 'PATCH',
				credentials: 'include',
				headers: {
					'X-CSRFToken': getCookie('csrftoken')
				},
				body: formData
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

import React, { useEffect, useState } from 'react';

import { IToken } from '@core/models/interfaces';
import { Checkboxes } from '@components/MainComponents/Checkboxes/Checkboxes';
import { TCheckboxList } from '@components/MainComponents/Checkboxes/Checkboxes.types';

export const CheckboxTokensItemDetail = ({
	tokens,
	checkedTokensId,
	checkedNetworks,
	setCheckedTokens
}: {
	tokens: IToken[];
	checkedTokensId: (string | number)[];
	checkedNetworks: TCheckboxList[];
	setCheckedTokens: React.Dispatch<React.SetStateAction<TCheckboxList[]>>;
}) => {
	const [checkedList, setCheckedList] = useState<TCheckboxList[]>([
		{ value: '', name: '' }
	]);

	useEffect(() => {
		setCheckedTokens(checkedList);
	}, [checkedList]);

	const checkedNetworksChainId = checkedNetworks.map(
		checkedNetwork => checkedNetwork.value
	);

	const list = tokens
		.filter(token => checkedNetworksChainId.includes(token.network.chain_id))
		.map(token => ({
			value: token.id,
			name: token.name,
			image: token.network.image
		}));

	const defaultCheckedList = tokens
		.filter(token => checkedTokensId.includes(token.id))
		.map(token => ({
			value: token.id,
			name: token.name
		}));

	return (
		<Checkboxes
			list={list}
			setCheckedList={setCheckedList}
			defaultCheckedList={defaultCheckedList}
			isAddIcons
			borderless
		/>
	);
};

import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { addAlert } from '@core/store/alert/alert.thunks';
import { setSearchCompanion } from '@core/store/chat/chat.slice';
import { foundRoomThunk } from '@core/store/chat/chat.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { AddressSearchWidget } from '@components/DashboardComponents/Dialog/Widget/AddressSearchWidget';

import styles from './Dialog.module.scss';

export const DialogWidgets = () => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [companion, foundRoom, username, nickname] = useAppSelector(
		({ chat, auth }) => [
			chat.searchCompanion,
			chat.foundRoom,
			auth.whoAmI.username,
			auth.whoAmI.nickname
		]
	);

	/* React hooks */
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		(async () => {
			if (companion) {
				await dispatch(foundRoomThunk(companion));
				setIsLoading(false);
				dispatch(setSearchCompanion(''));
			}
		})();
	}, [companion]);

	const handleSubmit = (value: string) => {
		if (value.length >= 1 && value.length < 5)
			return dispatch(
				addAlert({
					text: 'Nickname must be more than 5 symbols if you typed address, address must be 40 symbols',
					type: 'error'
				})
			);

		if (
			(nickname && nickname.toLowerCase() === value.toLowerCase()) ||
			value.toLowerCase() === username.toLowerCase()
		)
			return dispatch(
				addAlert({
					text: 'You cannot chat with yourself',
					type: 'error',
					isSubmit: true
				})
			);

		if (value.length >= 5) {
			dispatch(setSearchCompanion(value));
			setIsLoading(true);
		}
	};
	return (
		<div className={styles.dialogWidgets}>
			<AddressSearchWidget
				onSubmit={handleSubmit}
				minLength={5}
				isLoading={isLoading}
			/>
			{foundRoom?.id && <Redirect to={`/chats/${foundRoom.id}`} />}
		</div>
	);
};

import { createAsyncThunk } from '@reduxjs/toolkit';

import { Reputation } from '@core/services/reputation/reputation';
import { addReputationData } from '@core/store/reputation/reputation.slice';

export const setReputation = createAsyncThunk<void, { address: string }>(
	'reputation/setData',
	async (payload, { dispatch, signal }) => {
		if (payload.address) {
			const data = await Reputation.getData(payload.address, { signal });

			if (data) dispatch(addReputationData(data));
			else dispatch(addReputationData(null));
		}
	}
);

import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { NotificationModel } from '@core/models/enums';
import { addAlert } from '@core/store/alert/alert.thunks';
import {
	setChatRooms,
	setFoundChatRoom,
	setSearchCompanion
} from '@core/store/chat/chat.slice';
import { roomIdThunk, roomsThunk } from '@core/store/chat/chat.thunks';
import { lastNewNotificationReceived } from '@core/store/notification/notification.slice';
import { getRoomByIdThunk } from '@core/store/room/room.thunks';
import {
	startChatMessagesListening,
	stopChatMessagesListening
} from '@core/store/ws/chat/wsChat.thunks';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { useWindowMatchMedia } from '@core/utils/hooks/useWindowMatchMedia';
import { DialogsListProps } from '@pages/Dashboard/Chats/Chats.props';
import { Chat } from '@components/DashboardComponents/Chat/Chat';
import { DialogsList } from '@components/DashboardComponents/Dialog/List';
import { DialogWidgets } from '@components/DashboardComponents/Dialog/Widgets';

import styles from './Chats.module.scss';

export const Chats = () => {
	/* React router dom hooks */
	const params: { id: string; companion: string } = useParams();

	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [rooms, foundRoom, room, lastNewNotification, username, nickname] =
		useAppSelector(({ chat, room, notification, auth }) => [
			chat.rooms,
			chat.foundRoom,
			room,
			notification.lastNewNotification,
			auth.whoAmI.username,
			auth.whoAmI.nickname
		]);

	/* React hooks */
	const [dialogList, setDialogList] = useState<DialogsListProps[] | null>(null);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const firstUpdate = useRef({ statement1: true });

	useEffect(() => {
		const promise = dispatch(roomsThunk());

		return () => {
			promise.abort();
			setDialogList(null);
			dispatch(setChatRooms(null));
			dispatch(stopChatMessagesListening());
		};
	}, []);

	useEffect(() => {
		let companion = params.companion;
		if (
			(nickname &&
				nickname.toLowerCase() === params.companion &&
				params.companion.toLowerCase()) ||
			(username.toLowerCase() === params.companion &&
				params.companion.toLowerCase())
		) {
			dispatch(
				addAlert({
					text: 'You cannot chat with yourself',
					type: 'error',
					isSubmit: true
				})
			);
			companion = '';
		}
		dispatch(setSearchCompanion(companion));
	}, []);

	useEffect(() => {
		if (
			lastNewNotification?.action_object_content_type?.model ===
				NotificationModel.Chat &&
			!lastNewNotification.data.is_contract_chat &&
			lastNewNotification?.target_object_id
		) {
			dispatch(roomIdThunk(lastNewNotification.target_object_id));
			dispatch(lastNewNotificationReceived(null));
		}
	}, [lastNewNotification]);

	useEffect(() => {
		if (rooms.data)
			setDialogList(
				rooms.data.map(room => {
					return {
						roomId: room.id,
						member: room.companions[0],
						lastActivity: room.last_activity
					};
				})
			);
	}, [rooms]);

	useEffect(() => {
		if (params.id && room?.id !== +params.id) {
			const promise = dispatch(getRoomByIdThunk(+params.id));

			return () => {
				promise.abort();
			};
		}

		return () => {
			if (!firstUpdate.current.statement1)
				dispatch(stopChatMessagesListening());
			firstUpdate.current.statement1 = false;
			if (foundRoom) dispatch(setFoundChatRoom(null));
		};
	}, [params.id]);

	useEffect(() => {
		if (room?.id) dispatch(startChatMessagesListening(room.id));
	}, [room?.id]);

	/* React Custom hooks */
	useWindowMatchMedia(1200, setIsCollapsed);

	return (
		<div className={cn(styles.chatsPage, 'pageWrapper')} data-testid='chats'>
			<h1 className={'h5'}>Chat</h1>
			<div className={styles.chatBlocks}>
				{(!isCollapsed || !params.id) && (
					<nav
						className={cn(styles.chatsNav, {
							[styles.boundedChatsNav]: !isCollapsed
						})}
					>
						<DialogWidgets />
						<DialogsList rooms={dialogList} isLoading={rooms.isLoading} />
					</nav>
				)}
				{(!isCollapsed || params.id) && (
					<div
						className={cn(styles.chatWrapper, {
							[styles.chatWrapperLoading]: !room
						})}
					>
						<Chat
							className={styles.chat}
							chatId={+params.id}
							isCollapseMode={isCollapsed}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

import React, { useEffect, useState } from 'react';

import { ContractStatusName } from '@core/models/enums';
import { setContractAllFilters } from '@core/store/contract/contract.slice';
import { getCountsByStatus } from '@core/utils/helpers/getCountByStatus';
import { getURLSearchParams } from '@core/utils/helpers/getURLSearchParams';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { FilterNav } from '@components/DashboardComponents';
import { Select } from '@components/MainComponents/Select/Select';

import { ContractFilterList, ContractsNavProps } from './Contract.props';

import styles from './Contract.module.scss';

export const ContractsNav = ({ setCurrentPage }: ContractsNavProps) => {
	/* Redux hooks */
	const dispatch = useAppDispatch();
	const [contractsFilter, contracts] = useAppSelector(
		({ contractsFilter, contracts }) => [contractsFilter, contracts]
	);
	const renderFilterCount = (value?: ContractStatusName) => {
		if (!value) return contracts.counts.total_count;
		const key = getCountsByStatus(value);
		return contracts.counts[key];
	};
	const filterList: ContractFilterList[] = [
		{
			name: 'All',
			value: '',
			count: renderFilterCount()
		},
		{
			name: 'Draft',
			value: ContractStatusName.Draft,
			count: renderFilterCount(ContractStatusName.Draft)
		},
		{
			name: 'Approved',
			value: ContractStatusName.Approved,
			count: renderFilterCount(ContractStatusName.Approved)
		},
		{
			name: 'Deployed',
			value: ContractStatusName.Deployed,
			count: renderFilterCount(ContractStatusName.Deployed)
		},
		{
			name: 'Active',
			value: ContractStatusName.Active,
			count: renderFilterCount(ContractStatusName.Active)
		},
		{
			name: 'Fulfilled',
			value: ContractStatusName.Fulfilled,
			count: renderFilterCount(ContractStatusName.Fulfilled)
		},
		{
			name: 'Disputed',
			value:
				`${ContractStatusName.Disputed},${ContractStatusName.AgentInvited}` as ContractStatusName,
			count: renderFilterCount(ContractStatusName.Disputed)
		},
		{
			name: 'Executed',
			value:
				`${ContractStatusName.Executed},${ContractStatusName.DisputeFinished}` as ContractStatusName,
			count: renderFilterCount(ContractStatusName.Executed)
		}
	];

	/* Handlers */
	const handleClick = (value: ContractStatusName | '') => () => {
		setCurrentPage(1);
		if (value) {
			let status = value;
			switch (value) {
				case ContractStatusName.Disputed:
					status =
						`${ContractStatusName.Disputed},${ContractStatusName.AgentInvited}` as ContractStatusName;
					break;
				case ContractStatusName.Executed:
					status =
						`${ContractStatusName.Executed},${ContractStatusName.DisputeFinished}` as ContractStatusName;
					break;
			}
			dispatch(
				setContractAllFilters({
					...contractsFilter,
					offset: 0,
					status__in: status
				})
			);
		} else {
			return dispatch(
				setContractAllFilters({ ...contractsFilter, offset: 0, status__in: '' })
			);
		}
	};

	const filterListDropdown: { name: string; value: ContractStatusName | '' }[] =
		filterList.map(item => {
			return {
				name: `${item.name} <data>${item.count}</data>`,
				value: item.value
			};
		});

	/* React hooks */
	const [filterOption, setFilterOption] = useState(filterListDropdown[0]);
	useEffect(() => {
		const urlSearchParams = getURLSearchParams();
		const urlSearchParamsKeys = Object.keys(getURLSearchParams());

		if (urlSearchParamsKeys.includes('status__in')) {
			const defaultValue =
				filterListDropdown.find(
					item => item.value.toLowerCase() === urlSearchParams['status__in']
				) || filterListDropdown[0];
			setFilterOption(defaultValue);
		} else {
			setFilterOption(filterListDropdown[0]);
		}
	}, [contracts.counts.total_count]);

	/* Handler */
	const handleChangeFilterOptions = (option: {
		name: string;
		value: ContractStatusName | '';
	}) => {
		handleClick(option.value)();
		setFilterOption({ ...option, value: option.value });
	};
	return (
		<>
			<div className={styles.wideFilterNav}>
				<FilterNav<
					{
						name: string;
						value: ContractStatusName | '';
						count: number;
					},
					ContractStatusName | ''
				>
					filterList={filterList}
					handleChange={handleClick}
					count={contracts.counts.count}
					offset={contractsFilter.offset}
					status={contractsFilter.status__in}
				/>
			</div>
			<Select<ContractStatusName | ''>
				className={styles.contractFilterDropdown}
				defaultOption={filterOption}
				options={filterListDropdown}
				setOption={handleChangeFilterOptions}
				listStartSide='end'
			/>
		</>
	);
};

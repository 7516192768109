import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import AnimateHeight from 'react-animate-height';

import { IUserRating, IUserRatingCounts } from '@core/models/interfaces';
import { UserRatings } from '@core/services';
import {
	Button,
	Comment,
	ProgressBar,
	Rating,
	SvgSprite
} from '@components/index';
import { ReviewsSkeleton } from '@skeletons/profile/ReviewsSkeleton';

import { ProfileTabTypes, ReviewsProps } from '../Profile.types';

import styles from '@pages/Dashboard/Profile/Profile.module.scss';

export const Reviews = ({ user, handleNavBtnClick }: ReviewsProps) => {
	/** React hooks **/
	const [height, setHeight] = useState<string | number>(0);
	const [reviews, setReviews] = useState<{
		reviews: IUserRating[] | null;
		counts: IUserRatingCounts;
	}>({
		reviews: null,
		counts: {
			count: 0,
			count_1s: 0,
			count_2s: 0,
			count_3s: 0,
			count_4s: 0,
			count_5s: 0
		}
	});

	useEffect(() => {
		handleNavBtnClick(ProfileTabTypes.Reviews);
	}, []);

	useEffect(() => {
		(async () => {
			const reviews = await UserRatings.getAll({
				related_object_content_type__model: 'contract',
				rating__content_type__model: 'user',
				rating__object_id: user.id
			});
			if (reviews) {
				setReviews({
					counts: {
						count: reviews.count,
						count_1s: reviews.count_1s,
						count_2s: reviews.count_2s,
						count_3s: reviews.count_3s,
						count_4s: reviews.count_4s,
						count_5s: reviews.count_5s
					},
					reviews: reviews.results
				});
			}
		})();
	}, [user.username]);
	if (!reviews.reviews) return <ReviewsSkeleton />;
	if (!reviews.counts.count) {
		return <p className={cn('h5', styles.emptyText)}>No reviews to display</p>;
	}

	/** Handlers **/
	const handleToggle = () => setHeight(height === 0 ? 'auto' : 0);

	const handleProgressClick = (prop: number) => async () => {
		const body = await UserRatings.getAll({
			score: prop,
			related_object_content_type__model: 'contract',
			rating__content_type__model: 'user',
			rating__object_id: user.id
		});
		if (body) setReviews({ ...reviews, reviews: body.results });
	};

	const renderRatingBars = () => {
		return [5, 4, 3, 2, 1].map((n, i) => {
			const key = `count_${n}s` as keyof IUserRatingCounts;
			const currentCount = reviews.counts[key];
			const percentage = Math.round(
				(currentCount / reviews.counts.count) * 100
			);
			return (
				<label
					className={cn('subtitle-md', styles.averageRatingBarsLabel)}
					onClick={handleProgressClick(n)}
					key={i}
				>
					<span>{n} Star</span>
					<ProgressBar
						className={styles.averageRatingBar}
						percent={percentage}
					/>
					<span>{`${percentage}`.substring(0, 5)}%</span>
				</label>
			);
		});
	};

	const renderComments = () => {
		return reviews.reviews?.map((review, index) => (
			<Comment comment={review} key={index} />
		));
	};

	return (
		<div className={styles.reviews}>
			<div className={styles.averageRatingWrapper}>
				<Button
					onClick={handleToggle}
					className={styles.averageToggleBtn}
					type='button'
				>
					<div>
						<p className={cn('h6', styles.averageRatingNum)}>
							<span className='h4'>{user.rating.average.substring(0, 3)}</span>
							/5
						</p>
						<p className={cn('subtitle-md', styles.averageRatingCount)}>
							Based on {reviews.counts.count} Reviews
						</p>
					</div>
					<div className={styles.averageRatingStars}>
						<Rating rating={+user.rating.average} />
					</div>
					<SvgSprite
						className={cn(styles.chevronIcon, {
							[styles.active]: height
						})}
						iconId='arrow-down'
					/>
				</Button>
				<AnimateHeight duration={500} height={height}>
					<div className={styles.averageRatingBars}>{renderRatingBars()}</div>
				</AnimateHeight>
			</div>
			{renderComments()}
		</div>
	);
};

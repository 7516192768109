import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import {
	ContractStatusName,
	ContractStatusNumber,
	TransactionType
} from '@core/models/enums';
import { sendMoney } from '@core/services';
import { addAlert } from '@core/store/alert/alert.thunks';
import { getTxByType } from '@core/utils/helpers';
import { useAppDispatch, useAppSelector } from '@core/utils/hooks';
import { DetailsProps } from '@pages/Dashboard/Contracts/Contract.props';
import { CopiableLink } from '@components/DashboardComponents';
import { Badge, Button, Input, Tooltip } from '@components/index';
import { SvgSprite } from '@components/MainComponents/SvgIcon/SvgSprite';

import { StepContainer } from '../Steps/StepContainer';

import styles from '../Steps/Steps.module.scss';
import agentStyles from '@pages/Dashboard/Contracts/Contract.module.scss';

type Values = { buyer: number; seller: number; agent: number };

const toolTipsText = {
	buyer:
		'Buyer Allocation: Specify the percentage (0-99%) of the resolved amount you wish to allocate to the buyer. Ensure a fair distribution, considering the agent commission (1-3%), so the total equals 100%',
	seller:
		'Seller Allocation: Specify the percentage (0-99%) of the resolved amount you wish to allocate to the seller. Ensure a fair distribution, considering the agent commission (1-3%), so the total equals 100%',
	agent:
		'Agent Allocation: Enter a commission percentage (1-3%) based on the complexity of the resolved dispute. Tailor the rate to reflect the effort invested in solving the case, ensuring a fair compensation for your expertise'
};

export const AgentView = ({ contract }: DetailsProps) => {
	const { t } = useTranslation('common');
	const isNetworkNotMatched = useAppSelector(
		({ contracts }) => contracts.current.isNetworkNotMatched
	);

	/* Redux hooks */
	const dispatch = useAppDispatch();
	/* React hooks */
	//const [isSent, setIsSent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [values, setValues] = useState<Values>({
		buyer: 0,
		seller: 0,
		agent: 0
	});

	useEffect(() => {
		if (values.buyer + values.seller + values.agent === 100) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [values]);

	const auth = useAppSelector(({ auth }) => auth);

	/* React hooks */
	useEffect(() => {
		ReactTooltip.rebuild();
	}, [contract, auth]);

	const txByType = getTxByType(
		contract.transactions,
		TransactionType.dispute_finished
	);
	/* Handlers */
	const handleChange =
		(prop: keyof Values) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = +e.target.value;
			if (!isNaN(value) && value <= 99) setValues({ ...values, [prop]: value });
		};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (values.agent > 3) {
			setValues({ ...values, agent: 3 });
			return dispatch(
				addAlert({
					text: 'Agent value must be less than 4',
					type: 'error'
				})
			);
		} else if (values.agent < 1) {
			setValues({ ...values, agent: 1 });
			return dispatch(
				addAlert({
					text: 'Agent value must be greater than 0',
					type: 'error'
				})
			);
		}
		setIsLoading(true);
		const body = await sendMoney(
			contract.id,
			values.agent,
			values.buyer,
			values.seller
		);
		if (body) {
			//setIsSent(true);
			dispatch(
				addAlert({
					type: 'info',
					text: 'Successfully sent money',
					isSubmit: true
				})
			);
		}
		setIsLoading(false);
	};

	return (
		<StepContainer
			stepStatus={ContractStatusName.AgentInvited}
			contractStatus={ContractStatusName.AgentInvited}
			title='Contract Settlement'
			tooltip={t('app.toolTips.contracts.agent_invite')}
		>
			<div className={styles.stepContent}>
				{ContractStatusNumber[contract.status] ===
				ContractStatusNumber.agent_invited ? (
					<div className={cn(agentStyles.agentPage, 'pageWrapper')}>
						<form onSubmit={handleSubmit}>
							<div className={agentStyles.formItemList}>
								<div className={agentStyles.formItem}>
									<div className={agentStyles.formTitle}>
										<label htmlFor='buyer' className={agentStyles.label}>
											Buyer’s Percentage
										</label>
										<SvgSprite
											data-tip={toolTipsText.buyer}
											data-for='agentViewTooltip'
										/>
									</div>
									<div className={agentStyles.agentFormInput}>
										<Input
											value={values.buyer}
											largeness='md'
											id='buyer'
											onChange={handleChange('buyer')}
										/>
									</div>
								</div>
								<div className={agentStyles.formItem}>
									<div className={agentStyles.formTitle}>
										<label className={agentStyles.label} htmlFor='seller'>
											Seller’s Percentage
										</label>
										<SvgSprite
											data-tip={toolTipsText.seller}
											data-for='agentViewTooltip'
										/>
									</div>
									<div className={agentStyles.agentFormInput}>
										<Input
											value={values.seller}
											onChange={handleChange('seller')}
											id='seller'
											largeness='md'
										/>
									</div>
								</div>
								<div className={agentStyles.formItem}>
									<div className={agentStyles.formTitle}>
										<label
											className={cn('body-md', agentStyles.label)}
											htmlFor='agent'
										>
											Agent’s Percentage
										</label>
										<SvgSprite
											data-tip={toolTipsText.agent}
											data-for='agentViewTooltip'
										/>
									</div>
									<div className={agentStyles.agentFormInput}>
										<Input
											largeness='md'
											value={values.agent}
											onChange={handleChange('agent')}
											id='agent'
										/>
									</div>
								</div>
							</div>
							<Button
								variant='contained'
								color='primary'
								size='large'
								disabled={isDisabled || isNetworkNotMatched}
								isLoader={isLoading}
								className={agentStyles.agentSubmitBtn}
							>
								Send
							</Button>
						</form>
					</div>
				) : ContractStatusNumber[contract.status] ===
				  ContractStatusNumber.dispute_finished ? (
					<>
						<div className={styles.stepContent}>
							<Badge text='sm' color='success' hasIcon>
								The contract dispute has been settled.
							</Badge>
							<div className={cn(styles.copyTextWrapper, 'subtitle')}>
								<span className={styles.bold}>Transaction ID: </span>
								<CopiableLink
									text={txByType?.tx_hash || ''}
									url={`${contract.network.explorer_url}/tx/${
										txByType?.tx_hash || ''
									}`}
								/>
							</div>
						</div>
					</>
				) : (
					// Dior is govnocodit here
					<>
						<div className={styles.stepContent}>
							<Badge text='sm' color='await' hasIcon>
								Awaiting Invitation for Escrow Settlement
							</Badge>
							<div className={cn(styles.copyTextWrapper, 'subtitle')}>
								<span className={styles.stepText}>
									Please wait for an invitation from either the buyer or the
									seller to assist with the escrow settlement.
								</span>
							</div>
						</div>
					</>
				)}
				<Tooltip id='agentViewTooltip' />
				<Tooltip id='contractStepTooltip' />
			</div>
		</StepContainer>
	);
};

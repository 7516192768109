import React from 'react';
import cn from 'classnames';
import Moment from 'react-moment';

import { SvgSprite } from '@components/index';

import { BubbleProps } from './Bubble.props';

import styles from './Bubble.module.scss';

export const Bubble = ({
	position = 'end',
	children,
	hasIcon = false,
	unread = true,
	date,
	dateFormat = 'HH:mm',
	color = 'secondary',
	className
}: BubbleProps) => {
	return (
		<p
			className={cn(
				'subtitle',
				styles.bubble,
				styles[position],
				styles[color],
				className
			)}
		>
			<span className={cn({ [styles.flexCenter]: position === 'center' })}>
				{children}
			</span>
			{(date || hasIcon) && (
				<span className={cn('caption', styles.date)}>
					{date && <Moment format={dateFormat}>{date}</Moment>}
					{hasIcon && (
						<SvgSprite
							className={styles.checked}
							iconId={unread ? 'checked' : 'doublechecked'}
						/>
					)}
				</span>
			)}
		</p>
	);
};

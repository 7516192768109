import { API } from '@core/config/api';
import {
	FetchOptions,
	IUser,
	IUserGetResponse,
	IWhoAmIResponse
} from '@core/models/interfaces';
import { getSearchParams } from '@core/utils/helpers';
import { getCookie } from '@core/utils/helpers/getCookie';
import { logErrors } from '@core/utils/logErrors';

export const Users = {
	async getAll(queryParams: {
		[key: string]: string | number;
	}): Promise<IUserGetResponse | void> {
		const assignedParams = new URLSearchParams(getSearchParams(queryParams));
		try {
			const response = await fetch(`${API.users}?${assignedParams}`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async get(id: string, options?: FetchOptions): Promise<IUser | void> {
		try {
			const response = await fetch(`${API.users}${id}/`, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				signal: options?.signal,
				credentials: 'include'
			});
			if (!response.ok) {
				logErrors(await response.json());
				return Promise.reject('error');
			}

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},
	async patch({
		id,
		formData
	}: {
		id: string;
		formData: FormData;
	}): Promise<IUser | void> {
		try {
			const response = await fetch(`${API.users}${id}/`, {
				method: 'PATCH',
				credentials: 'include',
				headers: {
					'X-CSRFToken': getCookie('csrftoken')
				},
				body: formData
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	},

	async whoAmI(): Promise<IWhoAmIResponse | void> {
		try {
			const response = await fetch(API.whoAmI, {
				headers: {
					'Content-Type': 'application/json',
					'X-CSRFToken': getCookie('csrftoken')
				},
				credentials: 'include'
			});

			if (!response.ok) return logErrors(await response.json());

			return await response.json();
		} catch (e) {
			logErrors(e);
		}
	}
};

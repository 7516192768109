import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserGroupName } from '@core/models/enums';
import { INetwork, IToken, IWhoAmIResponse } from '@core/models/interfaces';
import { getCookie } from '@core/utils/helpers/getCookie';

import { AuthReducerState } from './auth.types';

export const initialState: AuthReducerState = {
	isLogged: !!getCookie('is_authenticated'),
	metamaskAddress: '',
	network: '',
	chain_id: '',
	role: null,
	whoAmI: {
		avatar: '',
		balance: '',
		bio: '',
		cover: '',
		email: '',
		is_superuser: false,
		is_staff: false,
		is_active: false,
		date_joined: '',
		last_login: '',
		first_name: '',
		last_name: '',
		nickname: '',
		rating: {
			total: 0,
			average: '0.0',
			count: 0,
			percentage: '0.0'
		},
		user_permissions: [],
		id: 0,
		groups: [],
		username: '',
		referral_code: '',
		referral_balance: ''
	},
	networks: null,
	tokens: null,
	isUnknownNetwork: false,
	maintenance: {
		isActive: false,
		message: ''
	}
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLogged: (state, action: PayloadAction<boolean>) => {
			return { ...state, isLogged: action.payload };
		},
		setMetamaskAddress: (state, action: PayloadAction<string>) => {
			return { ...state, metamaskAddress: action.payload.toLowerCase() };
		},
		setChainIdAction: (state, action: PayloadAction<string>) => {
			return {
				...state,
				chain_id: action.payload,
				isUnknownNetwork: !state.networks?.find(
					n => n.chain_id.toLowerCase() === action.payload.toLowerCase()
				)
			};
		},
		saveReceivedNetworks: (state, action: PayloadAction<INetwork[]>) => {
			if (action.payload) {
				return {
					...state,
					networks: action.payload,
					isUnknownNetwork: !action.payload.find(
						(n: INetwork) => n.chain_id === state.chain_id
					)
				};
			}
			return state;
		},
		saveReceivedTokens: (state, action: PayloadAction<IToken[]>) => {
			if (action.payload) {
				return {
					...state,
					tokens: action.payload
				};
			}
		},
		setRole: (state, action: PayloadAction<AuthReducerState['role']>) => {
			return { ...state, role: action.payload };
		},
		setNetworkAction: (
			state,
			action: PayloadAction<Pick<INetwork, 'display_name' | 'chain_id'>>
		) => {
			return {
				...state,
				network: action.payload.display_name,
				chain_id: action.payload.chain_id,
				isUnknownNetwork: !state.networks?.find(
					n =>
						n.chain_id.toLowerCase() === action.payload.chain_id.toLowerCase()
				)
			};
		},
		setWhoAmI: (state, action: PayloadAction<IWhoAmIResponse>) => {
			const isAgent =
				action.payload.groups.findIndex(g => {
					return (
						g.name === UserGroupName.Agent || g.name === UserGroupName.Zen_Agent
					);
				}) >= 0;
			return { ...state, whoAmI: { ...action.payload, isAgent } };
		},
		setUserBalance: (state, action: PayloadAction<string>) => {
			state.whoAmI.balance = action.payload;
		},
		setUserReferralBalance: (state, action: PayloadAction<string>) => {
			state.whoAmI.referral_balance = action.payload;
		},
		setIsUnknownNetwork: (state, action: PayloadAction<boolean>) => {
			return { ...state, isUnknownNetwork: action.payload };
		},
		setMaintenance: (
			state,
			action: PayloadAction<{ isActive: boolean; message: string }>
		) => {
			return { ...state, maintenance: action.payload };
		}
	}
});

export const {
	actions: {
		setWhoAmI,
		setNetworkAction,
		setLogged,
		setMetamaskAddress,
		setChainIdAction,
		saveReceivedNetworks,
		saveReceivedTokens,
		setRole,
		setMaintenance,
		setUserBalance,
		setUserReferralBalance
	},
	reducer: authReducer
} = authSlice;
